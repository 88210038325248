import * as React from "react"
import {
  DirectionFromOrigin,
  type EntityParty,
  type IntervalOrigin,
  type PartyRole,
  type TransactionSummary,
  useSummarizeTransactionsByTimeLazyQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"

export interface MostActiveSummary {
  role: PartyRole
  summaries: TransactionSummary[]
  totalCount: number
}

export function usePartyMostActiveSummary(party: EntityParty, origin: IntervalOrigin) {
  const viewKey = useViewVersion()
  const [summaryData, setSummaryData] = React.useState<MostActiveSummary>()
  const [getSummary] = useSummarizeTransactionsByTimeLazyQuery()

  const fetchAllRoleSummaries = React.useCallback(async () => {
    const roles = party.roles?.filter((role) => SUPPORTED_PARTY_ROLES.includes(role))
    if (!roles?.length) return

    const summaryPromises = roles.map(async (role) => {
      const { data } = await getSummary({
        variables: {
          filter: {
            viewKey,
            partyId: party.id,
            partyRole: role,
          },
          origin: {
            ...origin,
            intervalCount: 12,
          },
          direction: DirectionFromOrigin.Past,
        },
      })

      if (!data?.dimensionalTransactionSummary.time.length) return null

      const summaries = data.dimensionalTransactionSummary.time.map((t) => t.summary)
      const totalCount = summaries.reduce(
        (sum, summary) => sum + summary.total.transactionsCount,
        0
      )

      return {
        role,
        summaries,
        totalCount,
      }
    })

    const results = await Promise.all(summaryPromises)

    // Find the role with most transactions
    const mostActive = results.reduce((max, current) => {
      if (!max || (current && current.totalCount > max.totalCount)) {
        return current
      }
      return max
    }, null)

    if (mostActive) {
      setSummaryData(mostActive)
    }
  }, [getSummary, origin, party.id, party.roles, viewKey])

  React.useEffect(() => {
    fetchAllRoleSummaries()
  }, [fetchAllRoleSummaries])

  return summaryData
}
