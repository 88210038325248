import * as React from "react"
import { type SnackbarProps } from "@digits-shared/DesignSystem/Snackbar"
import { DeferredSnackbar } from "src/frontend/components/Shared/ManagedModals/DeferredSnackbar"
import {
  type Dismissal,
  type ManagedSnackbarName,
} from "src/frontend/components/Shared/ManagedModals/state/types"
import {
  useHideSnackbar,
  useWasSnackbarDismissed,
} from "src/frontend/components/Shared/ManagedModals/useManagedSnackbar"

/**
 * Pass `dismissal={Dismissal.Session}` and the snackbar will remain dismissed until the page is reloaded.
 * Pass `dismissal={Dismissal.Persistent}` and the snackbar will remain dismissed across refreshes.
 * Snackbar will hide automatically when a ManagedModal is present on screen.
 * Pass hideOnActiveDetailsView: true and the movdal will hide if a details view is activated
 */
export const ManagedSnackbar: React.FC<
  SnackbarProps & {
    name: ManagedSnackbarName
    dismissal: Dismissal
    hideOnActiveDetailsView?: boolean
  }
> = ({ name, dismissal, onDismiss, ...rest }) => {
  const wasSnackbarDismissed = useWasSnackbarDismissed(name)
  const hideSnackbar = useHideSnackbar(name, dismissal)
  const chainedOnDismissed = React.useCallback(() => {
    if (dismissal) {
      hideSnackbar()
    }
    onDismiss?.()
  }, [onDismiss, hideSnackbar, dismissal])

  // eslint-disable-next-line react/jsx-props-no-spreading
  return !wasSnackbarDismissed && <DeferredSnackbar onDismiss={chainedOnDismissed} {...rest} />
}
