import React from "react"
import { usePrevious } from "react-use"
import { ViewType } from "@digits-graphql/frontend/graphql-public"
import envHelper from "@digits-shared/helpers/envHelper"
import { storage } from "@digits-shared/helpers/storage/storage"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import type FrontendSession from "src/frontend/session"

/*
  CONSTANTS
*/
const OLD_VIEW_TYPE_STORAGE_NAMESPACE = "view-type"
const VIEW_TYPE_STORAGE_NAMESPACE = "stored-view-type"
// Digits on Digits Legal Entity ID
const DIGITS_LEGAL_ENTITY_ID = "e17d700b-b2c0-432b-9678-471d6e66a4b7"

/*
  INTERFACES
*/

export interface ViewTypeContextVal {
  viewType: ViewType
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
}

/*
  CONTEXT
*/

export const ViewTypeContext = React.createContext<ViewTypeContextVal>({
  setViewType: (vt: ViewType) => {},
  viewType: ViewType.Unknown,
})

/*
  PROVIDER
*/

export const ViewTypeContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  useClearUnusedStoredViewType()

  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const prevLegalEntityId = usePrevious(legalEntityId)

  const shouldResetViewType = prevLegalEntityId !== legalEntityId

  const initialView = useInitialViewType()
  const [viewType, setViewType] = React.useState<ViewType>(initialView)
  const { leViewNS } = useViewTypeNamespace()
  const hasAIBookkeeper = useHasAIBookkeeper()

  // Reset view type to initialView when legal entity changes
  React.useEffect(() => {
    if (shouldResetViewType) setViewType(initialView)
  }, [shouldResetViewType, initialView])

  // Store view type in local storage for current legal entity
  const setAndStoreViewType = React.useCallback(
    (vt: ViewType) => {
      let newVT = vt
      if (!hasAIBookkeeper && vt === ViewType.AIBookkeeper) {
        newVT = ViewType.Ledger
      }
      storage.local.setItem(leViewNS, newVT)
      setViewType(newVT)
    },
    [hasAIBookkeeper, leViewNS]
  )

  useUpdateViewTypeOnLEChange(setAndStoreViewType)

  const value = React.useMemo(
    () => ({
      viewType: shouldResetViewType ? initialView : viewType,
      setViewType: setAndStoreViewType,
    }),
    [initialView, setAndStoreViewType, shouldResetViewType, viewType]
  )

  return <ViewTypeContext.Provider value={value}>{children}</ViewTypeContext.Provider>
}

/*
  HOOKS
 */

export function useViewTypeContext() {
  return React.useContext(ViewTypeContext)
}

export function useViewType(viewTypeOverride?: ViewType) {
  const { viewType } = useViewTypeContext()

  if (viewTypeOverride) {
    return viewTypeOverride
  }

  return viewType
}

// View Type is stored in local storage for each legal entity
const useViewTypeNamespace = () => {
  const { currentLegalEntity } = useSession<FrontendSession>()

  // TODO: Remove this after a few sprints
  const oldView = storage.local.getItem(OLD_VIEW_TYPE_STORAGE_NAMESPACE)
  const oldLeViewNS = `${OLD_VIEW_TYPE_STORAGE_NAMESPACE}-${currentLegalEntity?.id}`
  const oldLeView = storage.local.getItem(oldLeViewNS)
  const leViewNS = `${VIEW_TYPE_STORAGE_NAMESPACE}-${currentLegalEntity?.id}`
  if (oldView) {
    storage.local.removeItem(OLD_VIEW_TYPE_STORAGE_NAMESPACE)
  }
  if (oldLeView) {
    storage.local.removeItem(`${OLD_VIEW_TYPE_STORAGE_NAMESPACE}-${currentLegalEntity?.id}`)
  }

  return React.useMemo(
    () => ({
      leViewNS,
      oldViewNS: OLD_VIEW_TYPE_STORAGE_NAMESPACE,
      oldLeViewNS,
    }),
    [leViewNS, oldLeViewNS]
  )
}

const useHasAIBookkeeper = () => {
  const { currentLegalEntity, sharingViewType } = useSession<FrontendSession>()

  return sharingViewType
    ? sharingViewType === ViewType.AIBookkeeper
    : !!currentLegalEntity?.hasAccessToAspect(AspectCode.AIBookkeeper)
}

const useLocalViewType = () => {
  const { leViewNS } = useViewTypeNamespace()

  return React.useMemo(() => {
    const view = storage.local.getItem(leViewNS)
    return view && view in ViewType ? ViewType[view as ViewType] : undefined
  }, [leViewNS])
}

const useClearUnusedStoredViewType = () => {
  const { oldViewNS, oldLeViewNS, leViewNS } = useViewTypeNamespace()
  const oldView = storage.local.getItem(OLD_VIEW_TYPE_STORAGE_NAMESPACE)
  const oldLeView = storage.local.getItem(oldLeViewNS)
  const view = storage.local.getItem(leViewNS)

  // Delete previously stored view-type preferences since we've removing the toggle
  // for Digits employees except for Digits Internal Accounting org (but they may have previously set and stored a preference)
  // TODO: Remove old view checks after a few sprints
  if (oldView) {
    storage.local.removeItem(oldViewNS)
  }
  if (oldLeView) {
    storage.local.removeItem(oldLeViewNS)
  }

  if (!useCanToggleViewType() && view) {
    storage.local.removeItem(leViewNS)
  }
}

const useInitialViewType = () => {
  const hasAIBookkeeper = useHasAIBookkeeper()
  const localViewType = useLocalViewType()

  // Fall back to Ledger view type if AIBookkeeper aspect is not turned
  return !hasAIBookkeeper ? ViewType.Ledger : localViewType || ViewType.AIBookkeeper
}

const useUpdateViewTypeOnLEChange = (setView: (view: ViewType) => void) => {
  const initialView = useInitialViewType()

  React.useEffect(() => {
    setView(initialView)
  }, [initialView, setView])
}

export const useCanToggleViewType = () => {
  const session = useSession<FrontendSession>()
  const { hasAccessToAspect } = session
  const isDoD = session.currentLegalEntityId === DIGITS_LEGAL_ENTITY_ID

  return (
    hasAccessToAspect(AspectCode.AIBookkeeper) &&
    // Only allow digits employees logged in and viewing DoD to toggle view type
    (envHelper.isProduction() ? session.isDigitsEmployee && isDoD : true)
  )
}
