import * as React from "react"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import { AnimatePresence, m } from "framer-motion"
import styled from "styled-components"
import {
  type Chip,
  type ChipHoverCallback,
  type SuggestionAnimProps,
  suggestionsVariants,
  suggestionVariants,
} from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"
import { SuggestionChip } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/SuggestionChip"
import { type SearchQuerySource } from "src/frontend/components/OS/Applications/Search/shared"
import type FrontendSession from "src/frontend/session/FrontendSession"

const SuggestionsContainer = styled(m.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: opacity 200ms ease-out;
`

const Suggestions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
`

const Label = styled(m.div)<SuggestionAnimProps>`
  flex-shrink: 0;
  font-size: 12px;
  color: ${colors.secondary};
  margin-right: 20px;
`

/*
  INTERFACES
*/

interface SuggestionChipsProps {
  labelText: string
  hide: boolean
  suggestions: string[]
  onHoveredChipChanged?: ChipHoverCallback
  source: SearchQuerySource
}

/*
  COMPONENTS
*/

export const SuggestionChips: React.FC<SuggestionChipsProps> = ({
  labelText,
  hide,
  suggestions,
  onHoveredChipChanged,
  source,
}) => {
  const { currentLegalEntity } = useSession<FrontendSession>()

  const { mouseEnterChip, mouseLeaveChip } = useChipHoverTracking(onHoveredChipChanged)

  const fadeOut = !suggestions.length || hide

  return (
    <SuggestionsContainer
      key={currentLegalEntity?.slug}
      variants={suggestionsVariants}
      animate={fadeOut ? "exiting" : "entering"}
    >
      <Suggestions>
        <AnimatePresence>
          {!suggestions.length
            ? []
            : [
                <Label
                  key="label"
                  fadeOut={fadeOut}
                  animIndex={0}
                  variants={suggestionVariants}
                  initial="exiting"
                  animate="entering"
                  exit="exiting"
                >
                  {labelText}
                </Label>,
                ...suggestions.map((suggestion, i) => (
                  <SuggestionChip
                    key={suggestion}
                    text={suggestion}
                    source={source}
                    fadeOut={fadeOut}
                    dataIndex={i}
                    // animIndex offset by one provide index 0 to the label above
                    animIndex={i + 1}
                    onMouseEnterChip={mouseEnterChip}
                    onMouseLeaveChip={mouseLeaveChip}
                  />
                )),
              ]}
        </AnimatePresence>
      </Suggestions>
    </SuggestionsContainer>
  )
}

function useChipHoverTracking(onHoveredChipChanged?: (chip: Chip | undefined) => void) {
  const { location } = useRouter()
  const [hoveredChip, setHoveredChip] = React.useState<Chip | undefined>()

  // Defer clearing the hovered chip so that the pop-over stays up if you mouse between them
  const [deferredClearHoveredChip, deferredClearRef] = useLazyTimeout((chip: Chip) => {
    // Don't clear the hovered state if another chip has already grabbed it
    if (hoveredChip === chip) {
      setHoveredChip(undefined)
    }
  }, 300)

  const mouseEnterChip = React.useCallback(
    (chip: Chip) => {
      if (deferredClearRef.current) clearTimeout(deferredClearRef.current)
      setHoveredChip(chip)
    },
    [deferredClearRef]
  )

  // Call the callback, if provided, when the hovered chip changes.
  React.useEffect(() => {
    onHoveredChipChanged?.(hoveredChip)
  }, [hoveredChip, onHoveredChipChanged])

  // Important to clear the hovered chip as we're navigating, otherwise it will briefly appear
  // again when we return.
  React.useEffect(() => {
    setHoveredChip(undefined)
  }, [location.key])

  return React.useMemo(
    () => ({
      hoveredChip,
      mouseEnterChip,
      mouseLeaveChip: deferredClearHoveredChip,
    }),
    [deferredClearHoveredChip, hoveredChip, mouseEnterChip]
  )
}
