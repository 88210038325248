import * as React from "react"
import { type ApolloError } from "@apollo/client"
import {
  type IntervalOrigin,
  type ListActivePartyRolesQuery,
  PartyRole,
  type ReadPartyQuery,
  useListActivePartyRolesQuery,
  useReadPartyQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import colorHelper from "@digits-shared/helpers/colorHelper"
import envHelper from "@digits-shared/helpers/envHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { FrontendPartyRole, SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"
import {
  useVibrantBackground,
  type VibrantBackgroundValues,
} from "src/shared/components/Elements/VibrantBackground"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { PARTY_HOVER_WIDTH } from "src/shared/components/PartyHover/Styles"
import {
  type PartyHoverState,
  usePartyIconHoverState,
} from "src/shared/components/PartyHover/usePartyIconHoverState"
import transitions from "src/shared/config/transitions"
import PartyDetails from "./Details"

export const DEFAULT_PARTY_ICON_SIZE = IconSize.Medium

/*
  STYLES
*/

export const WithPartyHover = styled.div<{ showPointer?: boolean }>`
  position: relative;
  align-items: center;
  ${({ showPointer }) =>
    showPointer &&
    css`
      cursor: pointer;
    `}
`

const PartyIconHoverStyled = styled.div<{
  isPreview: boolean
  isHoverVisible: boolean
  partyIconSize?: IconSize
}>`
  transition: ${transitions.defaultFadeIn.duration} opacity;
  width: ${PARTY_HOVER_WIDTH}px;
  cursor: auto;

  ${(props) =>
    props.isHoverVisible
      ? css`
          opacity: 1;
          pointer-events: auto;
          z-index: ${zIndexes.tooltip};
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `};

  ${(props) =>
    props.isPreview
      ? css`
          position: relative;
          margin-top: -30px;
        `
      : css`
          position: absolute;
          left: ${props.partyIconSize === IconSize.MediumLarge ? -15 : -17}px;
        `}

  &::before {
    position: absolute;
    height: 10px;
    width: 100%;
    content: "";
  }

  border-radius: ${borders.theme.dark.radius.modal}px;
  background-color: ${colors.white};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
`

const PartyHoverDown = styled(PartyIconHoverStyled)`
  top: calc(100% + 7px);

  &::before {
    top: -10px;
  }
`

const PartyHoverDownLeft = styled(PartyIconHoverStyled)`
  top: calc(100% + 7px);
  left: ${-PARTY_HOVER_WIDTH + 50}px;

  &::before {
    top: -10px;
  }
`

const PartyHoverUp = styled(PartyIconHoverStyled)`
  ${(props) =>
    !props.isPreview &&
    css`
      bottom: calc(100% + 7px);
    `};

  &::before {
    bottom: -10px;
  }
`

const PartyHoverUpLeft = styled(PartyIconHoverStyled)`
  left: ${-PARTY_HOVER_WIDTH + 50}px;
  ${(props) =>
    !props.isPreview &&
    css`
      bottom: calc(100% + 7px);
    `};

  &::before {
    bottom: -10px;
  }
`

const CHEVRON_SIZE = 7

const CHEVRON_STYLES = css<PartyContainerProps>`
  border-left: ${CHEVRON_SIZE}px solid ${colors.transparent};
  border-right: ${CHEVRON_SIZE}px solid ${colors.transparent};
  content: "";
  height: 0;
  position: absolute;
  left: ${(props) =>
    props.partyIconSize === IconSize.Small
      ? props.chevronOnRight
        ? "383px"
        : "20px"
      : props.chevronOnRight
        ? "383px"
        : "25px"};
  width: 0;
`

const topChevronStyles = (props: PartyContainerProps) => css<PartyContainerProps>`
  &::after {
    ${CHEVRON_STYLES};

    border-bottom: ${CHEVRON_SIZE}px solid ${props.backgroundColor};
    top: -${CHEVRON_SIZE}px;
  }
`

const bottomChevronStyles = (props: PartyContainerProps) => css<PartyContainerProps>`
  &::after {
    ${CHEVRON_STYLES};

    ${({ partyActivityPresent }) =>
      partyActivityPresent
        ? css`
            border-top: ${CHEVRON_SIZE}px solid ${colors.white};
          `
        : css`
            border-top: ${CHEVRON_SIZE}px solid ${props.backgroundColor};
          `};

    bottom: -${CHEVRON_SIZE}px;
  }
`

const PartyContainer = styled.div<PartyContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) => (props.chevronOnTop ? topChevronStyles(props) : bottomChevronStyles(props))}
`

const Message = styled.div`
  display: flex;
  align-items: center;
  min-height: 200px;
  font-size: 16px;
`

const ErrorStyled = styled(Message)`
  color: ${colors.orange};
`

/*
  INTERFACES
*/

type PartyResults = {
  loading: boolean
  categorySummaryIntervalOrigin?: IntervalOrigin
  partyData?: ReadPartyQuery
  activeRolesData?: ListActivePartyRolesQuery
  error?: ApolloError
}

interface PartyContainerProps {
  backgroundColor: string
  chevronOnTop: boolean
  chevronOnRight: boolean
  partyIconSize?: IconSize
  partyActivityPresent?: boolean
}

interface PartyIconDisplayFields {
  id: string
  roles?: PartyRole[] | null
  iconUrl?: string | null
  iconBlob?: string
}

interface PartyIconHoverProps {
  legalEntityId?: string
  party: PartyIconDisplayFields
  partyIconSize?: IconSize
  isPreview?: boolean
  className?: string
  // Specified as part of Props instead of using `withTimeContext` because we sometimes want to pass
  // an interval origin that is different from what is on the context
  // `undefined` implies not fetching or rendering transaction category summary
  intervalOrigin?: IntervalOrigin
  partyImageRef?: React.RefObject<HTMLImageElement>
  neverHover?: boolean
  withClickTarget?: boolean
}

interface ShowPartyProps {
  partyDetails: PartyResults
  vibrantBackground?: VibrantBackgroundValues
  partyRole: PartyRole
  transactionParty: PartyIconDisplayFields
  partyIconSize?: IconSize
  partyImageRef?: React.RefObject<HTMLImageElement>
  state: PartyHoverState
  // intervalOriginal undefined implies not fetching or rendering transaction category summary
  intervalOrigin?: IntervalOrigin
}

/*
  COMPONENT
*/

export const PartyIconHover: React.FC<PartyIconHoverProps> = ({
  legalEntityId,
  party,
  intervalOrigin,
  partyIconSize,
  isPreview,
  partyImageRef,
  className,
  neverHover,
  withClickTarget,
}) => {
  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()

  const { state, elementRef, onMouseEnter, onMouseLeave, preventClickBubbling, shouldShowHover } =
    usePartyIconHoverState(party.id, !!isPreview, !!neverHover)
  const { shouldHoverDown, shouldHoverLeft } = state

  const partyRole = React.useMemo(() => {
    const supportedRoles = party.roles
      ? party.roles.filter((role) => SUPPORTED_PARTY_ROLES.includes(role))
      : []
    return supportedRoles[0] ?? PartyRole.UnknownRole
  }, [party])

  const onPartyIconClick = React.useCallback(() => {
    if (!withClickTarget) return
    if (partyRole === PartyRole.UnknownRole) return

    history.push(
      generatePath(routes.partyDetails, {
        partyId: party.id,
        partyRole: FrontendPartyRole.findByRole(partyRole).urlKey,
      })
    )
  }, [withClickTarget, history, generatePath, party.id, partyRole])

  const PartyHoverComponent = shouldHoverDown
    ? shouldHoverLeft
      ? PartyHoverDownLeft
      : PartyHoverDown
    : shouldHoverLeft
      ? PartyHoverUpLeft
      : PartyHoverUp

  return (
    <div
      ref={elementRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={preventClickBubbling}
      className={className}
    >
      <WithPartyHover showPointer={!!withClickTarget} onClick={onPartyIconClick}>
        <PartyIcon
          size={partyIconSize || DEFAULT_PARTY_ICON_SIZE}
          party={party}
          partyImageRef={partyImageRef}
        />
        {shouldShowHover && (
          <PartyHoverComponent
            partyIconSize={partyIconSize}
            isHoverVisible={shouldShowHover}
            isPreview={!!isPreview}
          >
            <PartyIconWithQuery
              legalEntityId={legalEntityId}
              party={party}
              partyRole={partyRole as PartyRole}
              state={state}
              partyImageRef={partyImageRef}
              partyIconSize={partyIconSize}
              intervalOrigin={intervalOrigin}
              isPreview={isPreview}
            />
          </PartyHoverComponent>
        )}
      </WithPartyHover>
    </div>
  )
}

const PartyIconWithQuery: React.FC<
  PartyIconHoverProps & { partyRole: PartyRole; state: PartyHoverState }
> = ({
  legalEntityId,
  state,
  party,
  partyRole,
  partyIconSize,
  intervalOrigin,
  isPreview,
  partyImageRef,
}) => {
  const partyDetails = useReadPartyWithDetails({
    party,
    legalEntityId,
    isPreview,
    intervalOrigin,
  })

  const imageUrl = partyDetails.partyData?.readParty?.iconUrl || party.iconBlob || party.iconUrl
  const vibrantBackground = useVibrantBackground(imageUrl)

  if (!legalEntityId && isPreview)
    return (
      <PartyPreview
        partyDetails={partyDetails}
        partyRole={partyRole}
        transactionParty={party}
        state={state}
        partyImageRef={partyImageRef}
        partyIconSize={partyIconSize}
        intervalOrigin={intervalOrigin}
      />
    )

  if (!legalEntityId) {
    if (envHelper.isDevelopment()) {
      throw new Error("must set legal entity id for party hover that is not a preview")
    }
    return null
  }

  const Component = isPreview ? PartyPreview : PartyWithQueryResults
  return (
    <Component
      partyDetails={partyDetails}
      vibrantBackground={vibrantBackground}
      partyRole={partyRole}
      transactionParty={party}
      state={state}
      partyImageRef={partyImageRef}
      partyIconSize={partyIconSize}
      intervalOrigin={intervalOrigin}
    />
  )
}

const PartyPreview: React.FC<ShowPartyProps> = ({
  partyDetails,
  vibrantBackground,
  partyIconSize,
  partyImageRef,
  intervalOrigin,
  state,
}) => {
  const { loading } = partyDetails
  const invertValues = useInvertValues()
  const backgroundColor =
    (vibrantBackground && getBackgroundColor(vibrantBackground)) || colors.offBlack

  return (
    <PartyContainer
      chevronOnTop={state.shouldHoverDown}
      chevronOnRight={state.shouldHoverLeft}
      backgroundColor={backgroundColor}
      partyIconSize={partyIconSize}
    >
      <PartyDetails
        isPreview
        intervalOrigin={intervalOrigin}
        isLoading={vibrantBackground?.isLoading || loading || false}
        partyImageRef={partyImageRef}
        invertValues={invertValues}
        backgroundColor={backgroundColor}
        backgroundImage={vibrantBackground?.backgroundImage}
        textColor={vibrantBackground?.textColor || colors.white}
      />
    </PartyContainer>
  )
}

const PartyWithQueryResults: React.FC<ShowPartyProps> = ({
  partyDetails,
  partyRole,
  state,
  intervalOrigin,
  transactionParty,
  partyIconSize,
  vibrantBackground,
}) => {
  const { partyData, activeRolesData, loading, error } = partyDetails

  const backgroundColor =
    (vibrantBackground && getBackgroundColor(vibrantBackground)) || colors.offBlack
  const invertValues = useInvertValues()

  const partyDetailsPath = React.useMemo(() => {
    let role = partyRole

    // Try to fall back to any other party role this party has played
    if (role === PartyRole.UnknownRole) {
      const activeRoles = activeRolesData?.listActivePartyRoles
      if (!activeRoles?.length) return undefined
      role = SUPPORTED_PARTY_ROLES.find((r) => activeRoles.includes(r)) ?? PartyRole.UnknownRole
    }

    if (loading || role === PartyRole.UnknownRole) return undefined

    return routes.partyDetails.generateFromCurrentPath({
      partyRole: FrontendPartyRole.roleURLKey(role),
      partyId: transactionParty.id,
      ...intervalOrigin,
    })
  }, [
    activeRolesData?.listActivePartyRoles,
    intervalOrigin,
    loading,
    partyRole,
    transactionParty.id,
  ])

  const supportedPartyDetailsPath = SUPPORTED_PARTY_ROLES.includes(partyRole)
    ? partyDetailsPath
    : undefined

  const party = partyData?.readParty
  if (!loading && !party) return null

  if (error) return <ErrorStyled>{error.toString()}</ErrorStyled>

  return (
    <PartyContainer
      chevronOnTop={state.shouldHoverDown}
      chevronOnRight={state.shouldHoverLeft}
      backgroundColor={backgroundColor}
      partyIconSize={partyIconSize}
    >
      <PartyDetails
        intervalOrigin={intervalOrigin}
        isLoading={loading || !!vibrantBackground?.isLoading}
        party={partyData?.readParty}
        partyRole={partyRole}
        partyDetailsPath={supportedPartyDetailsPath}
        invertValues={invertValues}
        backgroundColor={backgroundColor}
        backgroundImage={vibrantBackground?.backgroundImage}
        textColor={vibrantBackground?.textColor || colors.white}
      />
    </PartyContainer>
  )
}

function getBackgroundColor(vibrantBackground: VibrantBackgroundValues) {
  if (vibrantBackground.backgroundColor) return vibrantBackground.backgroundColor
  if (!vibrantBackground.backgroundColor) return colorHelper.hexToRgba(colors.offBlack, 0.9)

  return vibrantBackground.backgroundColor
}

function useReadPartyWithDetails({
  party,
  legalEntityId,
  isPreview,
  intervalOrigin,
}: {
  party: PartyIconDisplayFields
  legalEntityId?: string
  isPreview?: boolean
  intervalOrigin?: IntervalOrigin
}) {
  const viewKey = useViewVersion({ legalEntityId })

  const {
    data: partyData,
    loading: partyLoading,
    error: partyError,
  } = useReadPartyQuery({
    variables: {
      partyId: party.id,
      viewKey,
    },
    skip: isPreview || !legalEntityId,
  })

  const {
    data: activeRolesData,
    loading: activeRolesLoading,
    error: activeRolesError,
  } = useListActivePartyRolesQuery({
    variables: {
      filter: {
        viewKey,
        partyId: party.id,
      },
    },
    context: { avsBatch: true },
  })

  return React.useMemo(
    (): PartyResults => ({
      partyData,
      activeRolesData,
      loading: partyLoading || activeRolesLoading,
      error: partyError || activeRolesError,
    }),
    [partyData, activeRolesData, partyLoading, activeRolesLoading, partyError, activeRolesError]
  )
}
