import * as React from "react"
import ReactDOM from "react-dom"
import { useEvent } from "react-use"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import styled from "styled-components"

const positionElement = (props: ModalPosition) => {
  const top = `${props.top + 8}px`
  const left = `${props.left}px`
  const minHeight = `${props.minHeight}px`

  if (!props.top) {
    return { style: { opacity: 0 } }
  }

  return {
    style: {
      top,
      left,
      minHeight,
    },
  }
}

const FixedPosition = styled.div.attrs<ModalPosition>(positionElement)`
  position: fixed;
  z-index: ${zIndexes.modalOverlay};
`

interface ModalPosition {
  top: number
  left: number
  minHeight: number | undefined
}

export const EntityPopOverPortal = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ popUpRef: React.RefObject<HTMLDivElement | null> }>
>(({ popUpRef, children }, ref) => {
  const modalRoot = useModalRoot()
  const { anchorRef, position } = usePortalState(popUpRef)

  return (
    <>
      <div ref={anchorRef} />
      {ReactDOM.createPortal(
        <FixedPosition
          ref={ref}
          top={position?.top ?? 0}
          left={position?.left ?? 0}
          minHeight={position?.minHeight ?? 0}
        >
          {children}
        </FixedPosition>,
        modalRoot
      )}
    </>
  )
})

function usePortalState(popUpRef: React.RefObject<HTMLDivElement | null>) {
  const [position, setPosition] = React.useState<ModalPosition | undefined>(undefined)
  const [anchorRef, rect] = useMeasure<HTMLDivElement>()

  const positionPortal = React.useCallback(() => {
    if (popUpRef.current) {
      let { top, left } = rect
      const { width, height } = popUpRef.current.getBoundingClientRect()

      const pushRight = left + width > window.innerWidth
      if (pushRight) {
        left = window.innerWidth - width - 20
      }

      setPosition((prevState) => ({
        top: top,
        left,
        minHeight: Math.max(prevState?.minHeight ?? 0, height),
      }))
    }
  }, [popUpRef, rect])

  useEvent("resize", positionPortal)
  useEvent("wheel", positionPortal)
  useEvent("mousewheel", positionPortal)

  React.useEffect(() => {
    positionPortal()
  }, [positionPortal])

  return React.useMemo(() => ({ anchorRef, position }), [anchorRef, position])
}
