import * as React from "react"
import {
  ReadPortalLayoutDocument,
  useCreatePortalMutation,
  useReadPortalLayoutQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { shallow } from "zustand/shallow"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { PortalMode } from "src/frontend/components/Shared/Portals/State/types"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export function useFetchPortalAndLayout() {
  const { currentLegalEntityId: legalEntityId, currentLegalEntity: legalEntity } =
    useSession<FrontendSession>()

  const canReadPortal = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Portals,
    flag: JWTPermissionFlag.Read,
  })

  const portalDispatch = usePortalDispatch()
  const { portalMode } = usePortalStore(
    (state) => ({
      portalMode: state.portalMode,
    }),
    shallow
  )
  const viewId = useViewVersion()

  const { data, loading } = useReadPortalLayoutQuery({
    variables: {
      legalEntityId,
    },
    // do not batch with other slow queries, this speeds up home loading times
    context: { noBatch: true },
    skip: !canReadPortal || !legalEntity.hasDashboardAccess(),
  })
  const hasPortal = data?.readPortalLayout.hasPortal

  const [createPortal, { called: createPortalCalled }] = useCreatePortalMutation()

  // Sync data to the store
  React.useEffect(() => {
    const layout = data?.readPortalLayout.layout
    portalDispatch({
      type: "layoutLoading",
      loading: loading,
    })
    if (!loading && layout) {
      portalDispatch({ type: "setLayout", layout })
      portalDispatch({ type: "setPortalMode", mode: PortalMode.Ready })
    }
  }, [data?.readPortalLayout.layout, loading, portalDispatch])

  // Enter bootstrap mode if no portal was found and this is an affiliate user
  React.useEffect(() => {
    if (!loading && hasPortal === false) {
      portalDispatch({ type: "setPortalMode", mode: PortalMode.Bootstrap })
    }
  }, [portalDispatch, loading, hasPortal])

  // When we enter bootstrap mode, create the portal and (re)fetch it
  React.useEffect(
    () => {
      if (!createPortalCalled && hasPortal === false && portalMode === PortalMode.Bootstrap) {
        createPortal({
          variables: {
            viewId,
          },
          // Causes ReadPortal to run again once the hasPortal value changes
          refetchQueries: [ReadPortalLayoutDocument],
          awaitRefetchQueries: true,
        }).catch((e) => {
          TrackJS?.track(e)
        })
      }
    },
    // Avoid re-triggering createPortal if things change which are unrelated to the central logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPortal, portalMode, hasPortal, portalDispatch]
  )
}
