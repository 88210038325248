import * as React from "react"
import { defined } from "@digits-shared/helpers/filters"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

const EXCLUDED_MODULES = [
  routes.root,
  routes.logout,
  routes.legalEntityHome,
  routes.dashboard,
  routes.layoutComponentDetails,
  routes.publicInvoice,
]
  .map((r) => r.moduleName?.({})?.toLocaleLowerCase())
  .filter(defined)

export const DocumentTitle: React.FC<{ title?: string }> = ({ title }) => {
  const session = useSession<FrontendSession>()
  const activeOrg = session.currentAffiliatedOrganization || session.currentOrganization
  const { history: browserHistory } = useRouter()

  const moduleName = React.useMemo(() => {
    const locationName = browserHistory.location.name
    const route = routes[locationName]
    if (!route?.moduleName) return undefined

    // can't use browser params because it only parses our params from the url if they are exactly matching
    const allParams = route.getParametersFromPath(browserHistory.location.pathname) || {}
    const name = route.moduleName(allParams) ?? undefined
    return !name || EXCLUDED_MODULES.includes(name.toLocaleLowerCase()) ? undefined : name
  }, [browserHistory.location])

  const setDocumentTitle = React.useCallback(
    (orgName: string) => {
      document.title = moduleName
        ? `${stringHelper.truncate(orgName, 20)} | ${moduleName}`
        : `${orgName} on Digits`
    },
    [moduleName]
  )

  const revertDocumentTitle = React.useCallback(() => {
    document.title = process.env.TITLE || "Digits"
  }, [])

  React.useEffect(() => {
    const orgName = activeOrg?.name
    if (title) {
      document.title = title ?? revertDocumentTitle()
    } else if (orgName) {
      setDocumentTitle(orgName)
    } else {
      revertDocumentTitle()
    }

    // on unmount restore title
    return () => {
      orgName ? setDocumentTitle(orgName) : revertDocumentTitle()
    }
  }, [activeOrg, setDocumentTitle, revertDocumentTitle, title])

  return null
}
