import * as React from "react"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useRouter from "@digits-shared/hooks/useRouter"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import styled from "styled-components"
import { ApplicationFullWidthPane } from "src/frontend/components/OS/Applications/ApplicationPane"
import { SearchHeader } from "src/frontend/components/OS/Applications/Search/SearchHeader"
import { SearchMarketing } from "src/frontend/components/OS/Applications/Search/SearchMarketing"
import { InsightPopUp } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/PopUp/InsightPopUp"
import { SearchSuggestions } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/SearchSuggestions"
import { type InsightChip } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"
import { FullScreenScrollableContainer } from "src/frontend/components/OS/Applications/Shared/FullScreenScrollableContainer"
import QueryBuilder from "./QueryBuilder"
import SearchQueries from "./SearchQueries"

/*
  STYLES
*/

const CenterContainer = styled.div`
  margin-bottom: 25px;
  padding-top: calc(var(--header-height) + 20px);
`

/*
  COMPONENTS
*/

const SearchApplication: React.FC<React.PropsWithChildren<{}>> = () => {
  const { location } = useRouter()
  const [hoveredInsightRef, setHoveredInsightRef] = React.useState<InsightChip>()
  const { isPopOverOpen: shouldKeepPopUpOpen, onMouseEnter, onMouseLeave } = usePopOverState()

  const { value: isSearchRequestInFlight, setValue: setIsSearchRequestInFlight } = useStateBoolean()

  const hoveredInsightChanged = React.useCallback(
    (insightChip: InsightChip | undefined) => {
      // Prevent clearing the hovered insight ref while the user is moused-over the pop-up.
      //
      // The search suggestions will fire a delayed event on chip mouse leave, setting the
      // chip to undefined. The delay allows for time for the user to mouse over the pop-up
      // itself.
      if (!insightChip && shouldKeepPopUpOpen) return

      setHoveredInsightRef(insightChip)
    },
    [shouldKeepPopUpOpen]
  )

  const builder = React.useMemo(() => new QueryBuilder(location.queryParams), [location])
  const query = builder.build()
  const isSearchActive = query.isValid

  return (
    <ApplicationFullWidthPane>
      <SearchHeader
        builder={builder}
        initialValue={query.text}
        isSearchActive={isSearchActive}
        isSearchRequestInFlight={isSearchRequestInFlight}
      />

      <FullScreenScrollableContainer fullWidth>
        <CenterContainer>
          <SearchSuggestions
            isSearchActive={isSearchActive}
            builder={builder}
            onHoveredInsightChanged={hoveredInsightChanged}
          />

          <MarketingOrInsightPopUp
            isSearchActive={isSearchActive}
            hoveredInsight={hoveredInsightRef}
            onMouseEnterPopUp={onMouseEnter}
            onMouseLeavePopUp={onMouseLeave}
          />
        </CenterContainer>

        <SearchQueries builder={builder} setIsSearchRequestInFlight={setIsSearchRequestInFlight} />
      </FullScreenScrollableContainer>
    </ApplicationFullWidthPane>
  )
}

export default SearchApplication

interface MarketingOrInsightPopUpProps {
  isSearchActive: boolean
  hoveredInsight: InsightChip | undefined
  onMouseEnterPopUp: (e: React.MouseEvent) => void
  onMouseLeavePopUp: (e: React.MouseEvent) => void
}

const MarketingOrInsightPopUp: React.FC<MarketingOrInsightPopUpProps> = ({
  isSearchActive,
  hoveredInsight,
  onMouseEnterPopUp,
  onMouseLeavePopUp,
}) => (
  <>
    {!isSearchActive && hoveredInsight && (
      <InsightPopUp
        insightChip={hoveredInsight}
        onMouseEnterPopUp={onMouseEnterPopUp}
        onMouseLeavePopUp={onMouseLeavePopUp}
      >
        Insight
      </InsightPopUp>
    )}
    <SearchMarketing hide={isSearchActive} />
  </>
)
