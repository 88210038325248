import * as React from "react"
import {
  type DetailsViewContextProps,
  DetailsViewContextProvider,
} from "@digits-shared/components/Contexts/DetailsViewContext"
import { type HistoryAction } from "@digits-shared/components/Router/History"
import { isNavigationEscape } from "@digits-shared/components/UI/Elements/Keys"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import { DIGITS_BACKGROUND } from "@digits-shared/themes/background"
import { m } from "framer-motion"
import styled, { createGlobalStyle, keyframes } from "styled-components"
import { ChromeScrollMemory } from "src/frontend/components/Shared/Chrome/ChromeScrollMemory"

/*
  STYLES
*/

export const DetailsViewTransitions = {
  classNamePrefix: "details-zoom",

  zoomIn: {
    duration: "350ms",
    delay: "150ms",
    rawDuration: 500, // duration + delay
  },

  zoomOut: {
    duration: "350ms",
    delay: "50ms",
    rawDuration: 400, // duration + delay
  },
}

export const DetailsViewKeyFrames = {
  zoomIn: keyframes`
    from { opacity: 0.3; transform: perspective(400px) translate3d(0, 0, 100px); }
    to { opacity: 1; transform: perspective(400px) translate3d(0, 0, 0); }
  `,

  zoomOut: keyframes`
    from { opacity: 0.5; transform: perspective(400px) translate3d(0, 0, 0); }
    to { opacity: 0; transform: perspective(400px) translate3d(0, 0, 100px); }
  `,
}

export const DetailsChrome = styled(m.div)`
  // Needs to be fixed to prevent render bug when screen size is too small
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.modalOverlay};

  ${DIGITS_BACKGROUND};
`

const DetailsChromeGlobalStyles = createGlobalStyle`
  html,
  body,
  main {
    // Prevents the chrome rendered applications (contents behind the details view)
    // from being scrollable. Prevents bug when screen width is small and scroll reveals 
    // the chrome application behind the details view.
    overflow: hidden;
  }
`

interface Props {
  isActive: boolean
  closeView: (action?: HistoryAction) => void
  children?: React.ReactNode
  backLabel?: string
}

interface State {
  overrideBackLabel: string | undefined
}

/*
  COMPONENTS
*/

export class DetailsViewComponent extends React.Component<Props, State> {
  closeModalRef = React.createRef<HTMLDivElement>()

  detailsViewContext: DetailsViewContextProps

  isMounted: boolean

  constructor(props: Props) {
    super(props)

    this.isMounted = false

    this.state = {
      overrideBackLabel: undefined,
    }

    this.detailsViewContext = {
      closeView: this.handleCloseClick,
      hideCloseButton: this.hideCloseButton,
      showCloseButton: this.showCloseButton,
      overrideBackLabel: this.overrideBackLabel,
      backLabel: props.backLabel,
    }
  }

  componentDidMount() {
    this.isMounted = true
    document.addEventListener("keydown", this.handleKeyDown, false)
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.isActive
  }

  componentWillUnmount() {
    this.isMounted = false
    document.removeEventListener("keydown", this.handleKeyDown, false)
  }

  render() {
    const { children, backLabel } = this.props
    const { overrideBackLabel } = this.state

    this.detailsViewContext.backLabel = overrideBackLabel ?? backLabel

    return (
      <ChromeScrollMemory>
        <DetailsViewContextProvider value={this.detailsViewContext}>
          <DetailsChromeGlobalStyles />
          {children}
        </DetailsViewContextProvider>
      </ChromeScrollMemory>
    )
  }

  overrideBackLabel = (label: string | undefined) => {
    if (!this.isMounted) {
      return
    }
    this.setState({
      overrideBackLabel: label,
    })
  }

  hideCloseButton = () => {
    if (this.closeModalRef.current) this.closeModalRef.current.style.display = "none"
  }

  showCloseButton = () => {
    if (this.closeModalRef.current) this.closeModalRef.current.style.display = ""
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (!this.props.isActive) return

    if (isNavigationEscape(event)) {
      this.props.closeView()
    }
  }

  handleCloseClick = () => {
    this.props.closeView()
  }
}
