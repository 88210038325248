import * as React from "react"

export const LazyParticles = React.lazy(() =>
  lazyInitParticles().then(() => import("@tsparticles/react"))
)

async function lazyInitParticles() {
  const { initParticlesEngine } = await import("@tsparticles/react")
  const { loadFull } = await import("tsparticles")
  return initParticlesEngine(async (engine) => {
    await loadFull(engine)
  })
}
