import * as React from "react"
import { Link } from "react-router-dom"
import {
  type EntityCategory,
  type EntityDepartment,
  type EntityParty,
  type IntervalOrigin,
  SummaryLineItem,
} from "@digits-graphql/frontend/graphql-bearer"
import { type PartyRole } from "@digits-graphql/frontend/graphql-public"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { ColorIconStyled } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import styled, { css } from "styled-components"
import { DisclaimerTooltip } from "src/frontend/components/Shared/Layout/Components/Headers/BalancesDisclaimer"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  useCategoryLinkPath,
  useComponentDetailsLinkPath,
  useDepartmentLinkPath,
  useLocationLinkPath,
  usePartyLinkPath,
} from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import type FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

export const PartyIconStyled = styled(PartyIcon)`
  margin-top: -1px;

  ${ColorIconStyled} {
    font-size: 8px;
  }
`

export const CategoryIconStyled = styled(SVGIconComponent)<{ componentSize: ComponentSize }>`
  padding: 2px;
  background: transparent;

  ${categoryIconSizeStyles}
  ${themedSVGPathStyles({ print: colors.black, light: colors.secondary, dark: colors.white }, 1.5)};
`

export const EntityIconStyled = styled(SVGIconComponent)<{ componentSize: ComponentSize }>`
  padding: 2px;
  background: transparent;

  ${categoryIconSizeStyles};
  ${svgPathStyles(colors.secondary)};
`

export const CategoryComponentTitle: React.FC<
  React.PropsWithChildren<{
    category: EntityCategory | undefined
    intervalOrigin: IntervalOrigin
    componentSize: ComponentSize
  }>
> = ({ category, intervalOrigin, componentSize, children }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const categoryPath = useCategoryLinkPath(category, intervalOrigin)

  if (!category) return null

  const title = children || <>{category.name}</>
  if (isSharingContextActive || !categoryPath) return title

  return (
    <ComponentExpandLink to={categoryPath} componentSize={componentSize}>
      {title}
    </ComponentExpandLink>
  )
}

export const CategoryComponentIcon: React.FC<{
  category: EntityCategory | undefined
  intervalOrigin: IntervalOrigin
  componentSize: ComponentSize
}> = ({ category, intervalOrigin, componentSize }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const categoryPath = useCategoryLinkPath(category, intervalOrigin)

  if (!category) return null

  const categoryIcon = (
    <CategoryIconStyled
      componentSize={componentSize}
      subjectDisplayKey={category.displayKey || category.name}
    />
  )

  return !isSharingContextActive && categoryPath ? (
    <Link to={categoryPath}>{categoryIcon}</Link>
  ) : (
    categoryIcon
  )
}

export const PartyComponentTitle: React.FC<
  React.PropsWithChildren<{
    party: EntityParty | undefined
    role: PartyRole | undefined | null
    intervalOrigin: IntervalOrigin | undefined
    componentSize: ComponentSize
  }>
> = ({ party, role, intervalOrigin, componentSize, children }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const partyPath = usePartyLinkPath(party, role, intervalOrigin)

  if (!party) return null

  const title = children || <>{party.name}</>

  const roleText = componentSize.isSmall
    ? ""
    : ` (${FrontendPartyRole.findByRole(role).displayName})`

  const titleRole = (
    <>
      {title}
      {roleText}
    </>
  )

  if (isSharingContextActive || !partyPath) return titleRole
  return (
    <ComponentExpandLink to={partyPath} componentSize={componentSize}>
      {titleRole}
    </ComponentExpandLink>
  )
}

export const PartyComponentIcon: React.FC<{
  party: EntityParty | undefined
  role: PartyRole | undefined | null
  intervalOrigin: IntervalOrigin | undefined
  componentSize: ComponentSize
}> = ({ party, role, intervalOrigin, componentSize }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const partyPath = usePartyLinkPath(party, role, intervalOrigin)

  if (!party) return null

  const size = componentSize.isSmall ? IconSize.Tiny : IconSize.Small

  const partyIcon = <PartyIconStyled size={size} party={party} />
  return !isSharingContextActive && partyPath ? <Link to={partyPath}>{partyIcon}</Link> : partyIcon
}

export const DepartmentComponentTitle: React.FC<
  React.PropsWithChildren<{
    department: EntityDepartment | undefined
    intervalOrigin: IntervalOrigin
    componentSize: ComponentSize
  }>
> = ({ department, intervalOrigin, componentSize, children }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const departmentPath = useDepartmentLinkPath(department, intervalOrigin)

  const title = children || <>{department?.name}</>
  if (isSharingContextActive || !departmentPath || !department) return title

  return (
    <ComponentExpandLink to={departmentPath} componentSize={componentSize}>
      {title}
    </ComponentExpandLink>
  )
}

export const DepartmentComponentIcon: React.FC<{
  department: EntityDepartment | undefined
  intervalOrigin: IntervalOrigin
  componentSize: ComponentSize
}> = ({ department, intervalOrigin, componentSize }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const path = useDepartmentLinkPath(department, intervalOrigin)
  const icon = <EntityIconStyled componentSize={componentSize} subjectDisplayKey="Building07" />

  if (!department) return icon

  return !isSharingContextActive && path ? <Link to={path}>{icon}</Link> : icon
}

export const LocationComponentTitle: React.FC<
  React.PropsWithChildren<{
    location: EntityDepartment | undefined
    intervalOrigin: IntervalOrigin
    componentSize: ComponentSize
  }>
> = ({ location, intervalOrigin, componentSize, children }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const path = useLocationLinkPath(location, intervalOrigin)

  const title = children || <>{location?.name}</>
  if (isSharingContextActive || !path || !location) return title

  return (
    <ComponentExpandLink to={path} componentSize={componentSize}>
      {title}
    </ComponentExpandLink>
  )
}

export const LocationComponentIcon: React.FC<{
  location: EntityDepartment | undefined
  intervalOrigin: IntervalOrigin
  componentSize: ComponentSize
}> = ({ location, intervalOrigin, componentSize }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const path = useLocationLinkPath(location, intervalOrigin)

  const icon = <EntityIconStyled componentSize={componentSize} subjectDisplayKey="MarkerPin02" />
  if (!location) return icon

  return !isSharingContextActive && path ? <Link to={path}>{icon}</Link> : icon
}

export const SummaryLineItemComponentTitle: React.FC<{
  item: SummaryLineItem | undefined
  intervalOrigin: IntervalOrigin
  componentSize: ComponentSize
  children?: string
}> = ({ item, intervalOrigin, componentSize, children }) => {
  const metricPath = useComponentDetailsLinkPath(item, intervalOrigin)
  const title = stringHelper.camelCaseToSpaces(children || item)
  if (!metricPath) return title

  const badge = item === SummaryLineItem.TotalCash ? <DisclaimerTooltip /> : undefined
  return (
    <ComponentExpandLink to={metricPath} linkBadge={badge} componentSize={componentSize}>
      {title}
    </ComponentExpandLink>
  )
}

/** Style helper function for turning ComponentSize into icon styles */
function categoryIconSizeStyles({ componentSize }: { componentSize: ComponentSize }) {
  switch (componentSize) {
    case ComponentSize.Small:
    case ComponentSize.PageSmall:
      return css`
        height: 23px;
        width: 23px;
      `

    case ComponentSize.Medium:
    case ComponentSize.PageMedium:
      return css`
        height: 26px;
        width: 26px;
      `
    case ComponentSize.Large:
      return css`
        margin-top: -2px;
        height: 28px;
        width: 28px;
      `
    default:
      return undefined
  }
}
