import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import {
  type IntervalOrigin,
  type LayoutComponentConfig,
  LayoutComponentType,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCoinsStacked03 } from "@digits-shared/components/SVGIcons/line/CoinsStacked03.svg"
import { SvgCoinsStacked04 } from "@digits-shared/components/SVGIcons/line/CoinsStacked04.svg"
import { SvgCurrencyDollarCircle } from "@digits-shared/components/SVGIcons/line/CurrencyDollarCircle.svg"
import { SvgSettings01 } from "@digits-shared/components/SVGIcons/line/Settings01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { Menu, MenuContent, MenuItem, MenuTrigger } from "@digits-shared/DesignSystem/Menu"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { RunwayDeltaSubHeader } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayDeltaSubHeader"
import { RunwayLineChartComponent } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayLineChart"
import { RunwayTile } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayTile"
import {
  useCashOutTimeLeft,
  useRunwayTimeseries,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { netBurnLookback } from "src/frontend/components/Shared/Layout/Drawer/configs"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import routes from "src/frontend/routes"
import { type Runway } from "src/frontend/types"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export const BURN_INTERVAL_COUNT_SEARCH_KEY = "burnIntervalCount"

/*
  STYLES
*/

const TileComponent = styled(RunwayTile)<{ height: number }>`
  max-width: 250px;
`

const MenuHeader = styled.div`
  ${fonts.scale.detail};
  color: ${colors.secondary};
  padding: 8px 12px;
  border-bottom: 1px solid ${colors.secondary20};
`

const SettingsGear = styled(SvgSettings01)``

const MenuDigitsButton = styled(DigitsButton)`
  padding: 0 0 0 4px;
  ${SettingsGear} {
    ${svgPathStyles(colors.secondary60)};
    &:hover {
      ${svgPathStyles(colors.secondary80)};
    }
  }
`

/*
  INTERFACES
*/

interface RunwayChartComponentProps extends SizingProps {
  origin: IntervalOrigin
  component: MatchedComponent<"runwayChart">
  componentSize: ComponentSize
  skipAnimations: boolean
  runway?: Runway
  loading: boolean
}

/*
  COMPONENTS
*/

export const RunwayChartComponent: React.FC<RunwayChartComponentProps> = ({
  runway,
  loading,
  origin,
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const { currentTimeseries, fullTimeseries } = useRunwayTimeseries(runway)
  const { title: cashOutTitle, deltaMonths } = useCashOutTimeLeft(runway)
  const { history } = useRouter()

  const baseRunwayPath = useComponentDetailsLinkPath(LayoutComponentType.RunwayChart, origin)
  const runwayPath = React.useMemo(() => {
    const burnIntervalCount = component.config.runwayChart.netBurnLookback?.intervalCount
    if (baseRunwayPath && burnIntervalCount) {
      return urlHelper.addSearchParams(baseRunwayPath, {
        [BURN_INTERVAL_COUNT_SEARCH_KEY]: burnIntervalCount,
      })
    }
    return baseRunwayPath
  }, [baseRunwayPath, component.config.runwayChart.netBurnLookback?.intervalCount])

  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
    },
    [highlight]
  )

  const onBarClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      if (!runwayPath) return
      const path = urlHelper.addSearchParams(runwayPath, { highlight: index })
      if (!path) return
      history.push(path)
    },
    [history, runwayPath]
  )

  const title = React.useMemo(() => {
    const isLive = dateTimeHelper.isCurrentInterval(origin)
    return (
      <ComponentExpandLink to={runwayPath} componentSize={componentSize}>
        {isLive && "Live "}Runway
      </ComponentExpandLink>
    )
  }, [origin, runwayPath, componentSize])
  const configMenu = <RunwayAverageMenu component={component} />

  const subHeader = <RunwayDeltaSubHeader deltaMonths={deltaMonths} />

  if (componentSize.isSmall) {
    return (
      <>
        <ComponentDateSummary
          componentSize={componentSize}
          title={title}
          timeseries={currentTimeseries}
          selectedIndex={selectedIndex}
          valueMenu={configMenu}
        />
        <TileComponent
          loading={loading}
          height={height}
          runway={runway}
          skipAnimations={skipAnimations}
        />
      </>
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={componentSize}
        title={title}
        timeseries={fullTimeseries}
        defaultValueNode={cashOutTitle}
        defaultTimeseriesIndex={currentTimeseries.length - 1}
        selectedIndex={selectedIndex}
        subHeaderCustomNode={subHeader}
        isEstimate={!!selectedIndex && selectedIndex > currentTimeseries.length - 1}
        valueMenu={configMenu}
      />
      <RunwayLineChartComponent
        runway={runway}
        skipAnimations={skipAnimations}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onBarClick}
        height={height}
      />
    </>
  )
}

const RunwayAverageMenu: React.FC<{ component: MatchedComponent<"runwayChart"> }> = ({
  component,
}) => {
  const {
    location: { fullPathname },
    history,
  } = useRouter()
  const origin = useIntervalOrigin()
  const current = component.config.runwayChart.netBurnLookback ?? netBurnLookback(origin, 3)

  const isDetailsView = !!useRouteMatch(routes.layoutComponentDetails.parameterizedPath)
  const isEditView = !!useRouteMatch(routes.legalEntityHomeEdit.parameterizedPath)
  const isReportView = !!useRouteMatch(routes.reportPackage.parameterizedPath)

  const options = [
    { label: "Last Month", intervalCount: 1, Icon: SvgCurrencyDollarCircle },
    { label: "3 Month Average", intervalCount: 3, Icon: SvgCoinsStacked04 },
    { label: "6 Month Average", intervalCount: 6, Icon: SvgCoinsStacked03 },
  ]

  const dispatch = usePortalDispatch()
  const onSelect = React.useCallback(
    (intervalCount: number) => {
      if (isDetailsView) {
        const updatedURL = urlHelper.addSearchParams(fullPathname, {
          [BURN_INTERVAL_COUNT_SEARCH_KEY]: intervalCount,
        })
        if (updatedURL) history.replace(updatedURL)
        return
      }

      const updatedConfig: LayoutComponentConfig = {
        ...component.config,
        runwayChart: {
          ...component.config.runwayChart,
          netBurnLookback: netBurnLookback(origin, intervalCount),
        },
      }

      dispatch({
        type: "setConfig",
        config: updatedConfig,
        componentId: component.componentId,
        save: true,
      })
    },
    [
      component.componentId,
      component.config,
      dispatch,
      fullPathname,
      history,
      isDetailsView,
      origin,
    ]
  )

  if (isReportView || isEditView) return null

  return (
    <Menu>
      <MenuTrigger asChild>
        <MenuDigitsButton $variant="ghost-light" size="small">
          <SettingsGear />
        </MenuDigitsButton>
      </MenuTrigger>
      <MenuContent>
        <MenuHeader>Based on net burn from&hellip;</MenuHeader>
        {options.map((option) => (
          <MenuItem
            key={option.intervalCount}
            label={option.label}
            onSelect={onSelect.bind(undefined, option.intervalCount)}
            active={current.intervalCount === option.intervalCount}
            Icon={option.Icon}
          />
        ))}
      </MenuContent>
    </Menu>
  )
}
