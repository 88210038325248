import * as React from "react"
import { Route } from "react-router-dom"
import { ApolloError } from "@apollo/client"
import { useLogOutSupportMutation } from "@digits-graphql/frontend/graphql-public"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { Banner, Message } from "@digits-shared/components/UI/Elements/Banner"
import { H2 } from "@digits-shared/components/UI/Elements/Header"
import { Pill } from "@digits-shared/components/UI/Elements/Pill"
import { TableTitle } from "@digits-shared/components/UI/Table"
import envHelper from "@digits-shared/helpers/envHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { AccessLevel } from "@digits-shared/session/DGAccessLevel"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { ApplicationFullWidthPane } from "src/frontend/components/OS/Applications/ApplicationPane"
import { DigitsEmployeePreferences } from "src/frontend/components/OS/Applications/Support/DigitsEmployeePreferences"
import DoppelgangerPermitsTable from "src/frontend/components/OS/Applications/Support/DoppelgangerPermitsTable"
import { useDoppelgangerRedirect } from "src/frontend/components/OS/Applications/Support/DoppelgangerRedirect"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

/*
 STYLES
*/

const StyledBanner = styled(Banner)`
  width: var(--application-pane-full-width);
  position: fixed;
  top: 0;
`

const DoppelgangerBanner = styled.div<{ isLoading: boolean }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px 20px;
  font-size: 13px;
  background-color: #cd5c5c;
  color: ${colors.white};
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`

const ActionPill = styled(Pill)<{ background: string; isSelected?: boolean }>`
  background: ${({ background }) => background};
  color: ${colors.white};
  margin-right: 10px;
  cursor: pointer;
  transition:
    box-shadow 250ms ease,
    opacity 250ms ease;

  &:hover {
    box-shadow: 0 0 5px ${colors.translucentWhite50};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const AccessLevels = styled.div`
  display: flex;
  justify-content: center;
`

/*
 COMPONENTS
*/

const SupportApplication: React.FC = () => {
  const { isDigitsEmployee, isDoppelganger } = useSession<FrontendSession>()

  const dgRedirect = useDoppelgangerRedirect()
  if (dgRedirect) {
    return <LoggedRedirect name="SupportApplication-doppelganger-redirect" to={dgRedirect} />
  }

  // DO NOT remove the isProduction check
  if (envHelper.isProduction() && !isDigitsEmployee && !isDoppelganger) {
    return <LoggedRedirect name="SupportApplication-unauthorized" to={routes.root.generate()} />
  }

  return (
    <ApplicationFullWidthPane>
      <OnboardRequiredBanner />
      <DigitsEmployeePreferences />
      <DoppelgangerAccessBanner />
      <DoppelgangerPermits />
    </ApplicationFullWidthPane>
  )
}

export default SupportApplication

// Present a warning to internal employees viewing this page if they have not yet
// onboarded an organization and provide a link for them to get there.
const OnboardRequiredBanner: React.FC = () => {
  const { hasActiveLegalEntity, user } = useSession<FrontendSession>()
  if (hasActiveLegalEntity) return null

  return (
    <StyledBanner>
      <Message to={routes.welcome.generate()}>
        FYI {user.givenName} - This is the only page you will be able to view until you onboard an
        organization. Click here to finish.
      </Message>
    </StyledBanner>
  )
}

const DoppelgangerAccessBanner: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { history } = useRouter()

  const setCustomerAccess = React.useCallback(
    () => (session.accessLevel = AccessLevel.Customer),
    [session]
  )
  const setDashboardAccess = React.useCallback(
    () => (session.accessLevel = AccessLevel.Dashboard),
    [session]
  )
  const setFullAccess = React.useCallback(
    () => (session.accessLevel = AccessLevel.FullAccess),
    [session]
  )

  const { value: isLoading, setTrue: setLoading } = useStateBoolean(false)
  const permitId = session.doppelgangerPermit || ""
  const [logoutSupportMutation] = useLogOutSupportMutation({
    variables: { id: permitId },
    context: { skipTokenUpdate: true, publicAPI: true },
  })
  const logoutDoppelganger = React.useCallback(() => {
    setLoading()
    logoutSupportMutation()
      .then((response) => {
        if (response.errors) throw new ApolloError({ graphQLErrors: response.errors })
        if (!response.data) throw new Error("No data returned from token refresh mutation")

        return session.onBearerChange(response.data.logOutSupport, true)
      })
      .then(() => {
        history.push(routes.root.generate())
      })
      .catch((error) => {
        TrackJS?.track(error)
        session.bearerFetchError(error)
        session.clear()
      })
  }, [logoutSupportMutation, setLoading, session, history])

  if (!session.doppelganger) return null

  return (
    <DoppelgangerBanner isLoading={isLoading}>
      <H2 css="text-align: center;">Caution!</H2>
      <div css="margin: 10px 0; text-align: center">
        {" "}
        You are currently a Doppelgänger of
        <b css="padding-right: 3px;">{` ${userHelper.displayName(session.user)}.`}</b>
      </div>
      <div css="display: flex; align-items: center; justify-content: center;">
        <b css="margin: 0 10px;">Access:</b>
        <AccessLevels>
          <ActionPill
            background={colors.green}
            isSelected={session.doppelganger?.level === AccessLevel.Customer}
            onClick={setCustomerAccess}
          >
            Customer
          </ActionPill>
          <ActionPill
            background={colors.poloBlue}
            isSelected={session.doppelganger?.level === AccessLevel.Dashboard}
            onClick={setDashboardAccess}
          >
            Dashboard
          </ActionPill>
          <ActionPill
            background={colors.orange}
            isSelected={session.doppelganger?.level === AccessLevel.FullAccess}
            onClick={setFullAccess}
          >
            Full Access
          </ActionPill>
        </AccessLevels>
        <div css="text-decoration: underline; cursor: pointer;" onClick={logoutDoppelganger}>
          {isLoading ? "Switching…" : "Return to Digits Account"}
        </div>
      </div>
    </DoppelgangerBanner>
  )
}

const DoppelgangerPermits: React.FC = () => {
  const { doppelganger } = useSession<FrontendSession>()
  if (doppelganger) return null

  return (
    <>
      <TableTitle>Doppelganger Permits</TableTitle>
      <Route component={DoppelgangerPermitsTable} />
    </>
  )
}
