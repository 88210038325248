import * as React from "react"
import {
  type SearchEntitiesQueryResult,
  type SearchHit,
  type SearchResultFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import useSearchContext from "src/frontend/components/OS/Applications/Search/SearchContext"
import { useMapResultsByType } from "src/frontend/components/OS/Applications/Search/SearchResults/resultType"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import { FetchMoreResults } from "./FetchMoreResults"
import ResultSection from "./ResultSection"

/*
 INTERFACES
*/

interface Props {
  result: SearchEntitiesQueryResult
}

interface MapperProps extends Pick<SearchEntitiesQueryResult, "fetchMore"> {
  search: SearchResultFieldsFragment
}

/*
 COMPONENT
*/

const SearchResults: React.FC<Props> = ({ result: { called, data, error, fetchMore } }) => {
  if (error) {
    TrackJS?.console.error(error)
  }

  if (!called || !data?.search) {
    return null
  }

  return <SearchResultsMapper search={data.search} fetchMore={fetchMore} />
}

export default SearchResults

const SearchResultsMapper: React.FC<MapperProps> = ({ search, fetchMore }) => {
  const sections = useMapResultsByType(search)
  const { query } = useSearchContext()
  const key = React.useRef(query.text)

  const long = key.current.length > query.text.length ? key.current : query.text
  const short = key.current.length > query.text.length ? query.text : key.current
  // new query? reset the key which will unmount the ResultSection components and trigger a new animation
  if (long.toLowerCase().indexOf(short.toLowerCase()) === -1) {
    key.current = query.text
  }

  const resultsSections = React.useMemo(
    () =>
      Array.from(sections)
        .sort(sortSections)
        .map(([type, hits]) => (
          <ResultSection key={`${type}_${key.current}`} type={type} hits={hits} search={search} />
        )),
    [search, sections]
  )

  return (
    <>
      {resultsSections}
      <FetchMoreResults search={search} fetchMore={fetchMore} />
    </>
  )
}

const SORTED_RESULTS_TYPES = Object.keys(SearchResultType)
function sortSections(
  section1: [SearchResultType, SearchHit[]],
  section2: [SearchResultType, SearchHit[]]
) {
  const index1 = SORTED_RESULTS_TYPES.indexOf(section1[0])
  const index2 = SORTED_RESULTS_TYPES.indexOf(section2[0])
  return index1 - index2
}
