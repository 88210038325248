import * as React from "react"
import {
  type CategoryHover,
  type EntityCategory,
  type EntityParty,
  type PartyDimension,
  type PartyHover,
  type StatementDeltas,
  type StatementDetails,
  type SummaryLineItemHover,
} from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { toTimeseries } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { type ColumnKey } from "src/frontend/components/Shared/Layout/Components/Statements/columnTypes"

export interface RowDetails {
  periodDetails?: StatementDetails | null
  ytdDetails?: StatementDetails | null
  deltas?: StatementDeltas | null
}

export type StatementCategoryDetails = Omit<StatementDetails, "hover" | "context"> & {
  hover: CategoryHover
  context?: { party: PartyDimension[] }
}
export type CategoryRowDetails = RowDetails & {
  periodDetails: StatementCategoryDetails
  ytdDetails: StatementCategoryDetails
}

export type StatementPartyDetails = StatementDetails & { hover: PartyHover }

export type StatementSummaryLineDetails = StatementDetails & { hover: SummaryLineItemHover }
export type SummaryLineRowDetails = RowDetails & {
  periodDetails: StatementSummaryLineDetails
  ytdDetails: StatementSummaryLineDetails
}

export function isCategoryRowDetails(
  details: RowDetails | undefined
): details is CategoryRowDetails {
  return isCategoryDetails(details?.periodDetails)
}

export function isSummaryLineRowDetails(
  details: RowDetails | undefined
): details is SummaryLineRowDetails {
  return isSummaryLineItemDetails(details?.periodDetails)
}

function isPartyEntity(entity: EntityParty | EntityCategory): entity is EntityParty {
  return !!(entity as EntityParty)?.designation
}

function isCategoryEntity(entity: EntityCategory | EntityParty): entity is EntityCategory {
  return !!(entity as EntityCategory)?.type
}

export function isCategoryDetails(
  details: StatementDetails | undefined | null
): details is StatementCategoryDetails {
  const entity = (details?.hover as CategoryHover)?.entity
  return !!entity && isCategoryEntity(entity)
}

export function isSummaryLineItemDetails(
  details: StatementDetails | undefined | null
): details is StatementSummaryLineDetails {
  const hover = details?.hover as SummaryLineItemHover
  return !!hover && !!hover.lineType
}

export function isPartyDetails(
  details: StatementDetails | undefined
): details is StatementPartyDetails {
  const entity = (details?.hover as PartyHover)?.entity
  return !!entity && isPartyEntity(entity)
}

export function extractPeriodDetails(optionKey: ColumnKey | undefined, details?: RowDetails) {
  switch (optionKey) {
    case "yearToDate":
    case "deltaYearToDate":
      return details?.ytdDetails
    default:
      return details?.periodDetails
  }
}

export function extractAccountDisplayNumber(details: StatementDetails | undefined | null) {
  return isCategoryDetails(details) ? details.hover.entity.chartOfAccountsDisplayNumber : undefined
}

export function useToOptionTimeSeries(optionKey: ColumnKey, details: RowDetails | undefined) {
  return React.useMemo(
    () => toDetailsTimeseries(extractPeriodDetails(optionKey, details)),
    [details, optionKey]
  )
}

export function useToTimeSeries(details: StatementDetails | undefined | null) {
  return React.useMemo(() => toDetailsTimeseries(details), [details])
}

function toDetailsTimeseries(details: StatementDetails | undefined | null) {
  if (!details) return details

  const entity =
    isCategoryDetails(details) || isPartyDetails(details) ? details.hover.entity : undefined

  const name = isSummaryLineItemDetails(details)
    ? stringHelper.camelCaseToSpaces(details.hover.lineType)
    : entity?.name

  const { history } = details.hover

  return toTimeseries(name || "", history.time)
}
