import * as React from "react"
import { type QueryResult } from "@apollo/client"
import { type LegalEntityPermission } from "@digits-graphql/frontend/graphql-bearer"
import { unique } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { Application, type BadgeCounts } from "src/frontend/components/OS/Applications/Application"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

interface BadgeContextValue {
  loading: boolean
  badgeCount: (leId: string, name: string) => number | undefined
  hasBadge: (leId: string, name: string) => boolean
  totalBadgeCount: (leId: string) => number | undefined
  getRefetchCount: (sa: string) => QueryResult["refetch"] | undefined
}

interface PermissionedApplication {
  springboardApplication: Application
  legalEntityIds: string[]
  skip: boolean
  legalEntityPerms?: LegalEntityPermission[]
}

interface UseApplicationCountQueryParameters {
  springboardApplication: Application
  permissionedApplications: PermissionedApplication[]
}

const defaultValue = {
  loading: false,
  badgeCount: () => undefined,
  hasBadge: () => false,
  totalBadgeCount: () => undefined,
  getRefetchCount: () => undefined,
}

const BadgeContext = React.createContext<BadgeContextValue>(defaultValue)

export const BadgeContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { permissionedApplications, legalEntityIds } = usePermissionedApplications()

  const {
    loading: boostLoading,
    counts: boostCounts,
    refetch: boostRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.QualityCheck,
  })

  const {
    loading: reportsLoading,
    counts: reportsCounts,
    refetch: reportsRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.DigitsReports,
  })

  const {
    loading: transactionReviewLoading,
    counts: reviewCounts,
    refetch: transactionReviewRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.TransactionReview,
  })

  const {
    loading: connectionsLoading,
    counts: connectionsCounts,
    refetch: connectionsRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.Connections,
  })

  const {
    loading: actionItemsLoading,
    counts: actionItemsCounts,
    refetch: actionItemsRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.ActionItems,
  })

  const {
    loading: dataSourcesLoading,
    counts: dataSourcesCounts,
    refetch: dataSourcesRefetch,
  } = useApplicationCountQuery({
    permissionedApplications,
    springboardApplication: Application.DataSources,
  })

  const refetchQueries = React.useMemo(
    () =>
      new Map([
        [Application.ActionItems.name, actionItemsRefetch],
        [Application.QualityCheck.name, boostRefetch],
        [Application.DigitsReports.name, reportsRefetch],
        [Application.TransactionReview.name, transactionReviewRefetch],
        [Application.Connections.name, connectionsRefetch],
        [Application.DataSources.name, dataSourcesRefetch],
      ]),
    [
      actionItemsRefetch,
      boostRefetch,
      reportsRefetch,
      transactionReviewRefetch,
      connectionsRefetch,
      dataSourcesRefetch,
    ]
  )

  const counts = React.useMemo(
    () =>
      /*
        Map: {
          legalEntity: Map {
            SpringboardApplication.name: count
          }
        }
      */
      new Map(
        legalEntityIds?.map((le) => {
          const actionItemsCount = findCount(le, actionItemsCounts)
          const boostCount = findCount(le, boostCounts)
          const reportsCount = findCount(le, reportsCounts)
          const transactionReviewCount = findCount(le, reviewCounts)
          const connectionsCount = findCount(le, connectionsCounts)
          const dataSourcesCount = findCount(le, dataSourcesCounts)
          const totalCount =
            boostCount +
            reportsCount +
            transactionReviewCount +
            connectionsCount +
            actionItemsCount +
            dataSourcesCount
          return [
            le,
            new Map([
              [Application.ActionItems.name, actionItemsCount],
              [Application.QualityCheck.name, boostCount],
              [Application.DigitsReports.name, reportsCount],
              [Application.TransactionReview.name, transactionReviewCount],
              [Application.Connections.name, connectionsCount],
              [Application.DataSources.name, dataSourcesCount],
              ["total" as const, totalCount],
            ]),
          ]
        })
      ),
    [
      actionItemsCounts,
      boostCounts,
      connectionsCounts,
      legalEntityIds,
      reportsCounts,
      reviewCounts,
      dataSourcesCounts,
    ]
  )

  const loading =
    boostLoading ||
    reportsLoading ||
    transactionReviewLoading ||
    connectionsLoading ||
    actionItemsLoading ||
    dataSourcesLoading

  const value = React.useMemo<BadgeContextValue>(
    () => ({
      loading,
      badgeCount: (leId: string, sa: string) => counts.get(leId)?.get(sa),
      totalBadgeCount: (leId: string) => counts.get(leId)?.get("total"),
      hasBadge: (leId: string, sa: string) => !!counts.get(leId)?.get(sa),
      getRefetchCount: (sa: string) => refetchQueries.get(sa),
    }),
    [counts, loading, refetchQueries]
  )

  return <BadgeContext.Provider value={value}>{children}</BadgeContext.Provider>
}

const useLegalEntities = () => {
  const session = useSession<FrontendSession>()
  const { currentOrganization, currentLegalEntity, isAffiliatedSession, affiliations } = session
  const affiliatedEntities = affiliations.map((af) => af.entity)

  return React.useMemo(() => {
    if (isAffiliatedSession) {
      return affiliatedEntities ?? []
    }

    if (
      !currentLegalEntity ||
      !currentOrganization?.permissions.hasReadPermission(FrontendPermissionModule.LegalEntities)
    ) {
      return []
    }

    return [currentLegalEntity]
  }, [
    affiliatedEntities,
    currentLegalEntity,
    currentOrganization?.permissions,
    isAffiliatedSession,
  ])
}

const usePermissionedApplications = () => {
  const legalEntities = useLegalEntities()
  const { currentLegalEntityId } = useSession<FrontendSession>()

  const canRead = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Transactions,
    flag: JWTPermissionFlag.Read,
  })

  return React.useMemo(() => {
    const legalEntitiesWithDashboardAccess = legalEntities.filter(
      (entity) => entity.hasDashboardAccess() && canRead
    )
    const currentLegalEntityOnly = legalEntitiesWithDashboardAccess.filter(
      (entity) => entity.id === currentLegalEntityId && canRead
    )
    const permissionedApplications = Application.all.reduce<PermissionedApplication[]>(
      (applications, springboardApplication) => {
        // only fetch all badge counts for ActionItems since this count is displayed on the legal entity selector
        // otherwise only fetch badge counts for the current legal entity
        const allowedLegalEntities =
          springboardApplication.name === Application.ActionItems.name
            ? legalEntitiesWithDashboardAccess
            : currentLegalEntityOnly

        const legalEntityIds = allowedLegalEntities.map(({ id }) => id)
        const perms = allowedLegalEntities.map((le) => ({
          legalEntityId: le.id,
          canManageTransactions: le.hasAccessToAspect(AspectCode.TransactionActionItems),
        }))

        return springboardApplication?.badgeQuery
          ? applications.concat([
              {
                springboardApplication,
                legalEntityIds,
                skip: legalEntityIds.length === 0,
                legalEntityPerms: perms,
              },
            ])
          : // Filter out applications that don't have a badge query
            applications
      },
      []
    )

    const legalEntityIds = permissionedApplications
      .flatMap(({ legalEntityIds: ids }) => ids)
      .filter(unique)

    return { permissionedApplications, legalEntityIds }
  }, [canRead, currentLegalEntityId, legalEntities])
}

const useApplicationCountQuery = ({
  permissionedApplications,
  springboardApplication,
}: UseApplicationCountQueryParameters) => {
  const application = permissionedApplications.find(
    ({ springboardApplication: permissionedApplication }) =>
      permissionedApplication === springboardApplication
  )

  if (!application?.springboardApplication?.badgeQuery) {
    return {
      loading: false,
      counts: application?.legalEntityIds.map((legalEntityId) => ({
        count: 0,
        legalEntityId,
      })),
    }
  }

  return application.springboardApplication.badgeQuery(
    application.legalEntityIds,
    application.skip,
    application.legalEntityPerms
  )
}

const findCount = (le: string, badgeCounts?: BadgeCounts[]) =>
  badgeCounts?.find(({ legalEntityId }) => le === legalEntityId)?.count ?? 0

export function useBadgeContext() {
  return React.useContext(BadgeContext)
}
