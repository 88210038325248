import React from "react"
import { Route, Switch } from "react-router-dom"
import { ManagedSnackbarName } from "src/frontend/components/Shared/ManagedModals/state/types"
import { RequiresRead } from "src/frontend/components/Shared/Permissions/Requires"
import { UpsellSnackbar } from "src/frontend/components/Shared/PLG/Upsell/UpsellSnackbar"
import routes from "src/frontend/routes"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export const UpsellSnackbars: React.FC = () => (
  <RequiresRead
    source={FrontendPermissionSource.LegalEntity}
    // TODO: replace with upsell aspect
    module={FrontendPermissionModule.Quotes}
  >
    <Switch>
      <Route path={routes.reportsStatement.parameterizedPath} component={FinancialsSnackbar} />
      <Route path={routes.accounting.parameterizedPath} component={TransactionsSnackbar} />
    </Switch>
  </RequiresRead>
)

const FinancialsSnackbar: React.FC = () => (
  <UpsellSnackbar
    snackbarName={ManagedSnackbarName.FinancialsUpsell}
    message="Add Expert Review"
    description="Upgrade for verified financials to share externally"
  />
)

const TransactionsSnackbar: React.FC = () => (
  <UpsellSnackbar
    snackbarName={ManagedSnackbarName.TransactionsUpsell}
    message="Add Expert Accounting"
    description="Upgrade for professional review of transactions"
  />
)
