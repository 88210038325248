import { useRouteMatch } from "react-router-dom"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

export enum AssistantDisplay {
  Hidden = "hidden",
  FullWidth = "full-width",
  Inline = "inline",
}

/**
 * Hook to determine which routes should show Ask Digits inline vs full-width
 */
export function useAssistantDisplay() {
  const { hasAccessToAspect } = useSession<FrontendSession>()
  const isExpenses = useRouteMatch(routes.expenses.parameterizedPath)
  const isSales = useRouteMatch(routes.revenue.parameterizedPath)
  const isAssistant = useRouteMatch(routes.assistant.parameterizedPath)

  if (!hasAccessToAspect(AspectCode.AskDigits)) return undefined

  return !!isExpenses || !!isSales
    ? AssistantDisplay.Inline
    : isAssistant
      ? AssistantDisplay.FullWidth
      : AssistantDisplay.Hidden
}
