import React from "react"
import {
  type EntityCategory,
  type EntityDepartment,
  type EntityLocation,
  type EntityParty,
  useSearchTermsQuery,
  type ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"
import { type QueryRequest } from "src/frontend/components/OS/Applications/Search/QueryBuilder"

export type EntitiesResultsType = EntityCategory | EntityParty | EntityLocation | EntityDepartment

export default function useTermsQuery(query: QueryRequest, viewVersion: ViewIdentifier) {
  const { text, isValid } = query

  return useSearchTermsQuery({
    variables: {
      ...viewVersion,
      text: text.trim(),
    },
    skip: !isValid,
  })
}

export function useEntities(query: QueryRequest, viewVersion: ViewIdentifier) {
  const { data, loading } = useTermsQuery(query, viewVersion)

  return React.useMemo(() => {
    if (loading || !data) {
      return { terms: [], loading }
    }
    const { entities: searchEntities } = data.searchTerms

    const nameById = [
      searchEntities.categories ?? [],
      searchEntities.parties ?? [],
      searchEntities.departments ?? [],
      searchEntities.locations ?? [],
    ]
      .flat()
      .reduce((map, entity) => {
        map.set(entity.id, entity)
        return map
      }, new Map<string, EntitiesResultsType>())

    // The search results are returned in a best-match order, so it's
    // important to iterate them in order. Iterate the results (hits),
    // using their IDs to look up the entity names to be returned.
    const entities = data.searchTerms.results.reduce<EntitiesResultsType[]>((acc, hit) => {
      const entity = nameById.get(hit.objectId.id)
      if (entity) {
        acc.push(entity)
      }
      return acc
    }, [])

    return { entities, loading }
  }, [data, loading])
}

export function useTerms(query: QueryRequest, viewVersion: ViewIdentifier) {
  const { entities, loading } = useEntities(query, viewVersion)

  return React.useMemo(() => {
    if (loading || !entities) {
      return { terms: [], loading }
    }

    const terms = entities.map((entity) => entity.name)

    return { terms, loading }
  }, [entities, loading])
}
