import * as React from "react"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useSession from "@digits-shared/hooks/useSession"
import Session from "@digits-shared/session/Session"
import type FrontendSession from "src/frontend/session"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

export function useUserSettings() {
  const session = useSession<FrontendSession>()
  const [_, forceUpdate] = useForceUpdate()

  // Session changes (login / out)
  React.useEffect(() => {
    session.on?.(Session.USER_PREFERENCES_CHANGE, forceUpdate)
    return () => {
      session.off?.(Session.USER_PREFERENCES_CHANGE, forceUpdate)
    }
  }, [forceUpdate, session])

  const toggleUserSetting = React.useCallback(
    (setting: string, enabled?: boolean) => {
      if (enabled === undefined) {
        return session.deleteUserPreference(setting)
      }
      session.setUserPreference(setting, enabled)
    },
    [session]
  )

  /**
   * Check if the expanded view preference is enabled.
   */
  const expandedViewEnabled = session.getBooleanUserPreference(userPreferenceKeys.expandedView)

  return React.useMemo(
    () => ({
      settings: {
        expandedViewEnabled,
      },
      toggleUserSetting,
    }),
    [expandedViewEnabled, toggleUserSetting]
  )
}
