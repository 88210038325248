import colors from "@digits-shared/themes/colors"
import { type ISourceOptions } from "@tsparticles/engine"

export const SIDE_CONFETTI_CONFIG: ISourceOptions = {
  fullScreen: {
    enable: true,
  },
  emitters: [
    {
      position: {
        x: 0,
        y: 30,
      },
      life: {
        count: 1,
        duration: 0.2,
      },
      rate: {
        quantity: 300,
        delay: 0.2,
      },
      particles: {
        move: {
          direction: "top-right",
          outModes: {
            top: "none",
            left: "none",
            default: "destroy",
          },
        },
      },
    },
    {
      position: {
        x: 100,
        y: 30,
      },
      life: {
        count: 1,
        duration: 0.2,
      },
      rate: {
        quantity: 300,
        delay: 0.2,
      },
      particles: {
        move: {
          direction: "top-left",
          outModes: {
            top: "none",
            right: "none",
            default: "destroy",
          },
        },
      },
    },
  ],
  particles: {
    color: {
      value: [colors.accentBlue, colors.teal, colors.purple, colors.primary, colors.white],
    },
    move: {
      decay: 0.05,
      direction: "top",
      enable: true,
      gravity: {
        enable: true,
      },
      outModes: {
        top: "none",
        default: "destroy",
      },
      speed: {
        min: 20,
        max: 50,
      },
    },
    number: {
      value: 0,
    },
    opacity: {
      value: 1,
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      animation: {
        enable: true,
        speed: 30,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 30,
      },
    },
    size: {
      value: 6,
      animation: {
        enable: true,
        startValue: "min",
        count: 1,
        speed: 16,
        sync: true,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 5,
        max: 15,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      speed: {
        min: -7,
        max: 7,
      },
    },
    shape: {
      type: ["square"],
      options: {},
    },
  },
}

export const BOTTOM_CONFETTI_CONFIG: ISourceOptions = {
  fullScreen: {
    enable: true,
  },
  emitters: {
    position: {
      x: 50,
      y: 100,
    },
    life: {
      count: 1,
      duration: 0.05,
      delay: 0,
    },
    rate: {
      quantity: 500,
      delay: 0.15,
    },
    size: {
      width: 10,
      height: 25,
    },
  },
  particles: {
    color: {
      value: [colors.accentBlue, colors.teal, colors.purple, colors.primary, colors.white],
    },
    move: {
      decay: 0.05,
      direction: "top",
      enable: true,
      random: true,
      gravity: {
        enable: true,
      },
      outModes: {
        top: "none",
        default: "destroy",
      },
      speed: {
        min: 80,
        max: 80,
      },
    },
    number: {
      value: 0,
    },
    opacity: {
      value: 1,
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      animation: {
        enable: true,
        speed: 30,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 30,
      },
    },
    size: {
      value: 5,
      animation: {
        enable: true,
        startValue: "min",
        count: 1,
        speed: 16,
        sync: true,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enlighten: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 5,
        max: 15,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      speed: {
        min: -7,
        max: 7,
      },
    },
    shape: {
      type: ["square"],
      options: {},
    },
  },
}

export const BURSTS_CONFETTI_CONFIG: ISourceOptions = {
  fullScreen: {
    enable: true,
  },
  particles: {
    number: {
      value: 0,
    },
    color: {
      value: [colors.accentBlue, colors.teal, colors.purple, colors.primary, colors.white],
    },
    shape: {
      type: ["circle", "square"],
      options: {},
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        speed: 2,
        startValue: "max",
        destroy: "min",
      },
    },
    size: {
      value: 5,
    },
    life: {
      duration: {
        sync: true,
        value: 5,
      },
      count: 1,
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 10,
      },
      speed: {
        min: 10,
        max: 20,
      },
      decay: 0.1,
      direction: "none",
      straight: false,
      outModes: {
        default: "destroy",
        top: "none",
      },
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      move: true,
      animation: {
        enable: true,
        speed: 60,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      move: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 60,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 15,
        max: 25,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      move: true,
      speed: {
        min: -15,
        max: 15,
      },
    },
  },
  emitters: {
    life: {
      count: 5,
      duration: 0.1,
      delay: 0.4,
    },
    rate: {
      delay: 0.1,
      quantity: 150,
    },
    size: {
      width: 0,
      height: 0,
    },
  },
}

export const CENTERED_CONFETTI_CONFIG: ISourceOptions = {
  fullScreen: {
    enable: true,
  },
  emitters: {
    position: {
      x: 50,
      y: 50,
    },
    size: {
      width: 1,
      height: 1,
    },
    life: {
      count: 1,
      duration: 0.1,
      delay: 0.4,
    },
    rate: {
      quantity: 500,
      delay: 0.15,
    },
  },
  particles: {
    color: {
      value: [colors.accentBlue, colors.teal, colors.purple, colors.primary, colors.white],
    },
    move: {
      straight: true,
      decay: 0.05,
      direction: "outside",
      enable: true,
      gravity: {
        enable: true,
      },
      outModes: {
        default: "destroy",
      },
      speed: {
        min: 50,
        max: 70,
      },
    },
    number: {
      value: 0,
    },
    opacity: {
      value: 1,
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      animation: {
        enable: true,
        speed: 30,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 30,
      },
    },
    size: {
      value: 5,
      animation: {
        enable: true,
        startValue: "min",
        count: 1,
        speed: 16,
        sync: true,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enlighten: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 5,
        max: 15,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      speed: {
        min: -7,
        max: 7,
      },
    },
    shape: {
      type: ["square"],
      options: {},
    },
  },
}
