import * as React from "react"
import { type CashSummaryChartConfig as Config } from "@digits-graphql/frontend/graphql-bearer"
import {
  SharedBarChartStyles,
  SharedLineChartStyles,
} from "src/frontend/components/OS/Shared/Charts/styles"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useCashSummaryChartLiveData } from "src/frontend/components/Shared/Layout/Components/CashSummary/useCashSummaryChartLiveData"
import { CashBadgedTitle } from "src/frontend/components/Shared/Layout/Components/Charts/CashBadge"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesBreakdownChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBreakdownChart"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"

/*
  INTERFACES
*/

interface CashSummaryChartConfigProps extends SizingProps {
  config: Config
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const CashSummaryChartConfig: React.FC<CashSummaryChartConfigProps> = ({
  config,
  height,
}) => {
  const removeConfig = useRemoveConfig()

  const { data, loading } = useCashSummaryChartLiveData(config, useViewVersion())

  const breakdownTimeseries = React.useMemo(
    () =>
      (data?.breakdownSummaries ?? []).map(({ category, history }) => ({
        label: category.name,
        values: toSortedTimeseriesValues(history),
      })),
    [data?.breakdownSummaries]
  )
  const totalCash = React.useMemo(
    () => toSortedTimeseriesValues(data?.totalCash),
    [data?.totalCash]
  )

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !data) {
      removeConfig()
    }
  }, [data, loading, removeConfig])

  return (
    <>
      <ComponentSummary
        componentSize={ComponentSize.Small}
        title={<CashBadgedTitle title="Cash Flow" />}
        timeseries={totalCash}
      />
      <ChartContainer height={height} width="auto">
        <ParentSizedTimeseriesBreakdownChart
          breakdownTimeseries={breakdownTimeseries}
          totalTimeseries={{ label: "Net Cash", values: totalCash }}
          barChartStyle={SharedBarChartStyles}
          lineChartStyle={SharedLineChartStyles}
          hideGrid
          noTooltip
          hideAxis
          skipAnimations
        />
      </ChartContainer>
    </>
  )
}
