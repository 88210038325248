import * as React from "react"
import { Link } from "react-router-dom"
import {
  type ButtonProps,
  type DigitsButtonProps,
  DigitsButtonStyle,
  LeftDecorator,
} from "@digits-shared/DesignSystem/Button"
import { ButtonContent } from "@digits-shared/DesignSystem/ButtonContent"
import styled from "styled-components"

/**
 * Digits Link Button
 *
 * Styles a <Link /> to look like the Digits Button.
 * Defaults to a primary link button with default size and rounded shape.
 * LinkButtons can have a custom width, but height should not be adjusted.
 */
export const DigitsLinkButton = styled(Link)<ButtonProps>`
  ${DigitsButtonStyle};
`

const LoadingAnchorLinkButton = React.forwardRef<
  HTMLAnchorElement,
  DigitsButtonProps<React.AnchorHTMLAttributes<HTMLAnchorElement>>
>(({ className, loading, children, label, leftDecorator, ...rest }, ref) => (
  <a ref={ref} data-loading={Boolean(loading).toString()} className={className} {...rest}>
    {leftDecorator && <LeftDecorator>{leftDecorator}</LeftDecorator>}
    <ButtonContent size={rest.size || "default"} loading={Boolean(loading)}>
      {label || children}
    </ButtonContent>
  </a>
))

/*
 * Styles an <a/> TAG to look like the Digits Button.
 * Use it only for external links, otherwise use DigitsLinkButton
 */
export const AnchorLinkButton = styled(LoadingAnchorLinkButton)<ButtonProps>`
  ${DigitsButtonStyle};
`
