import * as React from "react"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useReportIntervalOrigin(intervalCount?: number) {
  const origin = useIntervalOrigin()

  return React.useMemo(() => {
    if (intervalCount !== undefined) {
      return { ...origin, intervalCount }
    }
    return origin
  }, [intervalCount, origin])
}
