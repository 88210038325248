import * as React from "react"
import * as ReactDOM from "react-dom"
import { useAcceptTermsMutation } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsFullLogo } from "@digits-shared/components/SVGIcons/digits/DigitsFullLogo.svg"
import { Modal } from "@digits-shared/components/UI/Elements/Modals/Modal"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { usePortalElement } from "@digits-shared/hooks/usePortalElement"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { AcceptTerms } from "src/frontend/components/Shared/Legal/AcceptTerms"
import { MonitoredModalName } from "src/frontend/components/Shared/ManagedModals/state/types"
import { useMonitorModal } from "src/frontend/components/Shared/ManagedModals/useMonitorModal"
import type FrontendSession from "src/frontend/session"

const BORDER_RADIUS = 24

const StyledModal = styled(Modal)`
  width: 600px;
  height: 500px;
  background: ${colors.white};
  box-shadow: 0 0 16px rgba(139, 139, 139, 0.25);
  padding: 0;
  transition: opacity 500ms ease-in-out;
  border-radius: ${BORDER_RADIUS}px;
`
const Header = styled.div`
  height: 125px;
  width: calc(100% + 2px);
  border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.gradients.ai};
  background-size: cover;
  margin: -1px -1px 0 -1px;
`

const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 25px;
  color: ${colors.secondary};
`

const Logo = styled(SvgDigitsFullLogo)`
  width: auto;
  height: 52px;
  ${svgIconStyles(colors.white)};
`

const Title = styled.div`
  text-align: center;
  font-family: ${fonts.family.avenir};
  font-size: 24px;
  font-style: ${fonts.style.normal};
  font-weight: ${fonts.weight.heavy};
  line-height: normal;
`

const Copy = styled.div`
  text-align: center;
  font-family: ${fonts.family.avenir};
  font-size: 16px;
  font-style: ${fonts.style.normal};
  font-weight: ${fonts.weight.book};
  line-height: normal;
  letter-spacing: 0.16px;
  width: 344px;
  margin-top: 15px;
`

const StyledAcceptTerms = styled(AcceptTerms)`
  margin-top: 25px;
  justify-content: center;
  color: ${colors.secondary};
  font-size: 16px;
  font-style: ${fonts.style.normal};
  font-weight: ${fonts.weight.normal};
  line-height: normal;
`

export const TermsOfServiceModal: React.FC = () => {
  // Note: the DOM element with this ID is permanently in the index HTML template.
  const modalRoot = usePortalElement("tos-root")

  const [agreed, setAgreed] = React.useState(false)
  const session = useSession<FrontendSession>()
  const { termsOfServiceVersion: version } = session

  const [acceptTerms, { loading, called }] = useAcceptTermsMutation({
    variables: { version },
  })

  const onAgreedChanged = React.useCallback((checked: boolean) => {
    setAgreed(checked)
  }, [])

  const onAcceptClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      acceptTerms()
        .then(() => session.bearer())
        .catch((error) => TrackJS?.console.error("Failed to accept TOS", error))
    },
    [acceptTerms, session]
  )

  // monitor modal state to prevent snackbars from showing while modal is open
  useMonitorModal(MonitoredModalName.TermsOfService)

  return ReactDOM.createPortal(
    <StyledModal>
      <Header>
        <Logo />
      </Header>
      <Content>
        <Title>Terms of Service Update</Title>
        <Copy>
          Digits is continuing to evolve thanks to your feedback! Please review and accept our
          updated Terms of Service.
        </Copy>
        <StyledAcceptTerms onTermsAcceptChanged={onAgreedChanged} />

        <DigitsButton
          css={{ margin: "20px 0px" }}
          width={100}
          disabled={!agreed || loading || called}
          onClick={onAcceptClick}
          loading={loading}
        >
          Accept
        </DigitsButton>
      </Content>
    </StyledModal>,
    modalRoot
  )
}
