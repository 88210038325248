import * as React from "react"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { type MostActiveSummary } from "src/frontend/components/OS/Applications/Search/SearchResults/usePartyMostActiveSummary"
import { SummaryWithCount } from "src/shared/components/Transactions/TransactionRow/SummaryWithCount"
import { AmountAndCount } from "./Shared"

interface Props {
  summaryData?: MostActiveSummary
}

export const PartyRoleSummary: React.FC<Props> = ({ summaryData }) => {
  if (!summaryData?.summaries.length) {
    return null
  }

  const { moneyFlow } = summaryData.summaries.reduce(
    (totals, summary) => {
      totals.count += summary.total.transactionsCount
      totals.moneyFlow = moneyFlowHelper.add(totals.moneyFlow, summary.total.moneyFlow)
      return totals
    },
    {
      count: 0,
      moneyFlow: moneyFlowHelper.buildZeroMoneyFlow(
        summaryData?.summaries?.[0]?.total.moneyFlow?.businessFlow
      ),
    }
  )

  return (
    <AmountAndCount>
      <SummaryWithCount
        moneyFlow={moneyFlow}
        style={CurrencyStyle.Aggregation}
        absolute
        ignoreHover
      >
        Last 12 Months
      </SummaryWithCount>
    </AmountAndCount>
  )
}
