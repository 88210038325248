import { createSelector } from "@reduxjs/toolkit"
import {
  ManagedModalName,
  type ModalManagerState,
} from "src/frontend/components/Shared/ManagedModals/state/types"

export const modalManagerStateSelector = (state: ModalManagerState) => state

/* Modal Selectors */

export const modalNameSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.modalName
)

export const managedModalOpenSelector = createSelector(modalManagerStateSelector, (state) =>
  Boolean(state.modalName)
)

export const modalDismissalsSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.modalDismissals
)

/* Managed Modal Selectors */

export const showWelcomeModalSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.modalName === ManagedModalName.WelcomeModal
)

/* Snackbar Selectors */

export const snackbarDismissalsSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.snackbarDismissals
)

/* Monitored Modal Selectors */

export const monitoredModalSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.monitoredModals
)

export const monitoredModalOpenSelector = createSelector(
  modalManagerStateSelector,
  (state) => state.monitoredModalOpen
)
