import * as React from "react"
import {
  type Insight,
  type IntervalOrigin,
  type LayoutSection,
  ObjectKind,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheckCircle } from "@digits-shared/components/SVGIcons/line/CheckCircle.svg"
import { SvgPlusCircle } from "@digits-shared/components/SVGIcons/line/PlusCircle.svg"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import useReportPackageContext, {
  useReportPackageMode,
} from "src/frontend/components/OS/Applications/Reports/Packages/Viewer/ReportPackageContext"
import { useArchiveComponentData } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/hooks/useArchiveComponentData"
import {
  useLayoutDispatch,
  useLayoutStore,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/layoutStore"
import { createRow } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/reducer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { createInsightConfig } from "src/frontend/components/Shared/Layout/Drawer/configs"
import { useComponentIntervalOrigin } from "src/frontend/components/Shared/Layout/hooks/useComponentIntervalOrigin"
import { configToComponent } from "src/frontend/components/Shared/Layout/types"

const AddContainer = styled.div`
  padding: 0 10px 22px;
  text-align: center;
`

const SvgAddInsight = styled(SvgPlusCircle)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  ${svgPathStyles("#526d9f", 1.5)};
`

const addInsightColor = themedValue({ dark: colors.neonGreen, light: colors.primary })

const AddInsight = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: ${fonts.weight.medium};
  font-size: 14px;
  color: #526d9f;
  cursor: pointer;

  &:hover {
    color: ${addInsightColor};

    svg {
      ${(p) => svgPathStyles(addInsightColor(p), 1.5)};
    }
  }
`

const SvgAddedInsight = styled(SvgCheckCircle)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  ${(p) => svgPathStyles(addInsightColor(p), 1.5)};

  path[opacity="0.15"] {
    opacity: 1;
  }
`

const AddedInsight = styled(AddInsight)`
  color: ${addInsightColor};
  pointer-events: none;
`

interface AddInsightProps {
  insight: Insight | undefined | null
}

export const AddInsightToReport: React.FC<AddInsightProps> = ({ insight }) => {
  const origin = useComponentIntervalOrigin()
  const { value: added, setTrue: setAsAdded } = useStateBoolean(useIsAdded(insight, origin))
  const {
    packageState: { canAppendInsightsToSummary },
  } = useReportPackageContext()

  const mode = useReportPackageMode()
  const addComponent = useInsightAddition()

  const onAdd = React.useCallback(() => {
    if (!insight) return
    addComponent(insight)
    setAsAdded()
  }, [addComponent, insight, setAsAdded])

  // TODO: only supports intervalCount = 1 for now, and it doesnt support YTD insights
  if (mode === "VIEW" || (origin.intervalCount && origin.intervalCount > 1)) {
    return null
  }

  if (added) {
    return (
      <AddContainer>
        <AddedInsight>
          <SvgAddedInsight />
          Added to Executive Summary
        </AddedInsight>
      </AddContainer>
    )
  }

  if (!insight || !canAppendInsightsToSummary) return null

  return (
    <AddContainer>
      <AddInsight onClick={onAdd}>
        <SvgAddInsight />
        Add to Executive Summary
      </AddInsight>
    </AddContainer>
  )
}

function useIsAdded(insight: Insight | undefined | null, origin: IntervalOrigin) {
  const { packageState } = useReportPackageContext()

  return (
    !!insight?.subjectId &&
    !!packageState.addedInsights.find(
      (ai) =>
        ai.subjectId === insight.subjectId &&
        dateTimeHelper.areIntervalOriginsEqual(ai.origin, origin, true)
    )
  )
}

function useInsightAddition() {
  const { legalEntityId } = useViewVersion()
  const { packageDispatch } = useReportPackageContext()
  const [archiveComponentData] = useArchiveComponentData()

  // TODO: only supports intervalCount = 1 for now
  const origin = useComponentIntervalOrigin(1)

  const layout = useLayoutStore((state) => state.layout)
  const builderDispatch = useLayoutDispatch()

  const executiveSummarySection = React.useMemo(
    () =>
      (layout.sections ?? []).find((s) => s.title == "Executive Summary") ?? layout.sections?.[0],
    [layout.sections]
  )

  return React.useCallback(
    (insight: Insight) => {
      if (!insight || !executiveSummarySection?.rows) {
        return
      }

      const insightConfig = createInsightConfig(origin, {
        legalEntityId,
        kind: ObjectKind.Category,
        id: insight.subjectId,
      })

      const insightComponent = configToComponent(insightConfig)
      if (!insightComponent) return

      const newSection: LayoutSection = {
        ...executiveSummarySection,
        rows: [...executiveSummarySection.rows, createRow([insightComponent])],
      }

      builderDispatch({ type: "updateSection", section: newSection })
      packageDispatch({ type: "NEW_SUMMARY_INSIGHT", subjectId: insight.subjectId, origin })

      archiveComponentData(insightComponent).catch((error) => {
        TrackJS?.console.error(
          `Error archiving component data of type: ${insightComponent.config.type}`,
          error
        )
      })
    },
    [
      archiveComponentData,
      builderDispatch,
      executiveSummarySection,
      origin,
      legalEntityId,
      packageDispatch,
    ]
  )
}
