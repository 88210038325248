import React from "react"
import { EmployeeStatus, InvitationStatus } from "@digits-graphql/frontend/graphql-bearer"
import { usePortalViewers } from "src/frontend/components/Shared/Portals/hooks/usePortalViewers"

/** Syncs the list of employees with access to the client portal into the reducer state */
export function useEmployeesWithAccess() {
  const { loading: employeesLoading, portalViewers: employees } = usePortalViewers({
    limit: 500,
    includeInvited: true,
  })

  return React.useMemo(() => {
    const employeesWithAccess = employees?.filter((e) => e.status === EmployeeStatus.Activated)
    const employeesInvited = employees?.filter(
      (e) =>
        e.status === EmployeeStatus.Initialized && e.invitation?.status === InvitationStatus.Active
    )

    return { employeesWithAccess, employeesInvited, employeesLoading }
  }, [employees, employeesLoading])
}
