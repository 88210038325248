import {
  Interval,
  type IntervalOrigin,
  type MoneyFlow,
  type ReportDocumentOptions,
  ReportOptionComparison,
  type StatementDeltas,
  type StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { StatementSize } from "src/frontend/components/Shared/Layout/Components/Statements/shared"

export type ColumnOptionKey = keyof Omit<ReportDocumentOptions, "sparkline" | "collapsedSections">
export type ColumnKey = "triPeriodDelta" | ColumnOptionKey

export type StatementColumns = Omit<
  ReportDocumentOptions,
  "deltaMonthOverMonthPeriods" | "sparkline" | "sparklineLookbackPeriods" | "collapsedSections"
> & { triPeriodDelta?: ReportOptionComparison.TriPeriodDeltaPercent }

export function isDeltaColumn(optionKey: keyof StatementColumns, columns?: StatementColumns) {
  const comparison = columns?.[optionKey]
  if (!comparison || comparison === ReportOptionComparison.InvalidComparison) return false

  const isDelta = optionKey === "deltaMonthOverMonth" && !isBreakdownComparison(columns[optionKey])
  return optionKey !== "deltaMonthOverMonth" || isDelta
}

export function isBreakdownColumn(optionKey: ColumnKey, columns?: StatementColumns) {
  if (optionKey !== "deltaMonthOverMonth") return false
  const comparison = columns?.[optionKey]
  return isBreakdownComparison(comparison)
}

export function isBreakdownComparison(comparison: ReportOptionComparison | null | undefined) {
  return (
    comparison === ReportOptionComparison.Total ||
    comparison === ReportOptionComparison.DeltaPercent ||
    comparison === ReportOptionComparison.TriPeriodDeltaPercent
  )
}

// renders like | Apr '24 | Δ MoM (%) |
export function isDoubleColumn(
  optionKey: ColumnKey,
  comparison: ReportOptionComparison | undefined
) {
  return (
    (optionKey === "deltaYearToDate" ||
      optionKey === "deltaMonthOverMonth" ||
      optionKey === "deltaYearOverYear") &&
    (comparison === ReportOptionComparison.Amount || comparison === ReportOptionComparison.Percent)
  )
}

export function headerFormattedDate(origin: IntervalOrigin, size?: StatementSize) {
  const djs = dateTimeHelper.dayjsFromIntervalOrigin(origin)
  if (!origin || origin.interval === Interval.IntervalNone) return ""
  if (origin.intervalCount && origin.intervalCount > 1) {
    const fullFormatString = size === StatementSize.Condensed ? "MM/YY" : "MMM 'YY"
    const monthFormatString = size === StatementSize.Condensed ? "MM" : "MMM"

    return dateTimeHelper.displayNameForIntervalOriginRange(origin, {
      delimiter: " - ",
      fullFormatString,
      monthFormatString,
    })
  }
  return djs.format(intervalFormat(origin.interval))
}

export function headerTitle(
  optionKey: ColumnKey,
  comparison: ReportOptionComparison | undefined,
  origin: IntervalOrigin
) {
  const djs = dateTimeHelper.dayjsFromIntervalOrigin(origin)
  const { interval } = origin

  let comparisonSign = ""
  switch (comparison) {
    case ReportOptionComparison.Amount:
      comparisonSign = " ($)"
      break
    case ReportOptionComparison.Percent:
      comparisonSign = " (%)"
      break
    case ReportOptionComparison.TriPeriodDeltaPercent:
    case ReportOptionComparison.Total:
    case ReportOptionComparison.DeltaPercent:
    case ReportOptionComparison.InvalidComparison:
      break
  }

  switch (optionKey) {
    case "triPeriodDelta":
      return "Δ Avg (%)"
    case "comparedToTotal":
      return "% Total"
    case "comparedToIncome":
      return "% Income"
    case "deltaMonthOverMonth": {
      const prev = dateTimeHelper.addIntervalToDayjs(djs, interval, -1)
      const prefix =
        comparison === ReportOptionComparison.Total ||
        comparison === ReportOptionComparison.TriPeriodDeltaPercent
          ? ""
          : "Δ "
      const periodName = isBreakdownComparison(comparison)
        ? prev.format(intervalFormat(interval))
        : intervalOverInterval(interval)

      return `${prefix}${periodName}${comparisonSign}`
    }
    case "deltaYearOverYear": {
      return isBreakdownComparison(comparison) ? "YoY" : `Δ YoY${comparisonSign}`
    }
    case "yearToDate": {
      const currYear = djs.format(intervalFormat(Interval.Year))
      return `${currYear} YTD`
    }
    case "deltaYearToDate": {
      const prev = dateTimeHelper.addIntervalToDayjs(djs, Interval.Year, -1)
      const prevYear = prev.format(intervalFormat(Interval.Year))
      if (isBreakdownComparison(comparison)) {
        return `${prevYear} YTD`
      }
      return `Δ YTD${comparisonSign}`
    }
    case "deltaMonthOverMonthPeriods":
    case "sparklineLookbackPeriods":
    case "amountPrecision":
      return ""
  }
}

export function intervalFormat(interval: Interval) {
  switch (interval) {
    case Interval.Quarter:
      return "[Q]Q [']YY"

    case Interval.Year:
      return "YYYY"

    case Interval.Month:
      return "MMM [']YY"

    default:
      // Invalid
      return ""
  }
}

function intervalOverInterval(interval: Interval) {
  switch (interval) {
    case Interval.Quarter:
      return "QoQ"

    case Interval.Year:
      return "YoY"

    case Interval.Month:
      return "MoM"

    default:
      // Invalid
      return ""
  }
}

export function overTimeDeltaValue(
  optionKey: ColumnKey,
  seriesValues: MoneyFlow[],
  lookback: number,
  deltaMonthOverMonthPeriods: number,
  deltas?: StatementDeltas | null
) {
  const currentValue = seriesValues[0]
  if (!currentValue) {
    return {
      moneyFlow: undefined,
      deltaAmount: undefined,
      percentValue: undefined,
    }
  }

  switch (optionKey) {
    case "triPeriodDelta": {
      const count = Math.max(deltaMonthOverMonthPeriods, 2)
      if (seriesValues.length < count + 1) {
        return {
          moneyFlow: undefined,
          deltaAmount: undefined,
          percentValue: undefined,
        }
      }

      const lookups = seriesValues.slice(1, count + 1)
      const moneyFlow = moneyFlowHelper.calculateMean(...lookups)
      const deltaAmount = moneyFlowHelper.subtract(currentValue, moneyFlow)
      const percentValue = moneyFlowHelper.percentage(currentValue, moneyFlow)

      const deltaValue: StatementDeltaValue = {
        percentageOfAmount: percentValue,
        moneyFlow: deltaAmount,
      }

      return {
        moneyFlow,
        deltaValue,
        deltaAmount,
        percentValue,
      }
    }

    case "deltaMonthOverMonth":
    case "yearToDate":
    case "deltaYearToDate": {
      const nextValue = seriesValues[lookback]
      if (!nextValue) {
        return {
          moneyFlow: undefined,
          deltaAmount: undefined,
          percentValue: undefined,
        }
      }

      const moneyFlow = nextValue
      const deltaAmount = moneyFlowHelper.subtract(currentValue, nextValue)
      const percentValue = moneyFlowHelper.percentage(currentValue, nextValue)
      const deltaValue: StatementDeltaValue = {
        percentageOfAmount: percentValue,
        moneyFlow: deltaAmount,
      }

      return {
        moneyFlow,
        deltaValue,
        deltaAmount,
        percentValue,
      }
    }
    default: {
      const deltaValue = getDeltaValue(deltas, optionKey)
      const deltaAmount = deltaValue?.moneyFlow
      const percentValue = deltaValue?.percentageOfAmount

      const moneyFlow = deltaValue?.moneyFlow
        ? moneyFlowHelper.subtract(currentValue, deltaValue.moneyFlow)
        : undefined

      return {
        moneyFlow,
        deltaValue,
        deltaAmount,
        percentValue,
      }
    }
  }
}

function getDeltaValue(deltas: StatementDeltas | undefined | null, option: ColumnKey) {
  switch (option) {
    case "comparedToTotal":
      return deltas?.percentageTotal
    case "comparedToIncome":
      return deltas?.percentageIncome
    case "deltaYearOverYear":
      return deltas?.previousYear
    case "yearToDate":
    case "deltaYearToDate":
    case "deltaMonthOverMonth":
    case "deltaMonthOverMonthPeriods":
    case "sparklineLookbackPeriods":
    case "amountPrecision":
      return undefined
  }
}
