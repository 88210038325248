import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export const DISPLAY_OPTIONS_FILTER_LOADING_TEXT = "Loading…"

export const DisplayOptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 16px 12px;
`

export const DisplayOptionsColumnTitle = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  font-size: 10px;
  font-weight: ${fonts.weight.heavy};
`
