import * as React from "react"
import { SortDirection } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowCircleDown } from "@digits-shared/components/SVGIcons/line/ArrowCircleDown.svg"
import { SvgArrowCircleUp } from "@digits-shared/components/SVGIcons/line/ArrowCircleUp.svg"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { type DisplayOptionAccessor } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import {
  DisplayOptionsColumn,
  DisplayOptionsColumnTitle,
} from "src/frontend/components/OS/Shared/DisplayOptions/Shared"
import { type DimensionSort } from "src/frontend/types/DimensionFilter"

/*
  STYLES
*/

const DimensionCells = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SortDimensionCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-color: ${colors.secondary};
  }

  padding: 8px;
  border: 1px solid ${colors.secondary20};

  &.selected {
    border-color: ${colors.secondary};
  }

  svg {
    height: 20px;
    width: 20px;
    ${svgPathStyles(colors.secondary, 1.5)};
  }
`

const Direction = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  opacity: 0;
  .selected & {
    opacity: 1;
  }

  svg {
    height: 28px;
    width: 28px;
    ${svgPathStyles(colors.accentBlue, 1.5)};
    border-radius: 100px;
    padding: 3px;
    background: #eaedf2;
  }
`

/*
  COMPONENTS
*/

interface Props {
  className?: string
  dimensions: DimensionSort[]
  sortDirection: DisplayOptionAccessor<SortDirection>
}

export const SortColumn: React.FC<Props> = ({ className, dimensions, sortDirection }) => {
  const onToggleDirection = React.useCallback(() => {
    sortDirection.set(
      sortDirection.get() === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending
    )
  }, [sortDirection])

  return (
    <DisplayOptionsColumn className={className}>
      <DisplayOptionsColumnTitle>Sort By</DisplayOptionsColumnTitle>
      <DimensionCells>
        {dimensions.map(({ title, TitleIconElement, selected, onSelect }) => (
          <SortDimensionCell
            key={title}
            className={stringHelper.classNames(selected && "selected")}
            onClick={selected ? onToggleDirection : onSelect}
          >
            <TitleIconElement />
            <div css="flex: 1">{title}</div>
            <Direction>
              {sortDirection.get() === "Descending" && <SvgArrowCircleDown />}
              {sortDirection.get() === "Ascending" && <SvgArrowCircleUp />}
            </Direction>
          </SortDimensionCell>
        ))}
      </DimensionCells>
    </DisplayOptionsColumn>
  )
}
