import * as React from "react"
import { usePrevious } from "react-use"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { animated, useSpring, type UseSpringProps } from "@react-spring/web"
import styled, { css } from "styled-components"

const DEFAULTS = {
  bounce: 1,
  damping: 1,
  friction: 20,
  mass: 1,
  precision: 0.01,
  tension: 170,
  velocity: 0,
}

export interface DimensionAnimationOptions {
  onComplete?: () => void
  suppressInitialAnimation?: boolean
}

// "size" for height, "inline-size" for width
type ContainerType = { $containerType?: "inline-size" | "normal" | "size" }

const Container = styled.div<ContainerType>`
  will-change: height, width;

  ${({ $containerType }) =>
    $containerType &&
    css`
      container-type: ${$containerType};
    `};
`

export const ExpandableContainer = animated<React.ElementType>(Container)

export function useDimensionAnimation<T extends HTMLElement = HTMLDivElement>(
  dimension: "height" | "width",
  controls: Pick<UseSpringProps, "config" | "loop" | "reset" | "reverse" | "delay"> = {
    config: DEFAULTS,
    delay: 0,
  },
  options: DimensionAnimationOptions = {}
) {
  const { onComplete, suppressInitialAnimation } = options

  // Gets the dimension of the list
  const [ref, { [dimension]: dim }] = useMeasure<T>()
  const prevDimension = usePrevious(dim)

  // Animation
  const { value: atRest, setTrue: setAtRest, setFalse: unRest } = useStateBoolean()
  React.useEffect(() => {
    if (atRest && controls.reverse) {
      unRest()
    }
  }, [atRest, controls.reverse, unRest])

  const spring = useSpring({
    ...controls,
    delay: prevDimension ? 0 : controls.delay, // if there was a previous value (already rendered, don't delay)
    from: { [dimension]: "0px" },
    to: { [dimension]: `${dim}px` },
    immediate: suppressInitialAnimation ? !prevDimension : undefined,
    onRest: () => {
      setAtRest()
      onComplete?.()
    },
  })

  const style: Partial<CSSStyleDeclaration> = {}
  // Hide overflow while animation is happening, but unset once complete so tooltips aren't clipped
  if (!atRest) {
    style.overflow = "hidden"
  }

  if (atRest && !controls.reverse) {
    // unlock the animated dimension to avoid truncation
    style[dimension] = "auto"
  }

  return { style: { ...spring, ...style }, ref }
}
