import * as React from "react"
import { shallow } from "zustand/shallow"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"

export function useInviteClientsModalControls() {
  const portalDispatch = usePortalDispatch()
  const { showInviteClientsModal } = usePortalStore(
    (state) => ({
      showInviteClientsModal: state.showInviteClientsModal,
    }),
    shallow
  )

  const showModal = React.useCallback(() => {
    portalDispatch({ type: "setShowInviteClientsModal", show: true })
  }, [portalDispatch])

  const hideModal = React.useCallback(() => {
    portalDispatch({ type: "setShowInviteClientsModal", show: false })
  }, [portalDispatch])

  return React.useMemo(
    () => ({
      shouldShow: showInviteClientsModal,
      showModal,
      hideModal,
    }),
    [hideModal, showInviteClientsModal, showModal]
  )
}
