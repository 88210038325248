import * as React from "react"
import { type HitHighlight } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

const Text = styled.span``

const Match = styled.span`
  color: ${colors.accentBlue};
  font-weight: ${fonts.weight.heavy};
`

/*
  INTERFACE
*/

interface Props {
  fieldName: string | string[]
  children: string | string[]
  highlights: HitHighlight[]
}

/*
  COMPONENT
*/

const MatchedText: React.FC<Props> = ({ fieldName, children, highlights }) => {
  let remainingText = Array.isArray(children) ? children.join("") : children
  const matchedChildren: React.JSX.Element[] = [
    <React.Fragment key={remainingText}>{remainingText}</React.Fragment>,
  ]

  const fieldNames = Array.isArray(fieldName) ? fieldName : [fieldName]

  highlights
    .filter((h) => fieldNames.includes(h.field))
    .sort((h1, h2) => h2.start - h1.start) // making sure highlights are processed in reversed order
    .forEach((highlight, idx) => {
      const { term, start, end } = highlight
      const substr = remainingText.substring(start, end)
      if (substr.trim().toLocaleLowerCase() !== term.trim().toLocaleLowerCase()) {
        return
      }

      const preMatch = remainingText.substring(0, start)
      const match = <Match key={`${substr}${idx}`}>{substr}</Match>
      const postMatch = remainingText.substring(end)

      matchedChildren[0] = <React.Fragment key={`pre${term}${idx}`}>{preMatch}</React.Fragment>
      const postMatchChild = <React.Fragment key={`post${term}${idx}`}>{postMatch}</React.Fragment>
      matchedChildren.splice(1, 0, match, postMatchChild)

      remainingText = preMatch
    })
  return <Text>{matchedChildren}</Text>
}
export default MatchedText
