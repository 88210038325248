import { produce } from "immer"
import { type ModalManagerAction } from "src/frontend/components/Shared/ManagedModals/state/actions"
import { type ModalManagerState } from "src/frontend/components/Shared/ManagedModals/state/types"

export const INITIAL_STATE: ModalManagerState = {
  modalName: null,
  modalDismissals: new Set(),
  snackbarDismissals: new Set(),
  monitoredModals: new Map(),
  monitoredModalOpen: false,
}

export const reducer = produce((curState: ModalManagerState, action: ModalManagerAction) => {
  switch (action.type) {
    case "OpenModal": {
      curState.modalName = action.name
      break
    }

    case "CloseModal": {
      if (curState.modalName === action.name) {
        // do not close if a different modal has already taken precedence
        curState.modalName = null
      }

      if (action.dismissal) {
        curState.modalDismissals.add(action.name)
      }

      break
    }

    case "CloseSnackbar": {
      if (action.dismissal) {
        curState.snackbarDismissals.add(action.name)
      }
      break
    }

    case "UpdateMonitoredModal": {
      curState.monitoredModals.set(action.name, action.open)
      if (action.open) {
        curState.monitoredModalOpen = true
      } else {
        // if the monitored modal is now closed, check if there are any other monitored modals still open
        curState.monitoredModalOpen = Array.from(curState.monitoredModals.values()).some(
          (open) => open
        )
      }
      break
    }
  }

  return undefined
})
