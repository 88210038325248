import { assign, createMachine } from "xstate"
import {
  MAX_SUGGESTION_COUNT,
  SUGGESTION_DURATION,
} from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"

type QueryChangedEvent = {
  type: "QUERY_CHANGED"
  query: string
}

type TermsChangedEvent = {
  type: "TERMS_CHANGED"
  terms: string[]
}

export type SuggestionsStateMachineEvent = QueryChangedEvent | TermsChangedEvent

export type SuggestionsStateMachineContext = {
  query: string
  // terms is set to undefined initially, and when terms should be cleared from the screen
  // during a refetch. This is a separate state from [], which represents a completed terms
  // lookup that produced no other term expansions. [] is considered "empty", which triggers
  // the suggestions area to be collapsed.
  terms: string[] | undefined
}

const assignQuery = assign<SuggestionsStateMachineContext, QueryChangedEvent>({
  query: (ctx, e) => e.query,
})

const assignTerms = assign<SuggestionsStateMachineContext, TermsChangedEvent>({
  terms: (ctx, e) =>
    e.terms
      .filter((term) => term.toLowerCase() !== ctx.query.toLowerCase())
      .slice(0, MAX_SUGGESTION_COUNT),
})

/** Tag for states in which the insights suggestions should be hidden */
export const INSIGHTS_HIDDEN_TAG = "INSIGHTS_HIDDEN"

/** Tag for states in which the terms suggestions should be hidden */
export const TERMS_HIDDEN_TAG = "TERMS_HIDDEN"

/** Tag for states in which the suggestions area should be collapsed */
export const SUGGESTIONS_COLLAPSED_TAG = "SUGGESTIONS_COLLAPSED"

/** State machine which controls the behavior of the search suggestions area */
export const suggestionsStateMachine = createMachine<
  SuggestionsStateMachineContext,
  SuggestionsStateMachineEvent
>({
  predictableActionArguments: true,
  id: "searchSuggestions",
  initial: "start",
  context: {
    query: "",
    terms: undefined,
  },
  // Assigns values through to the context for any states that don't explicitly
  // declare this event handling
  on: {
    QUERY_CHANGED: {
      actions: [assignQuery],
    },
    TERMS_CHANGED: {
      actions: [assignTerms],
    },
  },
  states: {
    // Begin here before we've been told what the initial query string is
    start: {
      tags: [INSIGHTS_HIDDEN_TAG, TERMS_HIDDEN_TAG],
      on: {
        QUERY_CHANGED: [
          {
            target: "showInsights",
            actions: [assignQuery],
            cond: queryEmpty,
          },
          {
            target: "showTerms",
            actions: [assignQuery],
            cond: queryPresent,
          },
        ],
      },
    },
    // State where the suggestions are is collapsed, and neither insights
    // nor terms suggestions are shown.
    showNothing: {
      tags: [SUGGESTIONS_COLLAPSED_TAG, INSIGHTS_HIDDEN_TAG, TERMS_HIDDEN_TAG],
      on: {
        QUERY_CHANGED: [
          {
            target: "showInsights",
            actions: [assignQuery],
            cond: queryEmpty,
          },
          {
            target: "dismissTerms",
            actions: [assignQuery],
            cond: queryPresent,
          },
        ],
      },
    },
    // No query is present, and we're showing the insight suggestions
    showInsights: {
      tags: [TERMS_HIDDEN_TAG],
      on: {
        QUERY_CHANGED: [
          {
            target: "dismissTerms",
            actions: [assignQuery],
            cond: queryPresent,
          },
        ],
      },
    },
    // A query is present and we're showing term suggestions
    showTerms: {
      tags: [INSIGHTS_HIDDEN_TAG],
      always: [
        {
          target: "showNothing",
          cond: termsEmpty,
        },
      ],
      on: {
        QUERY_CHANGED: [
          {
            target: "showInsights",
            actions: [assignQuery],
            cond: queryEmpty,
          },
          {
            target: "dismissTerms",
            actions: [assignQuery],
            cond: queryPresent,
          },
        ],
      },
    },
    // Transitional state which delays for term exit animations to complete.
    // Also responsible for setting the terms in the context to undefined,
    // which clears the terms from the screen without collapsing the area.
    dismissTerms: {
      tags: [INSIGHTS_HIDDEN_TAG],
      entry: assign<SuggestionsStateMachineContext, SuggestionsStateMachineEvent>({
        terms: undefined,
      }),
      after: {
        [SUGGESTION_DURATION]: "awaitTerms",
      },
    },
    // Transitional state which we're in while terms results are loading
    awaitTerms: {
      tags: [INSIGHTS_HIDDEN_TAG],
      always: [
        {
          target: "showTerms",
          cond: termsPresent,
        },
        {
          target: "showNothing",
          cond: termsEmpty,
        },
      ],
      on: {
        QUERY_CHANGED: [
          {
            target: "showInsights",
            actions: [assignQuery],
            cond: queryEmpty,
          },
        ],
      },
    },
  },
})

/** Conditional guard which returns true if the event query text is the empty string */
function queryEmpty(context: SuggestionsStateMachineContext, event: QueryChangedEvent): boolean {
  return !event.query
}

/** Conditional guard which returns true if the event query text non-empty */
function queryPresent(context: SuggestionsStateMachineContext, event: QueryChangedEvent): boolean {
  return !!event.query
}

/**
 * Conditional guard which returns true if the terms array is defined, and empty.
 * terms === undefined is intentionally _not_ considered empty for this purpose.
 */
function termsEmpty(context: SuggestionsStateMachineContext): boolean {
  return !!context.terms && !context.terms.length
}

/** Conditional guard which returns true if the terms array is defined, and has values. */
function termsPresent(context: SuggestionsStateMachineContext): boolean {
  return !!context.terms?.length
}
