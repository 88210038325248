import * as React from "react"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@digits-shared/DesignSystem/Popovers/Popover"
import styled from "styled-components"

/*
  STYLES
*/

const PopupContainer = styled.div`
  display: flex;
`

/*
  COMPONENTS
*/

interface DisplayOptionsProps {
  title: string
  TitleIconElement: React.ElementType
  className?: string
  children?: React.ReactNode
}

export const DisplayOptionsSection: React.FC<DisplayOptionsProps> = ({
  title,
  TitleIconElement,
  className,
  children,
}) => (
  <Popover>
    <PopoverTrigger asChild>
      <DigitsButton className={className} $variant="secondary-dark" size="medium">
        <TitleIconElement />
        {title}
      </DigitsButton>
    </PopoverTrigger>

    <PopoverContent align="end" showCloseButton={false}>
      <PopupContainer>{children}</PopupContainer>
    </PopoverContent>
  </Popover>
)
