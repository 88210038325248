import {
  LegalEntityStatus,
  type SessionLegalEntity as GraphqlSessionEntity,
  type SessionViewKey,
  ViewType,
} from "@digits-graphql/frontend/graphql-public"
import { type AspectCode } from "@digits-shared/session/AspectCode"
import type DoppelgangerAccessLevel from "@digits-shared/session/DGAccessLevel"
import {
  type JWTAccountingViewVersions,
  type JWTLegalEntity,
} from "@digits-shared/session/jwt/jwtLegalEntity"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"

export enum ViewTypeCode {
  Accrual = "a",
  Ledger = "l",
  AIBookeeper = "k",
}

type ViewVersionsMap = Map<ViewType, string>
type ViewVersions = { accountingViewVersions: ViewVersionsMap; mutationVersions: ViewVersionsMap }

export interface SessionLegalEntityAttributes {
  readonly id: string
  readonly slug: string
  readonly name: string
  readonly status: LegalEntityStatus
  readonly accountingViewVersions: ViewVersionsMap
  readonly mutationVersions: ViewVersionsMap
  readonly source: "jwt" | "graphql"

  readonly organizationId: string
  readonly affiliationId?: string

  readonly aspects: Map<AspectCode, string>
  readonly termsAcceptedAt: Date | undefined
}

function isGraphqlSessionEntity(
  rawEntity: JWTLegalEntity | GraphqlSessionEntity
): rawEntity is GraphqlSessionEntity {
  return !!(rawEntity as GraphqlSessionEntity)?.viewKeys
}

function isJWTLegalEntity(
  rawEntity: JWTLegalEntity | GraphqlSessionEntity
): rawEntity is JWTLegalEntity {
  return !isGraphqlSessionEntity(rawEntity)
}

/**
 * Simple wrapper for legal entities stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export default class SessionLegalEntity {
  private readonly attributes: SessionLegalEntityAttributes

  constructor(
    organizationId: string,
    rawEntity: JWTLegalEntity | GraphqlSessionEntity,
    affiliationId?: string
  ) {
    const aspects = new Map<AspectCode, string>()
    rawEntity.aspects?.forEach((aspect) => aspects.set(aspect as AspectCode, aspect))

    let termsAcceptedAt: Date | undefined
    if (isJWTLegalEntity(rawEntity)) {
      const terms = rawEntity?.terms
      termsAcceptedAt = terms ? dayjs(terms, "YYYY-MM-DD").toDate() : undefined
    }

    const { accountingViewVersions, mutationVersions } = isGraphqlSessionEntity(rawEntity)
      ? graphqlViewVersions(rawEntity.viewKeys)
      : jwtViewVersions(rawEntity.avvs, rawEntity.mvvs)

    const source = isGraphqlSessionEntity(rawEntity) ? "graphql" : "jwt"

    this.attributes = {
      ...rawEntity,
      organizationId,
      affiliationId,
      aspects,
      accountingViewVersions,
      mutationVersions,
      termsAcceptedAt,
      source,
    }
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get slug() {
    return this.attributes.slug
  }

  get name() {
    return this.attributes.name
  }

  get status() {
    return this.attributes.status
  }

  get accountingViewVersions() {
    return this.attributes.accountingViewVersions
  }

  get mutationViewVersions() {
    return this.attributes.mutationVersions
  }

  get organizationId() {
    return this.attributes.organizationId
  }

  get affiliationId() {
    return this.attributes.affiliationId
  }

  get aspects() {
    return this.attributes.aspects
  }

  get termsAcceptedAt() {
    return this.attributes.termsAcceptedAt
  }

  get source() {
    return this.attributes.source
  }

  /*
    CONVENIENCE METHODS
  */

  get isApproved() {
    return this.status === LegalEntityStatus.Approved
  }

  get isActive() {
    return this.status === LegalEntityStatus.Active
  }

  hasAccessToAspect(aspect?: AspectCode) {
    return !!aspect && this.aspects.has(aspect)
  }

  hasDashboardAccess(doppelganger?: DoppelgangerAccessLevel) {
    return this.isApproved || this.isActive || doppelganger?.hasDashboardAccess
  }

  /*
    For now, consider Pending and PendingHold to mean the same thing.
   */
  get isPending() {
    return (
      this.status === LegalEntityStatus.Pending || this.status === LegalEntityStatus.PendingHold
    )
  }

  get isNew() {
    return this.status === LegalEntityStatus.New
  }

  get isAffiliated() {
    return !!this.affiliationId
  }
}

function jwtViewVersions(
  viewVersion: JWTAccountingViewVersions | undefined,
  mutationVersion: JWTAccountingViewVersions | undefined
): ViewVersions {
  const accountingViewVersions = mapJWTViewVersion(viewVersion)
  const mutationVersions = mapJWTViewVersion(mutationVersion)
  return { accountingViewVersions, mutationVersions }
}

function mapJWTViewVersion(viewVersions: JWTAccountingViewVersions | undefined): ViewVersionsMap {
  return Object.entries(viewVersions ?? {}).reduce((map, [vt, avv]) => {
    switch (vt) {
      case ViewTypeCode.Ledger:
        map.set(ViewType.Ledger, avv)
        break
      case ViewTypeCode.AIBookeeper:
        map.set(ViewType.AIBookkeeper, avv)
        break
    }
    return map
  }, new Map())
}

function graphqlViewVersions(viewKeys: SessionViewKey[]): ViewVersions {
  return viewKeys.reduce(
    (maps, viewKey) => {
      const { accountingViewVersions, mutationVersions } = maps
      if (viewKey.viewType && viewKey.viewVersion) {
        accountingViewVersions.set(viewKey.viewType, viewKey.viewVersion)
      }
      if (viewKey.viewType && viewKey.mutationVersion) {
        mutationVersions.set(viewKey.viewType, viewKey.mutationVersion)
      }
      return maps
    },
    { accountingViewVersions: new Map(), mutationVersions: new Map() } as ViewVersions
  )
}
