import { storage } from "@digits-shared/helpers/storage/storage"

/**
 * Stores a preference in local storage using the key `identifier::currentLegalEntityId`
 */
export function setLocalPreference(
  identifier: string,
  currentLegalEntityId: string,
  preference?: string
) {
  storage.local.setItem(
    makeStorageKey(identifier, currentLegalEntityId),
    typeof preference === "string" ? preference : Date.now().toString()
  )
}

/**
 * Retrieves a preference from local storage using the key `identifier::currentLegalEntityId`
 */
export function getLocalPreference(identifier: string, currentLegalEntityId: string) {
  return storage.local.getItem(makeStorageKey(identifier, currentLegalEntityId))
}

/**
 * Returns true if the key `identifier::currentLegalEntityId` has been set in local storage
 */
export function hasLocalPreference(identifier: string, currentLegalEntityId: string) {
  return Boolean(storage.local.getItem(makeStorageKey(identifier, currentLegalEntityId)))
}

/**
 * Removes a preference from local storage using the key `identifier::currentLegalEntityId`
 */
export function removeLocalPreference(identifier: string, currentLegalEntityId: string) {
  storage.local.removeItem(makeStorageKey(identifier, currentLegalEntityId))
}

/**
 * Returns a key in the form `identifier::currentLegalEntityId`
 */
function makeStorageKey(id: string, currentLegalEntityId: string) {
  return `${id}::${currentLegalEntityId}`
}
