import * as React from "react"
import { Link, type LinkProps, useRouteMatch } from "react-router-dom"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronRight } from "@digits-shared/components/SVGIcons/line/ChevronRight.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ComponentWrapper } from "src/frontend/components/Shared/Layout/Shared"
import routes from "src/frontend/routes"

const ExpandLink = styled(Link)`
  &.disabled {
    pointer-events: none;
  }

  display: flex;
  align-items: center;
  gap: 4px;
`

const ExpandContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ExpandIcon = styled(SvgChevronRight)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  ${svgPathStyles(colors.secondary, 1.5)};

  display: none;
  opacity: 0;
  ${ComponentWrapper}:hover a > & {
    display: unset;
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
`

const ExpandButton = styled(DigitsButton)`
  padding: 0 10px;
  font-size: 10px;
  height: 24px;
  margin-left: 4px;
  display: none;
  opacity: 0;
  ${ComponentWrapper}:hover & {
    display: unset;
    opacity: 1;
  }
`

type ComponentExpandLinkProps = Omit<LinkProps, "to"> & {
  componentSize: ComponentSize
  linkBadge?: React.ReactNode
  to?: LinkProps["to"]
}

export const ComponentExpandLink: React.FC<React.PropsWithChildren<ComponentExpandLinkProps>> = ({
  to,
  children,
  linkBadge,
  componentSize,
  ...linkProps
}) => {
  const isHomeView = !!useRouteMatch(routes.legalEntityHome.parameterizedPath)
  const isDetailsView = !!useRouteMatch(routes.layoutComponentDetails.parameterizedPath)
  const isEditView = !!useRouteMatch(routes.legalEntityHomeEdit.parameterizedPath)
  const isReportEditView = !!useRouteMatch(routes.reportPackageEditVersion.parameterizedPath)

  const safeTo = to || "#"
  const showExpandIcon = to && !isDetailsView && !isEditView && !isReportEditView
  const icon =
    componentSize.isSmall || isHomeView ? (
      <ExpandIcon />
    ) : (
      <ExpandButton $variant="secondary-dark">View Details</ExpandButton>
    )

  return (
    <ExpandLink
      className={stringHelper.classNames(!showExpandIcon && "disabled")}
      to={safeTo}
      {...linkProps}
    >
      <DigitsTooltip
        css="overflow: hidden"
        content={children}
        disabled={componentSize.isLarge || componentSize.isExtraLarge}
      >
        <ExpandContent>{children}</ExpandContent>
      </DigitsTooltip>
      {linkBadge}
      {showExpandIcon && icon}
    </ExpandLink>
  )
}
