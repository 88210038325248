import { type InviteClientState } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import { useEmployeesWithAccess } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/useEmployeesWithAccess"

export function useSuggestedUsers(inviteClientState: InviteClientState) {
  const { employeesWithAccess } = useEmployeesWithAccess()

  // suggested users is the set of auto complete users except any user in the set of employees or that's in the pending list
  return inviteClientState.autocompleteUsers.filter((user) => {
    const isInvitee = inviteClientState.invitees.find((u) => u.emailAddress === user.emailAddress)
    const isEmployee = employeesWithAccess?.find((u) => u.emailAddress === user.emailAddress)
    return !isInvitee && !isEmployee
  })
}
