import * as React from "react"
import {
  BOTTOM_CONFETTI_CONFIG,
  BURSTS_CONFETTI_CONFIG,
  CENTERED_CONFETTI_CONFIG,
  SIDE_CONFETTI_CONFIG,
} from "@digits-shared/DesignSystem/Animations/confettiConfigurations"
import { LazyParticles } from "@digits-shared/DesignSystem/Animations/LazyParticles"
import styled, { keyframes } from "styled-components"

export enum CONFETTI_TYPE {
  SIDE,
  CENTER,
  BOTTOM,
  BURSTS,
}

const ANIMATION_DELAY = 500
const ANIMATION_DURATION = 4000

const FADE_OUT = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
const ParticlesWrapper = styled.div`
  pointer-events: none;
  animation: ${FADE_OUT} ${ANIMATION_DURATION}ms ${ANIMATION_DELAY}ms ease-in-out forwards;
  z-index: 10;
`

export const Confetti: React.FC<{
  className?: string
  id?: string
  type?: CONFETTI_TYPE
  onAnimationEnd?: () => void
}> = React.memo(
  ({ className, id = "ts-particles-confetti", type = BOTTOM_CONFETTI_CONFIG, onAnimationEnd }) => {
    const CONFIG = React.useMemo(() => {
      switch (type) {
        case CONFETTI_TYPE.SIDE:
          return SIDE_CONFETTI_CONFIG
        case CONFETTI_TYPE.CENTER:
          return CENTERED_CONFETTI_CONFIG
        case CONFETTI_TYPE.BOTTOM:
          return BOTTOM_CONFETTI_CONFIG
        case CONFETTI_TYPE.BURSTS:
          return BURSTS_CONFETTI_CONFIG
        default:
          return BOTTOM_CONFETTI_CONFIG
      }
    }, [type])

    const onAnimationEndCallback = React.useCallback(
      (event: React.AnimationEvent) => {
        if (event.animationName !== FADE_OUT.getName()) return
        onAnimationEnd?.()
      },
      [onAnimationEnd]
    )

    return (
      <ParticlesWrapper className={className} onAnimationEnd={onAnimationEndCallback}>
        <LazyParticles id={id} options={CONFIG} />
      </ParticlesWrapper>
    )
  }
)
