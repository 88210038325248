import * as React from "react"
import { type EntityTransaction, type SearchHit } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { RowContent } from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import useSearchContext from "src/frontend/components/OS/Applications/Search/SearchContext"
import { ResultAmount } from "src/frontend/components/OS/Applications/Search/SearchResults/Shared"
import { transactionListRequestVariables } from "src/frontend/components/OS/Applications/Shared/Transactions/transactionListRequestVariables"
import routes from "src/frontend/routes"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { MissingParty } from "src/shared/components/Transactions/TransactionTableCell/Shared"
import MatchedText from "./MatchedText"
import {
  GridRow,
  Month,
  ResultDate,
  ResultDescription,
  ResultTitle,
  type RowProps,
  Year,
} from "./ResultRow"

/*
  STYLE
*/

const Details = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr 1.5fr;
`

const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const CategoryIcon = styled(SVGIconComponent)`
  height: 15px;
  min-width: 15px;
  width: 15px;
  ${svgPathStyles(colors.secondary70, 1.5)};
`

const CategoryName = styled(BodyText)`
  width: 100%;
  font-weight: ${fonts.weight.medium};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colors.secondary};
`

/*
  INTERFACES
*/

interface TransactionDetailsProps {
  entity: EntityTransaction
  hit: SearchHit
}

/*
  COMPONENT
*/

const TransactionResult: React.FC<RowProps<EntityTransaction>> = ({ entity, hit }) => {
  const { timestamp, moneyFlow } = entity
  const { query } = useSearchContext()

  const date = dayjs.unix(timestamp).utc()

  const amountWithCurrency = moneyFlowHelper.currency(moneyFlow, {
    absolute: true,
    style: CurrencyStyle.Detail,
  })

  const transactionPath = routes.defaultTransactionDetails.generateFromCurrentPath({
    transactionId: entity.factId,
  })

  return (
    <GridRow
      to={{
        pathname: transactionPath,
        state: transactionListRequestVariables.toLocationState({
          filter: {
            filterTerm: query.text,
          },
        }),
      }}
    >
      <ResultDate>
        <Month>{date.format("MMM DD")}</Month>
        <Year>{date.format("YYYY")}</Year>
      </ResultDate>
      <TransactionDetails entity={entity} hit={hit} />

      <ResultAmount
        isNormal={moneyFlow.isNormal}
        flow={moneyFlow.businessFlow}
        isZero={moneyFlow.value.amount === 0}
      >
        {amountWithCurrency}
      </ResultAmount>
    </GridRow>
  )
}

export default TransactionResult

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ entity, hit }) => {
  const { party, displayCategory, description } = entity
  const title = party?.name || description || entity.name || "No Party"
  const descriptionField = party?.name ? "party.name" : description ? "description" : "name"

  return (
    <Details>
      {party && <PartyIcon party={party} css="margin-right: 10px;" disableHoverBorder />}
      {!party && <MissingParty />}

      <RowContent>
        <ResultTitle>
          <MatchedText fieldName={descriptionField} highlights={hit.highlights}>
            {title}
          </MatchedText>
        </ResultTitle>
        {description && <ResultDescription>{description}</ResultDescription>}
      </RowContent>

      <Category>
        <CategoryIcon subjectDisplayKey={displayCategory.displayKey || displayCategory.name} />

        <CategoryName>
          <MatchedText
            fieldName={["debit_category.name", "credit_category.name"]}
            highlights={hit.highlights}
          >
            {displayCategory.name || "Uncategorized"}
          </MatchedText>
        </CategoryName>
      </Category>
    </Details>
  )
}
