import * as React from "react"
import { Link, type LinkProps } from "react-router-dom"
import {
  type EntityCategory,
  type EntityDepartment,
  type EntityInvoice,
  type EntityLocation,
  type EntityParty,
  type EntityReport,
  type EntityTransaction,
  type EntityUser,
  type SearchHit,
} from "@digits-graphql/frontend/graphql-bearer"
import { HOVERABLE_CLASS_NAME, SELECTED_CLASS_NAME } from "@digits-shared/components/UI/Table"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

const ResultsLink = styled(Link)`
  display: block;
  padding: 15px 25px;

  width: 100%;
  font-weight: ${fonts.weight.normal};

  transition:
    background-color 200ms ease,
    border-color 200ms ease,
    box-shadow 200ms ease;

  border-top: 1px solid rgba(50, 73, 127, 0.1);
  border-left: 1px solid ${colors.transparent};
  border-right: 1px solid ${colors.transparent};

  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &:hover,
  &.${SELECTED_CLASS_NAME} {
    border-top-color: rgba(50, 73, 127, 0.2);
    box-shadow: 0 0 12px 0 rgba(50, 73, 127, 0.15);
    background-color: ${colors.white};
  }
`

export const ResultTitle = styled.div`
  color: ${colors.secondary};
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ResultDescription = styled.div`
  color: ${colors.translucentSecondary60};
  font-weight: ${fonts.weight.normal};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ResultDate = styled.div`
  color: ${colors.secondary};
  font-weight: ${fonts.weight.black};
  font-size: 10px;
`

export const Month = styled.div`
  margin-top: 1px;
`

export const Year = styled.div`
  font-weight: ${fonts.weight.book};
`

/*
  INTERFACES
*/

type SupportedEntities =
  | EntityTransaction
  | EntityParty
  | EntityReport
  | EntityUser
  | EntityCategory
  | EntityInvoice
  | EntityDepartment
  | EntityLocation

export interface RowProps<T extends SupportedEntities> {
  entity: T
  hit: SearchHit
}

interface ResultRowProps {
  className?: string
}

/*
  COMPONENTS
*/

export const ResultRow: React.FC<LinkProps & ResultRowProps> = ({ className, ...rest }) => {
  const linkClassNames = [HOVERABLE_CLASS_NAME]
  if (className) {
    linkClassNames.push(className)
  }

  return <ResultsLink className={linkClassNames.join(" ")} {...rest} />
}

export const GridRow = styled(ResultRow)`
  display: grid;
  grid-template-columns: 55px minmax(0, 1fr) minmax(50px, auto);
  grid-column-gap: 5px;
  align-items: center;
`
