import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { type ScaleBand, type ScaleLinear, type ScaleTime } from "d3-scale"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { type TimeseriesAxisStyle } from "src/frontend/components/OS/Shared/Charts/styles"
import { type TimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

export const X_AXIS_HEIGHT = 32
export const Y_AXIS_WIDTH = 26
export const DOUBLE_Y_AXIS_WIDTH = 26 * 2 // 2 y-axis (left & right)

const Y_AXIS_TICKS = 4

const AXIS_LABEL_PROPS = {
  textAnchor: "middle" as const,
  fontSize: 10,
  fontFamily: fonts.family.avenir,
}

const AXIS_TICK_PROPS = {
  fontSize: 10,
  textAnchor: "end" as const,
  fontFamily: fonts.family.avenir,
}

/*
  INTERFACES
*/

interface SharedProps {
  timeseries: TimeseriesValues
  axisStyle: TimeseriesAxisStyle
  axisRight?: boolean
  xScale: ScaleBand<number> | ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
  width: number
  height: number
  yAxisWidth: number
}

type GridProps = Pick<SharedProps, "yScale" | "height" | "width" | "axisStyle" | "yAxisWidth">

type XAxisProps = Pick<
  SharedProps,
  "timeseries" | "xScale" | "height" | "width" | "axisStyle" | "yAxisWidth"
>

type YAxisProps = Pick<SharedProps, "yScale" | "width" | "axisStyle" | "yAxisWidth" | "axisRight">

/*
  COMPONENTS
*/

export const ChartGrid: React.FC<GridProps> = ({
  yScale,
  width,
  height,
  yAxisWidth,
  axisStyle,
}) => (
  <GridRows
    scale={yScale}
    top={0}
    left={yAxisWidth}
    height={height}
    width={width - yAxisWidth}
    stroke={axisStyle.gridStroke}
    numTicks={Y_AXIS_TICKS}
  />
)

export const ChartXAxis: React.FC<XAxisProps> = ({
  timeseries,
  xScale,
  height,
  width,
  axisStyle,
  yAxisWidth,
}) => {
  const period = timeseries[timeseries.length - 1]?.period
  const isYTD = period && dateTimeHelper.isPeriodYearToDate(period)
  const interval = isYTD ? Interval.Year : period?.interval || Interval.Month

  const hasYearAxis = interval !== Interval.Year
  const { axisFill, tickFill } = axisStyle
  const dateFormatCutoffWidth = 380

  const tickLabelProps = React.useCallback(
    () => ({
      ...AXIS_TICK_PROPS,
      fontWeight: fonts.weight.heavy,
      fill: tickFill,
      dy: "0.5em",
      textAnchor: "middle" as const,
    }),
    [tickFill]
  )

  const yearTickLabelProps = React.useCallback(
    () => ({ ...tickLabelProps(), fontWeight: fonts.weight.book }),
    [tickLabelProps]
  )

  const intervalFormat = React.useCallback(
    (value: number | Date, index: number) => {
      const djs = value instanceof Date ? dayjs.utc(value) : dayjs.unix(value).utc()

      // if space is too small... skip every other tick
      if (timeseries.length > 5 && width <= dateFormatCutoffWidth && index % 2 === 0) return ""

      if (interval === Interval.Day) {
        return dateTimeHelper.displayNameFromDayjs(djs, interval, DateFormat.Tiny)
      }

      const format = width >= dateFormatCutoffWidth ? DateFormat.Tiny : DateFormat.Micro

      // Ghetto check
      const suffix = isYTD ? " YTD" : ""
      return `${dateTimeHelper.displayNameFromDayjs(djs, interval, format).toUpperCase()}${suffix}`
    },
    [interval, isYTD, timeseries.length, width]
  )

  const yearDisplay = React.useCallback(
    (djs: dayjs.Dayjs) => {
      // if space is too small... skip every other tick
      if (width <= dateFormatCutoffWidth)
        return dateTimeHelper.displayNameFromDayjs(djs, Interval.Year, DateFormat.Tiny)

      return dateTimeHelper.displayNameFromDayjs(djs, Interval.Year, DateFormat.Default)
    },
    [width]
  )

  const yearFormat = React.useCallback(
    (value: number | Date, index: number) => {
      const djs = dateTimeHelper.dayjsFromTimestamp(value)

      // Second parameter to this function is index, but not the index into ts.
      const tsIndex = timeseries.findIndex(
        (ts) => dateTimeHelper.dayjsFromTimestamp(ts.period.startedAt).unix() === djs.unix()
      )
      const valueYear = yearDisplay(djs)
      const prevValue =
        tsIndex > 0 &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dateTimeHelper.dayjsFromTimestamp(timeseries[tsIndex - 1]!.period.startedAt!)
      const prevValueYear = (prevValue && yearDisplay(prevValue)) || ""

      // if space is too small... show second tick to align with interval
      if (width <= dateFormatCutoffWidth && index === 1) return valueYear

      // if space is large, show the first tick because all intervals are shown too
      if (width > dateFormatCutoffWidth && !index) return valueYear

      // Only display the year if it is different from the previous value
      if (prevValueYear && prevValueYear !== valueYear) return valueYear

      return ""
    },
    [timeseries, width, yearDisplay]
  )

  const labelProps = { ...AXIS_LABEL_PROPS, fill: axisFill, dy: "1em" }

  // set the number of ticks if there are less than 24 data points. this prevents duplicate dates from showing up
  const numTicks = timeseries.length < 24 ? timeseries.length : undefined

  return (
    <Group top={height} left={yAxisWidth}>
      <AxisBottom
        stroke="none"
        scale={xScale}
        tickLabelProps={tickLabelProps}
        tickLength={0}
        tickStroke="none"
        tickFormat={intervalFormat}
        labelProps={labelProps}
        hideZero
        numTicks={numTicks}
      />
      {hasYearAxis && (
        <AxisBottom
          top={14}
          stroke="none"
          scale={xScale}
          tickLabelProps={yearTickLabelProps}
          tickLength={0}
          tickStroke="none"
          tickFormat={yearFormat}
          labelProps={labelProps}
          hideZero
          numTicks={numTicks}
        />
      )}
    </Group>
  )
}

export const ChartYAxis: React.FC<YAxisProps> = ({
  yScale,
  width,
  axisStyle,
  yAxisWidth,
  axisRight,
}) => {
  const { tickFill } = axisStyle

  const tickLabelProps = React.useCallback(
    () => ({
      ...AXIS_TICK_PROPS,
      textAnchor: axisRight ? ("start" as const) : ("end" as const),
      fontWeight: fonts.weight.medium,
      fill: tickFill,
      dy: "0.3em",
      dx: `${axisRight ? 0.5 : -0.5}em`,
    }),
    [axisRight, tickFill]
  )

  const yAxisFormat = React.useCallback((value: number, index: number) => {
    const amount = numberHelper.buildMonetaryAmountFromUnmultiplied(value)
    return numberHelper.currency(amount, {
      style: CurrencyStyle.Summary,
      minSummaryAbbreviationValue: 1000,
    }) as string
  }, [])

  const Axis = axisRight ? AxisRight : AxisLeft

  return (
    <Axis
      top={0}
      left={!axisRight ? yAxisWidth : width - yAxisWidth}
      stroke={colors.transparent}
      scale={yScale}
      tickLabelProps={tickLabelProps}
      tickLength={0}
      tickStroke="none"
      tickFormat={yAxisFormat}
      numTicks={Y_AXIS_TICKS}
    />
  )
}
