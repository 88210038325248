import * as React from "react"
import {
  type BillingPlan,
  BillingProductName,
  type BillingTrial,
  type SubscriptionDetails,
  useCheckBillingOrganizationPlanQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { daysUntil } from "src/frontend/components/Shared/PLG/planHelpers"
import type FrontendSession from "src/frontend/session"
import { Experience } from "src/frontend/session/personas"

export type OperatorBillingPlan = {
  hasQueryResolved: boolean
  planName: BillingProductName
  plan?: BillingPlan | null
  hasBillingPlan: boolean
  hasActiveTrial: boolean
  subscriptionDetails?: SubscriptionDetails | null
  trialDaysRemaining: number
  trialPeriodDays: number
  trial?: BillingTrial | null
}

export function useOperatorBillingPlan(): OperatorBillingPlan {
  const { currentPrimaryExperience, currentOrganizationId } = useSession<FrontendSession>()
  const isOperator = currentPrimaryExperience === Experience.OperatorPortal

  const { data } = useCheckBillingOrganizationPlanQuery({
    variables: {
      organizationId: currentOrganizationId || "",
    },
    fetchPolicy: "cache-first",
    context: { noBatch: true },
    skip: !currentOrganizationId || !isOperator,
  })

  return React.useMemo(() => {
    // AIAccounting is currently the sole operator-focused billing plan. Organizations with this plan are
    // considered paying PLG organizations.
    if (
      data?.checkBillingOrganizationPlan.billingPlan?.product.name ===
      BillingProductName.AIAccounting
    ) {
      const { billingPlan: plan, trial } = data.checkBillingOrganizationPlan
      const { trialPeriodDays } = plan.product

      const daysRemaining = trial?.end ? daysUntil(trial.end) : 0

      return {
        subscriptionDetails: data.checkBillingOrganizationPlan.subscriptionDetails,
        hasQueryResolved: true,
        planName: BillingProductName.AIAccounting,
        hasBillingPlan: true,
        plan,
        hasActiveTrial: daysRemaining > 0,
        trialDaysRemaining: daysRemaining,
        trialPeriodDays: trialPeriodDays ?? 0,
        trial,
      }
    }

    const planName =
      data?.checkBillingOrganizationPlan.billingPlan?.product.name || BillingProductName.UnknownName

    // Legacy operator organizations have never had billable plans, so these defaults are appropriate to return for
    // organizations not on the FounderFinance plan.
    return {
      subscriptionDetails: null,
      hasQueryResolved: Boolean(data),
      planName,
      hasBillingPlan: planName != BillingProductName.UnknownName,
      plan: null,
      hasActiveTrial: false,
      trialDaysRemaining: 0,
      trialPeriodDays: 0,
      trial: null,
    }
  }, [data])
}
