import React from "react"
import {
  DirectionFromOrigin,
  Interval,
  useListBoostBadgeCountsQuery,
  WorkItemReviewState,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import { OPEN_STATES } from "src/frontend/components/OS/Applications/Boost/WorkItems/hooks/boostTypes"
import { useTransactionReviewBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useTransactionReviewBadgeCountsQuery"
import type FrontendSession from "src/frontend/session"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"

export function useBoostBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const session = useSession<FrontendSession>()
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 2)

  const legalEntitiesWithAuditAspect = legalEntityIds
    .map((legalEntityId) => session.findLegalEntityById(legalEntityId))
    .filter<SessionLegalEntity>(defined)
    .filter((legalEntity) => legalEntity.hasAccessToAspect(AspectCode.Audit))
    .filter((legalEntity) => {
      const organization = session.findOrganizationById(legalEntity.organizationId)
      return organization?.permissions.hasReadPermission(FrontendPermissionModule.WorkItems)
    })
    .map((legalEntity) => legalEntity.id)

  const boostResult = useListBoostBadgeCountsQuery({
    variables: {
      origin,
      direction: DirectionFromOrigin.Past,
      filter: {
        itemStates: OPEN_STATES,
        legalEntityIds: legalEntitiesWithAuditAspect,
        // this needs to match the transaction review badge counts request reviewStatesFilter as the counts for
        // transaction review are a subset of the counts for boost
        reviewStatesFilter: {
          reviewStates: [WorkItemReviewState.ClientResponded],
          includeNullReviewState: true,
        },
      },
    },
    skip: skip || !legalEntitiesWithAuditAspect.length,
  })

  const trResult = useTransactionReviewBadgeCountsQuery(
    legalEntitiesWithAuditAspect,
    !legalEntitiesWithAuditAspect.length
  )

  return React.useMemo(() => {
    const boostCounts = boostResult.data?.listBoostBadgeCounts
    const trCounts = trResult.counts

    if (boostResult.loading || trResult.loading) {
      return { counts: undefined, loading: true }
    }

    const countsMap = new Map<string, number>()

    boostCounts?.forEach((item) => {
      countsMap.set(item.legalEntityId, item.count)
    })

    trCounts?.forEach((item) => {
      const currentCount = countsMap.get(item.legalEntityId)
      const updatedCount = currentCount ? currentCount - item.count : 0
      countsMap.set(item.legalEntityId, updatedCount)
    })

    const counts = [...countsMap].map((entry) => ({
      legalEntityId: entry[0],
      count: entry[1],
    }))

    return { counts, loading: boostResult.loading }
  }, [boostResult, trResult])
}
