import * as React from "react"
import useScrollContext from "@digits-shared/hooks/useScrollContext"

const SCROLL_THRESHOLD = {
  UP: 30,
  DOWN: 50,
}

export enum ScrollDirection {
  Upwards = "Upwards",
  Downwards = "Downwards",
}

interface ScrollListenerProps {
  direction?: ScrollDirection
  onScroll?: (event: Event) => void
  loadMore?: () => boolean | undefined | void
}

const ScrollListener: React.FC<ScrollListenerProps> = ({
  direction = ScrollDirection.Downwards,
  onScroll,
  loadMore,
}) => {
  const { scrollElement } = useScrollContext()
  const continueLoadingMoreRef = React.useRef(true)
  const previousScrollPositionRef = React.useRef<number | null>(null)
  const previousScrollHeightRef = React.useRef<number>(0)

  const handleLoadMore = React.useCallback(() => {
    if (!loadMore) return
    const shouldContinue = loadMore()
    if (shouldContinue === false) {
      continueLoadingMoreRef.current = false
    }
  }, [loadMore])

  const handleDownwardsScroll = React.useCallback(
    (currentTarget: HTMLElement) => {
      const oldPrevious = previousScrollPositionRef.current
      previousScrollPositionRef.current = currentTarget.scrollTop

      if (!oldPrevious || currentTarget.scrollTop < oldPrevious) {
        return
      }

      const distanceScrolled = window.innerHeight + currentTarget.scrollTop
      if (distanceScrolled + SCROLL_THRESHOLD.DOWN > currentTarget.scrollHeight) {
        window.requestAnimationFrame(handleLoadMore)
      }
    },
    [handleLoadMore]
  )

  const handleUpwardsScroll = React.useCallback(
    (currentTarget: HTMLElement) => {
      const oldHeight = previousScrollHeightRef.current
      const oldPrevious = previousScrollPositionRef.current
      previousScrollPositionRef.current = currentTarget.scrollTop
      previousScrollHeightRef.current = currentTarget.scrollHeight

      if (
        !oldPrevious ||
        oldHeight !== currentTarget.scrollHeight ||
        currentTarget.scrollTop > oldPrevious
      ) {
        return
      }

      if (currentTarget.scrollTop < SCROLL_THRESHOLD.UP) {
        window.requestAnimationFrame(handleLoadMore)
      }
    },
    [handleLoadMore]
  )

  const handleScroll = React.useCallback(
    (event: Event) => {
      onScroll?.(event)

      if (!loadMore) return
      if (!continueLoadingMoreRef.current) return
      if (!event.currentTarget) return

      const currentTarget = event.currentTarget as HTMLElement

      switch (direction) {
        case ScrollDirection.Upwards:
          handleUpwardsScroll(currentTarget)
          break
        default:
          handleDownwardsScroll(currentTarget)
      }
    },
    [direction, onScroll, loadMore, handleUpwardsScroll, handleDownwardsScroll]
  )

  const addScrollListener = React.useCallback(() => {
    const scrollEl = scrollElement.current
    if (!scrollEl) {
      return
    }

    scrollEl?.removeEventListener("scroll", handleScroll)

    if (direction === ScrollDirection.Upwards && scrollEl.scrollTop === 0) {
      scrollEl.scrollTop = 1
    }

    scrollEl.addEventListener("scroll", handleScroll)
    previousScrollPositionRef.current = null
    previousScrollHeightRef.current = scrollEl.scrollHeight
  }, [scrollElement, handleScroll, direction])

  React.useEffect(() => {
    const scrollEl = scrollElement.current
    addScrollListener()
    return () => {
      scrollEl?.removeEventListener("scroll", handleScroll)
    }
  }, [addScrollListener, handleScroll, scrollElement])

  return null
}

export default ScrollListener
