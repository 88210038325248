import * as React from "react"
import {
  type CategoryChartConfig,
  type EntityCategory,
  type EntityHoverFieldsFragment,
  type EntityParty,
  type InsightComponentConfig,
  Interval,
  type LayoutComponent,
  type LayoutComponentConfig,
  LayoutComponentType,
  type PartyChartConfig,
  ProviderType,
  ReportKind,
  useReadCategorySummaryByTimeComponentDataLazyQuery,
  useReadCategorySummaryByTimeLiveDataLazyQuery,
  useReadInsightComponentDataLazyQuery,
  useReadInsightLiveDataLazyQuery,
  useReadPartySummaryByTimeComponentDataLazyQuery,
  useReadPartySummaryByTimeLiveDataLazyQuery,
  type ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { createEntityHoverData } from "src/frontend/components/Shared/Layout/Components/Insights/useListInsightsForComponent"
import { getStaticComponentTitle } from "src/frontend/components/Shared/Layout/Components/staticComponentTitle"
import { matchConfig, matchConfigWithOrigin } from "src/frontend/components/Shared/Layout/types"
import { useDataSourcePreference } from "src/frontend/hooks/useDataSourcePreference"

interface LayoutViewVersion {
  layoutId: string
  viewId: ViewIdentifier
}

export function useComponentTitle(component: LayoutComponent, viewVersion?: LayoutViewVersion) {
  const { title, config } = component
  return useComponentConfigTitle(config, viewVersion, title, component.dataId)
}

export function useComponentConfigPeriodName(config: LayoutComponentConfig) {
  return React.useMemo(() => {
    const { configKey, origin } = matchConfigWithOrigin(config)
    if (!configKey || !origin || origin.interval === Interval.IntervalNone) return undefined

    if (matchConfig(config, "statement", LayoutComponentType.Statement)) {
      const period = dateTimeHelper.periodFromIntervalOrigin(origin)
      switch (config.statement.kind) {
        case ReportKind.BalanceSheet:
        case ReportKind.CashFlow:
          return `As of ${dateTimeHelper.displayNameFromUnixTimestamp(period.endedAt, Interval.Day)}`
        case ReportKind.ProfitAndLoss:
          return dateTimeHelper.displayNameForTimeRange(
            period.startedAt,
            period.endedAt,
            Interval.Month
          )
        case ReportKind.ExpenseSummary:
        case ReportKind.Unknown:
          break
      }
    }

    return dateTimeHelper.displayNameForIntervalOriginRange(origin, { delimiter: " - " })
  }, [config])
}

export function useComponentConfigTitle(
  config: LayoutComponentConfig,
  viewVersion?: LayoutViewVersion,
  title?: string | null,
  dataId?: string | null
) {
  const [fetchCategory, { category }] = useCategoryComponent()
  const [fetchParty, { party }] = usePartyComponent()
  const [fetchInsight, { insightSubject }] = useInsightComponent()
  const dataSourcePreference = useDataSourcePreference(ProviderType.QuickBooks)
  const periodName = useComponentConfigPeriodName(config)

  const { layoutId, viewId } = viewVersion || {}
  React.useEffect(() => {
    if (
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart) ||
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)
    ) {
      fetchCategory(config.categoryChart, config.type, layoutId, viewId, dataId)
    }

    if (
      matchConfig(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
      matchConfig(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)
    ) {
      fetchParty(config.partyChart, config.type, layoutId, viewId, dataId)
    }

    if (matchConfig(config, "insight", LayoutComponentType.Insight)) {
      fetchInsight(config.insight, config.type, layoutId, viewId, dataId)
    }
  }, [config, dataId, fetchCategory, fetchParty, fetchInsight, layoutId, viewId])

  return React.useMemo(() => {
    if (matchConfig(config, "staticMetric", LayoutComponentType.StaticMetric)) {
      const displayTime = config.staticMetric.updatedAt
        ? dateTimeHelper.displayNameFromUnixTimestamp(config.staticMetric.updatedAt, Interval.Day)
        : undefined
      return {
        title: config.staticMetric.name || "Add Header",
        periodName: displayTime ? `as of ${displayTime}` : "",
      }
    }

    if (matchConfig(config, "insight", LayoutComponentType.Insight)) {
      return { title: insightSubject ? `${insightSubject.name} Analysis` : "Analysis", periodName }
    }

    if (title) {
      return { title, periodName }
    }

    if (
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart) ||
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)
    ) {
      return { title: category?.name || "", periodName }
    }

    if (
      matchConfig(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
      matchConfig(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)
    ) {
      return { title: party?.name || "", periodName }
    }

    const staticTitle = getStaticComponentTitle(config, dataSourcePreference)
    if (staticTitle) {
      return { title: staticTitle, periodName }
    }

    return { title: "Chart", periodName }
  }, [config, title, dataSourcePreference, periodName, insightSubject, category?.name, party?.name])
}

function useCategoryComponent(): [
  (
    config: CategoryChartConfig | undefined,
    type: LayoutComponentType,
    layoutId: string | undefined,
    viewId: ViewIdentifier | undefined,
    dataId: string | undefined | null
  ) => void,
  { category?: EntityCategory; loading: boolean },
] {
  const [fetchArchivedCategory, { data: archivedData, loading: archivedLoading }] =
    useReadCategorySummaryByTimeComponentDataLazyQuery()

  const [fetchLiveCategory, { data: liveData, loading: liveLoading }] =
    useReadCategorySummaryByTimeLiveDataLazyQuery()

  const category = React.useMemo(() => {
    const componentData = archivedData?.readComponentData.data
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityCategory"
    ) {
      return componentData.entity
    }

    const liveComponentData = liveData?.liveComponentData
    if (
      liveComponentData?.__typename === "Chart" &&
      liveComponentData?.entity.__typename === "EntityCategory"
    ) {
      return liveComponentData.entity
    }
    return undefined
  }, [archivedData, liveData])

  const req = React.useCallback(
    (
      categoryChart: CategoryChartConfig | undefined,
      type: LayoutComponentType,
      layoutId: string | undefined,
      viewId: ViewIdentifier | undefined,
      dataId: string | undefined | null
    ) => {
      if (!layoutId || !viewId) return
      if (dataId) {
        return fetchArchivedCategory({
          variables: {
            legalEntityId: viewId.legalEntityId,
            layoutId,
            dataId,
          },
        }).catch((error) => TrackJS?.track(error))
      }

      if (!viewId.viewVersion) return

      return fetchLiveCategory({
        variables: {
          viewId,
          config: {
            type,
            categoryChart,
          },
        },
      }).catch((error) => TrackJS?.track(error))
    },
    [fetchArchivedCategory, fetchLiveCategory]
  )

  return [req, { category, loading: liveLoading || archivedLoading }]
}

function usePartyComponent(): [
  (
    config: PartyChartConfig | undefined,
    type: LayoutComponentType,
    layoutId: string | undefined,
    viewId: ViewIdentifier | undefined,
    dataId: string | undefined | null
  ) => void,
  { party?: EntityParty; loading: boolean },
] {
  const [fetchArchivedParty, { data: archivedData, loading: archivedLoading }] =
    useReadPartySummaryByTimeComponentDataLazyQuery()
  const [fetchLiveParty, { data: liveData, loading: liveLoading }] =
    useReadPartySummaryByTimeLiveDataLazyQuery()

  const party = React.useMemo(() => {
    const componentData = archivedData?.readComponentData.data
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityParty"
    ) {
      return componentData.entity
    }

    const liveComponentData = liveData?.liveComponentData
    if (
      liveComponentData?.__typename === "Chart" &&
      liveComponentData?.entity.__typename === "EntityParty"
    ) {
      return liveComponentData.entity
    }

    return undefined
  }, [archivedData?.readComponentData, liveData?.liveComponentData])

  const req = React.useCallback(
    (
      partyChart: PartyChartConfig | undefined,
      type: LayoutComponentType,
      layoutId: string | undefined,
      viewId: ViewIdentifier | undefined,
      dataId: string | undefined | null
    ) => {
      if (!layoutId || !viewId) return

      if (dataId) {
        return fetchArchivedParty({
          variables: {
            legalEntityId: viewId.legalEntityId,
            layoutId,
            dataId,
          },
        }).catch((error) => TrackJS?.track(error))
      }

      if (!viewId.viewVersion) return
      return fetchLiveParty({
        variables: {
          viewId,
          config: {
            type,
            partyChart,
          },
        },
      }).catch((error) => TrackJS?.track(error))
    },
    [fetchArchivedParty, fetchLiveParty]
  )

  return [req, { party, loading: archivedLoading || liveLoading }]
}

function useInsightComponent(): [
  (
    config: InsightComponentConfig | undefined,
    type: LayoutComponentType,
    layoutId: string | undefined,
    viewId: ViewIdentifier | undefined,
    dataId: string | undefined | null
  ) => void,
  { insightSubject?: { name: string }; loading: boolean },
] {
  const [fetchedConfig, setFetchedConfig] = React.useState<InsightComponentConfig | undefined>(
    undefined
  )
  const [fetchArchivedInsight, { data: archivedData, loading: archivedLoading }] =
    useReadInsightComponentDataLazyQuery()
  const [fetchLiveInsight, { data: liveData, loading: liveLoading }] =
    useReadInsightLiveDataLazyQuery()

  const insightSubject = React.useMemo(() => {
    let hovers: EntityHoverFieldsFragment[] | undefined

    const componentData = archivedData?.readComponentData.data
    const liveComponentData = liveData?.liveComponentData
    if (componentData?.__typename === "InsightComponent") {
      hovers = componentData.hovers
    } else if (liveComponentData?.__typename === "InsightComponent") {
      hovers = liveComponentData.hovers
    }

    const { entities } = createEntityHoverData(hovers)
    const category = entities.categories?.find((c) => c.id === fetchedConfig?.objectId.id)
    const party = entities.parties?.find((p) => p.id === fetchedConfig?.objectId.id)

    const name = category?.type || party?.name
    return name ? { name } : undefined
  }, [
    archivedData?.readComponentData.data,
    fetchedConfig?.objectId.id,
    liveData?.liveComponentData,
  ])

  const req = React.useCallback(
    (
      insight: InsightComponentConfig | undefined,
      type: LayoutComponentType,
      layoutId: string | undefined,
      viewId: ViewIdentifier | undefined,
      dataId: string | undefined | null
    ) => {
      if (!layoutId || !viewId) return

      setFetchedConfig(insight)

      if (dataId) {
        return fetchArchivedInsight({
          variables: {
            legalEntityId: viewId.legalEntityId,
            layoutId,
            dataId,
            limit: 2,
          },
        }).catch((error) => TrackJS?.track(error))
      }

      if (!viewId.viewVersion) return
      return fetchLiveInsight({
        variables: {
          viewId,
          config: {
            type,
            insight,
          },
          limit: 2,
        },
      }).catch((error) => TrackJS?.track(error))
    },
    [fetchArchivedInsight, fetchLiveInsight]
  )

  return [req, { insightSubject, loading: archivedLoading || liveLoading }]
}
