import * as React from "react"
import { useAsync, useAsyncFn } from "react-use"
import { type ApolloError } from "@apollo/client"
import {
  type MoneyFlow,
  ReportOptionComparison,
  type Statement,
  type StatementConfig,
  type StatementRow,
  type StatementRowSummary,
  type TransactionSummary,
  type ViewIdentifierInput,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import { type CellObject } from "xlsx"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { type ReportColumns } from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportDocumentOptions"
import {
  type ColumnKey,
  headerFormattedDate,
  headerTitle,
  isBreakdownColumn,
  isDeltaColumn,
  isDoubleColumn,
  overTimeDeltaValue,
  type StatementColumns,
} from "src/frontend/components/Shared/Layout/Components/Statements/columnTypes"
import { extractAccountDisplayNumber } from "src/frontend/components/Shared/Layout/Components/Statements/toDetailsData"
import { useStatementComponentData } from "src/frontend/components/Shared/Layout/Components/Statements/useStatementComponentData"
import { type MatchedConfig } from "src/frontend/components/Shared/Layout/types"
import type FrontendSession from "src/frontend/session"

interface ExportExcelProps {
  title: string
  periodName: string | undefined
  config: MatchedConfig<"statement">
  layoutId: string
  layoutVersionId: string | undefined | null
  viewId: ViewIdentifierInput
  dataId: string | undefined | null
  options: ReportColumns | undefined
  skip?: boolean
}

interface Workbook {
  fileName: string
  download: () => void
  toBase64: () => string
}

interface ConversionResult {
  workbook?: Workbook
  error?: ApolloError
}

export function useExportToExcel(props: ExportExcelProps) {
  const { toWorkbook, loading } = useConvertToWorkbook(props)
  const { skip } = props

  const [_, exportExcel] = useAsyncFn(async () => {
    if (!skip && !loading) {
      const { workbook } = await toWorkbook()
      return workbook?.download()
    }
  }, [skip, loading, toWorkbook])

  return exportExcel
}

export function useExportStatementToBase64(props: ExportExcelProps) {
  const { toWorkbook, loading } = useConvertToWorkbook(props)
  const { skip } = props

  return useAsync(async () => {
    if (!skip && !loading) {
      const { workbook, error } = await toWorkbook()
      const downloadUrl = workbook
        ? {
            url: `data:application/octet-stream;base64,${workbook.toBase64()}`,
            fileName: workbook.fileName,
          }
        : undefined
      return { downloadUrl, error }
    }
    return undefined
  }, [skip, loading])?.value
}

function useConvertToWorkbook({
  title,
  periodName,
  config,
  layoutId,
  layoutVersionId,
  viewId,
  dataId,
  options = {
    columnKeys: [],
    columns: {},
    deltaMonthOverMonthPeriods: 0,
    hideAccountNumbers: false,
  },
  skip,
}: ExportExcelProps) {
  const {
    currentLegalEntity: { name },
  } = useSession<FrontendSession>()
  const { statement, loading, error } = useStatementComponentData(
    config,
    layoutId,
    layoutVersionId,
    viewId,
    dataId,
    skip
  )

  const toWorkbook = React.useCallback(async () => {
    if (!statement) return { error }

    const { hideAccountNumbers } = options
    const currencyFormatter = numberHelper.numberFormatter({ style: "currency", currency: "USD" })

    // This is large, so only load it on demand.
    const XLSX = await import("xlsx")
    const hasDisplayNumbers =
      !hideAccountNumbers && statement.rows.some((r) => extractAccountDisplayNumber(r.details))

    const today = dayjs().format("MMMM DD, YYYY [at] hh:mm A")
    const rows = mapRows(statement, config.statement, options, hasDisplayNumbers)

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows, { origin: "A6" })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, title)

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [[name]], { origin: "A1" })
    XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: "A2" })
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [[periodName ?? dateTimeHelper.displayNameForIntervalOriginRange(config.statement.origin)]],
      { origin: "A3" }
    )

    XLSX.utils.sheet_add_aoa(worksheet, [[`Exported from digits.com on ${today}`]], {
      origin: "A4",
    })

    // Hide titles for category name and display number.
    XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "A6" })
    if (hasDisplayNumbers) {
      XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "B6" })
    }

    /* calculate column width */
    const columnsWidths = rows.reduce((widths, row) => {
      Object.entries(row).forEach(([key, value_1], col) => {
        widths[col] = Math.max(
          widths[col] ?? 0,
          key.length + 2,
          typeof value_1.v === "number"
            ? currencyFormatter.format(value_1.v).length
            : (value_1.v?.toString().length ?? 0)
        )
      })
      return widths
    }, [] as number[])

    worksheet["!cols"] = columnsWidths.map((width) => ({ wch: width }))

    const download = () => {
      XLSX.writeFile(workbook, `${title} - ${periodName} (${name}).xlsx`, {
        compression: true,
        cellStyles: true,
      })
    }
    const toBase64 = () =>
      XLSX.write(workbook, { type: "base64", compression: true, cellStyles: true }) as string

    return {
      workbook: { download, toBase64, fileName: `${name} - ${periodName} - ${title}.xlsx` },
      error,
    }
  }, [config.statement, error, name, options, periodName, statement, title])

  if (!statement) return { toWorkbook: () => Promise.resolve<ConversionResult>({}), loading: !skip }

  return { toWorkbook, loading }
}

function mapRows(
  statement: Statement,
  config: StatementConfig,
  options: ReportColumns,
  hasDisplayNumbers: boolean
) {
  return statement.rows
    .map((row) => {
      const {
        depth,
        leafCategorySummary,
        parentCategorySummary,
        parentCategoryTitle,
        sectionSummary,
        sectionTitle,
        details,
      } = row

      const displayNumber = hasDisplayNumbers
        ? displayNumberCell(extractAccountDisplayNumber(details) || " ")
        : undefined

      /**
       * SECTION TITLE
       * parentCategorySummary
       *   leafCategorySummary
       * ---------------------
       * parentCategorySummary
       * ---------------------
       * sectionSummary
       */

      // Revenue, Accounts Receivable (A/R), etc
      if (leafCategorySummary) {
        return mapColumns(
          labelsRow(formatLabel(depth, leafCategorySummary.label), displayNumber),
          config,
          options,
          row,
          leafCategorySummary
        )
      }

      // Total Business Development, Total Payroll Expenses, etc
      if (parentCategorySummary)
        return mapColumns(
          labelsRow(formatLabel(depth, parentCategorySummary.label), displayNumber),
          config,
          options,
          row,
          parentCategorySummary
        )

      // Total Liabilities and Equity, Total Equity, etc
      if (sectionSummary)
        return mapColumns(
          labelsRow(formatLabel(depth, sectionSummary.label), displayNumber),
          config,
          options,
          row,
          sectionSummary
        )

      // Current Assets, Business Development, Payroll Expenses, etc
      if (parentCategoryTitle)
        return labelsRow(formatLabel(depth, parentCategoryTitle.title), displayNumber)

      // INCOME, OPERATING EXPENSES, etc
      if (sectionTitle) return labelsRow(formatLabel(depth, sectionTitle.title), displayNumber)

      return undefined
    })
    .filter(defined)
}

type Row = Record<string, CellObject>

function mapColumns(
  row: Row,
  config: StatementConfig,
  options: ReportColumns,
  statementRow: StatementRow,
  rowSummary: StatementRowSummary
) {
  const { origin } = config
  const { columnKeys, deltaMonthOverMonthPeriods, columns } = options
  const hasBreakdownColumns = isBreakdownColumn("deltaMonthOverMonth", columns)

  const summaries = (extractPeriodDetails(statementRow)?.hover.history.time?.slice() ?? []).sort(
    (s1, s2) => s1.summary.period.startedAt - s2.summary.period.startedAt
  )

  if (hasBreakdownColumns) {
    const count = hasBreakdownColumns ? Math.max(1, deltaMonthOverMonthPeriods) : 0
    summaries.slice(-(count + 1)).forEach(({ summary }) => mapSummaryToCell(row, summary))
  } else {
    const date = headerFormattedDate(origin)
    row[date] = formatMoneyFlow(rowSummary.moneyFlow)
  }

  columnKeys.forEach((optionKey: keyof StatementColumns) => {
    if (!isDeltaColumn(optionKey, columns)) return

    const deltaSummaries = extractPeriodDetails(statementRow, optionKey)?.hover.history.time
    if (!deltaSummaries?.length) return

    const comparison = columns[optionKey] as ReportOptionComparison

    const currentValue = deltaSummaries[0]?.summary?.total.moneyFlow

    const lookbackOffset = optionKey === "yearToDate" ? 0 : 1
    const series = deltaSummaries.map((ds) => ds.summary.total.moneyFlow).filter(defined)
    const summary = deltaSummaries[lookbackOffset]?.summary
    const nextValue = summary?.total.moneyFlow

    if (!currentValue || !nextValue) return

    const { moneyFlow, deltaAmount, percentValue } = overTimeDeltaValue(
      optionKey,
      series,
      lookbackOffset,
      deltaMonthOverMonthPeriods,
      statementRow.deltas
    )

    if (isDoubleColumn(optionKey, comparison)) {
      const deltaHeader = headerTitle(optionKey, ReportOptionComparison.Total, origin)
      const formatedValue = formatDeltaValue(
        ReportOptionComparison.Total,
        moneyFlow,
        deltaAmount,
        percentValue
      )
      if (formatedValue) {
        row[deltaHeader] = formatedValue
      }
    }

    const valueHeader = headerTitle(optionKey, comparison, origin)
    const formatedValue = formatDeltaValue(comparison, moneyFlow, deltaAmount, percentValue)
    if (formatedValue) {
      row[valueHeader] = formatedValue
    }
  })

  return row
}
function labelsRow(label: CellObject, displayNumber?: CellObject | null): Row {
  const newRow: Row = {}
  // use numeric indices to guarantee that these columns will always be in the right order
  if (displayNumber) {
    newRow[0] = displayNumber
  }
  newRow[1] = label
  return newRow
}

function mapSummaryToCell(row: Row, summary: TransactionSummary): Row {
  const { total, period } = summary
  const date = dateTimeHelper.displayNameFromPeriod(period, DateFormat.Default)
  row[date] = formatMoneyFlow(total.moneyFlow)
  return row
}

function formatDeltaValue(
  comparison: ReportOptionComparison,
  moneyFlow: MoneyFlow | undefined,
  deltaAmount: MoneyFlow | undefined,
  percentValue: number | undefined
): CellObject | null {
  switch (comparison) {
    case ReportOptionComparison.Total:
    case ReportOptionComparison.InvalidComparison:
      return formatMoneyFlow(moneyFlow)

    case ReportOptionComparison.TriPeriodDeltaPercent:
    case ReportOptionComparison.Percent:
    case ReportOptionComparison.DeltaPercent:
      return formatPercentValue(percentValue)

    case ReportOptionComparison.Amount:
      return formatMoneyFlow(deltaAmount)
  }
}

function displayNumberCell(displayNumber?: string | null): CellObject | null {
  if (!displayNumber) return null
  return {
    v: displayNumber ?? "",
    t: "s",
  }
}

function formatLabel(depth: number, label: string): CellObject {
  const text = depth ? label : label.toLocaleUpperCase()
  const spaces = new Array(depth * 4).join(" ")
  return {
    v: `${spaces}${text}`,
    t: "s",
  }
}

function formatMoneyFlow(moneyFlow?: MoneyFlow | null): CellObject {
  const { amount, currencyMultiplier } = moneyFlowHelper.toMonetaryValue(moneyFlow)

  // First, apply the currency multiplier to obtain the real value,
  // ensuring that a "zero-value" Monetary value doesn't display as $NaN
  const val = amount && currencyMultiplier ? amount / currencyMultiplier : 0

  return {
    v: val,
    t: "n",
    z: '"$"#,##0.00_)',
  }
}

function formatPercentValue(percentValue?: number | null): CellObject | null {
  if (isNaN(<number>percentValue)) {
    return null
  }

  const val = percentValue ? percentValue : 0
  return {
    v: val,
    t: "n",
    z: '0"%"',
  }
}

function extractPeriodDetails(statementRow: StatementRow, optionKey?: ColumnKey) {
  switch (optionKey) {
    case "yearToDate":
    case "deltaYearToDate":
      return statementRow?.ytdDetails
    default:
      return statementRow.details
  }
}
