import * as React from "react"
import { Snackbar, type SnackbarProps } from "@digits-shared/DesignSystem/Snackbar"
import { useChromeStructure } from "src/frontend/components/Shared/Chrome/chromeStructure"
import { DeferToModal } from "src/frontend/components/Shared/ManagedModals/DeferToModal"

/**
 * Snackbar will hide automatically when a ManagedModal is present on screen.
 * Optionally hide if a DetailsView is opened with `hideOnActiveDetailsView`
 */
export const DeferredSnackbar: React.FC<
  SnackbarProps & {
    hideOnActiveDetailsView?: boolean
  }
> = ({ hideOnActiveDetailsView, ...rest }) => {
  const { hasActiveDetailsView } = useChromeStructure()
  const hideForDetailsView = hideOnActiveDetailsView && hasActiveDetailsView

  return (
    !hideForDetailsView && (
      <DeferToModal>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Snackbar {...rest} />
      </DeferToModal>
    )
  )
}
