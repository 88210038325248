import * as React from "react"
import type { MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table"
import { Amount, RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { ResultAmount } from "src/frontend/components/OS/Applications/Search/SearchResults/Shared"

const Count = styled(RowContentDescription)`
  .${SELECTED_CLASS_NAME} &,
  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    color: ${colors.secondary};
  }

  text-transform: uppercase;
  font-size: 10px;
  white-space: nowrap;
`

/*
  INTERFACES
*/

interface SummaryWithCountProps {
  loading?: boolean
  moneyFlow?: MoneyFlow
  style?: CurrencyStyle
  absolute?: boolean
  ignoreHover?: boolean
  className?: string
  children?: React.ReactNode
}

/*
  COMPONENT
*/

export const SummaryWithCount: React.FC<SummaryWithCountProps> = ({
  loading,
  moneyFlow,
  children,
  ignoreHover,
  style = CurrencyStyle.Detail,
  absolute,
  className,
}) => {
  if (loading || !moneyFlow) {
    return (
      <>
        <Amount inbound ignoreHover={ignoreHover} className={className}>
          <LoadingBlock width="80px" height="12px" $randomWidthRange={30} />
        </Amount>
        <Count>
          <LoadingBlock width="100px" height="12px" $randomWidthRange={30} />
        </Count>
      </>
    )
  }

  const amountWithCurrency = moneyFlowHelper.currency(moneyFlow, {
    absolute,
    style,
  })

  return (
    <>
      <ResultAmount
        isNormal={moneyFlow.isNormal}
        flow={moneyFlow.businessFlow}
        isZero={moneyFlow.value.amount === 0}
      >
        {amountWithCurrency}
      </ResultAmount>
      <Count>{children}</Count>
    </>
  )
}
