import * as React from "react"
import { SvgSearchSm } from "@digits-shared/components/SVGIcons/line/SearchSm.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import {
  ApplicationHeader,
  ApplicationIconStyles,
  ApplicationTitle,
} from "@digits-shared/DesignSystem/Containers/Header"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import useRouter from "@digits-shared/hooks/useRouter"
import styled from "styled-components"
import type QueryBuilder from "src/frontend/components/OS/Applications/Search/QueryBuilder"
import { DigitsSearchBox } from "src/frontend/components/OS/Applications/Search/SearchBox"
import { type SearchQuerySource } from "src/frontend/components/OS/Applications/Search/shared"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

/*
  STYLES
*/

const SearchIcon = styled(SvgSearchSm)`
  ${ApplicationIconStyles};
`

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 14px;
`

const CenteredSearchBox = styled(DigitsSearchBox)`
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
`

/*
  INTERFACES
*/

interface HeaderProps {
  builder: QueryBuilder
  initialValue: string
  isSearchActive: boolean
  isSearchRequestInFlight: boolean
}

/*
  COMPONENTS
*/

export const SearchHeader: React.FC<HeaderProps> = ({
  builder,
  initialValue,
  isSearchActive,
  isSearchRequestInFlight,
}) => {
  const { history } = useRouter()
  const inputRef = React.useRef<HTMLInputElement>(null)

  const generatePath = useFrontendPathGenerator()

  const goBack = React.useCallback(() => {
    inputRef.current?.blur()
    history.replace(generatePath(routes.legalEntityHome))
  }, [generatePath, history])
  useEscapeKeyCapture(
    goBack,
    // needs to be nested within document to capture the escape event before DetailsView
    { target: document?.body }
  )

  const onSubmit = React.useCallback(
    (text: string) => {
      const urlParams = builder.setText(text).buildUrlParams()
      const path = generatePath(routes.search, urlParams)
      const source: SearchQuerySource = "searchbox"
      history.replace(path, { source })
    },
    [history, builder, generatePath]
  )

  const onClear = React.useCallback(() => {
    const path = generatePath(routes.search)
    history.push(path)
  }, [history, generatePath])

  return (
    <ApplicationHeader>
      <ApplicationTitle>
        <SearchIcon />
        Search
      </ApplicationTitle>

      <CenteredSearchBox
        ref={inputRef}
        onSubmit={onSubmit}
        onClear={onClear}
        initialValue={initialValue}
        isSearchActive={isSearchActive}
        isSearchRequestInFlight={isSearchRequestInFlight}
      />

      <ButtonsContainer>
        <DigitsButton $variant="secondary-dark" onClick={goBack}>
          Done
        </DigitsButton>
      </ButtonsContainer>
    </ApplicationHeader>
  )
}
