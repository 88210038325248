import * as React from "react"
import { useListQuotePackagesByLegalEntityQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import type FrontendSession from "src/frontend/session"

export enum CompanyCategory {
  AdvertisingAndMarketing = "Advertising & Marketing",
  AISoftware = "AI Software",
  BankingAndFinance = "Banking & Finance",
  Biotech = "Biotech",
  BookkeepingAndAccounting = "Bookkeeping & Accounting",
  CapitalMarkets = "Capital Markets",
  ConstructionAndEngineering = "Construction & Engineering",
  ConsumerGoods = "Consumer Goods",
  Crypto = "Crypto",
  EnterpriseSoftware = "Enterprise Software",
  FashionAndApparel = "Fashion & Apparel",
  FoodAndBeverage = "Food & Beverage",
  HealthCareProvidersAndServices = "Health Care Providers & Services",
  Marketplaces = "Marketplaces",
  OperationsAndHR = "Operations & HR",
  ProfessionalServices = "Professional Services",
  RealEstate = "Real Estate",
  RestaurantsHotelAndLeisure = "Restaurants, Hotel & Leisure",
  SaaSSoftware = "SaaS Software",
  Other = "Other",
}

export const COMPANY_CATEGORIES: CompanyCategory[] = [
  CompanyCategory.AdvertisingAndMarketing,
  CompanyCategory.AISoftware,
  CompanyCategory.BankingAndFinance,
  CompanyCategory.Biotech,
  CompanyCategory.BookkeepingAndAccounting,
  CompanyCategory.CapitalMarkets,
  CompanyCategory.ConstructionAndEngineering,
  CompanyCategory.ConsumerGoods,
  CompanyCategory.Crypto,
  CompanyCategory.EnterpriseSoftware,
  CompanyCategory.FashionAndApparel,
  CompanyCategory.FoodAndBeverage,
  CompanyCategory.HealthCareProvidersAndServices,
  CompanyCategory.Marketplaces,
  CompanyCategory.OperationsAndHR,
  CompanyCategory.ProfessionalServices,
  CompanyCategory.RealEstate,
  CompanyCategory.RestaurantsHotelAndLeisure,
  CompanyCategory.SaaSSoftware,
  CompanyCategory.Other,
]

export const UPSELL_CATEGORIES = new Set<CompanyCategory>([
  CompanyCategory.AdvertisingAndMarketing,
  CompanyCategory.AISoftware,
  CompanyCategory.EnterpriseSoftware,
  CompanyCategory.OperationsAndHR,
  CompanyCategory.ProfessionalServices,
  CompanyCategory.SaaSSoftware,
])

export function useIsInIcp(skip?: boolean) {
  const { currentLegalEntityId } = useSession<FrontendSession>()
  const { data } = useListQuotePackagesByLegalEntityQuery({
    variables: {
      legalEntityId: currentLegalEntityId,
    },
    fetchPolicy: "cache-first",
    skip,
  })

  return React.useMemo(
    () =>
      Boolean(
        data?.listQuotePackagesByLegalEntity?.[0]?.companyDetails.categories?.some((category) =>
          UPSELL_CATEGORIES.has(category as CompanyCategory)
        )
      ),
    [data?.listQuotePackagesByLegalEntity]
  )
}
