import * as React from "react"
import {
  type SearchEntitiesQueryResult,
  type SearchResultFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import { LogoLoading } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import ScrollListener from "@digits-shared/components/UI/Elements/ScrollListener"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

const PAGE_SIZE = 200

/*
 STYLES
*/

const FetchAction = styled.div`
  margin: 0 25px 50px;
  text-align: center;
`

const FetchActionButton = styled.div`
  display: inline-block;
  background: linear-gradient(0deg, rgba(19, 73, 85, 0.8), rgba(19, 73, 85, 0.8)), #1d5777;
  backdrop-filter: blur(2px);
  font-size: 11px;
  color: ${colors.white};
  text-transform: uppercase;
  border-radius: 25px;
  padding: 5px 20px 5px 10px;
  cursor: pointer;
`

const PointingDownChevron = styled(SvgChevronDown)`
  width: 18px;
  height: 18px;
  ${svgPathStyles(colors.white, 1.5)};
  margin: -3px 5px 0 5px;
  padding-top: 0px;
  vertical-align: middle;
`

const LoadingMore = styled.div`
  margin: 0 25px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

/*
 INTERFACES
*/

interface FetchMoreProps extends Pick<SearchEntitiesQueryResult, "fetchMore"> {
  search: SearchResultFieldsFragment
}

/*
 COMPONENT
*/

export const FetchMoreResults: React.FC<FetchMoreProps> = ({ search, fetchMore }) => {
  const { total, results } = search
  const [loading, setLoading] = React.useState(false)

  const offset = results.length
  const limit = Math.min(PAGE_SIZE, total - offset)

  const onFetchMoreClick = React.useCallback(() => {
    setLoading(true)
    fetchMore({
      variables: { limit, offset },
    }).finally(() => setLoading(false))
  }, [fetchMore, offset, limit])

  const onLoadMore = React.useCallback(() => {
    if (loading) return
    if (limit <= 0) {
      return false
    }
    onFetchMoreClick()
    return undefined
  }, [limit, loading, onFetchMoreClick])

  if (limit <= 0) {
    return null
  }

  if (loading) {
    return (
      <LoadingMore>
        <LogoLoading size="40px" />
      </LoadingMore>
    )
  }

  // first page
  if (offset < PAGE_SIZE) {
    return (
      <FetchAction>
        <FetchActionButton onClick={onFetchMoreClick}>
          <PointingDownChevron />
          see {total - offset} more
        </FetchActionButton>
      </FetchAction>
    )
  }

  return <ScrollListener loadMore={onLoadMore} />
}
