import * as React from "react"
import { useNotificationCreatedSubscription } from "@digits-graphql/frontend/graphql-bearer"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { Application } from "src/frontend/components/OS/Applications/Application"
import {
  BadgeContextProvider,
  useBadgeContext,
} from "src/frontend/components/Shared/Contexts/BadgeContext"

const NOOP = () => {}

interface NavSidebarContextValue {
  variant: "default" | "collapsed"
  // header menu
  isHeaderMenuOpen: boolean
  showHeaderMenu: () => void
  hideHeaderMenu: () => void
  toggleHeaderMenu: () => void
  onMouseLeaveHeaderMenu: ReturnType<typeof usePopOverState>["onMouseLeave"]
  // action items sidebar
  isActionItemsSidebarOpen: boolean
  toggleActionItemsSidebar: () => void
  hideActionItemsSidebar: () => void
  // footer menu
  isFooterMenuOpen: boolean
  showFooterMenu: () => void
  hideFooterMenu: () => void
  toggleFooterMenu: () => void
  onMouseLeaveFooterMenu: ReturnType<typeof usePopOverState>["onMouseLeave"]
  // derived state
  isNavPinned: boolean
}

export const NavSidebarContext = React.createContext<NavSidebarContextValue>({
  variant: "default",
  // header menu
  isHeaderMenuOpen: false,
  showHeaderMenu: NOOP,
  hideHeaderMenu: NOOP,
  toggleHeaderMenu: NOOP,
  onMouseLeaveHeaderMenu: NOOP,
  // action items sidebar
  isActionItemsSidebarOpen: false,
  toggleActionItemsSidebar: NOOP,
  hideActionItemsSidebar: NOOP,
  // footer menu
  isFooterMenuOpen: false,
  showFooterMenu: NOOP,
  hideFooterMenu: NOOP,
  toggleFooterMenu: NOOP,
  onMouseLeaveFooterMenu: NOOP,
  // derived state
  isNavPinned: false,
})

/**
 * Provides the state and actions for the NavSidebar.
 */
export const NavSidebarProvider: React.FC<
  React.PropsWithChildren<{ variant?: "default" | "collapsed" }>
> = ({ children, variant = "default" }) => {
  const { getRefetchCount } = useBadgeContext()
  const {
    isPopOverOpen: isHeaderMenuOpen,
    showPopOver: showHeaderMenu,
    hidePopOver: hideHeaderMenu,
    onMouseLeave: onMouseLeaveHeaderMenu,
  } = usePopOverState({
    waitToOpen: 600,
  })
  const openHeaderMenu = React.useCallback(() => {
    const refetchActionItemsCount = getRefetchCount(Application.ActionItems.name)
    // ensure the count is up to date when the menu is opened
    refetchActionItemsCount?.()
    showHeaderMenu()
  }, [showHeaderMenu, getRefetchCount])
  const toggleHeaderMenu = React.useCallback(
    () => (isHeaderMenuOpen ? hideHeaderMenu() : openHeaderMenu()),
    [isHeaderMenuOpen, openHeaderMenu, hideHeaderMenu]
  )

  const {
    isPopOverOpen: isFooterMenuOpen,
    showPopOver: showFooterMenu,
    hidePopOver: hideFooterMenu,
    onMouseLeave: onMouseLeaveFooterMenu,
  } = usePopOverState({
    waitToOpen: 600,
  })
  const toggleFooterMenu = React.useCallback(
    () => (isFooterMenuOpen ? hideFooterMenu() : showFooterMenu()),
    [isFooterMenuOpen, showFooterMenu, hideFooterMenu]
  )

  const {
    isOpen: isActionItemsSidebarOpen,
    toggle: toggleActionItemsSidebar,
    hide: hideActionItemsSidebar,
  } = useActionItemsSidebar(isHeaderMenuOpen || isFooterMenuOpen)

  useNotificationCreatedSubscription({
    onData: (subscriptionData) => {
      if (!subscriptionData.data.data?.notificationCreated.id) return
      // update action items count any time a web notification is received
      const refetchActionItemsCount = getRefetchCount(Application.ActionItems.name)
      refetchActionItemsCount?.()
    },
  })

  const value = React.useMemo(
    () => ({
      variant,
      // header menu
      isHeaderMenuOpen,
      showHeaderMenu,
      hideHeaderMenu,
      toggleHeaderMenu,
      onMouseLeaveHeaderMenu,
      // action items sidebar
      isActionItemsSidebarOpen,
      toggleActionItemsSidebar,
      hideActionItemsSidebar,
      // footer menu
      isFooterMenuOpen,
      showFooterMenu,
      hideFooterMenu,
      toggleFooterMenu,
      onMouseLeaveFooterMenu,
      // derived state
      isNavPinned: isActionItemsSidebarOpen,
    }),
    [
      variant,
      // header menu
      isHeaderMenuOpen,
      showHeaderMenu,
      hideHeaderMenu,
      toggleHeaderMenu,
      onMouseLeaveHeaderMenu,
      // action items sidebar
      isActionItemsSidebarOpen,
      toggleActionItemsSidebar,
      hideActionItemsSidebar,
      // footer menu
      isFooterMenuOpen,
      showFooterMenu,
      hideFooterMenu,
      toggleFooterMenu,
      onMouseLeaveFooterMenu,
    ]
  )

  return (
    <BadgeContextProvider>
      <NavSidebarContext.Provider value={value}>{children}</NavSidebarContext.Provider>
    </BadgeContextProvider>
  )
}

/**
 * Initialize ActionItemsSidebar state and and close sidebar if menu opens.
 */
function useActionItemsSidebar(isNavMenuOpen: boolean) {
  const { value: isOpen, setFalse: hide, toggle } = useStateBoolean()
  React.useEffect(() => {
    if (isNavMenuOpen && isOpen) {
      hide()
    }
  }, [isNavMenuOpen, hide, isOpen])
  return { isOpen, hide, toggle }
}
