// NOTE: These keys are sorted in the order that we want to show the results
export enum SearchResultType {
  Category = "Category",
  Party = "Party",
  Comment = "Comment",
  Report = "Report",
  User = "User",
  Invoice = "Invoice",
  Department = "Department",
  Location = "Location",

  // DO NOT add types below transactions as they have infinite scroll
  Transaction = "Transaction",
}
