import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  InsightSubjectType,
  Interval,
  useListInsightsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import objectEntitiesHelper from "@digits-shared/helpers/objectEntitiesHelper"
import {
  MAX_SUGGESTION_COUNT,
  type PeriodInsight,
} from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

interface UseSuggestionInsightsResult {
  insights: PeriodInsight[]
  insightSuggestions: string[]
}

export function useSuggestionInsights(
  isSearchActive: boolean,
  skipQuery: boolean
): UseSuggestionInsightsResult {
  const viewKey = useViewVersion()
  const { data, loading } = useListInsightsQuery({
    variables: {
      ...dateTimeHelper.todayIntervalOrigin(Interval.Month, 2),
      paginationDirection: DirectionFromOrigin.Past,
      paginationLimit: 10,
      paginationOffset: 0,
      filter: {
        viewKey,
        // TODO: Add parties.
        categoryTypes: [
          CategoryType.Expenses,
          CategoryType.OtherExpenses,
          CategoryType.CostOfGoodsSold,
          CategoryType.Income,
          CategoryType.OtherIncome,
        ],
        subjectTypes: [InsightSubjectType.Category],
      },
    },
    skip: isSearchActive || skipQuery,
    context: { noBatch: true },
  })

  const insights = React.useMemo(() => {
    const insights =
      data?.listInsights?.flatMap((periodInsight) =>
        periodInsight.insights.map((insight) => ({
          period: periodInsight.period,
          ...insight,
        }))
      ) ?? []

    // Create a map of subject ids to insights in order to dedupe subjects.
    // Iterate over all of the insights, and assign the insights to their
    // related subject id.
    // If the key already has an insight assigned to it, replace it as long
    // as the global score of the current insight is higher.
    // This will result in only a single insight being used for each subject.
    return Array.from(
      insights
        .reduce((map, pi) => {
          const existing = map.get(pi.subjectId)
          if (
            !existing ||
            !existing.globalScore ||
            (pi.globalScore && pi.globalScore > existing.globalScore)
          ) {
            map.set(pi.subjectId, pi)
          }
          return map
        }, new Map<string, PeriodInsight>())
        .values()
    )
      .sort((pi1, pi2) =>
        pi2.globalScore && pi1.globalScore
          ? pi2.globalScore - pi1.globalScore
          : pi2.score - pi1.score
      )
      .slice(0, MAX_SUGGESTION_COUNT)
  }, [data?.listInsights])

  const entityNames = React.useMemo(() => {
    const m = new Map<string, string>()
    const entities = data?.listInsights
      .map((i) => i.entities)
      .filter(defined)
      .reduce((merged, e) => objectEntitiesHelper.merge(merged, e))

    ;(entities?.parties ?? []).forEach((p) => {
      m.set(p.id, p.name)
    })
    ;(entities?.categories ?? []).forEach((c) => {
      m.set(c.id, c.name)
    })
    return m
  }, [data?.listInsights])

  // Insight suggestions are stable, don't react to the query changing, and
  // don't impact state decisions, so they're not tracked within the state machine.
  const insightSuggestions = React.useMemo(
    () => (loading ? [] : insights.map((i) => entityNames.get(i.subjectId)).filter(defined)),
    [entityNames, insights, loading]
  )

  // TODO re-enable when popovers are re-instated (see InsightPopUp.tsx)
  // // preload insights icons
  // React.useEffect(() => {
  //   if (!insights?.length) return
  //
  //   insights.forEach((insight) => {
  //     if (!insight.subjectIconUrl) return
  //
  //     const image = new Image()
  //     image.src = insight.subjectIconUrl
  //   })
  // }, [insights])

  return { insights, insightSuggestions }
}
