import { SITE_CENTER_CONTENT_WIDTH } from "@digits-shared/components/UI/Elements/Container"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

export const WIDGET_PADDING = 15

export const WIDGET_CONTAINER_STYLES = css`
  position: relative;
  padding: ${WIDGET_PADDING}px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px 0;
  background: linear-gradient(rgba(255, 255, 255, 0.74) 12.73%, rgba(255, 255, 255, 0.43) 90.37%);
  color: ${colors.secondary};
`

export const WidgetContainer = styled.div`
  ${WIDGET_CONTAINER_STYLES};
`

export const WIDE_WIDGET_PADDING = 25

export const WideWidgetContainer = styled(WidgetContainer)`
  padding: ${WIDE_WIDGET_PADDING}px;

  & + & {
    margin-top: 15px;
  }
`

export const WideWidgetTopContainer = styled(WidgetContainer)`
  padding: ${WIDE_WIDGET_PADDING}px;
  border-radius: 0 0 16px 16px;
  margin-top: -16px;
  margin-bottom: 16px;
`

export const MainWidget = styled(WideWidgetContainer)`
  background:
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
    linear-gradient(112.77deg, rgba(255, 255, 255, 0.38) -3.3%, rgba(254, 254, 254, 0.1) 96.1%);
  backdrop-filter: blur(35px);
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.45) 100%);
  flex: 1;
  display: flex;
  min-height: 630px;
  height: 100%;
  padding-right: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
  padding-left: 0;
  width: ${SITE_CENTER_CONTENT_WIDTH}px;
`
