import * as React from "react"
import { Interval, type Period } from "@digits-graphql/frontend/graphql-bearer"
import { FOCUS_VISIBLE_OUTLINE } from "@digits-shared/components/UI/Elements/Form/InputStyles"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { useDateRangeContext } from "src/shared/components/DateRangeSelector/DateRangeContext"
import { useFirstTransactionTimestamp } from "src/shared/hooks/useFirstTransactionTimestamp"

const IntervalsContainer = styled.div`
  display: flex;
  gap: 6px;
  width: fit-content;
  padding: 4px;
  border: 1px solid ${colors.primary};
  border-radius: 18px;
`

const IntervalOption = styled.button<{ $selected: boolean }>`
  all: unset;
  text-align: center;
  font-size: 12px;
  font-weight: ${fonts.weight.black};
  letter-spacing: 0.48px;
  text-transform: uppercase;

  &:focus-visible {
    ${FOCUS_VISIBLE_OUTLINE}
  }

  padding: 2px 8px;
  border-radius: 12px;
  cursor: pointer;
  opacity: 0.5;

  ${({ $selected }) =>
    $selected &&
    css`
      pointer-events: none;
      opacity: 1;
      background: ${colors.secondary};
      color: ${colors.white};
    `}
`

const EndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`

const ToLabel = styled.div`
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  letter-spacing: 0.64px;
  text-transform: uppercase;
`

const PeriodContainer = styled.div`
  overflow: hidden;
  overflow-y: auto;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const PeriodOption = styled.div<{ $selected: boolean; $disabled?: boolean }>`
  width: fit-content;
  position: relative;
  color: ${colors.secondary};
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 12px;
  cursor: pointer;

  transition:
    background-color 250ms ease,
    color 250ms ease,
    box-shadow 250ms ease;

  ${({ $selected }) =>
    $selected &&
    css`
      pointer-events: none;
      background: ${colors.secondary};
      color: ${colors.white};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`

interface PeriodOptionProps {
  index: number
  interval: Interval
  timestamp: number
  disableAfter?: number
  disableBefore?: number
  hideBefore?: number
  onTimestampChange: (period: Period) => void
}

export const IntervalSelector: React.FC = () => {
  const {
    dateRangeState: {
      timeRange: { interval },
    },
    dateRangeDispatch,
    includeDays,
    includeMonths,
    includeQuarters,
    includeYears,
    showIntervals,
  } = useDateRangeContext()

  const onIntervalSelected = React.useCallback(
    (newInterval: Interval) => {
      dateRangeDispatch({ type: "SET_INTERVAL", interval: newInterval })
    },
    [dateRangeDispatch]
  )

  return (
    showIntervals && (
      <IntervalsContainer>
        {includeDays && (
          <IntervalOption
            $selected={interval === Interval.Day}
            onClick={onIntervalSelected.bind(undefined, Interval.Day)}
          >
            day
          </IntervalOption>
        )}
        {includeMonths && (
          <IntervalOption
            $selected={interval === Interval.Month}
            onClick={onIntervalSelected.bind(undefined, Interval.Month)}
          >
            month
          </IntervalOption>
        )}
        {includeQuarters && (
          <IntervalOption
            $selected={interval === Interval.Quarter}
            onClick={onIntervalSelected.bind(undefined, Interval.Quarter)}
          >
            quarter
          </IntervalOption>
        )}
        {includeYears && (
          <IntervalOption
            $selected={interval === Interval.Year}
            onClick={onIntervalSelected.bind(undefined, Interval.Year)}
          >
            year
          </IntervalOption>
        )}
      </IntervalsContainer>
    )
  )
}

export const FromSelector: React.FC = () => {
  const {
    dateRangeState: {
      timeRange: { interval, startedAt, endedAt },
    },
    dateRangeDispatch,
  } = useDateRangeContext()

  const intervalCount = dateTimeHelper.intervalCount(endedAt, startedAt, interval)
  const disableAfter = intervalCount > 1 ? endedAt : undefined
  const hideBefore = useFirstTransactionTimestamp()

  const onPeriodChange = React.useCallback(
    (period: Period) => {
      if (!disableAfter) {
        const origin = dateTimeHelper.intervalOriginFromDayjs(
          dayjs.unix(period.startedAt).utc(),
          interval,
          1
        )
        return dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin })
      }

      dateRangeDispatch({ type: "SET_START", value: period.startedAt })
    },
    [dateRangeDispatch, disableAfter, interval]
  )

  return (
    <>
      <PeriodContainer>
        {Array.from({ length: 15 }).map((_, index) => (
          <Year
            key={index}
            index={index}
            interval={interval}
            timestamp={startedAt}
            onTimestampChange={onPeriodChange}
            disableAfter={disableAfter}
            hideBefore={hideBefore}
          />
        ))}
      </PeriodContainer>
      <PeriodContainer>
        {Array.from({ length: getPeriodLength(interval) })
          .map((_, index) => (
            <IntervalPeriodOption
              key={index}
              index={index}
              interval={interval}
              timestamp={startedAt}
              onTimestampChange={onPeriodChange}
              disableAfter={disableAfter}
              hideBefore={hideBefore}
            />
          ))
          .reverse()}
      </PeriodContainer>
    </>
  )
}

export const EndDateOption: React.FC = () => {
  const {
    dateRangeState: {
      timeRange: { interval, startedAt, endedAt },
    },
    dateRangeDispatch,
  } = useDateRangeContext()

  const intervalCount = dateTimeHelper.intervalCount(endedAt, startedAt, interval)
  const {
    value: hasEndDate,
    setFalse: hideEndDate,
    setTrue: showEndDate,
    setValue: setHasEndDate,
  } = useStateBoolean(intervalCount > 1)

  React.useEffect(() => {
    setHasEndDate(intervalCount > 1)
  }, [intervalCount, setHasEndDate])

  const onRemoveEndDate = React.useCallback(() => {
    const origin = dateTimeHelper.intervalOriginFromDayjs(dayjs.unix(startedAt).utc(), interval, 1)

    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin })
    hideEndDate()
  }, [dateRangeDispatch, hideEndDate, interval, startedAt])

  if (hasEndDate) {
    return (
      <>
        <EndDateContainer>
          <ToLabel>TO</ToLabel>
          <DigitsButton onClick={onRemoveEndDate} $circle size="small" $variant="secondary-dark">
            ✕
          </DigitsButton>
        </EndDateContainer>
        <ToSelector />
      </>
    )
  }

  return (
    <EndDateContainer>
      <DigitsButton onClick={showEndDate} $circle $variant="secondary-dark">
        ＋
      </DigitsButton>
      <div onClick={showEndDate}>Custom Range</div>
    </EndDateContainer>
  )
}

const ToSelector: React.FC = () => {
  const {
    dateRangeState: {
      timeRange: { interval, startedAt, endedAt },
    },
    dateRangeDispatch,
  } = useDateRangeContext()

  const onPeriodChange = React.useCallback(
    (period: Period) => {
      dateRangeDispatch({ type: "SET_END", value: period.endedAt })
    },
    [dateRangeDispatch]
  )

  return (
    <>
      <PeriodContainer>
        {Array.from({ length: 15 }).map((_, index) => (
          <Year
            key={index}
            index={index}
            interval={interval}
            timestamp={endedAt}
            onTimestampChange={onPeriodChange}
            disableBefore={startedAt}
          />
        ))}
      </PeriodContainer>
      <PeriodContainer>
        {Array.from({ length: getPeriodLength(interval) })
          .map((_, index) => (
            <IntervalPeriodOption
              key={index}
              index={index}
              interval={interval}
              timestamp={endedAt}
              onTimestampChange={onPeriodChange}
              disableBefore={startedAt}
            />
          ))
          .reverse()}
      </PeriodContainer>
    </>
  )
}

const Year: React.FC<PeriodOptionProps> = ({
  index,
  interval,
  timestamp,
  onTimestampChange,
  disableAfter,
  disableBefore,
  hideBefore,
}) => {
  const currentMoment = dayjs.unix(timestamp).utc()

  const originToBeRendered = React.useMemo(() => {
    const origin = dateTimeHelper.todayIntervalOrigin(Interval.Year)
    origin.year -= index
    return origin
  }, [index])

  const periodToBeRendered = React.useMemo(
    () => dateTimeHelper.periodFromIntervalOrigin(originToBeRendered),
    [originToBeRendered]
  )

  const handleYearClick = React.useCallback(() => {
    const newOrigin = dateTimeHelper.intervalOriginFromDayjs(currentMoment, interval)
    newOrigin.year = originToBeRendered.year
    onTimestampChange(dateTimeHelper.periodFromIntervalOrigin(newOrigin))
  }, [currentMoment, interval, onTimestampChange, originToBeRendered.year])

  const selected = currentMoment.year() === originToBeRendered.year
  const disabled = disableAfter
    ? disableAfter < periodToBeRendered.startedAt
    : disableBefore
      ? disableBefore > periodToBeRendered.endedAt
      : false

  React.useEffect(() => {
    if (!selected || !disabled) return

    const newTimestamp = disableAfter ?? disableBefore
    if (newTimestamp) {
      onTimestampChange(dateTimeHelper.periodFromDayjs(dayjs.unix(newTimestamp).utc(), interval))
    }
  }, [disableAfter, disableBefore, disabled, interval, onTimestampChange, selected])

  if (hideBefore && hideBefore > periodToBeRendered.endedAt) {
    return null
  }

  return (
    <PeriodOption onClick={handleYearClick} $selected={selected} $disabled={disabled}>
      {dateTimeHelper.displayNameFromIntervalOrigin(originToBeRendered)}
    </PeriodOption>
  )
}

const IntervalPeriodOption: React.FC<PeriodOptionProps> = ({
  index,
  interval,
  timestamp,
  onTimestampChange,
  disableAfter,
  disableBefore,
  hideBefore,
}) => {
  const currentIntervalOrigin = dateTimeHelper.intervalOriginFromDayjs(
    dayjs.unix(timestamp).utc(),
    interval
  )

  const todayOrigin = dateTimeHelper.todayIntervalOrigin(interval)
  const originToBeRendered = React.useMemo(
    () => ({ ...currentIntervalOrigin, index: index + 1 }),
    [currentIntervalOrigin, index]
  )
  const periodToBeRendered = React.useMemo(
    () => dateTimeHelper.periodFromIntervalOrigin(originToBeRendered),
    [originToBeRendered]
  )

  const handleIntervalClick = React.useCallback(() => {
    onTimestampChange(periodToBeRendered)
  }, [onTimestampChange, periodToBeRendered])

  const selected = currentIntervalOrigin.index === originToBeRendered.index
  const disabled = disableAfter
    ? disableAfter < periodToBeRendered.startedAt
    : disableBefore
      ? disableBefore > periodToBeRendered.endedAt
      : false

  React.useEffect(() => {
    if (!selected || !disabled) return

    const newTimestamp = disableAfter ?? disableBefore
    if (newTimestamp) {
      onTimestampChange(dateTimeHelper.periodFromDayjs(dayjs.unix(newTimestamp).utc(), interval))
    }
  }, [disableAfter, disableBefore, disabled, interval, onTimestampChange, selected])

  // If the currently selected year is the today year and we are trying to render an interval option
  // that is after today, return undefined to prevent rendering
  if (
    currentIntervalOrigin.year === todayOrigin.year &&
    originToBeRendered.index > todayOrigin.index
  ) {
    return null
  }

  if (hideBefore && hideBefore > periodToBeRendered.endedAt) {
    return null
  }

  return (
    <PeriodOption onClick={handleIntervalClick} $selected={selected} $disabled={disabled}>
      {dateTimeHelper.displayNameFromIntervalOrigin(originToBeRendered, DateFormat.Short)}
    </PeriodOption>
  )
}

function getPeriodLength(interval: Interval) {
  switch (interval) {
    case Interval.Quarter:
      return 4
    case Interval.Month:
      return 12
    default:
      return 0
  }
}
