import * as React from "react"
import {
  type MoneyFlow,
  ProductArea,
  type SummarizeTransactionsByTimeQueryVariables,
  type TransactionSummary,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import {
  AmountAndCount,
  ChartContainer,
} from "src/frontend/components/OS/Applications/Search/SearchResults/Shared"
import SparkBarChart from "src/frontend/components/OS/Shared/Charts/SparkBarChart"
import { SummaryWithCount } from "src/shared/components/Transactions/TransactionRow/SummaryWithCount"

/*
  INTERFACES
*/

interface SummaryProps {
  variables: SummarizeTransactionsByTimeQueryVariables
}

/*
  COMPONENTS
*/

const SearchResultActivitySummary: React.FC<SummaryProps> = ({ variables }) => {
  const { data, loading } = useSummarizeTransactionsByTimeQuery({
    variables,
    skip: variables.filter.productArea === ProductArea.None,
  })

  const summaries: TransactionSummary[] | undefined = React.useMemo(
    () => data?.dimensionalTransactionSummary.time.map((t) => t.summary),
    [data?.dimensionalTransactionSummary.time]
  )

  if (loading) {
    return (
      <AmountAndCount>
        <SummaryWithCount loading />
      </AmountAndCount>
    )
  }

  if (!summaries || !summaries.length) {
    return null
  }

  const { moneyFlow } = summaries.reduce(
    (totals, summary) => {
      totals.count += summary.total.transactionsCount
      totals.moneyFlow = moneyFlowHelper.add(totals.moneyFlow, summary.total.moneyFlow)
      return totals
    },
    {
      count: 0,
      moneyFlow: moneyFlowHelper.buildZeroMoneyFlow(summaries?.[0]?.total.moneyFlow?.businessFlow),
    }
  )

  return (
    <>
      <SummarySparkChart summaries={summaries} moneyFlow={moneyFlow} />
      <AmountAndCount>
        <SummaryWithCount
          moneyFlow={moneyFlow}
          style={CurrencyStyle.Aggregation}
          absolute
          ignoreHover
        >
          Last 12 Months
        </SummaryWithCount>
      </AmountAndCount>
    </>
  )
}

export default SearchResultActivitySummary

const SummarySparkChart: React.FC<{ summaries: TransactionSummary[]; moneyFlow: MoneyFlow }> = ({
  summaries,
  moneyFlow,
}) => {
  const series = React.useMemo(
    () =>
      summaries
        .flatMap((item) => {
          const periodMoney = item.total
          return {
            x: item.period,
            y: periodMoney.moneyFlow || moneyFlowHelper.buildZeroMoneyFlow(),
          }
        })
        .reverse(),
    [summaries]
  )

  // if total is less than $5.00 dont show a graph
  if (Math.abs(moneyFlow.value.amount / moneyFlow.value.currencyMultiplier) < 5) return null

  return (
    <ChartContainer>
      <SparkBarChart series={series} animateChartInitialDelay={800} />
    </ChartContainer>
  )
}
