import type * as React from "react"
import {
  managedModalOpenSelector,
  monitoredModalOpenSelector,
} from "src/frontend/components/Shared/ManagedModals/state/selectors"
import { useModalManagerStore } from "src/frontend/components/Shared/ManagedModals/state/store"

export const DeferToModal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isManagedModalOpen = useModalManagerStore(managedModalOpenSelector)
  const isMonitoredModalOpen = useModalManagerStore(monitoredModalOpenSelector)
  const isModalOpen = isManagedModalOpen || isMonitoredModalOpen
  return !isModalOpen && children
}
