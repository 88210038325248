import * as React from "react"
import * as ReactDOM from "react-dom"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgXClose } from "@digits-shared/components/SVGIcons/line/XClose.svg"
import { SvgInfoCircleSolid } from "@digits-shared/components/SVGIcons/solid/InfoCircleSolid.svg"
import { SvgAIFillGradient } from "@digits-shared/components/SVGIcons/SvgAIGradient"
import { type ButtonProps, DigitsButton } from "@digits-shared/DesignSystem/Button"
import { AI_GRADIENT_BORDER } from "@digits-shared/DesignSystem/Containers/AIGradients"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import { usePortalElement } from "@digits-shared/hooks/usePortalElement"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import { BodyText, DetailText } from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"

/*
 * STYLES
 */

const ANIMATION_DURATION_MS = 275
const VIEWPORT_PADDING = "25px"
const bottomRightStartingStyle = css`
  transform: translateX(calc(100% + ${VIEWPORT_PADDING}));
  opacity: 0;
`
const slideLeft = keyframes`
	from {
    ${bottomRightStartingStyle};
	}
	to {
		transform: translateX(0);
    opacity: 1;
	}
`

const bottomStartingStyle = css`
  transform: translateY(100%);
  opacity: 0;
`
const slideUp = keyframes`
	from {
    ${bottomStartingStyle};
	}
	to {
		transform: translateY(0);
    opacity: 1;
	}
`

const FloatingCloseButton = styled(DigitsButton)`
  background: ${colors.glowBackground};
  display: none;
  opacity: 0;
  position: absolute;
  top: -8px;
  right: -8px;
  border: 1px solid ${colors.glowDim};
  width: 20px;
  height: 20px;
  transition:
    background 275ms,
    opacity 275ms,
    display 275ms;
  transition-behavior: allow-discrete;
  &:hover:not([disabled]) {
    background: ${colors.secondary10};
  }
`

const SVG_STYLES = css<{ type?: SnackbarType; $isLineIcon?: boolean }>`
  width: 18px;
  height: 18px;

  ${({ type, $isLineIcon }) => {
    switch (type) {
      case "warning":
        return css`
          ${$isLineIcon ? svgPathStyles(colors.error, 1.5) : svgIconStyles(colors.error)};
        `
      case "success":
        return css`
          ${$isLineIcon
            ? svgPathStyles(colors.accentGreen, 1.5)
            : svgIconStyles(colors.accentGreen)};
        `
      case "glow":
        return css`
          ${$isLineIcon ? svgPathStyles(colors.secondary, 1.5) : svgIconStyles(colors.secondary)};
        `
      case "ai":
        return css`
          ${$isLineIcon ? svgPathStyles("#446BC8", 1.5) : svgIconStyles("#446BC8")};
        `
      case "info":
      default:
        return css`
          ${$isLineIcon ? svgPathStyles(colors.white, 1.5) : svgIconStyles(colors.white)};
        `
    }
  }};
`

const InfoIcon = styled(SvgInfoCircleSolid)<{ type?: SnackbarType }>`
  ${SVG_STYLES};
`

const SnackbarWrapper = styled.div<SnackbarWrapperProps>`
  background: ${({ $type }) => {
    switch ($type) {
      case "glow":
      case "ai":
        return colors.glowSoft
      default:
        return colors.black
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: fixed;
  padding: 16px 24px;
  width: fit-content;
  z-index: ${zIndexes.snackbar};
  box-shadow: 0px 4px 20px 0px ${colors.translucentBlack40};

  &:focus-visible {
    outline: 1px auto ${colors.accentBlue};
  }

  ${({ $position }) =>
    $position === "bottom-right"
      ? css`
          bottom: ${VIEWPORT_PADDING};
          right: ${VIEWPORT_PADDING};
          border-radius: 8px;
        `
      : css`
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 8px 8px 0 0;
        `}

  ${({ $type }) =>
    $type === "ai" &&
    css`
      ${AI_GRADIENT_BORDER};
      border: none; // need to remove the border because it is not relative (fixed positioned)
      box-shadow: 0 -2px 12px 0 rgba(94, 91, 233, 0.5);
    `};

  // NOTE: @starting-style is not baseline available so in firefox
  //       the close button will be visible without hovering
  &:hover,
  &:focus-visible,
  &:focus-within {
    ${FloatingCloseButton} {
      opacity: 1;
      display: flex;

      @starting-style {
        opacity: 0;
      }
    }
  }

  ${({ $animate, $position, $delayMs }) =>
    $animate &&
    ($position === "bottom"
      ? css`
          animation: ${ANIMATION_DURATION_MS}ms ease ${$delayMs || 0}ms forwards ${slideUp};
          // TODO: replace with @starting-style once baseline available
          ${$delayMs && bottomStartingStyle};
        `
      : css`
          animation: ${ANIMATION_DURATION_MS}ms ease ${$delayMs || 0}ms forwards ${slideLeft};
          // TODO: replace with @starting-style once baseline available
          ${$delayMs && bottomRightStartingStyle};
        `)}

  ${BodyText} {
    color: ${({ $type }) => {
      switch ($type) {
        case "glow":
          return colors.secondary
        case "ai":
          return "#446BC8"
        default:
          return colors.white
      }
    }};
  }

  ${DetailText} {
    color: ${({ $type }) => {
      switch ($type) {
        case "glow":
          return colors.secondary70
        case "ai":
          return "rgba(68, 107, 200, 0.70)"
        default:
          return colors.translucentWhite70
      }
    }};
  }
`

const SnackbarContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 360px;
`

/*
 * INTERFACES
 */

type SnackbarType = "info" | "success" | "warning" | "ai" | "glow"
type SnackbarPosition = "bottom" | "bottom-right"

type SnackbarWrapperProps = {
  $type?: PersistentSnackbarProps["type"]
  $position?: PersistentSnackbarProps["position"]
  $animate?: PersistentSnackbarProps["animate"]
  $delayMs?: PersistentSnackbarProps["delayMs"]
}

type PersistentSnackbarProps = {
  className?: string
  type?: SnackbarType
  position?: SnackbarPosition
  animate?: boolean
  delayMs?: number
  message: React.ReactNode
  description?: React.ReactNode
  buttonContents?: React.ReactNode
  buttonVariant?: ButtonProps["$variant"]
  onClick?: () => void
  buttonOverride?: React.ReactNode
  showIcon?: boolean
  IconComponent?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  isLineIcon?: boolean
  dismissibleOnHover?: boolean
  onDismiss?: () => void
}

export type SnackbarProps = PersistentSnackbarProps & {
  dismissible?: boolean
}

/*
 * COMPONENTS
 */

export const Snackbar: React.FC<React.PropsWithChildren<SnackbarProps>> = ({
  className,
  type,
  position = "bottom",
  animate = true,
  delayMs,
  message,
  description,
  buttonContents,
  buttonVariant,
  onClick,
  buttonOverride,
  showIcon = true,
  IconComponent,
  isLineIcon = false,
  dismissible = false,
  dismissibleOnHover = false,
  onDismiss,
  children,
}) => {
  const root = usePortalElement("snackbar-root")
  return ReactDOM.createPortal(
    <DismissibleSnackbar
      className={className}
      type={type}
      position={position}
      animate={animate}
      delayMs={delayMs}
      message={message}
      description={description}
      buttonContents={buttonContents}
      buttonVariant={buttonVariant}
      onClick={onClick}
      buttonOverride={buttonOverride}
      showIcon={showIcon}
      IconComponent={IconComponent}
      isLineIcon={isLineIcon}
      dismissible={dismissible}
      dismissibleOnHover={dismissibleOnHover}
      onDismiss={onDismiss}
      children={children}
    />,
    root
  )
}

/**
 *  @deprecated Prefer Snackbar to mount in Snackbar root portal
 */
export const DismissibleSnackbar: React.FC<React.PropsWithChildren<SnackbarProps>> = ({
  dismissible,
  onDismiss,
  onClick,
  ...rest
}) => {
  const { value: dismissed, setTrue: hideSnackbar } = useStateBoolean(false)
  const handleDismiss = React.useCallback(() => {
    hideSnackbar()
    onDismiss?.()
  }, [onDismiss, hideSnackbar])

  const handleClick = React.useCallback(() => {
    if (dismissible) {
      handleDismiss()
    }
    onClick?.()
  }, [dismissible, handleDismiss, onClick])

  if (dismissed) return null

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PersistentSnackbar {...rest} onClick={handleClick} onDismiss={handleDismiss} />
}

const PersistentSnackbar: React.FC<React.PropsWithChildren<PersistentSnackbarProps>> = ({
  className,
  type,
  position = "bottom",
  animate = true,
  delayMs,
  message,
  description,
  buttonContents,
  buttonVariant = "secondary-white",
  onClick,
  buttonOverride,
  showIcon = true,
  IconComponent,
  isLineIcon = false,
  dismissibleOnHover,
  onDismiss,
  children,
}) => (
  <SnackbarWrapper
    className={className}
    role="status"
    tabIndex={0}
    $type={type}
    $animate={animate}
    $position={position}
    $delayMs={delayMs}
  >
    {showIcon &&
      (IconComponent ? (
        <IconComponentStyled
          css={SVG_STYLES}
          type={type}
          $isLineIcon={isLineIcon}
          IconComponent={IconComponent}
        />
      ) : (
        <InfoIcon type={type} />
      ))}

    <SnackbarContent>
      <BodyText weight="heavy">{message}</BodyText>
      {description && <DetailText>{description}</DetailText>}
      {children}
    </SnackbarContent>

    {buttonOverride ||
      (buttonContents && (
        <DigitsButton $variant={buttonVariant} onClick={onClick} size="medium" type="button">
          {buttonContents}
        </DigitsButton>
      ))}
    {dismissibleOnHover && onDismiss && (
      <FloatingCloseButton
        size="small"
        $variant="secondary-dark"
        $circle
        onClick={onDismiss}
        type="button"
      >
        <SvgXClose />
      </FloatingCloseButton>
    )}
  </SnackbarWrapper>
)

const IconComponentStyled: React.FC<{
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
  className?: string
  type?: SnackbarType
  $isLineIcon?: boolean
}> = ({ IconComponent, className, type }) => {
  const icon = <IconComponent type={type} className={className} />
  if (type === "ai") {
    return <SvgAIFillGradient IconComponent={() => icon} css={SVG_STYLES} />
  }
  return icon
}
