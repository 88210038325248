import * as React from "react"
import { Link } from "react-router-dom"
import {
  type Insight,
  type InsightFieldsFragment,
  InsightSubjectType,
  InsightType,
  type IntervalOrigin,
  type ObjectEntities,
  PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import type Session from "@digits-shared/session/Session"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { insightDriversParser } from "src/frontend/components/Shared/Layout/Components/Insights/insightDriversParser"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { formatInsightChildNode } from "src/shared/components/Insights/sentenceParsing"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import sentenceParsingHelper, {
  type InsightTagInfo,
  SentenceTagKeys,
} from "src/shared/helpers/sentenceParsingHelper"

/*
 STYLES
*/

const InsightItemStyled = styled.li`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: start;

  &:last-child {
    border-bottom: none;
  }

  border-bottom: 1px solid ${colors.translucentWhite04};
`

const NoResultsListItem = styled(InsightItemStyled)`
  font-size: 13px;
`

const descriptionColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite80,
})
const InsightDescription = styled(RowContentDescription)`
  color: ${descriptionColor};
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  line-height: 19px;
  flex: 1;
  display: flow-root;
  align-items: center;
  white-space: normal;
`

export const Score = styled.div`
  font-size: 13px;
  font-style: ${fonts.style.oblique};
  color: ${colors.gray};
`

const boldColor = themedValue({
  light: colors.secondary,
  dark: colors.theme.dark.text,
})
const Bold = styled.span`
  color: ${boldColor};
  font-weight: ${fonts.weight.heavy};
  display: contents;
`

const Subject = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`

const highlightColor = themedValue({
  light: colors.secondary,
  dark: colors.white,
})
export const SubjectNameHighlight = styled(Link)<{ disabled: boolean }>`
  margin-left: 0;
  color: ${highlightColor};
  font-weight: ${fonts.weight.heavy};

  svg + & {
    margin-left: 24px;
  }

  &:hover {
    text-decoration: underline;
  }

  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
`

interface DeltaHighlightProps {
  absoluteDirection: AbsoluteDirection
  type: InsightType
}

const deltaHighligthColor = themedValue<DeltaHighlightProps>({
  light: ({ absoluteDirection, type }) =>
    absoluteDirection === AbsoluteDirection.Up
      ? type === InsightType.Income
        ? colors.primary
        : colors.deltaBad
      : type === InsightType.Income
        ? colors.deltaBad
        : colors.primary,
  dark: ({ absoluteDirection, type }) =>
    absoluteDirection === AbsoluteDirection.Up
      ? type === InsightType.Income
        ? colors.neonGreen
        : colors.deltaBad
      : type === InsightType.Income
        ? colors.deltaBad
        : colors.neonGreen,
})

const DeltaHighlight = styled.span<DeltaHighlightProps>`
  color: ${deltaHighligthColor};
  font-weight: ${fonts.weight.heavy};
  display: contents;
`

export const ENTITY_INSIGHT_STYLES = css`
  /* These must match the backend class names. */
  .dollar-value,
  .percentage {
    font-weight: bold;
  }
  .outbound {
    color: ${colors.orange};
  }
  .inbound {
    color: ${colors.primary};
  }
`

export const EntityInsight = styled.div`
  outline: none;
  resize: none;
  text-align: left;
  word-break: break-word;
  font-weight: 350;
  font-size: 14px;
  line-height: 23px;

  ${ENTITY_INSIGHT_STYLES};

  ul,
  ol {
    padding: revert;
  }
`

/*
 INTERFACES
*/

enum AbsoluteDirection {
  Up = "Up",
  Down = "Down",
}

interface SharedInsightProps {
  insight: Insight
  intervalOrigin: IntervalOrigin
  entities?: ObjectEntities | null
}

interface InsightItemProps extends SharedInsightProps {
  className?: string
  alwaysShowDate: boolean
  parseDrivers?: boolean
}

interface InsightTextProps extends SharedInsightProps {
  alwaysShowDate: boolean
  parseDrivers?: boolean
}

interface CategoryOrPartyNameProps extends SharedInsightProps {
  sentenceTagKey: SentenceTagKeys
  tagInfo: InsightTagInfo
}

interface SentenceTagProps extends SharedInsightProps {
  tagInfo: InsightTagInfo
  sentencePartCount: number
  sentenceTagKey?: SentenceTagKeys
}

/*
 COMPONENTS
*/

export const InsightItem: React.FC<InsightItemProps> = ({
  className,
  insight,
  alwaysShowDate,
  intervalOrigin,
  entities,
  parseDrivers,
}) => (
  <InsightItemStyled className={className}>
    <InsightText
      insight={insight}
      alwaysShowDate={alwaysShowDate}
      intervalOrigin={intervalOrigin}
      entities={entities}
      parseDrivers={parseDrivers}
    />
  </InsightItemStyled>
)

const InsightText: React.FC<InsightTextProps> = ({
  insight,
  alwaysShowDate,
  intervalOrigin,
  entities,
  parseDrivers,
}) => {
  if (!insight.periodValue) {
    console.error("Insight %o without a periodValue", insight)
    return null
  }

  let sentence = insight.sentence || ""

  // Heuristic to detect the new-style insights based on the absence of the old-style tags that used tildes.
  if (sentence && !sentenceParsingHelper.sentenceContainsUnparsedTag(sentence)) {
    return (
      <EntityInsight>
        <EntitiesParser
          text={parseDrivers ? insightDriversParser(sentence) : sentence}
          entities={entities}
          formatChildNode={formatInsightChildNode}
        />
      </EntityInsight>
    )
  }

  const sentenceParts = []
  const isCurrentPeriod = dateTimeHelper.isCurrentInterval(intervalOrigin)

  while (sentenceParsingHelper.sentenceContainsUnparsedTag(sentence)) {
    const sentenceTagKey = sentenceParsingHelper.getFirstStartTag(sentence)
    const tagInfo = sentenceParsingHelper.parseSentencePart(sentence, sentenceTagKey)

    if (!tagInfo) {
      sentence = "There was a problem processing this insight"
      break
    }

    const { tagStartIndex, tagEndIndex } = tagInfo
    if (tagStartIndex !== 0) {
      sentenceParts.push(sentence.substring(0, tagStartIndex))
    }

    if (
      !alwaysShowDate &&
      !isCurrentPeriod &&
      sentenceTagKey === SentenceTagKeys.CURRENT_TIME_PLACEHOLDER
    ) {
      sentence = sentence.substring(tagEndIndex)
      continue
    }

    sentenceParts.push(
      <SentenceTag
        key={sentenceParts.length}
        tagInfo={tagInfo}
        sentencePartCount={sentenceParts.length}
        sentenceTagKey={sentenceTagKey}
        insight={insight}
        intervalOrigin={intervalOrigin}
        entities={entities}
      />
    )

    sentence = sentence.substring(tagEndIndex)
  }

  if (sentence.length > 0) {
    sentenceParts.push(sentence)
  }

  return <InsightDescription>{sentenceParts}</InsightDescription>
}

const DeltaHighlightComponent: React.FC<{
  absoluteDirection: AbsoluteDirection
  absPercentDelta: string
  insightType: InsightType
}> = ({ absoluteDirection, absPercentDelta, insightType }) => (
  <DeltaHighlight absoluteDirection={absoluteDirection} type={insightType}>
    {absPercentDelta}
  </DeltaHighlight>
)

const CategoryOrPartyName: React.FC<CategoryOrPartyNameProps> = ({
  sentenceTagKey,
  tagInfo,
  insight,
  intervalOrigin,
  entities,
}) => {
  const { parsedValue, entityId } = tagInfo
  const session = useSession<FrontendSession>()

  const truncatedIntervalOrigin = {
    ...intervalOrigin,
    intervalCount: undefined,
  }
  let name = parsedValue
  let path = routeForInsight(insight, intervalOrigin)

  switch (sentenceTagKey) {
    case SentenceTagKeys.ENTITY_CATEGORY: {
      const category = entities?.categories?.find((c) => c.id === entityId)
      if (category) {
        name = category.name || parsedValue
        path = routes.categoryDetails.generateFromCurrentPath({
          categoryId: entityId,
          ...truncatedIntervalOrigin,
        })
      }
      break
    }

    case SentenceTagKeys.ENTITY_PARTY:
    case SentenceTagKeys.ENTITY_PARTY_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_PREPAID_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_OWED_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_CUSTOMER:
    case SentenceTagKeys.ENTITY_PARTY_UNPAID_CUSTOMER:
    case SentenceTagKeys.ENTITY_PARTY_SUPPLIER:
    case SentenceTagKeys.ENTITY_PARTY_SHAREHOLDER:
    case SentenceTagKeys.ENTITY_PARTY_LENDER:
    case SentenceTagKeys.ENTITY_PARTY_FACILITATOR: {
      const party = entities?.parties?.find((p) => p.id === entityId)
      if (party) {
        name = party.name || parsedValue
        const frontendPartyRole = FrontendPartyRole.findByRole(
          entities?.parties?.find((p) => p.id === entityId)?.roles?.[0] ||
            PartyRole.EntityVendorRole
        )
        path = routes.partyDetails.generateFromCurrentPath({
          partyId: entityId,
          partyRole: frontendPartyRole.urlKey,
          ...truncatedIntervalOrigin,
        })
      }
      break
    }
  }

  return (
    <Subject>
      <SubjectNameHighlight
        to={path}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        disabled={hackSharedSession(session) ? session.isSharingContextActive : false}
      >
        {name}
      </SubjectNameHighlight>
    </Subject>
  )
}

const SentenceTag: React.FC<SentenceTagProps> = ({
  tagInfo,
  sentencePartCount,
  sentenceTagKey,
  insight,
  intervalOrigin,
  entities,
}) => {
  if (!insight.periodValue) {
    console.error("Insight %o without a periodValue", insight)
    return null
  }

  const { parsedValue } = tagInfo
  const { periodValue } = insight

  switch (sentenceTagKey) {
    case SentenceTagKeys.SUBJECT_NAME:
    case SentenceTagKeys.ENTITY_CATEGORY:
    case SentenceTagKeys.ENTITY_CATEGORY_LEGACY:
    case SentenceTagKeys.ENTITY_PARTY:
    case SentenceTagKeys.ENTITY_PARTY_LEGACY:
    case SentenceTagKeys.ENTITY_PARTY_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_PREPAID_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_OWED_VENDOR:
    case SentenceTagKeys.ENTITY_PARTY_CUSTOMER:
    case SentenceTagKeys.ENTITY_PARTY_UNPAID_CUSTOMER:
    case SentenceTagKeys.ENTITY_PARTY_SUPPLIER:
    case SentenceTagKeys.ENTITY_PARTY_SHAREHOLDER:
    case SentenceTagKeys.ENTITY_PARTY_LENDER:
    case SentenceTagKeys.ENTITY_PARTY_FACILITATOR:
      return (
        <CategoryOrPartyName
          sentenceTagKey={sentenceTagKey}
          tagInfo={tagInfo}
          insight={insight}
          intervalOrigin={intervalOrigin}
          entities={entities}
        />
      )

    case SentenceTagKeys.PERCENT_DELTA_LESS:
      return (
        <DeltaHighlightComponent
          absoluteDirection={AbsoluteDirection.Down}
          absPercentDelta={numberHelper.formatPercentage(Number(parsedValue))}
          insightType={insight.type}
        />
      )

    case SentenceTagKeys.PERCENT_DELTA_GREATER:
      return (
        <DeltaHighlightComponent
          absoluteDirection={AbsoluteDirection.Up}
          absPercentDelta={numberHelper.formatPercentage(Number(parsedValue))}
          insightType={insight.type}
        />
      )

    case SentenceTagKeys.PREVIOUS_AMOUNT:
    case SentenceTagKeys.ABSOLUTE_AMOUNT:
    case SentenceTagKeys.ABSOLUTE_DELTA:
      return sentenceParsingHelper.getParsedAmountWithCurrency(Number(parsedValue), periodValue)

    case SentenceTagKeys.PREVIOUS_AMOUNT_LESS:
    case SentenceTagKeys.ABSOLUTE_DELTA_LESS:
    case SentenceTagKeys.ABSOLUTE_AMOUNT_LESS:
      return (
        <DeltaHighlightComponent
          absoluteDirection={AbsoluteDirection.Down}
          absPercentDelta={
            sentenceParsingHelper.getParsedAmountWithCurrency(
              Number(parsedValue),
              periodValue
            ) as string
          }
          insightType={insight.type}
        />
      )

    case SentenceTagKeys.ABSOLUTE_DELTA_GREATER:
    case SentenceTagKeys.PREVIOUS_AMOUNT_GREATER:
    case SentenceTagKeys.ABSOLUTE_AMOUNT_GREATER:
      return (
        <DeltaHighlightComponent
          absoluteDirection={AbsoluteDirection.Up}
          absPercentDelta={
            sentenceParsingHelper.getParsedAmountWithCurrency(
              Number(parsedValue),
              periodValue
            ) as string
          }
          insightType={insight.type}
        />
      )

    case SentenceTagKeys.PERCENT_DELTA_BOLD:
    case SentenceTagKeys.ABSOLUTE_AMOUNT_BOLD:
    case SentenceTagKeys.PREVIOUS_AMOUNT_BOLD:
    case SentenceTagKeys.ABSOLUTE_DELTA_BOLD:
      return (
        <Bold>
          &nbsp;
          {sentenceParsingHelper.getParsedAmountWithCurrency(Number(parsedValue), periodValue)}
        </Bold>
      )

    case SentenceTagKeys.PAST_TIME_PLACEHOLDER:
      return <span>{sentenceParsingHelper.formatPastTime(intervalOrigin, sentencePartCount)}</span>
    case SentenceTagKeys.CURRENT_TIME_PLACEHOLDER: {
      return (
        <span>{sentenceParsingHelper.formatCurrentTime(intervalOrigin, sentencePartCount)}</span>
      )
    }
    case SentenceTagKeys.FULL_DATE_PLACEHOLDER: {
      return <span>{sentenceParsingHelper.formatFullDate(Number(parsedValue))}</span>
    }

    case SentenceTagKeys.LOOKBACK_WINDOW:
    case SentenceTagKeys.PERCENT_DELTA:
    case undefined:
      return parsedValue
  }
}

function hackSharedSession(session: Session): session is FrontendSession {
  return (session as FrontendSession)?.hasLegalEntity
}

export const routeForInsight = (
  insight: Insight | InsightFieldsFragment,
  intervalOrigin: IntervalOrigin
) => {
  const truncatedIntervalOrigin = {
    ...intervalOrigin,
    intervalCount: undefined,
  }

  if (insight.subjectType === InsightSubjectType.Party) {
    const frontendPartyRole = FrontendPartyRole.findByInsightType(insight.type)
    return routes.partyDetails.generateFromCurrentPath({
      partyId: insight.subjectId,
      partyRole: frontendPartyRole.urlKey,
      ...truncatedIntervalOrigin,
    })
  }

  return routes.categoryDetails.generateFromCurrentPath({
    categoryId: insight.subjectId,
    ...truncatedIntervalOrigin,
  })
}

export const EmptyInsight = () => <NoResultsListItem>No Executive Summary</NoResultsListItem>

export const LoadingInsight: React.FC<{ className?: string }> = ({ className }) => (
  <InsightItemStyled className={className}>
    <LoadingBlock height="20px" width="100%" $randomWidthRange={40} />
  </InsightItemStyled>
)
