import * as React from "react"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { type ColumnKey } from "src/frontend/components/Shared/Layout/Components/Statements/columnTypes"
import useRowSelectionContext from "src/frontend/components/Shared/Layout/Components/Statements/RowSelectionContext"

interface CellProps {
  optionKey: ColumnKey
  timeseriesValue?: TimeseriesValue
}

export function useStatementCellEvents(rowId: string, props?: CellProps) {
  const optionKey = props?.optionKey
  const timeseriesValue = props?.timeseriesValue

  const { pinnedCell, setPinnedCell, setSelectedValue } = useRowSelectionContext()

  const selectTimeSeriesValue = React.useCallback(() => {
    if (!timeseriesValue) return setSelectedValue(undefined)

    switch (optionKey) {
      case "deltaYearToDate":
      case "yearToDate":
      case "deltaMonthOverMonth":
        setSelectedValue({ optionKey, value: timeseriesValue })
        break
      default:
        setSelectedValue(undefined)
    }
  }, [optionKey, setSelectedValue, timeseriesValue])

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      if (e.target === pinnedCell?.target) {
        setPinnedCell(undefined)
        return
      }
      setPinnedCell({ rowId, target: e.currentTarget })
      selectTimeSeriesValue()
    },
    [pinnedCell?.target, rowId, selectTimeSeriesValue, setPinnedCell]
  )

  const onMouseEnter = React.useCallback(() => {
    if (pinnedCell && pinnedCell?.rowId !== rowId) {
      return
    }
    selectTimeSeriesValue()
  }, [pinnedCell, rowId, selectTimeSeriesValue])

  return { onClick, onMouseEnter }
}
