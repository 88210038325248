import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

export const CONTAINER_STYLES = css`
  background: ${colors.translucentWhite40};
  border-radius: 10px;
  border: 0.5px solid ${colors.translucentWhite04};
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;

  transition: background-color 200ms ease-in-out;
`
