import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

const NotFound = styled.div`
  margin-top: 30px;
  color: ${colors.secondary};
  font-size: 18px;
  text-align: center;

  b {
    font-weight: ${fonts.weight.heavy};
    font-size: 22px;
  }
`

/*
 COMPONENTS
*/

export const NoResultsFound: React.FC<{ className?: string }> = ({ className }) => (
  <NotFound className={className}>
    <b>No results found</b>
    <div css="margin-top: 14px">Try a different keyword or check for typos</div>
  </NotFound>
)
