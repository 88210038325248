import {
  type ManagedModalName,
  type ManagedSnackbarName,
  Managee,
} from "src/frontend/components/Shared/ManagedModals/state/types"

export function makeModalStorageId(modalName: ManagedModalName) {
  return makeStorageId(Managee.Modal, modalName)
}

export function makeSnackbarStorageId(snackbarName: ManagedSnackbarName) {
  return makeStorageId(Managee.Snackbar, snackbarName)
}

function makeStorageId(managee: Managee, name: string) {
  return `${managee}::${name}`
}
