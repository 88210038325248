import * as React from "react"
import { type LayoutComponentConfig } from "@digits-graphql/frontend/graphql-bearer"
import { useIntervalOriginWithDefaultCount } from "src/shared/hooks/useIntervalOrigin"

type ComponentConfigs = Required<Omit<LayoutComponentConfig, "type">>

type Config = ComponentConfigs[keyof ComponentConfigs]

// Helper hook which accepts a layout component config, and applies a lookback period of
// some number of intervals, starting from the interval origin from the client portal
// period selector.
//
// This should be used for configs that present charts with time periods that look backwards
// in time.
export function useConfigLookbackOriginOverride<T extends Config>(config: T): T {
  const origin = useIntervalOriginWithDefaultCount()

  return React.useMemo(
    () => ({
      ...config,
      origin,
    }),
    [config, origin]
  )
}

// Helper hook which accepts a layout component config, and applies the interval origin
// from the client portal period selector.
//
// This should be used for configs that present data without showing data from previous intervals.
export function useConfigOriginOverride<T extends Config>(
  config: T,
  intervalCount?: number,
  lookbackIntervalCount?: number
): T {
  const origin = useIntervalOriginWithDefaultCount()

  return React.useMemo(
    () => ({
      ...config,
      origin: {
        ...origin,
        intervalCount: intervalCount ?? origin.intervalCount,
      },
      lookback: lookbackIntervalCount
        ? { ...origin, intervalCount: lookbackIntervalCount }
        : undefined,
    }),
    [config, intervalCount, lookbackIntervalCount, origin]
  )
}
