import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { type PathGenerator, usePathGenerator } from "@digits-shared/hooks/usePathGenerator"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useQuarterlyDashboardPathGenerator(): PathGenerator {
  const { hasAccessToAspect } = useSession<FrontendSession>()
  const quarterlyDashboard = hasAccessToAspect(AspectCode.QuarterlyDashboard)
  const defaultOrigin = useIntervalOrigin()
  const origin = React.useMemo(() => {
    if (quarterlyDashboard) {
      return dateTimeHelper.changeIntervalInOrigin(defaultOrigin, Interval.Quarter, undefined)
    }

    return defaultOrigin
  }, [defaultOrigin, quarterlyDashboard])
  return usePathGenerator(routes, origin)
}
