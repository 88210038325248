import * as React from "react"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import styled from "styled-components"
import {
  Date,
  Description,
  Title as RowTitle,
  TopTransactionsList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { useTopTransactionsLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopTransactionsLiveData"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"

const StyledTopTransactionsList = styled(TopTransactionsList)`
  ${RowTitle}, ${Amount} {
    color: #0c404b;
  }

  ${Description} {
    color: #0c404b99;
  }

  ${Date} {
    color: #0c404b99;
  }
`

/*
  INTERFACES
*/

export type TopTransasctionsProps = SizingProps & {
  component: MatchedComponent<"topTransactions">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const TopTransactions: React.FC<TopTransasctionsProps> = ({ component, componentSize }) => {
  const overriddenConfig = useConfigOriginOverride(component.config.topTransactions, 1)
  const config = React.useMemo(
    () => ({ ...component.config, topTransactions: overriddenConfig }),
    [component.config, overriddenConfig]
  )

  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  const { title, periodName } = useComponentConfigTitle(config)

  const { data, loading } = useTopTransactionsLiveData(overriddenConfig, component.config.type)

  return (
    <>
      <DigitsTooltip content={<div>{title}</div>} disabled={!isTextOverflowing}>
        <ComponentHeaderTitle
          ref={textElementRef}
          title={title}
          componentSize={componentSize}
          periodName={periodName}
        />
      </DigitsTooltip>
      <StyledTopTransactionsList padding={10} transactions={data?.transactions} loading={loading} />
    </>
  )
}
