import envHelper from "@digits-shared/helpers/envHelper"
import { create } from "zustand"
import { devtools, redux } from "zustand/middleware"
import { type ModalManagerAction } from "src/frontend/components/Shared/ManagedModals/state/actions"
import * as effectFunctions from "src/frontend/components/Shared/ManagedModals/state/effects"
import { INITIAL_STATE, reducer } from "src/frontend/components/Shared/ManagedModals/state/reducer"
import { type ModalManagerState } from "src/frontend/components/Shared/ManagedModals/state/types"
import { createEffects, type Effect } from "src/frontend/state/effectsMiddleware"

const EFFECTS: Effect<ModalManagerState, ModalManagerAction>[] = Object.values(effectFunctions)
const effects = createEffects<ModalManagerState, ModalManagerAction>()

/** The Zustand state store for the ModalManager Details application */
export const useModalManagerStore = envHelper.isProduction()
  ? create(
      effects.middleware(
        redux<ModalManagerState, ModalManagerAction>(reducer, INITIAL_STATE),
        EFFECTS
      )
    )
  : create(
      effects.middleware(
        devtools(
          redux<ModalManagerState, ModalManagerAction, [["zustand/devtools", never]]>(
            reducer,
            INITIAL_STATE
          ),
          { name: "ModalManager store" }
        ),
        EFFECTS
      )
    )

export function useModalManagerDispatch() {
  return useModalManagerStore((state) => state.dispatch)
}

// Hook into Vite hot module reload API
import.meta.hot?.accept("./effects.ts", (mod) => {
  if (!mod) return

  effects.reload(mod)
})
