import * as React from "react"
import { type RunwayChartConfig as Config } from "@digits-graphql/frontend/graphql-bearer"
import { CashBadgedTitle } from "src/frontend/components/Shared/Layout/Components/Charts/CashBadge"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { RunwayLineChartComponent } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayLineChart"
import {
  useCashOutTimeLeft,
  useRunwayTimeseries,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { useRunwayLiveData } from "src/frontend/components/Shared/Portals/hooks/useRunwayLiveData"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface RunwayChartConfigProps extends SizingProps {
  config: Config
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const RunwayChartConfig: React.FC<RunwayChartConfigProps> = ({
  config,
  skipAnimations,
  height,
}) => {
  const removeConfig = useRemoveConfig()

  const { runway, loading } = useRunwayLiveData(config)
  const { title: cashOutTitle } = useCashOutTimeLeft(runway)
  const { currentTimeseries } = useRunwayTimeseries(runway)

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !runway?.current?.length) {
      removeConfig()
    }
  }, [loading, removeConfig, runway])

  return (
    <>
      <ComponentSummary
        componentSize={ComponentSize.Small}
        title={<CashBadgedTitle title="Runway" />}
        timeseries={currentTimeseries}
        defaultValueNode={cashOutTitle}
      />
      <ChartContainer height={height} width="auto">
        <RunwayLineChartComponent
          runway={runway}
          skipAnimations={skipAnimations}
          preview
          height={height}
        />
      </ChartContainer>
    </>
  )
}
