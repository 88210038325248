import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import {
  BillingProductName,
  useCheckBillingOrganizationPlanQuery,
  useReadLegalEntityQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { ModuleLoading } from "@digits-shared/components/Loaders"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import { type PathGenerator } from "@digits-shared/hooks/usePathGenerator"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useMobileRoutes } from "src/frontend/components/Shared/Responsive/mobileRoutes"
import { useObjectSharingState } from "src/frontend/hooks/useObjectSharingState"
import { useQuarterlyDashboardPathGenerator } from "src/frontend/hooks/useQuarterlyDashboardPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"

export const COMMON_APPLICATION_ROUTES = [
  routes.sharedWithMe,
  routes.internalFeatures,
  routes.internalDGRedirect,
]
export const COMMON_APPLICATION_PARAMETERIZE_PATHS = COMMON_APPLICATION_ROUTES.map(
  (r) => r.parameterizedPath
)

export function useLegalEntityRedirects() {
  const generatePath = useQuarterlyDashboardPathGenerator()
  const { location, params } = useRouter()
  const organizationPath = useRouteMatch(routes.organization.parameterizedPath)
  const session = useSession<FrontendSession>()
  const { noJWTPermission } = useObjectSharingState()
  const viewVersion = useViewVersion()
  const hasGetPaid = session.hasAccessToAspect(AspectCode.GetPaid)
  const firstDashboardLE = session.findFirstDashboardAccessLegalEntity()
  const mobileRoutes = useMobileRoutes()
  const urlLESlug = params.leSlug
  const currentLESlug = session.currentLegalEntity?.slug

  return React.useMemo(() => {
    // DO NOT block redirects here, it breaks onboarding
    // if (!state.blockLoginRedirect) {
    //   return undefined
    // }

    // For common application routes, we don't need to perform any redirects
    const currentRoute = routes[location.name]

    if (
      currentRoute &&
      (organizationPath ||
        COMMON_APPLICATION_ROUTES.find((r) => r.isRouteOrChildOfRoute(currentRoute)))
    ) {
      return undefined
    }

    const mobileHomePath =
      viewVersion.viewVersion && firstDashboardLE
        ? generatePath(mobileRoutes.home, { leSlug: firstDashboardLE.slug })
        : hasGetPaid && firstDashboardLE
          ? generatePath(routes.getPaid, { leSlug: firstDashboardLE.slug })
          : generatePath(routes.sharedWithMe)

    // Since we are passed our common application routes that we want to handle, we can assume
    // we have a legal entity slug in our URL. If not, something went wrong, so head back to root.
    if (!urlLESlug) {
      const mobileRedirect = legalEntityNotFoundOnMobile(session, mobileHomePath)
      if (mobileRedirect) return mobileRedirect

      const redirect = currentLegalEntityNotFound(session, generatePath)
      if (redirect) return redirect

      return (
        <LoggedRedirect
          name="useLegalEntityRedirects-noLESlugFound"
          to={generatePath(routes.root)}
        />
      )
    }

    // If there is no JWT permission for the shared object, we should be on sharedWithMe
    if (noJWTPermission) {
      return (
        <LoggedRedirect
          name="useLegalEntityRedirects-shareLoading"
          to={generatePath(routes.sharedWithMe)}
        />
      )
    }

    // Identify the legal entity by the slug in the URL
    const urlLegalEntity = session.findLegalEntityBySlug(urlLESlug)

    // If it's a mismatch, we should redirect
    if (currentLESlug && urlLESlug !== currentLESlug) {
      const redirect = urlLegalEntityMismatchRedirect(session, urlLegalEntity, generatePath)
      if (redirect) return redirect
    }

    // If the legal entity in the URL is available in session and if its not ready then redirect
    if (urlLegalEntity) {
      const redirect = urlLegalEntityNotReadyRedirect(session, urlLESlug, generatePath)
      if (redirect) return redirect
    }

    // If the current legal entity slug is not present, figure out where else we should be
    if (!currentLESlug) {
      const redirect = currentLegalEntityNotFound(session, generatePath)
      if (redirect) return redirect
    }

    if (isMobile) {
      const mobileRedirect = legalEntityMobileHome(currentRoute, generatePath, mobileRoutes)
      if (mobileRedirect) return mobileRedirect
    }

    // We are good to go! No redirects needed
    return undefined
  }, [
    location.name,
    organizationPath,
    viewVersion.viewVersion,
    firstDashboardLE,
    generatePath,
    hasGetPaid,
    urlLESlug,
    noJWTPermission,
    session,
    currentLESlug,
    mobileRoutes,
  ])
}

function urlLegalEntityNotReadyRedirect(
  session: FrontendSession,
  urlLESlug: string,
  generatePath: PathGenerator
) {
  const { doppelganger } = session
  const urlLegalEntity = session.findLegalEntityBySlug(urlLESlug)
  if (
    !doppelganger?.hasDashboardAccess &&
    (urlLegalEntity?.isPending || urlLegalEntity?.isNew) &&
    !session.hasDashboardAffiliations
  ) {
    // Show the PLG onboarding complete step
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-urlLegalEntityNotReadyPLGRedirect"
        to={generatePath(routes.plgOnboarding)}
      />
    )
  }

  if (
    !doppelganger?.hasDashboardAccess &&
    (urlLegalEntity?.isPending || urlLegalEntity?.isNew) &&
    session.hasDashboardAffiliations
  ) {
    // Show the application submitted screen
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-urlLegalEntityNotReadyRedirect"
        to={generatePath(routes.onboardApplicationStatus, { entity: urlLESlug })}
      />
    )
  }

  return undefined
}

function urlLegalEntityMismatchRedirect(
  session: FrontendSession,
  urlLegalEntity: SessionLegalEntity | undefined,
  generatePath: PathGenerator
) {
  const { currentLegalEntity, doppelganger } = session
  const urlLESlug = urlLegalEntity?.slug
  const currentLESlug = currentLegalEntity?.slug

  // If the current legal entity matches the sharing legal entity, it's likely the first load viewing a
  // details page with a share grant, which is overlaid on top of our content area (this component).
  // This means we should redirect to sharedWithMe, which should always be behind a shared page.
  if (currentLegalEntity && currentLegalEntity.id === session.sharingLegalEntity?.id) {
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-isShareLegalEntity"
        to={generatePath(routes.sharedWithMe)}
      />
    )
  }

  // If we found the legal entity in the URL and its has dashboard access, redirect to it
  if (urlLegalEntity) {
    if (urlLegalEntity.hasDashboardAccess(doppelganger)) {
      return (
        <LoggedRedirect
          name="useLegalEntityRedirects-urlLegalEntityMismatchRedirect-urlLegalEntity"
          to={generatePath(routes.legalEntityHome, { leSlug: urlLESlug })}
        />
      )
    }
    return undefined
  }

  // If we have a current legal entity slug, use that for the redirect
  if (currentLESlug) {
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-urlLegalEntityMismatchRedirect-currentLegalEntity"
        to={generatePath(routes.legalEntityHome, { leSlug: currentLESlug })}
      />
    )
  }

  // As a final fallback, go back to root since we don't have anywhere better to go
  return (
    <LoggedRedirect
      name="useLegalEntityRedirects-urlLegalEntityMismatchRedirect-root"
      to={generatePath(routes.root)}
    />
  )
}

// Final fallbacks if current legal entity is not found
function currentLegalEntityNotFound(session: FrontendSession, generatePath: PathGenerator) {
  const firstDashboardLE = session.findFirstDashboardAccessLegalEntity()

  if (!firstDashboardLE) {
    const pendingLE = session.findFirstOnboardingLegalEntity()
    if (pendingLE) {
      return <WaitWhilePending entity={pendingLE} generatePath={generatePath} />
    }

    if (session.hasNewOnboardingLegalEntity) {
      return (
        <LoggedRedirect
          name="useLegalEntityRedirects-currentLegalEntityNotFound-newOnboardingLE"
          to={routes.plgOnboarding.parameterizedPath}
        />
      )
    }

    // If we don't have a dashboard LE fallback, we'll assume we should be sharedWithMe
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-currentLegalEntityNotFound-noDashboardLE"
        to={generatePath(routes.sharedWithMe)}
      />
    )
  }

  // Otherwise, redirect to dashboard
  return (
    <LoggedRedirect
      name="useLegalEntityRedirects-currentLegalEntityNotFound"
      to={generatePath(routes.legalEntityHome, { leSlug: firstDashboardLE.slug })}
    />
  )
}

// redirects to a mobile home path if no slug in URL
function legalEntityNotFoundOnMobile(session: FrontendSession, homePath: string) {
  const firstDashboardLE = session.findFirstDashboardAccessLegalEntity()

  if (!firstDashboardLE || !isMobile) {
    return null
  }

  // Otherwise, redirect to home path
  return <LoggedRedirect name="useLegalEntityRedirects-legalEntityNotFoundOnMobile" to={homePath} />
}

// redirects to our mobile home (client portal) if current route is not mobile-ready
function legalEntityMobileHome(
  currentRoute: DigitsRoute,
  generatePath: PathGenerator,
  mobileRoutes: ReturnType<typeof useMobileRoutes>
) {
  const isMobileReady = mobileRoutes.findMobileRoute(currentRoute)
  if (!isMobile || isMobileReady) {
    return null
  }

  // Otherwise, redirect to dashboard
  return (
    <LoggedRedirect
      name="useLegalEntityRedirects-legalEntityMobileHome"
      to={generatePath(mobileRoutes.home)}
    />
  )
}

const WaitWhilePending: React.FC<{ entity: SessionLegalEntity; generatePath: PathGenerator }> = ({
  entity,
  generatePath,
}) => {
  const session = useSession<FrontendSession>()

  const {
    called: legalEntityCalled,
    loading: legalEntityLoading,
    data: legalEntityData,
  } = useReadLegalEntityQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: entity.id,
    },
  })

  // verify the user has read billing permission to the organization before attempting to check the plan
  const hasReadBillingPermission = React.useMemo(() => {
    if (!legalEntityData) return undefined
    const organization = session.findOrganizationById(
      legalEntityData?.readLegalEntity.organizationId
    )
    if (!organization) return false
    return organization.permissions.hasReadPermission(FrontendPermissionModule.Billing)
  }, [legalEntityData, session])

  const {
    called: billingPlanCalled,
    loading: billingPlanLoading,
    data: billingPlanData,
  } = useCheckBillingOrganizationPlanQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      organizationId: legalEntityData?.readLegalEntity.organizationId || "",
    },
    skip: !legalEntityData || !hasReadBillingPermission,
  })

  const billingProduct = React.useMemo(
    () => billingPlanData?.checkBillingOrganizationPlan?.billingPlan?.product,
    [billingPlanData]
  )

  if (hasReadBillingPermission === false) {
    return (
      <LoggedRedirect
        name="useLegalEntityRedirects-currentLegalEntityNotFound-pendingLE"
        to={generatePath(routes.onboardApplicationStatus)}
      />
    )
  }

  if (!billingPlanCalled || !legalEntityCalled || legalEntityLoading || billingPlanLoading) {
    return <ModuleLoading />
  }

  if (billingProduct?.name === BillingProductName.AIAccounting) {
    return (
      <LoggedRedirect
        name="plg-onboarding-ai-accounting"
        to={routes.plgOnboarding.parameterizedPath}
      />
    )
  }

  // send to application status
  return (
    <LoggedRedirect
      name="useLegalEntityRedirects-currentLegalEntityNotFound-pendingLE"
      to={generatePath(routes.onboardApplicationStatus)}
    />
  )
}
