import React from "react"
import { SvgArrowNarrowDownLeft } from "@digits-shared/components/SVGIcons/line/ArrowNarrowDownLeft.svg"
import { SvgArrowNarrowUpRight } from "@digits-shared/components/SVGIcons/line/ArrowNarrowUpRight.svg"
import { type ButtonVariant, DigitsButton } from "@digits-shared/DesignSystem/Button"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import stringHelper from "@digits-shared/helpers/stringHelper"
import styled from "styled-components"
import { ApplicationFullWidthPane } from "src/frontend/components/OS/Applications/ApplicationPane"
import { useUserSettings } from "src/frontend/hooks/useUserSettings"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

export const EXPANDED_CLASS_NAME = "application-pane-expanded"

/*
  STYLES
*/

export const ExpandablePane = styled(ApplicationFullWidthPane)`
  margin: 0 auto;

  &.${EXPANDED_CLASS_NAME} {
    width: 100%;
  }
`

const IconContainer = styled.div`
  position: relative;
  margin-left: -1px;
  svg {
    position: absolute;
    top: -6px;
    left: -8px;
    width: 14px;
    height: 12px;
    transition: transform 250ms ease-in-out;

    path {
      stroke-width: 3px;
    }
  }

  svg:first-child {
    transform: translate3d(5.5px, -4.5px, 0);
  }

  svg:last-child {
    transform: translate3d(-3.5px, 4.5px, 0);
  }

  &.${EXPANDED_CLASS_NAME} {
    svg:first-child {
      transform: translate3d(-3.5px, 4.5px, 0);
    }

    svg:last-child {
      transform: translate3d(5.5px, -4.5px, 0);
    }
  }
`

/*
  COMPONENTS
*/

export const ApplicationExpandablePane: React.FCWithRef<
  HTMLDivElement,
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const {
    settings: { expandedViewEnabled },
  } = useUserSettings()

  const classes = expandedViewEnabled
    ? stringHelper.classNames(className, EXPANDED_CLASS_NAME)
    : className

  return <ExpandablePane className={classes}>{children}</ExpandablePane>
}

export const ExpandPaneButton: React.FC<{ className?: string; variant?: ButtonVariant }> = ({
  className,
  variant = "secondary-dark",
}) => {
  const {
    settings: { expandedViewEnabled },
    toggleUserSetting,
  } = useUserSettings()
  const iconRef = React.useRef<HTMLDivElement>(null)

  const onButtonClick = React.useCallback(() => {
    iconRef.current?.classList.toggle(EXPANDED_CLASS_NAME, !expandedViewEnabled)
    const timer = setTimeout(
      () => toggleUserSetting(userPreferenceKeys.expandedView, !expandedViewEnabled),
      350
    )

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [expandedViewEnabled, toggleUserSetting])

  return (
    <DigitsTooltip content={<>{expandedViewEnabled ? "Minimize" : "Maximize"}</>}>
      <DigitsButton className={className} $circle onClick={onButtonClick} $variant={variant}>
        <IconContainer ref={iconRef} className={expandedViewEnabled ? EXPANDED_CLASS_NAME : ""}>
          <SvgArrowNarrowUpRight />
          <SvgArrowNarrowDownLeft />
        </IconContainer>
      </DigitsButton>
    </DigitsTooltip>
  )
}
