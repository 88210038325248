// Return titles for components that can be determined without making any requests.
import {
  CategoryType,
  type DataSourcePreference,
  DimensionSortMode,
  type LayoutComponentConfig,
  LayoutComponentType,
  ObjectKind,
  ReportKind,
  type TaxForm,
  type TopEntitiesConfig,
  type TopTransactionsConfig,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import stringHelper from "@digits-shared/helpers/stringHelper"
import {
  reportKindDisplayName,
  type ReportPackageDocumentKind,
} from "src/frontend/components/OS/Applications/Reports/Packages/shared"
import { matchConfig } from "src/frontend/components/Shared/Layout/types"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import {
  adjectiveForCategoryType,
  invertValuesForCategoryType,
} from "src/shared/helpers/categoryHelper"

export function getStaticComponentTitle(
  config: LayoutComponentConfig,
  dataSourcePreference?: DataSourcePreference
) {
  if (matchConfig(config, "text", LayoutComponentType.Text)) {
    return "Text"
  }

  if (matchConfig(config, "staticMetric", LayoutComponentType.StaticMetric)) {
    return `KPI${config.staticMetric.name ? ` (${config.staticMetric.name})` : ""}`
  }

  if (matchConfig(config, "summaryLineItem", LayoutComponentType.SummaryLineItemChart)) {
    return stringHelper.camelCaseToSpaces(config.summaryLineItem.item)
  }

  if (matchConfig(config, "metricChart", LayoutComponentType.MetricSummaryChart)) {
    return stringHelper.camelCaseToSpaces(config.metricChart.item)
  }

  if (matchConfig(config, "cashSummaryChart", LayoutComponentType.CashSummaryChart)) {
    return "Cash Flow"
  }

  if (matchConfig(config, "runwayChart", LayoutComponentType.RunwayChart)) {
    return "Runway"
  }

  if (matchConfig(config, "statement", LayoutComponentType.Statement)) {
    return displayNameForDocumentKind(config.statement.kind, dataSourcePreference?.taxForm)
  }

  if (
    matchConfig(config, "topEntities", LayoutComponentType.TopEntities) &&
    config.topEntities.kind === ObjectKind.Category
  ) {
    return topCategoriesTitle(config.topEntities)
  }

  if (
    matchConfig(config, "topEntities", LayoutComponentType.TopEntities) &&
    config.topEntities.kind === ObjectKind.Party
  ) {
    return topPartiesTitle(config.topEntities)
  }

  if (matchConfig(config, "topTransactions", LayoutComponentType.TopTransactions)) {
    return topTransactionsTitle(config.topTransactions)
  }

  if (matchConfig(config, "documentCollection", LayoutComponentType.DocumentCollection)) {
    return "File Dropzone"
  }

  return ""
}

function topCategoriesTitle(config: TopEntitiesConfig) {
  const invertValues = invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType)
  const categoryTypeName = adjectiveForCategoryType(config.categoryType)

  let sortModeName
  switch (config.sort) {
    case DimensionSortMode.Total:
      sortModeName = ""
      break
    case DimensionSortMode.Growing:
      sortModeName = invertValues ? "Shrinking" : "Growing"
      break
    case DimensionSortMode.Shrinking:
      sortModeName = invertValues ? "Growing" : "Shrinking"
      break
  }
  return ["Top", sortModeName, categoryTypeName, "Categories"].filter(defined).join(" ")
}

function topPartiesTitle(config: TopEntitiesConfig) {
  const role = config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined
  return role
    ? `Top ${stringHelper.pluralize(config.limit, role.displayName, role.pluralDisplayName)}`
    : ""
}

function topTransactionsTitle(config: TopTransactionsConfig) {
  const role = config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined

  if (role) {
    return `Top ${stringHelper.pluralize(
      config.limit,
      `${role.displayName} Transaction`,
      `${role.pluralDisplayName} Transactions`
    )}`
  }

  if (config.categoryType) {
    const typeName = adjectiveForCategoryType(config.categoryType)

    return `Top ${stringHelper.pluralize(
      config.limit,
      `${typeName} Transaction`,
      `${typeName} Transactions`
    )}`
  }

  return ""
}

function displayNameForDocumentKind(kind: ReportPackageDocumentKind, taxForm?: TaxForm): string {
  switch (kind) {
    case "ExecutiveSummary":
    case "Layout":
      return "Executive Summary"
    case ReportKind.BalanceSheet:
    case ReportKind.ExpenseSummary:
    case ReportKind.CashFlow:
    case ReportKind.ProfitAndLoss:
    case ReportKind.APAging:
    case ReportKind.ARAging:
    case ReportKind.Unknown:
      return reportKindDisplayName(kind, taxForm)
  }
}
