import React from "react"
import {
  ConnectionStatus,
  ConnectionSyncStatus,
  useListConnectionBadgeCountsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import type FrontendSession from "src/frontend/session/FrontendSession"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { Experience } from "src/frontend/session/personas"

export function useConnectionsBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const session = useSession<FrontendSession>()
  const legalEntitiesWithAspectsAndPermissions = legalEntityIds
    .map((legalEntityId) => session.findLegalEntityById(legalEntityId))
    .filter<SessionLegalEntity>(defined)
    .filter((legalEntity) => legalEntity.hasAccessToAspect(AspectCode.AIBookkeeper))
    .filter((legalEntity) =>
      session
        .findOrganizationByLegalEntityId(legalEntity.id)
        ?.permissions.hasReadPermission(FrontendPermissionModule.Connections)
    )
    .map((legalEntity) => legalEntity.id)

  const result = useListConnectionBadgeCountsQuery({
    variables: {
      filter: {
        legalEntityIds: legalEntitiesWithAspectsAndPermissions,
        connectionStatuses:
          session.currentPrimaryExperience === Experience.AccountantPortal
            ? [ConnectionStatus.ActivationPending, ConnectionStatus.ConfigurationPending]
            : [],
        syncStatuses: [ConnectionSyncStatus.LoginRequired],
      },
    },
    skip: skip || !legalEntitiesWithAspectsAndPermissions.length,
  })

  return React.useMemo(() => {
    const counts = result?.data?.listConnectionBadgeCounts
    return { counts, loading: result.loading }
  }, [result])
}
