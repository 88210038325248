import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import {
  type DigitsHistory,
  HistoryAction,
  HistoryType,
} from "@digits-shared/components/Router/History"
import autoBind from "auto-bind"
import {
  type Action,
  type Href,
  type Location,
  type LocationDescriptorObject,
  type LocationListener,
  type Path,
  type TransitionPromptHook,
  type UnregisterCallback,
} from "history"

/**
 * SwitchableHistory needs to behave differently about notifying listeners when used by the
 * content view router vs. the ContextualViewRouter. This delegate interface allows those
 * subclasses to customize this logic.
 */
export interface SwitchableHistoryDelegate {
  shouldNotifyMemoryListener: (
    activeHistory: () => DigitsHistory,
    memoryHistory: DigitsHistory,
    browserHistory: DigitsHistory,
    location: DigitsLocation
  ) => boolean

  shouldNotifyBrowserListener: (
    activeHistory: () => DigitsHistory,
    memoryHistory: DigitsHistory,
    browserHistory: DigitsHistory,
    location: DigitsLocation
  ) => boolean
}

/**
 * A proxy object which presents itself as a DigitsHistory, while wrapping both a memory history
 * and a browser history. It then offers the ability to control which of those two history objects
 * is connected to the methods this object exposes.
 *
 * This class is needed because a router can't have its history object changed after it is
 * constructed. Thus, the best we can do is create a history object that is internally switchable.
 */
export class SwitchableHistory implements DigitsHistory {
  private activeHistory: DigitsHistory
  private readonly memoryHistory: DigitsHistory
  private readonly browserHistory: DigitsHistory
  private readonly delegate: SwitchableHistoryDelegate

  constructor(
    memoryHistory: DigitsHistory,
    browserHistory: DigitsHistory,
    delegate: SwitchableHistoryDelegate,
    initialHistory = HistoryType.Browser
  ) {
    this.delegate = delegate
    this.memoryHistory = memoryHistory
    this.browserHistory = browserHistory
    this.activeHistory =
      initialHistory === HistoryType.Memory ? this.memoryHistory : this.browserHistory

    // Need to bind all methods at construction because this history's methods will be pulled off
    // and called in other contexts at time. In order for us to properly switch between memory
    // and browser, we must be in the context of this instance, so binding must happen up front.
    // We cannot do fat arrow binding (`=>`) because we want this class to implement `DigitsHistory`.
    // Using fat arrow for the method definitions would not satisfy the interface correct.
    autoBind(this)
  }

  switchTo(whichHistory: HistoryType, action: HistoryAction = HistoryAction.Push) {
    const newActiveHistory =
      whichHistory === HistoryType.Memory ? this.memoryHistory : this.browserHistory

    // Don't do any more work if we are already using the correct history type
    if (newActiveHistory === this.activeHistory) return

    // Update to the newly specified active history
    this.activeHistory = newActiveHistory

    if (action === HistoryAction.None) return

    // if the route is marked "protected", don't add an entry
    // to the history as we don't want to navigate back to it
    if (
      this.activeHistory === this.memoryHistory &&
      this.browserHistory.previousLocation?.protected
    ) {
      return
    }

    const source = this.memoryHistory.previousLocation?.state?.source
      ? { source: "back" }
      : undefined

    // If we are returning to a content view with the browserHistory and
    // the previous location is marked as protected, use replace even if
    // it wasn't requested so that we can overwrite the protected record
    if (
      this.activeHistory === this.browserHistory &&
      this.activeHistory.previousLocation?.protected
    ) {
      return this.activeHistory.replace(this.nextPath, source)
    }

    switch (action) {
      case HistoryAction.Push:
        this.activeHistory.push(this.nextPath, source)
        break
      case HistoryAction.Replace:
        this.activeHistory.replace(this.nextPath, source)
    }
  }

  listen(listener: LocationListener<Record<string, string>>): UnregisterCallback {
    const memoryCallback = (location: Location<Record<string, string>>, action: Action) => {
      const shouldNotify = this.delegate.shouldNotifyMemoryListener(
        () => this.activeHistory,
        this.memoryHistory,
        this.browserHistory,
        location as DigitsLocation
      )

      if (shouldNotify) {
        listener(location, action)
      }
    }
    const browserCallback = (location: Location<Record<string, string>>, action: Action) => {
      const shouldNotify = this.delegate.shouldNotifyBrowserListener(
        () => this.activeHistory,
        this.memoryHistory,
        this.browserHistory,
        location as DigitsLocation
      )

      if (shouldNotify) {
        listener(location, action)
      }
    }
    const memoryUnregister = this.memoryHistory.listen(memoryCallback)
    const browserUnregister = this.browserHistory.listen(browserCallback)

    return () => {
      memoryUnregister()
      browserUnregister()
    }
  }

  get length() {
    return this.activeHistory.length
  }

  get action() {
    return this.activeHistory.action
  }

  get location() {
    return this.activeHistory.location
  }

  set location(location: DigitsLocation) {
    this.activeHistory.location = location
  }

  get previousLocation() {
    return this.activeHistory.previousLocation
  }

  set previousLocation(location: DigitsLocation | undefined) {
    this.activeHistory.previousLocation = location
  }

  push(
    pathOrLocation: Path | LocationDescriptorObject<Record<string, string>>,
    state?: Record<string, string>
  ) {
    this.activeHistory.push(pathOrLocation, state)
  }

  replace(
    pathOrLocation: Path | LocationDescriptorObject<Record<string, string>>,
    state?: Record<string, string>
  ) {
    this.activeHistory.replace(pathOrLocation, state)
  }

  go(n: number) {
    this.activeHistory.go(n)
  }

  goBack() {
    this.activeHistory.goBack()
  }

  goForward() {
    this.activeHistory.goForward()
  }

  block(
    prompt?: boolean | string | TransitionPromptHook<Record<string, string>>
  ): UnregisterCallback {
    return this.activeHistory.block(prompt)
  }

  createHref(location: LocationDescriptorObject<Record<string, string>>): Href {
    return this.activeHistory.createHref(location)
  }

  /** The next path after switching routers, redirecting to root if a protected location is detected */
  get nextPath() {
    if (this.activeHistory === this.memoryHistory) {
      return this.browserHistory.previousLocation?.protected
        ? "/"
        : this.browserHistory.previousLocation?.fullPathname || "/"
    }

    return this.memoryHistory.location.protected ? "/" : this.memoryHistory.location.fullPathname
  }
}
