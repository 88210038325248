import * as React from "react"
import { Tooltip, TooltipLabel } from "@digits-shared/components/UI/Elements/Tooltip"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import colors from "@digits-shared/themes/colors"
import { animated, type PickAnimated, type SpringValues, useSprings } from "@react-spring/web"
import { localPoint } from "@visx/event"
import { ParentSize } from "@visx/responsive"
import { scaleBand, scaleLinear } from "@visx/scale"
import { Bar } from "@visx/shape"
import { useTooltip } from "@visx/tooltip"
import { bisect, max, min, range } from "d3-array"
import { type ScaleBand, type ScaleLinear } from "d3-scale"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"
import {
  BAR_BORDER_ID,
  BAR_FILL_ID,
  BarSummaryChartGradients,
  EMPTY_BAR_FILL_ID,
  HIGHLIGHT_BAR_FILL_ID,
  SELECTED_BAR_FILL_ID,
} from "src/frontend/components/OS/Shared/Charts/BarSummaryChartGradients"
import {
  type SparkChartPoint,
  type SparkChartProps,
} from "src/frontend/components/OS/Shared/Charts/index"
import {
  useSpringControls,
  useSpringControlsKey,
} from "src/shared/components/Contexts/SpringControlsContext"
import { useRefEvent } from "src/shared/hooks/useRefEvent"

const ANIMATION_INITIAL_DELAY = 60
const ANIMATION_PER_BAR_DELAY = 25

const MIN_VALUE_BAR_HEIGHT = 3

const ZERO_VALUE_NUDGE = 1.5
// Because we nudge the zero value to straddle zero on the Y axis
// We need to ensure there's a little extra padding on the top and
// bottom in case the zero line is right at the bottom (or top)
const ZERO_VALUE_NUDGE_PADDING = 2

const BAR_DEEMPHASIZED_CLASS_NAME = "bar-deemphasized"

const SPARK_BAR_CHART_SPRING_CONFIG = "SparkBarChart"

/*
 STYLES
*/

// <ElementType> is a workaround for TS compiler error triggered by react-springs v9 & styled-components
// https://github.com/pmndrs/react-spring/issues/1515
// By setting these generic react element type we lose the Bar's property inference.
// Once issue is fixed or there is a better work around we should remove the generic type.
const AnimatedBar = animated<React.ElementType>(Bar)

const SparkBarChartContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .${BAR_DEEMPHASIZED_CLASS_NAME} {
    opacity: 0.3;
  }
`

const AmountTooltip = styled(Tooltip)`
  text-align: center;
  display: block;
  pointer-events: none;
`

const TooltipAmount = styled(TooltipLabel)``

const TooltipDate = styled(TooltipLabel)`
  font-size: 9px;
  text-transform: none;
  color: ${colors.altoGray};
`

/*
 INTERFACES
*/

interface CommonBarProps {
  chartId: string
  xScale: ScaleBand<number>
  yScale: ScaleLinear<number, number>
  hoveredBarIndex?: number
}

type SparkBarsProps = CommonBarProps & {
  series: SparkChartPoint[]
  width: number
  height: number
  animateBarsInitialDelay?: number
  animateBars?: boolean
  highlightedIndices?: Set<number>
}

type SparkBarProps = CommonBarProps & {
  point: SparkChartPoint
  spring: SpringValues<PickAnimated<{ scale: number }>>
  selected: boolean
  highlighted: boolean
  index: number
}

interface ChartTooltipProps {
  containerRef: React.RefObject<HTMLDivElement | null>
  graphWidth: number
  series: SparkChartPoint[]
  xScale: ScaleBand<number>
  onBarHovered: (index?: number) => void
}

interface ToolTipData {
  data: SparkChartPoint
  index: number
}

/*
 COMPONENTS
*/

const SparkBarChart: React.FC<SparkChartProps> = ({
  series,
  className,
  animateChartInitialDelay,
  animateChart,
  highlightedIndices,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [hoveredBarIndex, setHoveredBarIndex] = React.useState<number | undefined>()
  const key = useSpringControlsKey(SPARK_BAR_CHART_SPRING_CONFIG)
  const highlightedIndicesSet = React.useMemo(
    () => highlightedIndices && new Set(highlightedIndices),
    [highlightedIndices]
  )
  const chartId = useConstant<string>(generateUUID)

  return (
    <ParentSize className={className}>
      {({ width, height }) => {
        const xScale = scaleBand({
          range: [0, width],
          round: true,
          domain: series.map(xValue),
        })

        const minYValue = Math.min(min(series, yValue) || 0, 0)
        const maxYValue = Math.max(max(series, yValue) || 0, 0)

        const yScale: ScaleLinear<number, number> = scaleLinear({
          range: [height - ZERO_VALUE_NUDGE_PADDING, ZERO_VALUE_NUDGE_PADDING],
          round: true,
          domain: [minYValue, maxYValue],
          nice: true,
        })

        return (
          <SparkBarChartContainer className={className} ref={containerRef}>
            <SparkBars
              key={key}
              chartId={chartId}
              series={series}
              width={width}
              height={height}
              xScale={xScale}
              yScale={yScale}
              hoveredBarIndex={hoveredBarIndex}
              animateBarsInitialDelay={animateChartInitialDelay}
              animateBars={animateChart}
              highlightedIndices={highlightedIndicesSet}
            />

            <ChartTooltip
              containerRef={containerRef}
              graphWidth={width}
              series={series}
              xScale={xScale}
              onBarHovered={setHoveredBarIndex}
            />
          </SparkBarChartContainer>
        )
      }}
    </ParentSize>
  )
}

export default SparkBarChart

const SparkBars: React.FC<SparkBarsProps> = ({
  chartId,
  series,
  width,
  height,
  xScale,
  yScale,
  hoveredBarIndex,
  animateBarsInitialDelay,
  animateBars = true,
  highlightedIndices,
}) => {
  const controls = useSpringControls(SPARK_BAR_CHART_SPRING_CONFIG, {
    friction: 15,
  })
  const [springs] = useSprings(series.length, (index) => {
    const delay = animateBars
      ? (animateBarsInitialDelay ?? ANIMATION_INITIAL_DELAY) + index * ANIMATION_PER_BAR_DELAY
      : 0

    return {
      ...controls,
      from: { scale: 0 },
      to: { scale: 1 },
      delay,
      immediate: !animateBars,
    }
  })

  return (
    <svg width={width} height={height}>
      <BarSummaryChartGradients id={chartId} />
      {series.map((point, index) => {
        const selected = hoveredBarIndex === index
        const highlighted =
          !selected && !!highlightedIndices?.has(index) && hoveredBarIndex === undefined

        return (
          <SparkBar
            key={index}
            chartId={chartId}
            point={point}
            index={index}
            xScale={xScale}
            yScale={yScale}
            selected={selected}
            highlighted={highlighted}
            spring={springs[index] as SparkBarProps["spring"]}
            hoveredBarIndex={hoveredBarIndex}
          />
        )
      })}
    </svg>
  )
}

function barFillIdName(p: { yVal: number; highlighted: boolean; selected: boolean }) {
  const amountZero = p.yVal === 0
  const isPositiveValue = p.yVal > 0

  if (amountZero) return EMPTY_BAR_FILL_ID

  if (p.selected) {
    return isPositiveValue ? SELECTED_BAR_FILL_ID : `${SELECTED_BAR_FILL_ID}-inverted`
  }

  if (p.highlighted) {
    return isPositiveValue ? HIGHLIGHT_BAR_FILL_ID : `${HIGHLIGHT_BAR_FILL_ID}-inverted`
  }

  return isPositiveValue ? BAR_FILL_ID : `${BAR_FILL_ID}-inverted`
}

const SparkBar: React.FC<SparkBarProps> = ({
  chartId,
  xScale,
  yScale,
  selected,
  highlighted,
  spring,
  point,
  index,
}) => {
  const yVal = yValue(point)
  const xVal = xValue(point)
  const zeroY = yScale(0) ?? 0
  const negative = yVal < 0
  const positive = yVal > 0
  const fillId = `${chartId}-${barFillIdName({ yVal, highlighted, selected })}`

  // This logic is tricky because SVGs have their 0,0 origin in the top left corner.
  // We draw positive bars from the tops of their bars towards the zero line,
  // and draw negative bars from the zero line towards the bottom of the chart
  //
  // Zero values are given a constant non-zero size so that they are visible, and
  // are shifted one pixel up so that they straddle the zero line.
  const rawBarHeight = zeroY - (yScale(Math.abs(yVal)) ?? 0)
  const barHeight = Math.max(rawBarHeight, MIN_VALUE_BAR_HEIGHT)

  const x = xScale(xVal)
  const y = negative
    ? zeroY // negative value
    : positive
      ? spring.scale.to((s: number) => zeroY - s * barHeight) // positive value
      : zeroY - ZERO_VALUE_NUDGE // zero value

  const classNames = [...(point.deemphasized ? [BAR_DEEMPHASIZED_CLASS_NAME] : [])].join(" ")

  const barWidth = xScale.bandwidth() / 2.5
  const cornerRadius = Math.min(1, barWidth / 2)

  return (
    <AnimatedBar
      key={index}
      className={classNames}
      width={barWidth}
      height={spring.scale.to((s: number) => s * barHeight)}
      x={x}
      y={y}
      rx={cornerRadius}
      ry={cornerRadius}
      fill={`url(#${fillId})`}
      stroke={`url(#${chartId}-${BAR_BORDER_ID})`}
      strokeWidth={0.5}
    />
  )
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  containerRef,
  graphWidth,
  series,
  xScale,
  onBarHovered,
}) => {
  const { showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop } =
    useTooltip<ToolTipData>()

  const onHover = React.useCallback(
    (event: MouseEvent) => {
      const point = localPoint(event)
      const r = xScale.range()
      const rangePoints = range(r[0], r[1], xScale.step())
      const index = bisect(rangePoints, point?.x ?? 0) - 1

      const data = series[index]
      if (!data) {
        onBarHovered(undefined)
        return
      }

      onBarHovered(index)
      showTooltip({
        tooltipData: { data, index },
        tooltipLeft: (point?.x || 0) + 10,
        tooltipTop: (point?.y || 0) - 20,
      })
    },
    [series, showTooltip, onBarHovered, xScale]
  )

  const onUnHover = React.useCallback(() => {
    onBarHovered(undefined)
    hideTooltip()
  }, [onBarHovered, hideTooltip])

  useRefEvent(containerRef, "mouseenter", onHover)
  useRefEvent(containerRef, "mousemove", onHover)
  useRefEvent(containerRef, "mouseleave", onUnHover)

  const tooltipRight =
    tooltipData && tooltipData.index >= series.length - 2
      ? graphWidth - (tooltipLeft ?? 0) + 20
      : undefined

  return (
    <>
      {tooltipData && (
        <div>
          <AmountTooltip
            top={tooltipTop}
            left={tooltipRight ? undefined : tooltipLeft}
            right={tooltipRight}
          >
            <TooltipAmount>{displayAmount(tooltipData.data)}</TooltipAmount>
            <TooltipDate>{displayDate(tooltipData.data)}</TooltipDate>
          </AmountTooltip>
        </div>
      )}
    </>
  )
}

const xValue = (d: SparkChartPoint) => d.x.startedAt

const yValue = ({
  y: {
    value: { amount, currencyMultiplier },
    isNormal,
  },
}: SparkChartPoint) => (Math.abs(amount) * (isNormal ? 1 : -1)) / currencyMultiplier

const displayDate = (p: SparkChartPoint) =>
  dateTimeHelper.displayNameFromPeriod(p.x, DateFormat.Medium)

const displayAmount = (p: SparkChartPoint) =>
  moneyFlowHelper.currency(p.y, {
    style: CurrencyStyle.Aggregation,
  })
