import * as React from "react"
import { Link } from "react-router-dom"
import {
  type IntervalOrigin,
  type Party,
  type PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingCircle } from "@digits-shared/components/Loaders"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { type VibrantBackgroundValues } from "src/shared/components/Elements/VibrantBackground"
import { PartyAdditionalDetails } from "src/shared/components/PartyHover/AdditionalDetails"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import customKeyframes from "src/shared/config/customKeyframes"
import transitions from "src/shared/config/transitions"

/*
  STYLES
*/

const PartyFlex = styled.div`
  animation: ${customKeyframes.fadeIn} ${transitions.defaultFadeIn.duration};
  width: 100%;
`

const PartyHeader = styled.div<PartyHeaderProps>`
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px;
  gap: 20px;
  overflow: hidden;
  border-top-left-radius: ${borders.theme.dark.radius.modal}px;
  border-top-right-radius: ${borders.theme.dark.radius.modal}px;

  ${(props) => css`
    background-color: ${props.backgroundColor};
    background-image: ${props.backgroundImage};
    color: ${props.textColor};
  `}

  ${(props) =>
    props.hasBottomBorderRadius &&
    css`
      border-radius: ${borders.theme.dark.radius.modal}px;
    `}
`

/*
  INTERFACES
*/

interface PartyDetailsProps {
  isLoading: boolean
  intervalOrigin?: IntervalOrigin
  isPreview?: boolean
  party?: Party & { iconBlob?: string }
  partyRole?: PartyRole
  partyImageRef?: React.RefObject<HTMLImageElement>
  invertValues: boolean
  textColor: string
  backgroundColor: string
  backgroundImage?: string
  partyDetailsPath?: string
  categoryDetailsRoute?: DigitsRoute
}

interface PartyHeaderProps {
  backgroundColor?: string
  backgroundImage?: string
  textColor?: string
  hasBottomBorderRadius?: boolean
}

/*
  COMPONENT
*/

export default class PartyDetails extends React.PureComponent<PartyDetailsProps> {
  render() {
    const { party, isLoading } = this.props
    if (!party || isLoading) return this.renderLoadingParty()

    const { backgroundColor, textColor, isPreview, partyDetailsPath } = this.props

    const background: VibrantBackgroundValues = {
      backgroundColor,
      textColor,
      isLoading,
    }

    return (
      <PartyFlex>
        <PartyHeader backgroundColor={backgroundColor} textColor={textColor} hasBottomBorderRadius>
          {this.renderPartyIcon()}
          <PartyAdditionalDetails
            party={party}
            isPreview={isPreview}
            partyDetailsPath={partyDetailsPath}
            vibrantBackgroundValues={background}
          />
        </PartyHeader>
      </PartyFlex>
    )
  }

  renderPartyIcon = () => {
    const {
      party,
      isLoading,
      backgroundColor,
      partyImageRef,
      backgroundImage,
      textColor,
      partyDetailsPath,
    } = this.props

    const background: VibrantBackgroundValues = {
      backgroundColor,
      backgroundImage,
      textColor,
      isLoading,
    }

    const icon = (
      <PartyIcon
        size={IconSize.Large}
        partyImageRef={partyImageRef}
        party={party}
        background={background}
        disableHoverBorder
      />
    )

    return partyDetailsPath ? <Link to={partyDetailsPath}>{icon}</Link> : icon
  }

  renderLoadingParty = () => {
    const { backgroundColor, backgroundImage, textColor } = this.props

    return (
      <PartyFlex>
        <PartyHeader
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          textColor={textColor}
          hasBottomBorderRadius
        >
          <LoadingCircle width="80px" height="80px" backgroundColor={colors.translucentWhite20} />
        </PartyHeader>
      </PartyFlex>
    )
  }
}
