import React from "react"
import { Route, Switch } from "react-router-dom"
import {
  ConnectionStatus,
  ConnectionSyncStatus,
  useListConnectionBadgeCountsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { AspectCode } from "@digits-shared/session/AspectCode"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { ManagedSnackbar } from "src/frontend/components/Shared/ManagedModals/ManagedSnackbar"
import { ManagedSnackbarName } from "src/frontend/components/Shared/ManagedModals/state/types"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export const ConnectAlerts: React.FC = () => {
  const { value: dismissed, setTrue: dismiss } = useStateBoolean()
  const session = useSession<FrontendSession>()
  const hasDashboard = React.useMemo(
    () => session.currentLegalEntity?.hasDashboardAccess(),
    [session.currentLegalEntity]
  )
  return (
    !dismissed &&
    hasDashboard && (
      <Switch>
        <Route
          path={routes.legalEntityHome.parameterizedPath}
          render={() => <ConnectAlert dismiss={dismiss} />}
        />
      </Switch>
    )
  )
}

const ConnectAlert: React.FC<{ dismiss: () => void }> = ({ dismiss }) => {
  const { currentLegalEntityId, hasAccessToAspect } = useSession<FrontendSession>()
  const hasAIB = hasAccessToAspect(AspectCode.AIBookkeeper)
  const hasConnectionStatusesPermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.ConnectionAccounts,
    flag: JWTPermissionFlag.Update,
  })
  const hasSyncStatusesPermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Connections,
    flag: JWTPermissionFlag.Update,
  })
  const hasPermission = hasAIB && (hasSyncStatusesPermission || hasConnectionStatusesPermission)

  const result = useListConnectionBadgeCountsQuery({
    variables: {
      filter: {
        legalEntityIds: [currentLegalEntityId],
        connectionStatuses: hasConnectionStatusesPermission
          ? [ConnectionStatus.ActivationPending, ConnectionStatus.ConfigurationPending]
          : undefined,
        syncStatuses: hasSyncStatusesPermission ? [ConnectionSyncStatus.LoginRequired] : undefined,
      },
    },
    skip: !hasPermission || !currentLegalEntityId,
    // we only want to show this on login, so rely on cache when available
    fetchPolicy: "cache-first",
  })

  const hasErrors = React.useMemo(
    () => Boolean(result.data?.listConnectionBadgeCounts?.[0]?.count),
    [result.data]
  )
  return hasErrors && <ConnectErrorAlert dismiss={dismiss} />
}

const ConnectErrorAlert: React.FC<{ dismiss: () => void }> = ({ dismiss }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()

  return (
    <ManagedSnackbar
      name={ManagedSnackbarName.ConnectAlert}
      hideOnActiveDetailsView
      dismissal="Session"
      type="glow"
      message="Your connections need attention"
      description="Manage them to properly sync data to Digits"
      showIcon={false}
      dismissibleOnHover
      onDismiss={dismiss}
      // add a slight delay so the alert doesn't open before the welcome modal
      delayMs={2_000}
      buttonOverride={
        <DigitsLinkButton
          onClick={dismiss}
          $variant="secondary-dark"
          to={generatePath(routes.connections, {
            leSlug: currentLegalEntity?.slug,
          })}
        >
          Manage
        </DigitsLinkButton>
      }
    />
  )
}
