import * as React from "react"
import {
  type EntityCategory,
  type Insight,
  type ObjectEntities,
} from "@digits-graphql/frontend/graphql-bearer"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import borders from "@digits-shared/themes/borders"
import styled from "styled-components"
import { useComponentIntervalOrigin } from "src/frontend/components/Shared/Layout/hooks/useComponentIntervalOrigin"
import { AddInsightToReport } from "src/frontend/components/Shared/Layout/PopOver/DetailsPopOver/Reports/AddInsightToReport"
import { useIsInsideReport } from "src/frontend/components/Shared/Layout/PopOver/DetailsPopOver/Reports/useIsInsideReport"
import { InsightItem } from "src/shared/components/Insights/Item"

const assistant = require("static/images/icons/stars.gif")

/*
  STYLES
*/

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 20px 20px;
  padding-top: 15px;
  ${borders.theme.dark.divider.top};
`

const InsightContainer = styled.div`
  flex: 1;
`

const StyledInsightItem = styled(InsightItem)`
  padding: 0;
  align-items: flex-start;
  border: none;

  ${RowContentDescription} {
    font-size: 12px;
    line-height: 17px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: -10px;
`

const Icon = styled.img`
  padding: 6px;
  width: 32px;
  height: 32px;
`

/*
  INTERFACES
*/

interface CategoryInsightProps {
  category: EntityCategory
  insight: Insight | undefined | null
  isYTDInsight?: boolean // disable "Add to summary" for YTD (unsupported atm)
}

/*
  COMPONENTS
*/

export const CategoryInsight: React.FC<CategoryInsightProps> = ({
  category,
  insight,
  isYTDInsight,
}) => {
  const intervalOrigin = useComponentIntervalOrigin(1)
  const entities: ObjectEntities = { categories: [category] }
  const insideReport = useIsInsideReport()

  if (!insight) return null

  return (
    <>
      <Container>
        <IconContainer>
          <Icon src={`${assistant}?.rand=${category.id}`} />
        </IconContainer>
        <InsightContainer>
          <StyledInsightItem
            alwaysShowDate
            insight={insight}
            entities={entities}
            intervalOrigin={intervalOrigin}
          />
        </InsightContainer>
      </Container>
      {insideReport && !isYTDInsight && <AddInsightToReport insight={insight} />}
    </>
  )
}
