import * as React from "react"
import { type Period } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import { useIsPrintTheme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { useThemedConstant } from "@digits-shared/themes/themedFunctions"
import { animated, useSpring } from "@react-spring/web"
import { localPoint } from "@visx/event"
import { Group } from "@visx/group"
import { PatternLines } from "@visx/pattern"
import { ParentSize } from "@visx/responsive"
import { scaleLinear, scaleTime } from "@visx/scale"
import { AreaClosed, Circle, Line, LinePath } from "@visx/shape"
import { bisector, extent } from "d3-array"
import { type ScaleLinear, type ScaleTime } from "d3-scale"
import { type CurveFactory } from "d3-shape"
import { css } from "styled-components"
import { v4 as generateUUID } from "uuid"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import {
  SharedLineChartStyles,
  type TimeseriesLineChartStyle,
} from "src/frontend/components/OS/Shared/Charts/styles"
import {
  ChartContainer,
  SVGContainer,
} from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import {
  ChartGrid,
  ChartXAxis,
  ChartYAxis,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesChartAxis"
import {
  TimeseriesGradient,
  useGradients,
} from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesGradients"
import {
  type TimeseriesValue,
  type TimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentZeroState } from "src/frontend/components/Shared/Layout/Shared"
import customKeyframes from "src/shared/config/customKeyframes"

const ANIMATION_INITIAL_DELAY = 50
const ANIMATION_INITIAL_LINES_DELAY = ANIMATION_INITIAL_DELAY + 100
const ANIMATION_DOT_DELAY = ANIMATION_INITIAL_DELAY + 500

/*
  STYLES
*/

// <ElementType> is a workaround for TS compiler error triggered by react-springs v9 & styled-components
// https://github.com/pmndrs/react-spring/issues/1515
// By setting these generic react element type we lose the real component's property inference.
// Once issue is fixed or there is a better work around we should remove the generic type.
const AnimatedRect = animated<React.ElementType>("rect")

/*
  INTERFACES
*/

interface ChartProps {
  timeseries: TimeseriesValues
  preselectedValue?: TimeseriesValue
  projectionAfter?: Period
  onClick?: (value: TimeseriesValue, index: number) => void
  onMouseOver?: (value: TimeseriesValue, index: number) => void
  onMouseOut?: (value?: TimeseriesValue) => void
  className?: string
  hideGrid?: boolean
  hideAxis?: boolean
  noTooltip?: boolean
  skipAnimations?: boolean
  width: number
  height: number
  strokeWidth?: number
  chartStyle?: TimeseriesLineChartStyle
}

interface LinesProps {
  uuid: string
  timeseries: TimeseriesValues
  skipAnimations?: boolean
  xScale: ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
  strokeWidth: number
  strokeDashLength?: number
  curve?: CurveFactory
  stripedFill?: boolean
}

interface TooltipProps {
  xScale: ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
  timeseries: TimeseriesValues
  width: number
  height: number
  chartStyle: TimeseriesLineChartStyle
  hoveredValue: TimeseriesValue | undefined
}

interface DotProps {
  xScale: ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
  timeseries: TimeseriesValues
  hoveredValue: TimeseriesValue | undefined
  chartStyle: TimeseriesLineChartStyle
  projectionAfter?: Period
  skipAnimations?: boolean
}

/*
  COMPONENTS
*/

const xValue = ({ period: { startedAt } }: TimeseriesValue) =>
  dateTimeHelper.dayjsFromTimestamp(startedAt).toDate()

const yValue = ({
  moneyFlow: {
    value: { amount, currencyMultiplier },
    isNormal,
  },
}: TimeseriesValue) => (Math.abs(amount) * (isNormal ? 1 : -1)) / currencyMultiplier

export const ParentSizedTimeseriesLineChart: React.FC<Omit<ChartProps, "width" | "height">> = ({
  timeseries,
  preselectedValue,
  className,
  onClick,
  onMouseOver,
  onMouseOut,
  hideGrid,
  hideAxis,
  skipAnimations,
  strokeWidth,
  projectionAfter,
  chartStyle,
}) => (
  <ParentSize>
    {(parent) => {
      const { width, height } = parent

      return (
        <TimeseriesLineChart
          timeseries={timeseries}
          preselectedValue={preselectedValue}
          className={className}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          hideGrid={hideGrid}
          hideAxis={hideAxis}
          skipAnimations={skipAnimations}
          width={width}
          height={height}
          strokeWidth={strokeWidth}
          projectionAfter={projectionAfter}
          chartStyle={chartStyle}
        />
      )
    }}
  </ParentSize>
)

export const TimeseriesLineChart: React.FC<ChartProps> = ({
  timeseries,
  preselectedValue,
  projectionAfter,
  className,
  onClick,
  onMouseOver,
  onMouseOut,
  hideGrid,
  hideAxis,
  skipAnimations,
  width,
  height,
  strokeWidth = 3,
  chartStyle = SharedLineChartStyles,
}) => {
  const [hoveredValue, setHoveredValue] = React.useState<TimeseriesValue | undefined>(
    preselectedValue
  )
  const uuid = useConstant<string>(generateUUID)

  React.useEffect(() => {
    setHoveredValue(preselectedValue)

    const indexOf = preselectedValue ? timeseries.indexOf(preselectedValue) : -1
    if (preselectedValue && indexOf !== -1) {
      onMouseOver?.(preselectedValue, indexOf)
    }
  }, [onMouseOver, preselectedValue, timeseries])

  const [minXValue, maxXValue] = React.useMemo(() => {
    const [minVal, maxVal] = extent(timeseries, xValue)
    return [minVal ?? dayjs.utc(0).toDate(), maxVal ?? dayjs.utc().toDate()]
  }, [timeseries])

  const [minYValue, maxYValue] = React.useMemo(() => {
    const [minVal, maxVal] = extent(timeseries, yValue)
    return [minVal ?? 0, maxVal ?? 0]
  }, [timeseries])

  const yMax = Math.ceil(maxYValue || 10)
  const yMaxDomain = yMax < 0 ? 0 : yMax

  const yMin = Math.ceil(minYValue || 0)
  // if the minimum amount in the series is a negative number use it,
  // otherwise default to 0
  // BEFORE this change:
  //    const yMinDomain = yMaxDomain === yMin ? 0 : yMin
  const yMinDomain = yMin < 0 ? yMin : 0

  const svgHeight = hideAxis ? height : height - X_AXIS_HEIGHT
  const yScale = scaleLinear({
    range: [svgHeight, 0],
    round: true,
    domain: [yMinDomain, yMaxDomain],
  })

  const xMin = hideAxis ? 0 : Y_AXIS_WIDTH / 3
  const xMaxPadding = 20
  const xMax = hideAxis ? width : width - xMaxPadding

  const bisectDate = bisector(xValue).center

  const xScale = scaleTime({
    domain: [minXValue, maxXValue],
    range: [xMin, xMax],
  })

  const onMouseLeave = React.useCallback(() => {
    const defaultValue = preselectedValue ?? undefined
    setHoveredValue(defaultValue)
    onMouseOut?.(defaultValue)
  }, [onMouseOut, preselectedValue])

  const pointToValue = React.useCallback(
    (event: React.MouseEvent) => {
      const point = localPoint(event)
      const x = point ? point.x : 0
      const yAxisOffset = hideAxis ? 0 : Y_AXIS_WIDTH
      const date = xScale.invert(x - yAxisOffset)
      const index = bisectDate(timeseries, date)
      return { index, value: timeseries[index] }
    },
    [bisectDate, hideAxis, timeseries, xScale]
  )

  const onMouseMove = React.useCallback(
    (event: React.MouseEvent) => {
      const { index, value } = pointToValue(event)
      if (index === -1 || !value) return
      setHoveredValue(value)
      onMouseOver?.(value, index)
    },
    [onMouseOver, pointToValue]
  )

  const onMouseClick = React.useCallback(
    (event: React.MouseEvent) => {
      const { index, value } = pointToValue(event)
      if (index === -1 || !value) return
      onClick?.(value, index)
    },
    [onClick, pointToValue]
  )

  const noActivity = React.useMemo(
    () => timeseries?.every((value) => value.moneyFlow.value.amount === 0),
    [timeseries]
  )

  if (width === 0 || height === 0) return null

  if (noActivity) {
    return (
      <ChartContainer className={className} width={width} height={height}>
        <ComponentZeroState />
      </ChartContainer>
    )
  }

  if (minYValue === maxYValue && minYValue === 0) {
    return null
  }

  // moves the area and line next to the YAxis
  const offsetXAxis = 8
  const linesLeft = hideAxis ? 0 : Y_AXIS_WIDTH - offsetXAxis
  return (
    <ChartContainer className={className} onMouseLeave={onMouseLeave} width={width} height={height}>
      <SVGContainer
        width={width}
        height={svgHeight}
        onMouseMove={onMouseMove}
        onClick={onMouseClick}
      >
        <defs>
          <TimeseriesGradient
            uuid={uuid}
            timeseries={timeseries}
            hideAxis={hideAxis}
            chartStyle={chartStyle}
          />
        </defs>
        {!hideGrid && (
          <ChartGrid
            yScale={yScale}
            height={svgHeight}
            width={width}
            axisStyle={chartStyle}
            yAxisWidth={Y_AXIS_WIDTH}
          />
        )}
        {!hideAxis && (
          <ChartYAxis
            yScale={yScale}
            axisStyle={chartStyle}
            yAxisWidth={Y_AXIS_WIDTH}
            width={width}
          />
        )}
        {!hideAxis && (
          <Group top={5} left={-offsetXAxis}>
            <ChartXAxis
              timeseries={timeseries}
              xScale={xScale}
              height={svgHeight}
              width={width}
              axisStyle={chartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
            />
            <DashedLine
              timeseries={timeseries}
              hoveredValue={hoveredValue}
              yScale={yScale}
              xScale={xScale}
              height={svgHeight}
              width={width}
              chartStyle={chartStyle}
            />
          </Group>
        )}
        <Group top={0} left={linesLeft}>
          <SummaryLines
            uuid={uuid}
            xScale={xScale}
            yScale={yScale}
            timeseries={timeseries}
            skipAnimations={skipAnimations}
            strokeWidth={strokeWidth}
            curve={chartStyle?.curve}
            projectionAfter={projectionAfter}
          />
          <Dot
            timeseries={timeseries}
            hoveredValue={hoveredValue}
            yScale={yScale}
            xScale={xScale}
            chartStyle={chartStyle}
            projectionAfter={projectionAfter}
            skipAnimations={skipAnimations}
          />
        </Group>
      </SVGContainer>
    </ChartContainer>
  )
}

const SummaryLines: React.FC<LinesProps & Pick<ChartProps, "projectionAfter">> = ({
  uuid,
  timeseries,
  xScale,
  yScale,
  skipAnimations,
  strokeWidth,
  curve,
  projectionAfter,
}) => {
  const pastSeries = React.useMemo(
    () =>
      projectionAfter
        ? timeseries.filter((v) => v.period.startedAt <= projectionAfter.startedAt)
        : timeseries,
    [projectionAfter, timeseries]
  )

  const projectedSeries = React.useMemo(
    () =>
      projectionAfter
        ? timeseries.filter((v) => v.period.startedAt >= projectionAfter.startedAt)
        : undefined,
    [projectionAfter, timeseries]
  )

  const lines = [{ series: pastSeries }, { series: projectedSeries, dash: 4, striped: true }]

  return (
    <>
      {lines.map(
        ({ series, dash, striped }, index) =>
          series && (
            <SummaryLine
              key={index}
              uuid={uuid}
              xScale={xScale}
              yScale={yScale}
              timeseries={series}
              skipAnimations={skipAnimations}
              strokeDashLength={dash}
              strokeWidth={strokeWidth}
              curve={curve}
              stripedFill={striped}
            />
          )
      )}
    </>
  )
}

const SummaryLine: React.FC<LinesProps> = ({
  uuid,
  timeseries,
  xScale,
  yScale,
  skipAnimations,
  strokeWidth,
  strokeDashLength,
  curve,
  stripedFill,
}) => {
  const areaUUID = useConstant(() => generateUUID())

  const isPrintMode = useIsPrintTheme()
  const immediate = skipAnimations || isPrintMode
  const delay = immediate ? 0 : ANIMATION_INITIAL_LINES_DELAY

  const areaSpring = useSpring({
    config: {
      friction: 30,
      tension: 180,
    },
    from: { y: yScale(0) },
    to: { y: 0 },
    delay,
    immediate,
  })

  const transition =
    !immediate &&
    css`
      opacity: 0;
      animation: ${customKeyframes.fadeIn} 400ms ${ANIMATION_DOT_DELAY}ms forwards;
    `

  const stroke = useThemedConstant<string>({
    print: colors.translucentBlack50,
    light: `url(#line-stroke-gradient-${uuid})`,
    dark: `url(#line-stroke-gradient-${uuid})`,
  })
  const x = React.useCallback((point: TimeseriesValue) => xScale(xValue(point)) ?? 0, [xScale])

  const y = React.useCallback(
    (point: TimeseriesValue, index: number) => {
      const scaledPoint = yScale(yValue(point)) ?? 0
      return index ? scaledPoint : scaledPoint - 0.001
    },
    [yScale]
  )

  const maskId = `under-line-area-mask-${areaUUID}`
  return (
    <g>
      <mask id={maskId}>
        {/* This has to be inside the mask for Safari. */}
        {stripedFill && (
          <PatternLines
            id={`lines-${areaUUID}`}
            height={8}
            width={8}
            stroke="white"
            strokeWidth={3}
            orientation={["diagonal"]}
          />
        )}
        <AreaClosed
          data={timeseries}
          yScale={yScale}
          x={x}
          y={(t, i) => (yValue(t) >= 0 ? y(t, i) : yScale(0))}
          y0={(t, i) => (yValue(t) >= 0 ? yScale(0) : y(t, i))}
          strokeWidth={3}
          stroke="transparent"
          strokeLinecap="round"
          fill={stripedFill ? `url(#lines-${areaUUID})` : "white"}
          curve={curve}
        />
      </mask>
      <AnimatedRect
        x={0}
        y={areaSpring.y.to((s: number) => s)}
        width="100%"
        height="100%"
        // Use both mask and fill so we can apply
        // both a gradient and a pattern. Patterns
        // can't have gradient fills.
        fill={`url(#line-cover-gradient-${uuid})`}
        mask={`url(#${maskId})`}
      />
      <LinePath
        css={transition}
        data={timeseries}
        x={x}
        y={y}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDashLength}
        curve={curve}
      />
    </g>
  )
}

const DashedLine: React.FC<TooltipProps> = ({ hoveredValue, xScale, height, chartStyle }) => {
  if (!hoveredValue) return null

  const left = Y_AXIS_WIDTH + (xScale(xValue(hoveredValue)) ?? 0)
  return (
    <Line
      from={{ x: left, y: 0 }}
      to={{ x: left, y: height }}
      stroke={chartStyle.lineStroke}
      strokeWidth={1}
      strokeDasharray="2,2"
      style={{ pointerEvents: "none" }}
    />
  )
}

const Dot: React.FC<DotProps> = ({
  timeseries,
  xScale,
  yScale,
  hoveredValue,
  chartStyle,
  projectionAfter,
  skipAnimations,
}) => {
  const { interpolateIndex } = useGradients(timeseries, chartStyle)
  const hoveredIndex = hoveredValue ? timeseries.indexOf(hoveredValue) : undefined
  const projectionStartIndex =
    projectionAfter && timeseries.findIndex((v) => v.period.startedAt >= projectionAfter.startedAt)
  const defaultIndex =
    projectionStartIndex && projectionStartIndex !== -1
      ? projectionStartIndex
      : timeseries.length - 1
  const index = hoveredIndex ?? defaultIndex

  const point = timeseries[index]
  if (!point) return null

  const x = xScale(xValue(point)) ?? 0
  const y = yScale(yValue(point)) ?? 0

  const transition =
    !skipAnimations &&
    css`
      opacity: 0;
      animation: ${customKeyframes.fadeIn} 250ms ${ANIMATION_DOT_DELAY}ms forwards;
    `

  return <Circle css={transition} cx={x} cy={y} fill={interpolateIndex(index)} r={5} />
}
