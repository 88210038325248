import * as React from "react"
import { LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { StatementSize } from "src/frontend/components/Shared/Layout/Components/Statements/shared"
import { Statement } from "src/frontend/components/Shared/Layout/Components/Statements/Statement"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePortalStatementLiveData } from "src/frontend/components/Shared/Portals/hooks/usePortalStatementLiveData"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

/*
  INTERFACES
*/

type Props = SizingProps & {
  component: MatchedComponent<"statement">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const StatementComponent: React.FC<Props> = ({ component, componentSize }) => {
  // Historically, some portal components were archived with intervalCount 12.
  // Statements interpret intervalCount as the number of interval to show in the
  // "primary" column, which is not what we want to show on the main portal when unspecified.
  //  We don't want to override the value provided from the TimeContext either
  //  so that details pages display correctly.
  const origin = useIntervalOrigin()
  const overriddenConfig = useConfigOriginOverride(
    component.config.statement,
    origin.intervalCount ?? 1
  )
  const config = React.useMemo(
    () => ({ ...component.config, statement: overriddenConfig }),
    [component.config, overriddenConfig]
  )
  const statementPath = useComponentDetailsLinkPath(config.statement.kind, origin)

  const { statement, loading } = usePortalStatementLiveData(
    overriddenConfig,
    LayoutComponentType.Statement
  )
  const statementSize = componentSize.isSmall ? StatementSize.Condensed : StatementSize.Full
  const { title, periodName } = useComponentConfigTitle(config)

  const titleLink = React.useMemo(
    () => (
      <ComponentExpandLink to={statementPath} componentSize={componentSize}>
        {title}
      </ComponentExpandLink>
    ),
    [componentSize, statementPath, title]
  )

  return (
    <>
      <ComponentHeaderTitle
        css="margin-bottom: 0"
        title={titleLink}
        componentSize={componentSize}
        periodName={periodName}
      />
      <Statement statement={statement} size={statementSize} loading={loading} />
    </>
  )
}
