import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  type EntityDepartment,
  Interval,
  type IntervalOrigin,
  type SummarizeTransactionsByTimeQueryVariables,
} from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import styled from "styled-components"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import routes from "src/frontend/routes"
import MatchedText from "./MatchedText"
import { ResultRow, ResultTitle, type RowProps } from "./ResultRow"
import SearchResultActivitySummary from "./SearchResultActivitySummary"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
  align-items: center;
`

const DepartmentTitle = styled(ResultTitle)`
  flex: 1;
`

/*
  COMPONENT
*/

const DepartmentResult: React.FC<RowProps<EntityDepartment>> = ({ entity, hit }) => {
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)
  const path = routes.departmentDetails.generateFromCurrentPath({
    departmentId: entity.id,
    ...origin,
  })

  return (
    <ResultRow to={path}>
      <Details>
        <ColorIcon fallbackText={entity.name} css="margin-right: 10px;" />
        <DepartmentTitle>
          <MatchedText fieldName="name" highlights={hit.highlights}>
            {entity.name}
          </MatchedText>
        </DepartmentTitle>
        <DepartmentSummary department={entity} origin={origin} />
      </Details>
    </ResultRow>
  )
}

export default DepartmentResult

interface SummaryProps {
  department: EntityDepartment
  origin: IntervalOrigin
}

const DepartmentSummary: React.FC<SummaryProps> = ({ department, origin }) => {
  const viewKey = useViewVersion()

  const variables: SummarizeTransactionsByTimeQueryVariables = {
    filter: {
      viewKey,
      departmentIds: [department.id],
      categoryTypes: [
        CategoryType.Expenses,
        CategoryType.OtherExpenses,
        CategoryType.CostOfGoodsSold,
      ],
    },
    directBookings: true,
    origin,
    direction: DirectionFromOrigin.Past,
  }

  return <SearchResultActivitySummary variables={variables} />
}
