import * as React from "react"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { H3 } from "@digits-shared/components/UI/Elements/Header"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { DRAWER_CONTENTS_PADDING, DRAWER_WIDTH } from "src/shared/components/Drawer/constants"

/*
 STYLES
*/

const SectionHeader = styled(H3)`
  display: flex;
  align-items: center;
  margin-bottom: ${DRAWER_CONTENTS_PADDING}px;
`

export const LeftDrawerSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${DRAWER_WIDTH}px;
  padding: 0 ${DRAWER_CONTENTS_PADDING}px;
`

const LoadingItem = styled.div`
  padding: 21px 20px 19px;
  border-radius: ${borders.radius.card}px;
  border: solid 1px #607bb5;
  background:
    linear-gradient(151.21deg, rgba(55, 158, 242, 0.3) 17.73%, rgba(101, 70, 173, 0.3) 83.52%),
    linear-gradient(
      164.29deg,
      rgba(143, 248, 255, 0.1625) 7.11%,
      rgba(70, 159, 184, 0.1365) 53.53%,
      rgba(0, 75, 118, 0.3445) 97.18%
    );
  margin: 0 0 15px 0;
  display: flex;
  align-items: flex-start;

  & > div:last-child {
    flex: 1;
  }
`

/*
 INTERFACES
*/

interface SectionProps {
  icon?: React.JSX.Element
  title?: string
  className?: string
  children?: React.ReactNode
}

interface LoadingProps {
  widths: number[]
}

/*
 COMPONENTS
*/

export const DrawerSection: React.FC<SectionProps> = ({ icon, title, children, className }) => (
  <LeftDrawerSectionContainer className={className}>
    {(icon || title) && (
      <SectionHeader>
        {icon}
        {title}
      </SectionHeader>
    )}
    {children}
  </LeftDrawerSectionContainer>
)

export const SidebarSectionLoading: React.FC<LoadingProps> = ({ widths }) => {
  const iconSize = 40
  const loading = widths.map((width, idx) => (
    <LoadingItem key={`loading_${idx}`}>
      <LoadingCircle
        backgroundColor={colors.translucentWhite20}
        $shineColor={colors.translucentWhite50}
        width={`${iconSize}px`}
        height={`${iconSize}px`}
        margin="0 20px 0 0"
      />
      <div>
        <LoadingBlock
          display="block"
          backgroundColor={colors.translucentWhite20}
          $shineColor={colors.translucentWhite50}
          width={`${width}%`}
          height="13px"
          margin="0 0 5px 0"
        />
        <LoadingBlock
          display="block"
          backgroundColor={colors.translucentWhite20}
          $shineColor={colors.translucentWhite50}
          width={`${width * 0.9}%`}
          height="13px"
          margin="0 0 5px 0"
        />
        <LoadingBlock
          display="block"
          backgroundColor={colors.translucentWhite20}
          $shineColor={colors.translucentWhite50}
          width={`${width * 0.4}%`}
          height="8px"
          margin="0 0 5px 0"
        />
      </div>
    </LoadingItem>
  ))
  return <>{loading}</>
}
