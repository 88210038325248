import * as React from "react"
import {
  type CompanyFinancialValue,
  type InstitutionProduct,
  type QuotePackageFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import { isMobile, isTablet } from "@digits-shared/helpers/devicesHelper"
import { type PlgOnboardAction } from "src/frontend/components/Onboard/PLG/Context/plgOnboardingReducer"

export enum PlgOnboardingStep {
  InitialCheckpoint = "InitialCheckpoint",
  Redirect = "Redirect",
  ContactSupport = "ContactSupport",
  CreateYourAccount = "CreateYourAccount",
  HoldForEmail = "HoldForEmail",
  PromptMagicLink = "PromptMagicLink",
  MobileRegistration = "MobileRegistration",
  ChooseYourSoftware = "ChooseYourSoftware",
  ChosenSoftware = "ChosenSoftware",
  CutoverDate = "CutoverDate",
  CompanyInfo = "CompanyInfo",
  PaymentSetup = "PaymentSetup",
  ConnectBanksAndCards = "ConnectBanksAndCards",
  ConnectOtherFinancials = "ConnectOtherFinancials",
  OnboardingComplete = "OnboardingComplete",
}

export enum SplineAnimationState {
  Text = "Text",
  Logo = "Logo",
  LogoZoom = "LogoZoom",
}

export enum PlgAnimationState {
  NOT_STARTED = "NOT_STARTED",
  ANIMATING_IN = "ANIMATING_IN",
  PAUSE = "PAUSE",
  ANIMATING_OUT = "ANIMATING_OUT",
  COMPLETE = "COMPLETE",
}

export enum Subscription {
  AIAccounting = "AIAccounting",
  TaxReadyBooks = "TaxReadyBooks",
  MonthlyAccounting = "MonthlyAccounting",
  AccountingTaxes = "AccountingTaxes",
}

export enum Software {
  Digits = "Digits",
  QuickBooks = "QuickBooks",
  QuickBooksDesktop = "QuickBooksDesktop",
  Puzzle = "Puzzle",
  Pilot = "Pilot",
  Fondo = "Fondo",
  Xero = "Xero",
  Excel = "Excel",
  Other = "Other",
  Unknown = "Unknown",
}

export type User = { givenName: string; familyName: string }

export type Financials = Record<InstitutionProduct, CompanyFinancialValue[]>

export type Preferences = {
  cutoverDate: number | null
  hasQbo?: boolean | null
  software: Software
}

export enum EmailRequest {
  None = "None",
  Verification = "Verification",
  MagicLink = "MagicLink",
}

export interface PlgOnboardingContextValue {
  state: PlgOnboardState
  dispatch: React.Dispatch<PlgOnboardAction>
  setStep: (step: PlgOnboardingStep) => void
  startLoading: () => void
  stopLoading: () => void
}

export interface PlgOnboardState {
  step: PlgOnboardingStep
  back: boolean
  deferredStep: {
    step: PlgOnboardingStep
    back: boolean
  } | null
  isStepAnimating: boolean
  user: User | null
  email: string | null
  emailRequest: EmailRequest
  emailVerificationToken: string | null
  loading: boolean
  animation: PlgAnimationState
  animating: boolean
  financials: Partial<Financials>
  quotePackage: QuotePackageFieldsFragment | null
  preferences: Preferences | null
  splineAnimation: SplineAnimationState
  showConfetti: boolean
}

export const DEFAULT_STATE: PlgOnboardState = {
  step: PlgOnboardingStep.InitialCheckpoint,
  back: false,
  deferredStep: null,
  isStepAnimating: false,
  user: null,
  email: null,
  emailRequest: EmailRequest.None,
  emailVerificationToken: null,
  loading: false,
  animation: PlgAnimationState.NOT_STARTED,
  animating: false,
  financials: {},
  quotePackage: null,
  preferences: null,
  splineAnimation: isMobile && !isTablet ? SplineAnimationState.Logo : SplineAnimationState.Text,
  showConfetti: false,
}

const NOOP = () => {}

export const PlgOnboardingContext = React.createContext<PlgOnboardingContextValue>({
  state: DEFAULT_STATE,
  dispatch: NOOP,
  setStep: NOOP,
  startLoading: NOOP,
  stopLoading: NOOP,
})

export const usePlgOnboarding = (): PlgOnboardingContextValue => {
  const value = React.useContext(PlgOnboardingContext)

  if (!value) {
    throw new Error("usePlgOnboarding must be called within a PlgOnboardingContextProvider")
  }

  return value
}
