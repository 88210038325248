import * as React from "react"
import colors from "@digits-shared/themes/colors"
import { AnimatePresence, m } from "framer-motion"
import styled, { css } from "styled-components"
import { Done } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/Done"
import { InviteClients } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/InviteClients/InviteClients"
import { MyClients } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/MyClients"
import {
  MODAL_HEIGHT,
  MODAL_RADIUS,
  MODAL_WIDTH,
  type ModalStateProps,
  transitionVariants,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/shared"
import { type InviteClientAction } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/actions"
import { type InviteClientState } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import { useEmployeesWithAccess } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/useEmployeesWithAccess"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"

const ContentSwitcher = styled(m.div)`
  width: 100%;
  height: 100%;
`

const StableBackground = styled.div<ModalStateProps>`
  position: absolute;
  left: 0;
  border-radius: ${MODAL_RADIUS}px;
  width: ${MODAL_WIDTH}px;
  height: ${MODAL_HEIGHT}px;
  border: none;

  ${({ modalState }) =>
    modalState === InviteClientsModalState.Done
      ? css`
          background: linear-gradient(177.24deg, #6b92d5 0.5%, #6cb1cb 100%);
        `
      : css`
          background-color: ${colors.translucentWhite50};
        `}
`

export const Content: React.FC<{
  dispatch: React.Dispatch<InviteClientAction>
  state: InviteClientState
  onClose: () => void
  onDone: () => void
}> = ({ state, dispatch, onClose, onDone }) => (
  <StableBackground modalState={state.modalState}>
    <AnimatePresence mode="wait">
      <ContentSwitcher
        key={state.modalState}
        variants={transitionVariants}
        initial="exiting"
        animate="entering"
        exit="exiting"
      >
        <TransitionedContent state={state} dispatch={dispatch} onDone={onDone} onClose={onClose} />
      </ContentSwitcher>
    </AnimatePresence>
  </StableBackground>
)

const TransitionedContent: React.FC<{
  className?: string
  state: InviteClientState
  dispatch: React.Dispatch<InviteClientAction>
  onClose: () => void
  onDone: () => void
}> = ({ className, state, dispatch, onClose, onDone }) => {
  const { employeesWithAccess, employeesInvited, employeesLoading } = useEmployeesWithAccess()

  switch (state.modalState) {
    case InviteClientsModalState.Done:
      return <Done className={className} state={state} onDone={onDone} />
    case InviteClientsModalState.MyClients:
      return (
        <MyClients
          className={className}
          modalState={state.modalState}
          activatedClients={employeesWithAccess}
          invitedClients={employeesInvited}
          loading={employeesLoading}
          onClose={onClose}
          dispatch={dispatch}
        />
      )
    default:
      return (
        <InviteClients className={className} dispatch={dispatch} state={state} onClose={onClose} />
      )
  }
}
