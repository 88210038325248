import * as React from "react"
import { SvgBellRinging04 } from "@digits-shared/components/SVGIcons/line/BellRinging04.svg"
import styled from "styled-components"
import { useCurrentLegalEntityActionItemsBadgeCount } from "src/frontend/components/OS/Applications/hooks/useActionItemsBadgeCountsQuery"
import { SIDEBAR_PATH_SVG_STYLES } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import routes from "src/frontend/routes"

/*
  STYLES
*/

export const InboxIcon = styled(SvgBellRinging04)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

/*
  COMPONENT
*/

export const ActionItemsSidebarRow: React.FC<{ params?: Record<string, string> }> = ({
  params,
}) => {
  const actionItemCount = useCurrentLegalEntityActionItemsBadgeCount()

  return (
    <SidebarRow
      badge={actionItemCount}
      badgeLimit={99}
      Icon={InboxIcon}
      route={routes.inboxLatest}
      params={params}
      activeRoutes={[routes.inbox]}
    >
      Inbox
    </SidebarRow>
  )
}
