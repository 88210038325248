import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { SummaryWithCount } from "src/shared/components/Transactions/TransactionRow/SummaryWithCount"
import {
  isTransactionGroup,
  type TransactionGroupProps,
  type TransactionProps,
  type TransactionRowProps,
} from "src/shared/components/Transactions/TransactionRow/types"
import transactionHelper from "src/shared/helpers/transactionHelper"

/*
  COMPONENTS
*/

export const TransactionAmount: React.FC<TransactionRowProps> = ({ data, loading, className }) => {
  if (loading || !data) {
    return <LoadingBlock width="100px" height="12px" />
  }

  const transaction = isTransactionGroup(data) ? data.transactions[0] : data
  if (isTransactionGroup(data) && data.transactions.length > 1) {
    return <GroupedTransactionAmount group={data} className={className} />
  }

  return transaction && <SingleTransactionAmount transaction={transaction} className={className} />
}

const SingleTransactionAmount: React.FC<TransactionProps> = ({ transaction, className }) => {
  const { moneyFlow } = transaction

  if (transactionHelper.isTransfer(transaction)) {
    return null
  }

  const amountWithCurrency = moneyFlowHelper.currency(moneyFlow, {
    style: CurrencyStyle.Detail,
  })

  return (
    <Amount flow={moneyFlow.businessFlow} className={className}>
      {amountWithCurrency}
    </Amount>
  )
}

const GroupedTransactionAmount: React.FC<TransactionGroupProps> = ({ group, className }) => {
  const size = group.transactions.length
  const groupSize = `${size} ${size === 1 ? "transaction" : "transactions"}`

  return (
    <SummaryWithCount moneyFlow={group.totalAmount} className={className}>
      {groupSize}
    </SummaryWithCount>
  )
}
