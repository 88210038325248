import * as React from "react"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import {
  DisplayOptionsColumn,
  DisplayOptionsColumnTitle,
} from "src/frontend/components/OS/Shared/DisplayOptions/Shared"

/*
  STYLES
*/

/*
  COMPONENTS
*/

interface Props {
  onResetClick: () => void
  currentFiltersCount: number
  children?: React.ReactNode
}

export const FilterColumn: React.FC<Props> = ({ onResetClick, currentFiltersCount, children }) => (
  <DisplayOptionsColumn css="padding-right: 0; padding-bottom: 0">
    <DisplayOptionsColumnTitle>
      <div css="flex: 1">Filter By</div>
      {!!currentFiltersCount && (
        <DigitsButton size="small" onClick={onResetClick} $variant="ghost-dark">
          Clear Filters
        </DigitsButton>
      )}
    </DisplayOptionsColumnTitle>
    {children}
  </DisplayOptionsColumn>
)
