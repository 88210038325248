import * as React from "react"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowCircleUpSolid } from "@digits-shared/components/SVGIcons/solid/ArrowCircleUpSolid.svg"
import { type ButtonProps } from "@digits-shared/DesignSystem/Button"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

const UpgradeIcon = styled(SvgArrowCircleUpSolid)`
  ${svgIconStyles(colors.secondary)};
  margin-left: 0 !important;
  path {
    stroke: ${colors.transparent} !important;
  }
`

export const UpgradeLinkButton: React.FC<
  { className?: string; onClick?: () => void } & Pick<ButtonProps, "$fullWidth">
> = ({ className, $fullWidth, onClick }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const pathGenerator = useFrontendPathGenerator()
  return (
    <DigitsLinkButton
      className={className}
      $fullWidth={$fullWidth}
      onClick={onClick}
      to={pathGenerator(routes.subscriptionSettings, {
        leSlug: currentLegalEntity?.slug,
      })}
    >
      <UpgradeIcon /> Upgrade
    </DigitsLinkButton>
  )
}
