import * as React from "react"
import { useModalManagerDispatch } from "src/frontend/components/Shared/ManagedModals/state/store"
import { type MonitoredModalName } from "src/frontend/components/Shared/ManagedModals/state/types"

/**
 * Monitor a modal based on its render lifecycle
 */
export function useMonitorModal(modalName: MonitoredModalName) {
  const dispatch = useModalManagerDispatch()
  React.useEffect(() => {
    dispatch({
      type: "UpdateMonitoredModal",
      name: modalName,
      open: true,
    })

    return () => {
      dispatch({
        type: "UpdateMonitoredModal",
        name: modalName,
        open: false,
      })
    }
  }, [dispatch, modalName])
}
