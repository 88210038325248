import * as React from "react"
import { type SortDirection } from "@digits-graphql/frontend/graphql-bearer"
import { SvgCurrencyDollarCircle } from "@digits-shared/components/SVGIcons/line/CurrencyDollarCircle.svg"
import { SvgFilterFunnel01 } from "@digits-shared/components/SVGIcons/line/FilterFunnel01.svg"
import { SvgLineHeight } from "@digits-shared/components/SVGIcons/line/LineHeight.svg"
import { SvgSwitchVertical01 } from "@digits-shared/components/SVGIcons/line/SwitchVertical01.svg"
import { useDimensionDisplayOptionGroup } from "src/frontend/components/OS/Shared/DisplayOptions/DimensionDisplayOptionGroupContext"
import { type DisplayOptionAccessor } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import { DisplayOptionsSection } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOptionsSection"
import { DimensionActivityFilter } from "src/frontend/components/OS/Shared/DisplayOptions/Filters/DimensionActivityFilter"
import { FilterColumn } from "src/frontend/components/OS/Shared/DisplayOptions/Filters/FilterColumn"
import { SortColumn } from "src/frontend/components/OS/Shared/DisplayOptions/Sorts/SortColumn"
import { DimensionSortBy } from "src/frontend/types/DimensionFilter"

/*
  COMPONENTS
*/

export const DimensionDisplayOptionGroup: React.FC<{ className?: string }> = ({ className }) => {
  const { clearOnlyFilters, currentFilterURLKeysCount, sortBy, sortDirection } =
    useDimensionDisplayOptionGroup()
  const dimensions = useDimensionSortElements(sortBy, sortDirection)

  return (
    <>
      <DisplayOptionsSection title="Filter" TitleIconElement={SvgFilterFunnel01}>
        <FilterColumn
          onResetClick={clearOnlyFilters}
          currentFiltersCount={currentFilterURLKeysCount}
        >
          <DimensionActivityFilter />
        </FilterColumn>
      </DisplayOptionsSection>
      <DisplayOptionsSection title="Sort" TitleIconElement={SvgSwitchVertical01}>
        <SortColumn dimensions={dimensions} sortDirection={sortDirection} />
      </DisplayOptionsSection>
    </>
  )
}

export function useDimensionSortElements(
  sortBy: DisplayOptionAccessor<DimensionSortBy>,
  sortDirection: DisplayOptionAccessor<SortDirection>
) {
  const onSelect = React.useCallback(
    (value: DimensionSortBy) => {
      sortBy.set(value)
      sortDirection.clear()
    },
    [sortBy, sortDirection]
  )

  return React.useMemo(
    () =>
      [
        {
          title: "Amount",
          TitleIconElement: SvgCurrencyDollarCircle,
          value: DimensionSortBy.Amount,
        },
        {
          title: "Name",
          TitleIconElement: SvgLineHeight,
          value: DimensionSortBy.Name,
        },
      ].map(({ value, ...rest }) => {
        const selected = sortBy.get() === value
        return {
          ...rest,
          selected,
          onSelect: () => onSelect(value),
        }
      }),
    [onSelect, sortBy]
  )
}
