export interface OverlayPosition {
  top: number
  left: number
}

export enum AnchorLocation {
  TopLeft = "TopLeft",
  TopRight = "TopRight",
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
}

/**
 * Helper which determines an absolute viewport offset position given an anchor element ref
 * and an anchor location.
 *
 * Takes into account the scroll amounts of all scroll containers in the hierarchy.
 *
 * NOTE: You may have make your scrolling element `position: relative;` in order for it to be
 * properly handled.
 */
export function getAnchorOffsets(
  ref: React.RefObject<HTMLElement | null>,
  location: AnchorLocation = AnchorLocation.BottomLeft
): OverlayPosition {
  let offsetTop = 0
  let offsetLeft = 0
  let parent = ref.current as HTMLElement
  const offsetWidth =
    parent && (location === AnchorLocation.TopRight || location === AnchorLocation.BottomRight)
      ? parent.offsetWidth
      : 0
  const offsetHeight =
    parent && (location === AnchorLocation.BottomLeft || location === AnchorLocation.BottomRight)
      ? parent.offsetHeight
      : 0

  while (parent) {
    offsetTop += parent.offsetTop - parent.scrollTop
    offsetLeft += parent.offsetLeft - parent.scrollLeft
    parent = parent.offsetParent as HTMLElement
  }

  return { top: Math.max(0, offsetTop + offsetHeight), left: Math.max(0, offsetLeft + offsetWidth) }
}
