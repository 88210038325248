import * as React from "react"
import { DeferredSnackbar } from "src/frontend/components/Shared/ManagedModals/DeferredSnackbar"
import { type ManagedSnackbarName } from "src/frontend/components/Shared/ManagedModals/state/types"
import {
  useHideSnackbar,
  useWasSnackbarDismissed,
} from "src/frontend/components/Shared/ManagedModals/useManagedSnackbar"
import { UpgradeLinkButton } from "src/frontend/components/Shared/PLG/Upsell/UpgradeButton"
import { useIsInIcp } from "src/frontend/components/Shared/PLG/Upsell/upsell"

export const UpsellSnackbar: React.FC<{
  snackbarName: ManagedSnackbarName
  message: string
  description: string
}> = ({ snackbarName, message, description }) => {
  const wasSnackbarDismissed = useWasSnackbarDismissed(snackbarName)
  const isInIcp = useIsInIcp(wasSnackbarDismissed)
  const dismissSnackbar = useHideSnackbar(snackbarName, "Persistent")

  return (
    isInIcp &&
    !wasSnackbarDismissed && (
      <DeferredSnackbar
        type="glow"
        position="bottom-right"
        showIcon={false}
        message={message}
        description={description}
        dismissibleOnHover
        onDismiss={dismissSnackbar}
        delayMs={2_000}
        buttonOverride={<UpgradeLinkButton onClick={dismissSnackbar} />}
      />
    )
  )
}
