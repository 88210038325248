import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import type { AspectCode } from "@digits-shared/session/AspectCode"
import type FrontendSession from "src/frontend/session"

type RequireAspect = { aspect: AspectCode }
type RequireAspects = { aspects: AspectCode[] }
type RequiresProps = React.PropsWithChildren<RequireAspect | RequireAspects>

function isSingleAspect(props: RequireAspect | RequireAspects): props is RequireAspect {
  return (props as RequireAspect).aspect !== undefined
}

export const RequiresAspect: React.FC<RequiresProps> = (props) => {
  const { children } = props
  const { currentLegalEntity, hasAccessToAspect } = useSession<FrontendSession>()

  const hasAspects = React.useMemo(() => {
    const aspects = isSingleAspect(props) ? [props.aspect] : props.aspects
    return aspects.every((a) => hasAccessToAspect(a))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, currentLegalEntity?.id, hasAccessToAspect])

  if (!hasAspects) return null

  return <>{children}</>
}
