import * as React from "react"
import { useEvent } from "react-use"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAi } from "@digits-shared/components/SVGIcons/custom/Ai.svg"
import { SvgOrderedList } from "@digits-shared/components/SVGIcons/custom/OrderedList.svg"
import { SvgAlignCenter } from "@digits-shared/components/SVGIcons/line/AlignCenter.svg"
import { SvgAlignLeft } from "@digits-shared/components/SVGIcons/line/AlignLeft.svg"
import { SvgAlignRight } from "@digits-shared/components/SVGIcons/line/AlignRight.svg"
import { SvgDotpoints01 } from "@digits-shared/components/SVGIcons/line/Dotpoints01.svg"
import { SvgLink01 } from "@digits-shared/components/SVGIcons/line/Link01.svg"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { Input } from "@digits-shared/components/UI/Elements/Form/Input"
import { Label } from "@digits-shared/components/UI/Elements/Form/Label"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { AlertModal } from "@digits-shared/DesignSystem/Modals/AlertModal"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AnimatePresence, m } from "framer-motion"
import styled, { css } from "styled-components"
import { TagNames } from "src/shared/components/EditableContent/editableContentConstants"
import { ExecutiveSummaryAIGenerator } from "src/shared/components/EditableContent/ExecutiveSummaryAIGenerator"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

export const TOOLBAR_HEIGHT = 36

/*
 STYLES
*/

const ToolbarContainer = styled(m.div)<{ rect: RangeRect; toolbarWidth: number }>`
  position: absolute;
  top: ${({ rect }) => rect.top - TOOLBAR_HEIGHT - 13}px;
  left: ${({ rect, toolbarWidth }) => rect.width / 2 + rect.left - toolbarWidth / 2}px;
  opacity: ${({ toolbarWidth }) => (toolbarWidth ? 1 : 0)};
  transition: opacity 100ms;
  z-index: ${zIndexes.modalOverlay + 1};
`

const PortalPositionedContainer = styled(m.div)`
  position: absolute;
  z-index: ${zIndexes.modalOverlay + 1};
`

const Toolbar = styled.div<{ hideChevron?: boolean }>`
  position: relative;
  display: flex;
  background: ${colors.white};
  color: ${colors.secondary};
  border-radius: 7px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px ${colors.translucentBlack10};
  height: ${TOOLBAR_HEIGHT}px;
  padding: 3px 6px;

  ${({ hideChevron }) =>
    !hideChevron &&
    css`
      &::after {
        position: absolute;
        ${chevronStyles(PointingDirection.Down, "10px", colors.white)};
        left: 50%;
        margin-left: -10px;
        bottom: -8px;
      }
    `}
`

const Divider = styled.div`
  width: 1px;
  margin: 8px 7px 9px 4px;
  background-color: ${colors.translucentSecondary50};
`

const ToolbarButton = styled.div<{ active: boolean }>`
  font-size: 15px;
  width: 30px;
  padding: 6px 0px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: ${colors.translucentSecondary10};
    `};

  &:hover {
    background: ${colors.translucentSecondary20};
  }
`

const SVGToolbarButton = styled(ToolbarButton)<{ fillIcon?: boolean }>`
  svg {
    padding: 0 4px;
    ${({ fillIcon }) =>
      fillIcon ? svgIconStyles(colors.secondary) : svgPathStyles(colors.secondary, 1.5)};
  }
`

const Bold = styled(ToolbarButton)`
  font-weight: ${fonts.weight.heavy};
`

const Italic = styled(ToolbarButton)`
  font-style: italic;
`

const Underline = styled(ToolbarButton)`
  text-decoration: underline;
`

const StrikeThrough = styled(ToolbarButton)`
  text-decoration: line-through;
`

const ToolbarColorButton = styled(ToolbarButton)`
  position: relative;

  &::before {
    border-radius: 50%;
    border: 1px solid ${colors.white};
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    box-shadow: inset 0 0 2px ${colors.black};
  }
`

const Orange = styled(ToolbarColorButton)`
  &::before {
    background-color: ${colors.orange};
  }
`

const Green = styled(ToolbarColorButton)`
  &::before {
    background-color: ${colors.primary};
  }
`

const Blue = styled(ToolbarColorButton)`
  &::before {
    background-color: ${colors.secondary};
  }
`

/*
 INTERFACES
*/

type CommandID =
  | "bold"
  | "decreaseFontSize"
  | "foreColor"
  | "increaseFontSize"
  | "indent"
  | "insertOrderedList"
  | "insertUnorderedList"
  | "italic"
  | "justifyCenter"
  | "justifyLeft"
  | "justifyRight"
  | "outdent"
  | "strikeThrough"
  | "underline"

interface ActiveStyles {
  bold: boolean
  underline: boolean
  strikeThrough: boolean
  italic: boolean
  textAlign: string
  hyperlink: boolean
}

export interface LinkPreset {
  text: string
  url: string
}

interface Props {
  className?: string
  children?: React.ReactNode
  editableContentRef: React.RefObject<HTMLElement | null>
  showSelectColors?: boolean
  hideChevron?: boolean
  linkPresets?: LinkPreset[]
  onOpen?: () => void
  onClose?: () => void
  onFormat?: () => void
  onHyperlinkModal: (shown: boolean) => void
}

interface ToolbarProps {
  editableContentRef: React.RefObject<HTMLElement | null>
  selectionState: SelectionState | undefined
  activeStyles: ActiveStyles
  toolbarRef: React.RefObject<HTMLDivElement | null>
  showSelectColors?: boolean
  hideChevron?: boolean
  linkPresets?: LinkPreset[]
  onFormat: (() => void) | undefined
  onHyperlinkModal: (shown: boolean) => void
  children?: React.ReactNode
}

interface SelectionState {
  range: Range
  rect: RangeRect
  styles: ActiveStyles
}

interface RangeRect {
  top: number
  left: number
  width: number
  height: number
}

/*
 COMPONENTS
*/

export const RichTextToolbar: React.FC<Props> = ({
  className,
  showSelectColors,
  editableContentRef,
  hideChevron,
  linkPresets,
  onOpen,
  onClose,
  onFormat,
  onHyperlinkModal,
}) => {
  const toolbarRef = React.useRef<HTMLDivElement | null>(null)
  const [toolbarWidth, setToolbarWidth] = React.useState(0)

  const selectionState = useSelectionState(editableContentRef, toolbarRef)

  React.useLayoutEffect(() => {
    setToolbarWidth(toolbarRef.current?.clientWidth ?? 0)
    if (selectionState?.range) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }, [onClose, onOpen, selectionState?.range])

  return (
    <AnimatePresence>
      {selectionState && (
        <ToolbarContainer
          className={className}
          rect={selectionState.rect}
          toolbarWidth={toolbarWidth}
          transition={{ duration: 0.1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ToolbarContent
            editableContentRef={editableContentRef}
            selectionState={selectionState}
            activeStyles={selectionState.styles}
            toolbarRef={toolbarRef}
            hideChevron={hideChevron}
            onFormat={onFormat}
            onHyperlinkModal={onHyperlinkModal}
            showSelectColors={showSelectColors}
            linkPresets={linkPresets}
          />
        </ToolbarContainer>
      )}
    </AnimatePresence>
  )
}

export const PortalPositionedRichTextToolbar: React.FC<
  Props & { toolbarRef: React.RefObject<HTMLDivElement | null> }
> = ({
  className,
  toolbarRef,
  editableContentRef,
  showSelectColors,
  hideChevron,
  linkPresets,
  onFormat,
  onHyperlinkModal,
  children,
}) => {
  const selectionState = useSelectionState(editableContentRef, toolbarRef)

  const activeStyles = selectionState?.styles ?? {
    bold: false,
    italic: false,
    strikeThrough: false,
    textAlign: "",
    underline: false,
    hyperlink: false,
  }

  return (
    <PortalPositionedContainer
      className={className}
      transition={{ duration: 0.1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ToolbarContent
        editableContentRef={editableContentRef}
        selectionState={selectionState}
        activeStyles={activeStyles}
        toolbarRef={toolbarRef}
        showSelectColors={showSelectColors}
        hideChevron={hideChevron}
        onFormat={onFormat}
        onHyperlinkModal={onHyperlinkModal}
        linkPresets={linkPresets}
      >
        {children}
      </ToolbarContent>
    </PortalPositionedContainer>
  )
}

export const ToolbarContent: React.FC<ToolbarProps> = ({
  editableContentRef,
  selectionState,
  activeStyles,
  toolbarRef,
  hideChevron,
  linkPresets,
  onFormat,
  onHyperlinkModal,
  showSelectColors = true,
  children,
}) => {
  const { linkSelection, hideModal, onHyperlink, onExecCommand, onColorChange } =
    useSelectionActions(editableContentRef, selectionState, onFormat, onHyperlinkModal)

  return (
    <Toolbar ref={toolbarRef} hideChevron={hideChevron}>
      <Bold active={activeStyles.bold} onMouseDown={onExecCommand("bold")}>
        B
      </Bold>
      <Italic active={activeStyles.italic} onMouseDown={onExecCommand("italic")}>
        I
      </Italic>
      <Underline active={activeStyles.underline} onMouseDown={onExecCommand("underline")}>
        U
      </Underline>
      <StrikeThrough
        active={activeStyles.strikeThrough}
        onMouseDown={onExecCommand("strikeThrough")}
      >
        S
      </StrikeThrough>
      <SVGToolbarButton active={activeStyles.hyperlink} onMouseDown={onHyperlink}>
        <SvgLink01 />
      </SVGToolbarButton>
      <Divider />
      <SVGToolbarButton
        active={activeStyles.textAlign === "left"}
        onMouseDown={onExecCommand("justifyLeft")}
      >
        <SvgAlignLeft />
      </SVGToolbarButton>
      <SVGToolbarButton
        active={activeStyles.textAlign === "center"}
        onMouseDown={onExecCommand("justifyCenter")}
      >
        <SvgAlignCenter />
      </SVGToolbarButton>
      <SVGToolbarButton
        active={activeStyles.textAlign === "right"}
        onMouseDown={onExecCommand("justifyRight")}
      >
        <SvgAlignRight />
      </SVGToolbarButton>
      <Divider />
      <SVGToolbarButton active={false} onMouseDown={onExecCommand("insertUnorderedList")}>
        <SvgDotpoints01 />
      </SVGToolbarButton>
      <SVGToolbarButton active={false} onMouseDown={onExecCommand("insertOrderedList")} fillIcon>
        <SvgOrderedList />
      </SVGToolbarButton>
      <ExecutiveSummaryAIGenerator editableContentRef={editableContentRef}>
        <Divider />
        <SVGToolbarButton active={false} fillIcon>
          <SvgAi />
        </SVGToolbarButton>
      </ExecutiveSummaryAIGenerator>
      {showSelectColors && (
        <>
          <Divider />
          <Blue active={false} onMouseDown={onColorChange(colors.secondary)} />
          <Green active={false} onMouseDown={onColorChange(colors.primary)} />
          <Orange active={false} onMouseDown={onColorChange(colors.orange)} />
        </>
      )}
      {children}
      {linkSelection && (
        <HyperlinkTextModal
          linkSelection={linkSelection}
          hyperlinked={activeStyles.hyperlink}
          onCancel={hideModal}
          presets={linkPresets}
        />
      )}
    </Toolbar>
  )
}

function useSelectionState(
  editableContentRef: React.RefObject<HTMLElement | null>,
  toolbarRef: React.RefObject<HTMLDivElement | null>
): SelectionState | undefined {
  const [range, setRange] = React.useState<Range>()

  const onSelectionChange = React.useCallback(() => {
    const selection = window.getSelection()
    const selectionRange = (selection?.rangeCount && selection.getRangeAt(0)) || undefined

    let selectedNode = selection?.focusNode
    if (toolbarRef.current === selectedNode) {
      return
    }

    while (selectedNode && selectedNode !== editableContentRef.current) {
      selectedNode = selectedNode.parentElement
    }
    if (!selectedNode) return

    if (!selectionRange || selection?.isCollapsed) {
      setRange(undefined)
      return
    }

    setRange(selectionRange)
  }, [editableContentRef, toolbarRef])
  useEvent("selectionchange", onSelectionChange, document)

  let element: HTMLElement = window.getSelection()?.focusNode as HTMLElement
  while (element && element.nodeType !== Node.ELEMENT_NODE) {
    element = element.parentElement as HTMLElement
  }
  const computedStyle = element ? window.getComputedStyle(element) : undefined

  return React.useMemo(() => {
    if (!range || !editableContentRef.current) return undefined

    // cache slow DOM lookups
    const rangeRect = range.getBoundingClientRect()
    const contentRect = editableContentRef.current.getBoundingClientRect()
    const rect = {
      top: rangeRect.top - contentRect.top,
      left: rangeRect.left - contentRect.left,
      width: rangeRect.width,
      height: rangeRect.height,
    }

    const bold = computedStyle?.fontWeight === fonts.weight.heavy.toString()
    const italic = computedStyle?.fontStyle === "italic"
    const underline = computedStyle?.textDecoration?.includes("underline") ?? false
    const strikeThrough = computedStyle?.textDecoration?.includes("line-through") ?? false
    const textAlign = computedStyle?.textAlign ?? "left"
    const hyperlink = element.tagName === TagNames.Anchor

    return { range, rect, styles: { bold, italic, underline, strikeThrough, textAlign, hyperlink } }
  }, [element, range, editableContentRef, computedStyle])
}

function useSelectionActions(
  editableContentRef: React.RefObject<HTMLElement | null>,
  selectionState: SelectionState | undefined,
  onFormat?: () => void,
  onHyperlinkModal?: (show: boolean) => void
) {
  const [linkSelection, setLinkSelection] = React.useState<Range | null>(null)

  React.useEffect(() => {
    onHyperlinkModal?.(!!linkSelection)
  }, [linkSelection, onHyperlinkModal])

  const hideModal = React.useCallback(() => setLinkSelection(null), [])

  const focusAfterFormat = React.useCallback(() => {
    onFormat?.()
    editableContentRef.current?.focus()

    if (selectionState?.range) {
      const selection = window.getSelection()
      selection?.addRange(selectionState.range) // make the range just created the visible selection
    }
  }, [editableContentRef, onFormat, selectionState?.range])

  const execCommand = React.useCallback(
    (event: React.MouseEvent, commandId: CommandID, showUI?: boolean, value?: string) => {
      event.preventDefault()
      event.stopPropagation()
      document.execCommand("styleWithCSS", false, "true")
      document.execCommand(commandId, showUI, value)
      focusAfterFormat()
    },
    [focusAfterFormat]
  )

  const onExecCommand = React.useCallback(
    (commandId: CommandID, showUI?: boolean, value?: string) => (event: React.MouseEvent) => {
      execCommand(event, commandId, showUI, value)
    },
    [execCommand]
  )

  const onHyperlink = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      const selection = window.getSelection()
      if (selection) {
        setLinkSelection(selection.getRangeAt(0))
      }

      focusAfterFormat()
    },
    [focusAfterFormat]
  )

  const onColorChange = React.useCallback(
    (color: string) => (event: React.MouseEvent) => {
      execCommand(event, "foreColor", false, color)
    },
    [execCommand]
  )

  return React.useMemo(
    () => ({
      linkSelection,
      hideModal,
      onHyperlink,
      onExecCommand,
      onColorChange,
    }),
    [linkSelection, hideModal, onHyperlink, onExecCommand, onColorChange]
  )
}

const HyperlinkTextModal: React.FC<{
  linkSelection: Range
  hyperlinked: boolean
  onCancel: () => void
  presets?: LinkPreset[]
}> = ({ hyperlinked, linkSelection, onCancel, presets }) => {
  const stopPropagation = useStopClickPropagation()

  const textRef = React.useRef<HTMLInputElement>(null)
  const linkRef = React.useRef<HTMLInputElement>(null)

  const initialUrl = React.useMemo(() => {
    if (hyperlinked) {
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(linkSelection)

      let element: HTMLElement = selection?.focusNode as HTMLElement
      while (element && element.nodeType !== Node.ELEMENT_NODE) {
        element = element.parentElement as HTMLElement
      }
      if (element.parentElement && element?.tagName === TagNames.Anchor) {
        return element.getAttribute("href") ?? undefined
      }
    }

    try {
      return new URL(linkSelection.toString()).toString()
    } catch {
      return undefined
    }
  }, [hyperlinked, linkSelection])
  const [validUrl, setValidUrl] = React.useState(
    initialUrl === undefined ? undefined : !!initialUrl
  )
  const onUrlChange = React.useCallback(() => {
    const url = linkRef.current?.value
    try {
      setValidUrl(!!new URL(url || ""))
    } catch {
      setValidUrl(false)
    }
  }, [])

  const onLinkify = React.useCallback(() => {
    try {
      const url = new URL(linkRef.current?.value ?? "")
      const text = textRef.current?.value || url.toString()

      const selection = window.getSelection()
      if (!url || !text || !linkSelection || !selection) return

      const a = document.createElement("a")
      a.rel = "noreferrer"
      a.href = url.toString()
      selection.removeAllRanges()
      selection.addRange(linkSelection)
      linkSelection.surroundContents(a)

      a.innerText = text
      onCancel()
    } catch {
      // ignored
    }
  }, [linkSelection, onCancel])

  const onPresetClick = React.useCallback(
    (preset?: LinkPreset) => {
      if (textRef.current && linkRef.current) {
        textRef.current.value = preset?.text ?? ""
        linkRef.current.value = preset?.url ?? ""
      }
      onUrlChange()
    },
    [onUrlChange]
  )

  if (!linkSelection) return null

  const currentText = textRef.current?.value ?? linkSelection.toString()
  const currentUrl = linkRef.current?.value ?? initialUrl

  return (
    <AlertModal
      disabled={!validUrl}
      headerLabel="Add Link"
      HeaderIcon={SvgLink01}
      primaryButtonLabel="Save"
      primaryButtonClick={onLinkify}
      secondaryButtonLabel="Cancel"
      secondaryButtonClick={onCancel}
    >
      {presets?.length && (
        <div css="display: flex; margin-bottom: 12px; gap: 8px;">
          <PresetHyperLinkButton
            currentText={currentText}
            currentUrl={currentUrl}
            onClick={onPresetClick}
          />
          {presets.map((preset, i) => (
            <PresetHyperLinkButton
              key={i}
              preset={preset}
              currentText={currentText}
              currentUrl={currentUrl}
              onClick={onPresetClick}
            />
          ))}
        </div>
      )}
      <div onClick={stopPropagation} onClickCapture={stopPropagation}>
        <Label>Text</Label>
        <Input forwardRef={textRef} defaultValue={linkSelection.toString()} required autoFocus />
        <Label>Link</Label>
        <Input
          forwardRef={linkRef}
          defaultValue={initialUrl}
          placeholder="https:// or mailto:"
          required
          type="url"
          isInvalid={validUrl === undefined ? undefined : !validUrl}
          onChange={onUrlChange}
        />
      </div>
    </AlertModal>
  )
}

const PresetHyperLinkButton: React.FC<{
  preset?: LinkPreset
  currentText?: string
  currentUrl?: string
  onClick: (preset?: LinkPreset) => void
}> = ({ preset, currentText, currentUrl, onClick: updatePreset }) => {
  const active =
    (preset?.text ?? "") === (currentText ?? "") && (preset?.url ?? "") === (currentUrl ?? "")
  const onClick = React.useCallback(() => {
    updatePreset(preset)
  }, [preset, updatePreset])
  return (
    <DigitsButton
      $variant={active ? "secondary-dark" : "ghost-dark"}
      $active={active}
      size="small"
      onClick={onClick}
    >
      {preset?.text || "Custom"}
    </DigitsButton>
  )
}
