import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

export const AI_GRADIENT_BORDER = css`
  border: 1px solid transparent;
  box-sizing: content-box;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 1px; /* Border width */
    border-radius: inherit;
    background: ${colors.gradients.ai};
    mask:
      linear-gradient(white 0, white 0) content-box,
      linear-gradient(white 0, white 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const AI_GRADIENT_TEXT = css`
  background: ${colors.gradients.ai};
  background-clip: text;
  color: transparent;
`
