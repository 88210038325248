import React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgBuilding02 } from "@digits-shared/components/SVGIcons/line/Building02.svg"
import { IconSVGContainerNoBackgroundColor } from "@digits-shared/components/UI/Icons/Icon"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { TD_SPACING } from "src/frontend/components/OS/Applications/Accounting/TransactionDashboard/TransactionUI"
import { TransactionCategoryIcon } from "src/shared/components/Transactions/TransactionRow/TransactionCategoryIcon"

export enum Align {
  Left = "left",
  Right = "right",
  Center = "center",
}

export enum Padding {
  None = "none",
  Small = "small",
  Medium = "medium",
  Normal = "normal",
}

export const Td = styled.td<{
  alignment?: Align
  padding?: Padding
}>`
  height: 60px;
  ${fonts.scale.label};
  font-weight: ${fonts.weight.black};
  text-align: ${({ alignment }) => {
    switch (alignment) {
      case Align.Right:
        return "right"
      case Align.Center:
        return "center"
      default:
        return "left"
    }
  }};

  padding-right: ${({ padding }) => {
    switch (padding) {
      case Padding.None:
        return "0"
      case Padding.Small:
        return "5"
      case Padding.Medium:
        return "10"
      default:
        return TD_SPACING
    }
  }}px;
`

export const CellText = styled(BodyText)`
  width: 100%;
  font-weight: ${fonts.weight.medium};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colors.secondary};
`

const PartyContainer = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 7px;
`

export const SplitContainer = styled(PartyContainer)`
  height: 45px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  gap: 0;

  &:hover {
    background: none;
  }
`

export const TransactionCategoryIconStyled = styled(TransactionCategoryIcon)`
  display: inline-flex;
  margin-right: 5px;

  ${IconSVGContainerNoBackgroundColor} {
    border-radius: 5px;
    background: transparent;

    svg {
      ${svgPathStyles("rgba(112, 128, 165, 1)", 1.5)};
    }
  }
`

const MissingPartyContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MissingPartyBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed ${colors.translucentSecondary60};
  border-radius: 4px;
`

const MissingPartyIcon = styled(SvgBuilding02)`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  ${svgPathStyles(colors.translucentSecondary60)};
`

export const MissingParty: React.FC = () => (
  <MissingPartyContainer>
    <MissingPartyBox>
      <MissingPartyIcon />
    </MissingPartyBox>
  </MissingPartyContainer>
)
