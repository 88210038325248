import * as React from "react"
import {
  Flow,
  type MoneyFlow,
  ReportOptionComparison,
  type StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowDown } from "@digits-shared/components/SVGIcons/line/ArrowDown.svg"
import { SvgArrowUp } from "@digits-shared/components/SVGIcons/line/ArrowUp.svg"
import colorHelper from "@digits-shared/helpers/colorHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import {
  useReportCurrencyOptions,
  useReportDocumentOptions,
} from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportDocumentOptions"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  type ColumnKey,
  isBreakdownColumn,
  isBreakdownComparison,
  isDeltaColumn,
  isDoubleColumn,
  overTimeDeltaValue,
  type StatementColumns,
} from "src/frontend/components/Shared/Layout/Components/Statements/columnTypes"
import { TotalCell } from "src/frontend/components/Shared/Layout/Components/Statements/shared"
import {
  type RowDetails,
  useToOptionTimeSeries,
  useToTimeSeries,
} from "src/frontend/components/Shared/Layout/Components/Statements/toDetailsData"
import { useStatementCellEvents } from "src/frontend/components/Shared/Layout/Components/Statements/useStatementCellEvents"

/*
  STYLES
*/

const deltaStyle = themedValue({
  print: css`
    opacity: 1;
    color: ${colors.translucentBlack50};
  `,
  light: (props: DeltaProps) => deltaValueColor(props),
  dark: (props: DeltaProps) => deltaValueColor(props),
})

const DeltaStyled = styled(TotalCell)<DeltaProps>`
  ${deltaStyle};
  display: flex;
  align-items: center;
  gap: 0;

  div.holder,
  svg {
    height: 14px;
    width: 14px;
    margin-right: -7px;
  }
`

/*
  INTERFACES
*/

interface DeltasProps {
  rowId: string
  details?: RowDetails
}

interface DeltaProps {
  deltaValue?: StatementDeltaValue | null
  optionKey: ColumnKey
  comparison: ReportOptionComparison
}

/*
  COMPONENTS
*/

export const RowDeltas: React.FC<DeltasProps> = ({ rowId, details }) => {
  const reportOptions = useReportDocumentOptions()
  return (
    <>
      {details &&
        reportOptions?.columnKeys.map(
          (optionKey: keyof StatementColumns) =>
            isDeltaColumn(optionKey, reportOptions.columns) && (
              <DeltasOverTime
                key={optionKey}
                rowId={rowId}
                optionKey={optionKey}
                comparison={reportOptions.columns[optionKey] as ReportOptionComparison}
                details={details}
              />
            )
        )}
    </>
  )
}

export const DeltasOverTime: React.FC<{
  rowId: string
  optionKey: ColumnKey
  comparison: ReportOptionComparison
  details: RowDetails
}> = ({ rowId, optionKey, details, comparison }) => {
  const values = useToOptionTimeSeries(optionKey, details)?.values.slice().reverse()
  if (!values) return <div />

  const lookbackOffset = optionKey === "yearToDate" ? 0 : 1
  const dualColumn = isDoubleColumn(optionKey, comparison)

  return (
    <>
      {dualColumn && (
        <ValueOverTime
          rowId={rowId}
          lookback={lookbackOffset}
          optionKey={optionKey}
          comparison={ReportOptionComparison.Total}
          values={values}
          details={details}
        />
      )}
      <ValueOverTime
        rowId={rowId}
        lookback={lookbackOffset}
        optionKey={optionKey}
        comparison={comparison}
        values={values}
        details={details}
      />
    </>
  )
}

export const TotalsOverTime: React.FC<DeltasProps> = ({ rowId, details }) => {
  const reportOptions = useReportDocumentOptions()
  const optionKey = "deltaMonthOverMonth"

  const periodDetails = details?.periodDetails
  const values = useToTimeSeries(periodDetails)?.values.slice().reverse()
  const comparison = reportOptions.columns[optionKey]

  if (
    !details ||
    !isBreakdownColumn(optionKey, reportOptions?.columns) ||
    !isBreakdownComparison(comparison)
  )
    return null

  const count = Math.max(1, reportOptions.deltaMonthOverMonthPeriods)
  if (!values?.length) {
    return (
      <>
        {Array.from({ length: count }).map((_, idx) => (
          <div key={idx}></div>
        ))}
      </>
    )
  }

  return (
    <>
      {Array.from({ length: count }).map((_, idx) => (
        <ValueOverTime
          key={idx}
          rowId={rowId}
          lookback={count - idx}
          optionKey={optionKey}
          comparison={comparison}
          details={details}
          values={values}
        />
      ))}
    </>
  )
}

const ValueOverTime: React.FC<{
  rowId: string
  lookback: number
  optionKey: ColumnKey
  comparison: ReportOptionComparison
  details: RowDetails
  values: TimeseriesValue[]
}> = ({ rowId, lookback, values, details, optionKey, comparison }) => {
  const currentValue = values[0]?.moneyFlow
  const timeseriesValue = values[lookback]

  const seriesValues = React.useMemo(() => values.map(({ moneyFlow }) => moneyFlow), [values])
  const nextValue = seriesValues[lookback]

  const { value, deltaValue } = useOvertimeValues(
    optionKey,
    comparison,
    details,
    seriesValues,
    lookback
  )

  if (!currentValue || !nextValue) return null

  return (
    <DeltaColumn
      rowId={rowId}
      timeseriesValue={timeseriesValue}
      deltaValue={deltaValue}
      optionKey={optionKey}
      comparison={comparison}
    >
      {value}
    </DeltaColumn>
  )
}

const DeltaColumn: React.FC<
  React.PropsWithChildren<{
    rowId: string
    optionKey: ColumnKey
    timeseriesValue?: TimeseriesValue
    deltaValue?: StatementDeltaValue | null
    comparison: ReportOptionComparison
  }>
> = ({ rowId, timeseriesValue, optionKey, deltaValue, comparison, children }) => {
  const { onMouseEnter, onClick } = useStatementCellEvents(rowId, { optionKey, timeseriesValue })

  return (
    <DeltaStyled
      deltaValue={deltaValue}
      optionKey={optionKey}
      comparison={comparison}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div>{children}</div>
      <DeltaArrow optionKey={optionKey} deltaValue={deltaValue} comparison={comparison} />
    </DeltaStyled>
  )
}

const DeltaArrow: React.FC<{
  optionKey: ColumnKey
  deltaValue?: StatementDeltaValue | null
  comparison: ReportOptionComparison
}> = ({ optionKey, deltaValue, comparison }) => {
  if (!deltaValue || comparison !== ReportOptionComparison.DeltaPercent) {
    return null
  }

  const { isPositive, isSignificant, invertColors } = deltaValueSignificance(deltaValue, comparison)
  if (!isSignificant) return <div className="holder" />

  const style = svgPathStyles(
    (invertColors ? !isPositive : isPositive) ? colors.primary : colors.orange
  )
  return deltaValue.percentageOfAmount < 0 ? (
    <SvgArrowUp css={style} />
  ) : (
    <SvgArrowDown css={style} />
  )
}

function useOvertimeValues(
  optionKey: ColumnKey,
  comparison: ReportOptionComparison,
  details: RowDetails | undefined,
  seriesValues: MoneyFlow[],
  lookback: number
) {
  const currencyOptions = useReportCurrencyOptions()
  const reportOptions = useReportDocumentOptions()
  const { deltaMonthOverMonthPeriods } = reportOptions

  return React.useMemo(() => {
    const { moneyFlow, deltaValue, deltaAmount, percentValue } = overTimeDeltaValue(
      optionKey,
      seriesValues,
      lookback,
      deltaMonthOverMonthPeriods,
      details?.deltas
    )

    const amount = moneyFlow ? moneyFlowHelper.currency(moneyFlow, currencyOptions) : "-"

    const delta = deltaAmount
      ? moneyFlowHelper.currency(deltaAmount, {
          ...currencyOptions,
          signDisplay: "always",
        })
      : "-"

    const percentFractionDigits = Math.abs(percentValue ?? 0) < 1 ? 1 : 0
    const percentSignDisplay =
      optionKey !== "triPeriodDelta" &&
      (isBreakdownComparison(comparison) ||
        optionKey === "comparedToIncome" ||
        optionKey === "comparedToTotal")
        ? "never"
        : "always"

    const percentage = percentValue
      ? numberHelper
          .numberFormatter({
            ...currencyOptions,
            maximumFractionDigits: percentFractionDigits,
            minimumFractionDigits: percentFractionDigits,
            style: "percent",
            signDisplay: percentSignDisplay,
          })
          .format(percentValue / 100)
      : "-"

    if (optionKey === "triPeriodDelta") {
      return { deltaValue, value: percentage }
    }

    switch (comparison) {
      case ReportOptionComparison.Amount:
        return { deltaValue, value: delta }

      case ReportOptionComparison.Percent:
      case ReportOptionComparison.DeltaPercent:
        return { deltaValue, value: percentage }

      case ReportOptionComparison.Total:
      case ReportOptionComparison.InvalidComparison:
      case ReportOptionComparison.TriPeriodDeltaPercent:
        return { deltaValue: undefined, value: amount }
    }
  }, [
    optionKey,
    seriesValues,
    lookback,
    deltaMonthOverMonthPeriods,
    details?.deltas,
    currencyOptions,
    comparison,
  ])
}

// FUNCTIONS

function deltaValueColor({ deltaValue, optionKey, comparison }: DeltaProps) {
  if (!deltaValue) {
    return css`
      color: ${colors.secondary};
    `
  }

  const {
    absolute: abs,
    isPositive,
    isSignificant,
    invertColors,
  } = deltaValueSignificance(deltaValue, comparison)
  if (!abs || !deltaValue.moneyFlow?.value.amount) {
    return css`
      color: ${colors.translucentSecondary40};
    `
  }

  const alpha = Math.min(1, 0.4 + (Math.max(0, abs - 0.1) * 0.7) / 0.9)
  const weight = isSignificant ? fonts.weight.heavy : fonts.weight.medium

  switch (optionKey) {
    case "comparedToTotal":
    case "comparedToIncome": {
      const color = colorHelper.hexToRgba(colors.secondary, alpha)
      return css`
        color: ${color};
        font-weight: ${weight};
      `
    }
    case "triPeriodDelta":
    case "yearToDate":
    case "deltaYearToDate":
    case "amountPrecision":
    case "deltaMonthOverMonth":
    case "deltaYearOverYear":
    case "deltaMonthOverMonthPeriods":
    case "sparklineLookbackPeriods": {
      const color = (invertColors ? !isPositive : isPositive)
        ? colorHelper.hexToRgba(colors.primary, alpha)
        : colorHelper.hexToRgba(colors.orange, alpha)
      return css`
        color: ${color};
        font-weight: ${weight};
      `
    }
  }
}

function deltaValueSignificance(
  deltaValue: StatementDeltaValue,
  comparison: ReportOptionComparison
) {
  const {
    percentageOfAmount,
    moneyFlow: { businessFlow, isNormal },
  } = deltaValue
  const absolute = Math.abs(percentageOfAmount / 100)
  const isSignificant = absolute > 0.2

  // For inbound flows, normal means positive numbers are positive growth
  // For outbound flows, normal means negative numbers are positive growth
  const isPositive = isNormal
    ? businessFlow === Flow.Inbound
      ? percentageOfAmount > 0
      : percentageOfAmount < 0
    : businessFlow === Flow.Inbound
      ? percentageOfAmount < 0
      : percentageOfAmount > 0

  return {
    absolute,
    isPositive,
    isSignificant,
    invertColors: comparison === ReportOptionComparison.DeltaPercent,
  }
}
