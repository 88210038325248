import * as React from "react"
import { type DateRange, DayPicker, type OnSelectHandler } from "react-day-picker"
import colorHelper from "@digits-shared/helpers/colorHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { DATE_RANGE_MAX_DAYS } from "src/shared/components/DateRangeSelector/dateRangeConstants"
import { useDateRangeContext } from "src/shared/components/DateRangeSelector/DateRangeContext"
import "react-day-picker/dist/style.css"

const DayPickerContainer = styled.div`
  .rdp-root {
    --rdp-day-height: 30px;
    --rdp-day-width: 30px;
    --rdp-weekday-padding: 0;
    --rdp-font-family: ${fonts.family.avenir};
    --rdp-selected-font: bold 14px ${fonts.family.avenir};
    --rdp-range_middle-font: normal 14px ${fonts.family.avenir};
    --rdp-caption-font-size: 16px;
    --rdp-outline: none;
    --rdp-outline-selected: none;
    --rdp-accent-color: ${colors.secondary};

    .rdp-months {
      flex-wrap: nowrap;
    }

    .rdp-day.rdp-today:not(.rdp-day_outside) {
      font-weight: 700;
    }
  }

  &.invalid {
    .rdp-root {
      --rdp-range_middle-background-color: ${colorHelper.hexToRgba(colors.error, 0.15)};
      --rdp-range_start-date-background-color: ${colors.error};
      --rdp-selected-border: ${colors.error};
      --rdp-range_end-date-background-color: ${colors.error};
    }
  }
`

interface DayRangePickerProps {
  className?: string
}

interface DayRange {
  from: Date | undefined
  to: Date | undefined
}

export const DayRangePicker: React.FC<DayRangePickerProps> = ({ className }) => {
  const {
    dateRangeState: { timeRange, valid },
    dateRangeDispatch,
  } = useDateRangeContext()
  const currentOrigin = dateTimeHelper.intervalOriginFromPeriodRange(timeRange)

  const now = dayjs().utc()
  const [dates, setDates] = React.useState<DayRange>(() => {
    const fromMmt = dateTimeHelper.dayjsFromTimestamp(timeRange.startedAt)
    const from = dateTimeHelper.dayjsToLocalDate(fromMmt)
    const toMmt = dateTimeHelper.dayjsFromTimestamp(timeRange.endedAt)
    const to = dateTimeHelper.dayjsToLocalDate(toMmt)

    return {
      from,
      to,
    }
  })

  const disabledDays: { after: Date; before?: Date } = { after: now.toDate(), before: undefined }
  const defaultMonth = dateTimeHelper.dayjsFromIntervalOrigin(currentOrigin).toDate()

  const onRangeSelect = React.useCallback<OnSelectHandler<DateRange>>(
    (selected, triggerDate, modifiers) => {
      if (modifiers.disabled || !selected) {
        return
      }
      const from = dayjs(selected.from)
      const to = dayjs(selected.to)

      if (selected.from && selected.to) {
        const daysInRange = to.diff(from, "days")
        dateRangeDispatch({
          type: "SET_VALID",
          // requires strictly less than, as `diff` is not inclusive of the endpoints
          valid: daysInRange < DATE_RANGE_MAX_DAYS,
        })
      }

      dateRangeDispatch({ type: "SET_START", value: from.utc().unix() })
      dateRangeDispatch({ type: "SET_END", value: to.utc().unix() })

      setDates({
        from: selected.from,
        to: selected.to,
      })
    },
    [dateRangeDispatch]
  )

  return (
    <DayPickerContainer className={stringHelper.classNames(className, !valid && "invalid")}>
      <DayPicker
        mode="range"
        endMonth={now.toDate()}
        disabled={disabledDays}
        onSelect={onRangeSelect}
        selected={dates}
        numberOfMonths={2}
        defaultMonth={defaultMonth}
      />
    </DayPickerContainer>
  )
}
