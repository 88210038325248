import * as React from "react"
import ReactDOM from "react-dom"
import { type ArrivalContext, type ObjectIdentifier } from "@digits-graphql/frontend/graphql-bearer"
import { Modal, ModalAnchor } from "@digits-shared/components/UI/Elements/Modals/Modal"
import { Confetti, CONFETTI_TYPE } from "@digits-shared/DesignSystem/Animations/Confetti"
import { BackButton } from "@digits-shared/DesignSystem/BackButton"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { Content } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/Content"
import { FailureBanner } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/FailureBanner"
import {
  MODAL_HEIGHT,
  MODAL_RADIUS,
  MODAL_WIDTH,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/shared"
import {
  DEFAULT_STATE,
  reducer,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/reducer"
import { MonitoredModalName } from "src/frontend/components/Shared/ManagedModals/state/types"
import { useMonitorModal } from "src/frontend/components/Shared/ManagedModals/useMonitorModal"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const StyledConfetti = styled(Confetti)`
  position: absolute;
  inset: 0;
  z-index: -1;
  pointer-events: none;
`

const StyledModal = styled(Modal)`
  box-shadow: 0 0 30px ${colors.translucentBlack20};
  background: none;
  border: none;
  width: unset;
  height: unset;
  position: relative;

  border-radius: ${MODAL_RADIUS}px;
  padding: 0;

  ${ModalAnchor} {
    height: 100%;
  }

  &::before {
    border: none;
  }
`

const ModalContent = styled.div`
  position: relative;
  width: ${MODAL_WIDTH}px;
  height: ${MODAL_HEIGHT}px;
`

const AboveModal = styled.div`
  position: absolute;
  bottom: 100%;
`

const StyledBackButton = styled(BackButton)`
  margin-bottom: 20px;
`

/*
  COMPONENTS
*/

export const InviteClientModal: React.FC<{
  onClose: () => void
  onDone: () => void
  arrivalContext?: ArrivalContext
  contextObject?: ObjectIdentifier
  backMessage?: string
  showViewUsers: boolean
}> = ({ onClose, onDone, arrivalContext, contextObject, backMessage, showViewUsers }) => {
  const { isAffiliatedSession } = useSession<FrontendSession>()
  const modalRoot = useModalRoot()
  const [state, dispatch] = React.useReducer(reducer, {
    ...DEFAULT_STATE,
    modalState: InviteClientsModalState.InviteClients,
    arrivalContext,
    contextObject,
    showViewUsers,
  })

  // monitor modal to prevent snackbars from showing while modal is open
  useMonitorModal(MonitoredModalName.InviteClient)

  const backButtonMsg = backMessage || "Back to Dashboard"

  React.useEffect(() => {
    dispatch({ type: "setIsAffiliatedSession", isAffiliatedSession })
  }, [isAffiliatedSession])

  return ReactDOM.createPortal(
    <StyledModal>
      <ModalContent>
        <AboveModal>
          {state.modalState !== InviteClientsModalState.Done && (
            <StyledBackButton onClick={onClose}>{backButtonMsg}</StyledBackButton>
          )}
          <FailureBanner message={state.errorMessage} />
        </AboveModal>
        <Content dispatch={dispatch} state={state} onDone={onDone} onClose={onClose} />
      </ModalContent>
      {state.modalState === InviteClientsModalState.Done && (
        <React.Suspense>
          <StyledConfetti id="invite-client" type={CONFETTI_TYPE.CENTER} />
        </React.Suspense>
      )}
    </StyledModal>,
    modalRoot
  )
}
