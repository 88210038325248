import * as React from "react"
import useScrollContext from "@digits-shared/hooks/useScrollContext"
import styled, { css } from "styled-components"
import { EXPANDED_CLASS_NAME } from "src/frontend/components/OS/Applications/ApplicationExpandablePane"
import { APPLICATION_PANE_FULL_WIDTH } from "src/frontend/components/OS/Applications/ApplicationPane"
import { ChromeScrollMemory } from "src/frontend/components/Shared/Chrome/ChromeScrollMemory"
import { SIDEBAR_COLLAPSED_WIDTH } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"

const ScrollableContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  flex: 1;
  overflow: auto;
  padding: 0 0;

  @media only screen and (min-width: ${SIDEBAR_COLLAPSED_WIDTH + APPLICATION_PANE_FULL_WIDTH}px) {
    // dont change this here, if you really have to, use "styled(FullScreenScrollableContainer)"
    width: calc(100vw - var(--sidebar-content-offset, 0px));
    margin-left: 50%;
    transform: translateX(-50%);
  }

  display: flex;

  &:before,
  &:after {
    content: "";
    display: block;
    flex: 1;
  }

  & > div {
    container-type: inline-size;
    container-name: scrollable-container-contents;

    ${({ fullWidth }) => css`
      width: var(${fullWidth ? "--application-pane-full-width" : "--application-pane-width"}, 0px);
    `}

    .${EXPANDED_CLASS_NAME} & {
      width: var(--application-full-width, 100%);
      min-width: var(--application-pane-full-width);
      max-width: 1920px;
    }

    height: fit-content;
    padding-bottom: 30px;
  }
`

interface ScrollableContainerProps {
  className?: string
  children?: React.ReactNode
  fullWidth?: boolean
}

export const FullScreenScrollableContainer: React.FC<ScrollableContainerProps> = ({
  className,
  children,
  fullWidth,
}) => (
  <ChromeScrollMemory>
    <ScrollableContainerWithContext className={className} fullWidth={fullWidth}>
      {children}
    </ScrollableContainerWithContext>
  </ChromeScrollMemory>
)

const ScrollableContainerWithContext: React.FC<ScrollableContainerProps> = ({
  className,
  children,
  fullWidth,
}) => {
  const { scrollElement } = useScrollContext()
  return (
    <ScrollableContainer ref={scrollElement} className={className} fullWidth={fullWidth}>
      <div>{children}</div>
    </ScrollableContainer>
  )
}
