import * as React from "react"
import { interpolateRgbBasis } from "d3-interpolate"
import { RunwayLineChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import {
  type TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  RUNWAY_GRADIENT,
  runwayColors,
  useCashOutMonthsLeft,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"
import { ComponentZeroState } from "src/frontend/components/Shared/Layout/Shared"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { type Runway } from "src/frontend/types"

/*
  INTERFACES
*/

interface RunwayLineChartComponentProps extends SizingProps {
  runway?: Runway
  skipAnimations: boolean
  onMouseOver?: (value: TimeseriesValue, index: number) => void
  onMouseOut?: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  preview?: boolean
}

/*
  COMPONENTS
*/

export const RunwayLineChartComponent = React.memo<RunwayLineChartComponentProps>(
  ({ runway, height, skipAnimations, onClick, onMouseOver, onMouseOut, preview }) => {
    const currentTimeseries = React.useMemo(
      () => toSortedTimeseriesValues(runway?.current),
      [runway]
    )

    const futureTimeseries = React.useMemo(
      () => toSortedTimeseriesValues(runway?.future),
      [runway?.future]
    )
    const fullTimeseries = React.useMemo(
      () => currentTimeseries.concat(futureTimeseries),
      [currentTimeseries, futureTimeseries]
    )

    const lineGradientStops = useComputeGradientStops(runway, currentTimeseries, fullTimeseries)
    const chartStyle = { ...RunwayLineChartStyles, lineGradientStops }

    const noActivity = React.useMemo(
      () => fullTimeseries.every((value) => value.moneyFlow.value.amount === 0),
      [fullTimeseries]
    )
    if (noActivity) {
      return (
        <ChartContainer height={height} width="auto" css="margin: 0 5px">
          <ComponentZeroState />
        </ChartContainer>
      )
    }

    return (
      <ChartContainer height={height} width="auto" css="margin: 0 5px">
        <ParentSizedTimeseriesLineChart
          timeseries={fullTimeseries}
          skipAnimations={skipAnimations}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          hideGrid={preview}
          noTooltip={preview}
          hideAxis={preview}
          chartStyle={chartStyle}
          projectionAfter={currentTimeseries[currentTimeseries.length - 1]?.period ?? undefined}
        />
      </ChartContainer>
    )
  }
)

// Approximate a gradient based on number of months until cash out for each point.
function useComputeGradientStops(
  runway?: Runway,
  currentTimeseries?: TimeseriesValue[],
  fullTimeseries?: TimeseriesValue[]
) {
  const monthsLeft = useCashOutMonthsLeft(runway)
  const redToGreen = React.useMemo(() => interpolateRgbBasis(RUNWAY_GRADIENT), [])

  const gradientStops = React.useMemo(() => {
    if (!runway || !currentTimeseries?.length || !monthsLeft) return undefined
    const estimatedCurrentPeriodNetBurn =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentTimeseries[currentTimeseries.length - 1]!.moneyFlow.value.amount / monthsLeft

    return fullTimeseries?.map(({ moneyFlow }) => {
      const estimatedMonthsLeft = moneyFlow.value.amount / estimatedCurrentPeriodNetBurn

      // Gradient is red at 0, 24 months is solid green.
      return redToGreen(Math.min(1, estimatedMonthsLeft / 24))
    })
  }, [runway, currentTimeseries, monthsLeft, fullTimeseries, redToGreen])

  if (!runway || !currentTimeseries) return undefined
  if (runway?.cashOutDate && monthsLeft === 0) return [runwayColors.yellow, runwayColors.red] // running out of cash imminently
  if (!runway?.cashOutDate || !monthsLeft) return [runwayColors.green, runwayColors.green] // never running out of cash

  return gradientStops?.length ? gradientStops : undefined
}
