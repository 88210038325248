import { EMPTY, filter, switchMap, tap, withLatestFrom } from "rxjs"
import { type ModalManagerAction } from "src/frontend/components/Shared/ManagedModals/state/actions"
import {
  makeModalStorageId,
  makeSnackbarStorageId,
} from "src/frontend/components/Shared/ManagedModals/state/storageIds"
import { type ModalManagerState } from "src/frontend/components/Shared/ManagedModals/state/types"
import { setLocalPreference } from "src/frontend/components/Shared/ManagedModals/storage"
import { actionType, createEffect } from "src/frontend/state/effectsMiddleware"

export const closeModal = createEffect<ModalManagerState, ModalManagerAction>((actions, states) =>
  actions.pipe(
    filter(actionType("CloseModal", "CloseSnackbar")),
    withLatestFrom(states),
    tap(([action, state]) => {
      if (action.dismissal === "Persistent" && action.legalEntityId) {
        const identifier =
          action.type === "CloseModal"
            ? makeModalStorageId(action.name)
            : makeSnackbarStorageId(action.name)
        setLocalPreference(identifier, action.legalEntityId)
      }
    }),
    switchMap(() => EMPTY)
  )
)
