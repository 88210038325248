import * as React from "react"
import { InsightComponent } from "src/frontend/components/Shared/Layout/Components/Insights/InsightComponent"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { useEntityPopOver } from "src/frontend/components/Shared/Layout/PopOver/EntityPopOver"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface ComponentProps extends SizingProps {
  component: MatchedComponent<"insight">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const InsightPortalComponent: React.FC<ComponentProps> = ({
  component,
  componentSize,
  height,
}) => {
  const layoutId = usePortalStore((state) => state.layout.layoutId)
  const config = useConfigOriginOverride(component.config.insight, 1, 12)

  return (
    <InsightComponent
      layoutId={layoutId}
      component={component}
      configOverride={config}
      componentSize={componentSize}
      height={height}
      hasHeader
      entityPopOver={useEntityPopOver}
    />
  )
}
