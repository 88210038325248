import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgBarChartSquarePlus } from "@digits-shared/components/SVGIcons/line/BarChartSquarePlus.svg"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { RequiresUpdate } from "src/frontend/components/Shared/Permissions/Requires"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"
import { ActivePeriodContainer } from "src/shared/components/PeriodSelector/ActivePeriod"
import { PeriodSelector } from "src/shared/components/PeriodSelector/PeriodSelector"

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const ToggleContainer = styled.div`
  position: relative;
`

const EditIcon = styled(SvgBarChartSquarePlus)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const PeriodSelectorWrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  align-items: center;

  ${ActivePeriodContainer} {
    font-size: 20px;
  }
`

export const LayoutHeader: React.FC = () => (
  <Container>
    <PeriodSelectorWrapper>
      <PeriodSelector />
    </PeriodSelectorWrapper>

    <RequiresUpdate
      source={FrontendPermissionSource.LegalEntity}
      module={FrontendPermissionModule.Layouts}
    >
      <EditLayoutToggle />
    </RequiresUpdate>
  </Container>
)

const EditLayoutToggle: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  const { currentLegalEntity } = useSession<FrontendSession>()
  const isEditing = useIsEditLayoutActive()

  return (
    <ToggleContainer>
      {isEditing ? (
        <DigitsLinkButton
          width={124}
          to={generatePath(routes.legalEntityHome, { leSlug: currentLegalEntity?.slug })}
        >
          Done
        </DigitsLinkButton>
      ) : (
        <DigitsLinkButton
          $variant="secondary-dark"
          to={generatePath(routes.legalEntityHomeEdit, {
            leSlug: currentLegalEntity?.slug,
          })}
        >
          <EditIcon />
          Edit
        </DigitsLinkButton>
      )}
    </ToggleContainer>
  )
}
