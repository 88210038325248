import { type Layout } from "@digits-graphql/frontend/graphql-bearer"
import { type QueryRequest } from "src/frontend/components/OS/Applications/Search/QueryBuilder"
import {
  type ComponentMap,
  type DeleteComponentAnimation,
  type PendingDeleteComponent,
  type Sidebar,
  type SortableComponentConfig,
} from "src/frontend/components/Shared/Layout/types"

export enum PortalMode {
  // We're fetching the portal
  Pending = "Pending",

  // We're provisioning the initial portal and layout
  Bootstrap = "Bootstrap",

  // The portal is ready to use
  Ready = "Ready",
}

export enum InviteClientsModalState {
  Initial = "Initial",
  MyClients = "MyClients",
  InviteClients = "InviteClients",
  SendingInvites = "SendingInvites",
  Done = "Done",
}

export interface PortalState {
  portalMode: PortalMode
  confirmDeleteComponent: PendingDeleteComponent | undefined
  deleteComponentAnimation: DeleteComponentAnimation | undefined
  dropAllowed: boolean
  componentMap: ComponentMap
  layout: Layout
  layoutLoading: boolean
  sidebar: Sidebar
  activeConfig: SortableComponentConfig | null
  activeComponentId: string | null
  dragOverlayContent: React.JSX.Element | null
  textInFocus: boolean
  dirty: boolean
  showInviteClientsModal: boolean
  query: QueryRequest
  emptySearch: boolean
}

export const PORTAL_CONFIG = {
  maxComponentsPerRow: 4,
  rowWidth: 1080,
}
