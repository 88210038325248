import * as React from "react"
import {
  type EntityHoverFieldsFragment,
  type TextTagOptions,
} from "@digits-graphql/frontend/graphql-bearer"
import { EntityCategoryPopOver } from "src/frontend/components/Shared/Layout/PopOver/EntityCategoryPopOver"
import { EntityPartyPopOver } from "src/frontend/components/Shared/Layout/PopOver/EntityPartyPopOver"
import { type HoverableEntity } from "src/shared/components/ObjectEntities/entitiesParserShared"
import {
  type EntityHover,
  type EntityPopOverComponent,
  type EntityProps,
} from "src/shared/components/ObjectEntities/entityPopOverTypes"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface PopOverProps {
  entity: HoverableEntity
  hovers: EntityHover[] | undefined
  options: TextTagOptions | undefined
  className?: string
}

interface HoverProps {
  hoverData: EntityHover
}

/*
  COMPONENTS
*/

export function useEntityPopOver(hovers: EntityHover[] | undefined): EntityPopOverComponent {
  return React.useMemo(
    () => ({
      Component: ({ entity, options }: EntityProps) => (
        <PopOverByEntityType entity={entity} hovers={hovers} options={options} />
      ),
    }),
    [hovers]
  )
}

const PopOverByEntityType: React.FC<PopOverProps> = ({ entity, hovers, options }) => {
  const hoverData = useFindHoverData(entity, options, hovers)
  if (!hoverData || hoverData.__typename === "SummaryLineItemHover") return null

  return <DetailsByType hoverData={hoverData} />
}

const DetailsByType: React.FC<HoverProps> = ({ hoverData }) => {
  switch (hoverData.__typename) {
    case "CategoryHover":
      return <EntityCategoryPopOver hoverData={hoverData} />
    case "PartyHover":
      return <EntityPartyPopOver hoverData={hoverData} />
  }
  return null
}

function useFindHoverData(
  entity: HoverableEntity,
  options?: TextTagOptions,
  hovers?: EntityHoverFieldsFragment[]
) {
  return React.useMemo(
    () =>
      hovers?.find((hover) => {
        let sameId = false
        switch (hover.__typename) {
          case "CategoryHover":
          case "PartyHover":
            sameId = hover.entity.id === entity.id
            break
        }
        if (!options) return sameId

        switch (hover.__typename) {
          case "CategoryHover":
            return sameId
          case "PartyHover":
            if (!options.partyRole || !hover.entity.roles) return sameId
            return sameId && hover.entity.roles.includes(options.partyRole)
        }
        return sameId
      }),
    [entity.id, hovers, options]
  )
}
