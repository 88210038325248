import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  type EntityLocation,
  Flow,
  Interval,
  type IntervalOrigin,
  type SummarizeTransactionsByTimeQueryVariables,
} from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import styled from "styled-components"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import routes from "src/frontend/routes"
import MatchedText from "./MatchedText"
import { ResultRow, ResultTitle, type RowProps } from "./ResultRow"
import SearchResultActivitySummary from "./SearchResultActivitySummary"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
  align-items: center;
`

const LocationTitle = styled(ResultTitle)`
  flex: 1;
`

/*
  COMPONENT
*/

const LocationResult: React.FC<RowProps<EntityLocation>> = ({ entity, hit }) => {
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)
  const path = routes.locationDetails.generateFromCurrentPath({
    locationId: entity.id,
    ...origin,
  })

  return (
    <ResultRow to={path}>
      <Details>
        <ColorIcon fallbackText={entity.name} css="margin-right: 10px;" />
        <LocationTitle>
          <MatchedText fieldName="name" highlights={hit.highlights}>
            {entity.name}
          </MatchedText>
        </LocationTitle>
        <LocationSummary location={entity} origin={origin} />
      </Details>
    </ResultRow>
  )
}

export default LocationResult

interface SummaryProps {
  location: EntityLocation
  origin: IntervalOrigin
}

const LocationSummary: React.FC<SummaryProps> = ({ location, origin }) => {
  const viewKey = useViewVersion()

  const variables: SummarizeTransactionsByTimeQueryVariables = {
    filter: {
      viewKey,
      locationIds: [location.id],
      categoryTypes: [
        CategoryType.Expenses,
        CategoryType.OtherExpenses,
        CategoryType.CostOfGoodsSold,
        CategoryType.Income,
        CategoryType.OtherIncome,
      ],
    },
    directBookings: true,
    // Locations are viewed from the lens of profit, so we must specify Income based money flow
    naturalFlow: Flow.Inbound,
    defaultCategoryType: CategoryType.Income,
    origin,
    direction: DirectionFromOrigin.Past,
  }

  return <SearchResultActivitySummary variables={variables} />
}
