import * as React from "react"
import {
  type ObjectEntities,
  ObjectKind,
  type ObjectKindFacet,
  type SearchHit,
  type SearchResult,
  type SearchResultFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import useSearchContext from "src/frontend/components/OS/Applications/Search/SearchContext"
import { SearchResultType } from "src/frontend/types/SearchResultType"

type ResultsMap = Map<SearchResultType, SearchHit[]>

export function useMapResultsByType(search: SearchResultFieldsFragment | undefined): ResultsMap {
  const {
    query: { occurredAfter, occurredBefore },
  } = useSearchContext()

  return React.useMemo(() => {
    const sections = new Map<SearchResultType, SearchHit[]>()
    if (!search) return sections

    const { results, entities } = search

    results.reduce((map, hit) => {
      const types = searchHitToResultTypes(hit, entities)
      if (!types || !types.length) return map

      types.forEach((type) => {
        let hits = map.get(type)
        if (!hits) {
          hits = []
          map.set(type, hits)
        }
        hits.push(hit)
      })

      return map
    }, sections)

    // if there are any filters (time) and no results, add an empty section to allow to reset them.
    if (!sections.size && (occurredAfter || occurredBefore)) {
      sections.set(SearchResultType.Transaction, [])
    }

    return sections
  }, [search, occurredAfter, occurredBefore])
}

function searchHitToResultTypes(hit: SearchHit, entities: ObjectEntities) {
  const { kind } = hit.objectId

  switch (kind) {
    case ObjectKind.Transaction:
    case ObjectKind.Report:
    case ObjectKind.Comment:
    case ObjectKind.Category:
    case ObjectKind.User:
    case ObjectKind.Invoice:
    case ObjectKind.Department:
    case ObjectKind.Location:
    case ObjectKind.Party:
      return [SearchResultType[kind]]

    case ObjectKind.Thread:
    case ObjectKind.DataSource:
    case ObjectKind.LegalEntity:
    case ObjectKind.ReportPackage:
    case ObjectKind.WorkItem:
    case ObjectKind.Portal:
    case ObjectKind.Bill:
    case ObjectKind.LedgerTransaction:
      TrackJS?.console.error("Invalid Entity Kind in search results:", kind)
      return undefined
  }
}

export function useHitsCount(search: SearchResult): ObjectKindFacet | undefined {
  const { kindCounts } = search
  const {
    query: { kind },
  } = useSearchContext()
  return kindCounts.find((kc) => kc.kind === kind)
}
