import * as React from "react"
import { useComponentContext } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/LayoutViewer/ComponentContext"
import { matchConfigWithOrigin } from "src/frontend/components/Shared/Layout/types"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useComponentIntervalOrigin(customIntervalCount?: number) {
  const fallbackOrigin = useIntervalOrigin()
  const { component } = useComponentContext()

  return React.useMemo(() => {
    const { origin: matchedOrigin } = matchConfigWithOrigin(component.config)
    const origin = matchedOrigin ?? fallbackOrigin
    const { year, interval, index } = origin

    let intervalCount = customIntervalCount
    if (!intervalCount && origin.intervalCount && origin.intervalCount > 1) {
      intervalCount = origin.intervalCount
    }

    return { year, interval, index, intervalCount }
  }, [component.config, customIntervalCount, fallbackOrigin])
}
