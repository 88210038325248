import * as React from "react"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { LogoFooter } from "src/frontend/components/Shared/NavSidebar/Footer/LogoFooter"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import { useOperatorBillingPlan } from "src/frontend/components/Shared/PLG/useOperatorBillingPlan"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

const LazySidebarOnboardingFooter = React.lazy(
  () => import("src/frontend/components/Shared/NavSidebar/WelcomeModal/SidebarOnboardingFooter")
)

export const OnboardingFooter: React.FC = () => {
  const hasPermissions = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    // TODO: replace with more direct check
    module: FrontendPermissionModule.Quotes,
    flag: JWTPermissionFlag.Read,
  })

  return hasPermissions ? <FooterWithModal /> : <LogoFooter />
}

const FooterWithModal: React.FC = () => {
  const operatorBillingPlan = useOperatorBillingPlan()
  return operatorBillingPlan.hasActiveTrial ? (
    <React.Suspense fallback={<LogoFooter />}>
      <LazySidebarOnboardingFooter />
    </React.Suspense>
  ) : (
    <LogoFooter />
  )
}
