/* eslint-disable max-nested-callbacks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from "@apollo/client/utilities"
import envHelper from "@digits-shared/helpers/envHelper"
import { BehaviorSubject, catchError, type Observable, Subject, type Subscription } from "rxjs"
import { type ModuleNamespace } from "vite/types/hot"
import { type StateCreator, type StoreMutatorIdentifier } from "zustand/vanilla"

/**
 * A middleware that implements an RxJS-based effects system for managing side-effects and async work
 * in concert with a Zustand store. Modeled after https://ngrx.io/guide/effects
 */
declare module "zustand/vanilla" {
  interface StoreMutators<S, A> {
    "digits/effects": WithEffects<S, A>
  }
}

type Action = { type: string }

type Write<T, U> = Omit<T, keyof U> & U

type WithEffects<S, A> = Write<S, StoreEffects<A>>

type StoreEffects<A> = {
  dispatch: (a: A) => A
}

type Effects<State extends object, Actions extends Action> = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  initializer: StateCreator<T, [...Mps, ["digits/effects", never]], Mcs>,
  effects: Effect<State, Actions>[]
) => StateCreator<T, Mps, [["digits/effects", never], ...Mcs]>

declare module "zustand/vanilla" {
  interface StoreMutators<S, A> {
    "digits/effects": WithEffects<S, A>
  }
}

export type ActionBundle<Actions extends Action> = Actions | Actions[]

export type Effect<State extends object, Actions extends Action> = (
  states: Observable<State>
) => (actions: Observable<Actions>) => Observable<ActionBundle<Actions>>

class ReloadableEffects<State extends object, Actions extends Action> {
  private subscriptions: Subscription[] = []

  private actions: Subject<Actions>
  private states: BehaviorSubject<State>

  // The dispatch implementation that we get from the redux middleware
  private baseDispatch: any

  constructor() {
    this.actions = new Subject<Actions>()
  }

  wrappedDispatch(action: Actions) {
    this.baseDispatch(action)
    this.actions.next(action)
    return action
  }

  reload(mod: ModuleNamespace) {
    const effects = Object.keys(mod).reduce(
      (effectsList, key) => {
        effectsList.push(mod[key])
        return effectsList
      },
      [] as Effect<State, Actions>[]
    )

    this.registerEffects(effects)
  }

  registerEffects(effects: Effect<State, Actions>[]) {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe()
    })

    const statesSubj = this.states
    const actionsSubj = this.actions
    try {
      effects.forEach((effectCtor) => {
        // Create a self-healing observable for the effect that is resilient to errors
        // the effect may produce.
        function createObservable(): Observable<ActionBundle<Actions>> {
          return effectCtor(statesSubj)(actionsSubj).pipe(
            catchError((e) => {
              if (!envHelper.isProduction()) {
                console.error(e)
              } else {
                TrackJS?.track(e)
              }
              // If there's an error, recreate the observable and return it so that
              // the effect may continue to operate.
              return createObservable()
            })
          )
        }

        // Subscribe to the effect observable and dispatch the actions it produces back against the store.
        this.subscriptions.push(
          createObservable().subscribe((action) => {
            setTimeout(() => {
              if (isArray(action)) {
                action.forEach((act) => this.wrappedDispatch(act))
              } else {
                this.wrappedDispatch(action)
              }
            }, 0)
          })
        )
      })
    } catch (e) {
      if (!envHelper.isProduction()) {
        console.error(e)
      } else {
        TrackJS?.track(e)
      }
    }
  }

  middleware: Effects<State, Actions> =
    (storeInitializer, effects) =>
    (...a) => {
      const [set, get, api] = a

      const initialState = storeInitializer(set as any, get as any, api as any)

      this.states = new BehaviorSubject<State>(initialState as unknown as State)

      api.subscribe((state) => {
        this.states.next(state as unknown as State)
      })

      this.baseDispatch = (api as any).dispatch
      ;(api as any).dispatch = this.wrappedDispatch.bind(this)

      this.registerEffects(effects)

      return {
        dispatch: (action: Actions) => this.wrappedDispatch.bind(this)(action),
        ...initialState,
      }
    }
}

// The publicly exposed function to use as zustand middleware
export function createEffects<State extends object, Actions extends Action>() {
  return new ReloadableEffects<State, Actions>()
}

/**  */
export function createEffect<State extends object, Actions extends Action>(
  effectSetup: (
    actions: Observable<Actions>,
    states: Observable<State>
  ) => Observable<ActionBundle<Actions>>
): Effect<State, Actions> {
  return (states: Observable<State>) => (actions: Observable<Actions>) =>
    effectSetup(actions, states)
}

type ActionTypes<Actions extends Action> = Actions["type"]

type SpecificAction<Actions extends Action, T extends ActionTypes<Actions>> = Actions & { type: T }

export function actionType<Actions extends Action, T extends ActionTypes<Actions>>(
  ...typeNames: T[]
): (action: Actions) => action is SpecificAction<Actions, T> {
  return (action: Actions): action is SpecificAction<Actions, T> =>
    typeNames.some((type) => type === action.type)
}
