// Known permission modules in which access can be controlled. Should be kept up to date with
// https://github.com/digits/go-services/blob/main/shared/auth/user/modules.go
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"

export enum FrontendPermissionModule {
  Accounting = "accounting",
  ActionItems = "action-items",
  ActiveViewVersions = "active-view-versions",
  Activity = "activity",
  Affiliations = "affiliations",
  Aspects = "aspects",
  BillPayments = "bill-payments",
  Billing = "billing",
  Bills = "bills",
  ChartOfAccounts = "chart-of-accounts",
  Comments = "comments",
  Connections = "connections",
  ConnectionAccounts = "connection-accounts",
  DataSources = "data-sources",
  Departments = "departments",
  Locations = "locations",
  Employees = "employees",
  IdentityVerification = "identity-verification",
  Invitations = "invitations",
  Invoices = "invoices",
  Layouts = "layouts",
  LegalEntities = "legal-entities",
  Notifications = "notifications",
  Organizations = "organizations",
  Payments = "payments",
  Portals = "portal",
  Quotes = "quotes",
  Recurrence = "recurrence",
  Reports = "reports",
  Search = "search",
  SecureFiles = "secure-files",
  Teams = "teams",
  Transactions = "transactions",
  Vendors = "vendors",
  WorkItems = "work-items",
}

export enum FrontendPermissionSource {
  Organization = "Organization",
  LegalEntity = "LegalEntity",
}

export function objectKindToModule(kind: ObjectKind): FrontendPermissionModule {
  switch (kind) {
    case ObjectKind.Category:
      return FrontendPermissionModule.ChartOfAccounts
    case ObjectKind.Comment:
      return FrontendPermissionModule.Comments
    case ObjectKind.DataSource:
      return FrontendPermissionModule.DataSources
    case ObjectKind.Invoice:
      return FrontendPermissionModule.Invoices
    case ObjectKind.LegalEntity:
      return FrontendPermissionModule.LegalEntities
    case ObjectKind.Party:
      return FrontendPermissionModule.Vendors
    case ObjectKind.Report:
    case ObjectKind.ReportPackage:
      return FrontendPermissionModule.Reports
    case ObjectKind.Thread:
      return FrontendPermissionModule.Comments
    case ObjectKind.Transaction:
    case ObjectKind.LedgerTransaction:
      return FrontendPermissionModule.Transactions
    case ObjectKind.User:
      return FrontendPermissionModule.Employees
    case ObjectKind.WorkItem:
      return FrontendPermissionModule.WorkItems
    case ObjectKind.Portal:
      return FrontendPermissionModule.Portals
    case ObjectKind.Department:
      return FrontendPermissionModule.Departments
    case ObjectKind.Location:
      return FrontendPermissionModule.Locations
    case ObjectKind.Bill:
      return FrontendPermissionModule.Bills
  }
}
