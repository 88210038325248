import * as React from "react"
import SearchBox, { ClearBox, SearchIcon } from "@digits-shared/components/UI/Elements/SearchBox"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const SearchBoxStyled = styled(SearchBox)<{ translateDown: boolean }>`
  border-color: transparent;
  color: ${colors.secondary};
  background: ${colors.translucentSecondary05};

  && {
    ${SearchIcon} {
      display: none;
    }
  }

  height: 40px;
  padding: 1px 12px;
  width: 500px;

  input::placeholder {
    font-size: inherit;
    text-transform: unset;
  }

  ${ClearBox} {
    font-size: 20px;
  }
`

const SearchingIcon = styled.img`
  padding: 2px;
  height: 26px;
`

/*
  INTERFACES
*/

interface Props {
  className?: string
  initialValue?: string
  isSearchActive?: boolean
  isSearchRequestInFlight?: boolean
  onSubmit: (term: string) => void
  onClear?: () => void
  isDisabled?: boolean
  autoFocus?: boolean
}

/*
  COMPONENTS
*/

export const DigitsSearchBox: React.FCWithRef<HTMLInputElement, Props> = ({
  ref,
  className,
  initialValue,
  isSearchActive,
  isSearchRequestInFlight,
  onSubmit,
  onClear,
  isDisabled,
  autoFocus = true,
}) => {
  const [showSpinner, setShowSpinner] = React.useState(isSearchRequestInFlight)
  const { currentLegalEntity } = useSession<FrontendSession>()

  // Delaying hiding the spinner for no good reason other than we have a spinner so lets use it.
  React.useEffect(() => {
    if (isSearchRequestInFlight) return setShowSpinner(true)

    const timer = setTimeout(() => setShowSpinner(false), 1200)
    return () => {
      clearTimeout(timer)
    }
  }, [isSearchRequestInFlight])

  return (
    <SearchBoxStyled
      ref={ref}
      className={className}
      translateDown={!isSearchActive}
      initialValue={initialValue}
      placeholder={`Search ${currentLegalEntity.name}`}
      onChange={onSubmit}
      onSubmit={onSubmit}
      onClear={onClear}
      shouldDebounceOnChange
      noClear={showSpinner}
      disabled={isDisabled}
      autoFocus={autoFocus}
    >
      {showSpinner && <SearchingIcon src={require("static/images/shared/loader.gif")} />}
    </SearchBoxStyled>
  )
}
