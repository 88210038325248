import { PopUp } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
  STYLES
*/

export const DetailsPopUp = styled(PopUp)`
  display: block;
  font-size: 12px;
  background: ${colors.white};
  margin: 0 0 50px 10px;
  border: none;
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.5);
`
