import * as React from "react"
import {
  type IntervalOrigin,
  LayoutComponentType,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { BreakdownChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BreakdownChartComponent"
import { CashBadge } from "src/frontend/components/Shared/Layout/Components/Charts/CashBadge"
import {
  type Timeseries,
  type TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { type EntityCategoryTransactionSummaryHistory } from "src/frontend/types"

/*
  INTERFACES
*/

interface ChartComponentProps extends SizingProps {
  componentSize: ComponentSize
  skipAnimations: boolean
  origin: IntervalOrigin
  totalCash: TransactionSummary[] | undefined
  breakdownSummaries: EntityCategoryTransactionSummaryHistory[] | undefined
}

/*
  COMPONENTS
*/

export const CashflowChartComponent: React.FC<ChartComponentProps> = ({
  totalCash,
  breakdownSummaries,
  origin,
  componentSize,
  height,
  skipAnimations,
}) => {
  const { history } = useRouter()
  const metricPath = useComponentDetailsLinkPath(LayoutComponentType.CashSummaryChart, origin)

  const totalTimeseries: Timeseries = React.useMemo(
    () => ({ label: "Net Cash", values: toSortedTimeseriesValues(totalCash) }),
    [totalCash]
  )

  const breakdownTimeseries = React.useMemo<Timeseries[]>(
    () =>
      (breakdownSummaries ?? []).map(({ category, history: summary }) => ({
        label: category.name,
        values: toSortedTimeseriesValues(summary),
      })),
    [breakdownSummaries]
  )

  const onBarClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      if (!metricPath) return
      const path = urlHelper.addSearchParams(metricPath, { highlight: index })
      if (!path) return
      history.push(path)
    },
    [metricPath, history]
  )

  const title = React.useMemo(
    () => (
      <ComponentExpandLink to={metricPath} linkBadge={<CashBadge />} componentSize={componentSize}>
        Cash Flow
      </ComponentExpandLink>
    ),
    [metricPath, componentSize]
  )

  return (
    <BreakdownChartComponent
      title={title}
      icon={undefined}
      breakdownTimeseries={breakdownTimeseries}
      totalTimeseries={totalTimeseries}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
      onBarClick={onBarClick}
    />
  )
}
