import * as React from "react"
import {
  DirectionFromOrigin,
  InsightSubjectType,
  Interval,
  ProductArea,
  type TransactionSummary,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import styled from "styled-components"
import { type InsightChip } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"
import SparkBarChart from "src/frontend/components/OS/Shared/Charts/SparkBarChart"
import { ProductAreaContext } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
/*
  STYLES
*/

const ChartSizing = styled.div`
  position: absolute;
  top: 50px;
  right: 20px;
  width: 175px;
  height: 64px;
`

const StyledSparkBarChart = styled(SparkBarChart)`
  margin: 0;
`

/*
  COMPONENTS
*/

export const InsightPopUpChart: React.FC<{ insightChip: InsightChip }> = ({ insightChip }) => {
  const { insight } = insightChip
  const { productArea: insightProductArea, subjectType, subjectId, period, type } = insight
  // if the product area is null we want to default to All which is what the SummarizeTransactions endpoint expects
  // if we want to summarize by a non product area pivot
  const productArea = insightProductArea ?? ProductArea.All
  const categoryId = subjectType === InsightSubjectType.Category ? subjectId : undefined
  const partyId = subjectType === InsightSubjectType.Party ? subjectId : undefined

  const viewKey = useViewVersion()

  // the party role is needed for transaction summaries only for party insights
  const partyRoleFromInsightType =
    subjectType === InsightSubjectType.Party
      ? FrontendPartyRole.findByInsightType(type).partyRole
      : undefined

  const { data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: {
        ...dateTimeHelper.todayIntervalOrigin(Interval.Month),
        intervalCount: 12,
      },
      filter: {
        viewKey,
        categoryId,
        productArea,
        partyId,
        partyRole: partyRoleFromInsightType,
      },
      direction: DirectionFromOrigin.Past,
    },
    context: { avsBatch: true },
  })

  const summaries: TransactionSummary[] | undefined = React.useMemo(
    () => data?.dimensionalTransactionSummary.time.map((t) => t.summary),
    [data?.dimensionalTransactionSummary.time]
  )

  const series = React.useMemo(
    () =>
      summaries
        ?.flatMap((item) => {
          const periodMoney = item.total
          return {
            x: item.period,
            y: periodMoney.moneyFlow || moneyFlowHelper.buildZeroMoneyFlow(),
            deemphasized: !dateTimeHelper.arePeriodsEqual(item.period, period),
          }
        })
        .reverse(),
    [period, summaries]
  )

  if (!series?.length) return null

  return (
    <ProductAreaContext.Provider value={productArea}>
      <InvertValuesContext.Provider value={productArea === ProductArea.Expenses}>
        <ChartSizing>
          <StyledSparkBarChart series={series} />
        </ChartSizing>
      </InvertValuesContext.Provider>
    </ProductAreaContext.Provider>
  )
}
