export interface ModalManagerState {
  /** The name of the currently active managed modal */
  modalName: ManagedModalName | null
  /** The set of managed modals that have been dismissed */
  modalDismissals: Set<ManagedModalName>
  /** The set of managed ssnackbars that have been dismissed */
  snackbarDismissals: Set<ManagedSnackbarName>
  /** A map of monitored modals and their latest state */
  monitoredModals: Map<MonitoredModalName, boolean>
  /** A boolean indicating if any of the monitored modals are open */
  monitoredModalOpen: boolean
}

export enum Managee {
  Modal = "M",
  Snackbar = "S",
}

/**
 * Modals whose visibility is actively controlled
 * Renaming enum member names is ok, but avoid renaming enum
 * member values as they might be tracked in local storage
 */
export enum ManagedModalName {
  WelcomeModal = "WelcomeModal",
}

/**
 * Modals whose visibility is passively observed
 */
export enum MonitoredModalName {
  TermsOfService = "TermsOfService",
  InviteClient = "InviteClient",
}

/**
 * Snackbars that show deference to both ManagedModals and MonitoredModals
 */
export enum ManagedSnackbarName {
  ConnectAlert = "ConnectAlert",
  FinancialsUpsell = "FinancialsUpsell",
  TransactionsUpsell = "TransactionsUpsell",
}

export type Dismissal = "Session" | "Persistent"
