import * as React from "react"
import { type StatementDeltas, type Transaction } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { toStatementDeltas } from "src/frontend/components/Shared/Layout/Components/Statements/toStatementDeltas"
import { OverTimeChart } from "src/frontend/components/Shared/Layout/PopOver/DetailsPopOver/OverTimeChart"
import {
  PopOverTab,
  PopOverTabs,
  type TabProps,
} from "src/frontend/components/Shared/Layout/PopOver/DetailsPopOver/PopOverTabs"
import { TopTransactions } from "src/frontend/components/Shared/Layout/PopOver/DetailsPopOver/TopTransactions"
import { EntityPopOverPortal } from "src/frontend/components/Shared/Layout/PopOver/EntityPopOverPortal"
import { PartyTitle } from "src/frontend/components/Shared/Layout/PopOver/EntityPopOverTitle"
import { DetailsPopUp } from "src/frontend/components/Shared/Layout/PopOver/shared"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { type TextPartyHover } from "src/shared/components/ObjectEntities/entityPopOverTypes"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface PopOverProps {
  hoverData: TextPartyHover
}

/*
  COMPONENTS
*/

export const EntityPartyPopOver: React.FC<PopOverProps> = ({ hoverData }) => {
  const popUpRef = React.useRef<HTMLDivElement>(null)
  const { entity: party, topTransactions } = hoverData

  const invertValues = React.useMemo(() => {
    const partyRole = FrontendPartyRole.findByRole(party.roles?.[0])
    return partyRole.invertValues
  }, [party.roles])

  const amountOverTime = React.useMemo(() => {
    if (!hoverData) return undefined
    const values = toSortedTimeseriesValues(hoverData.history.time)
    return {
      label: party.name,
      values,
    }
  }, [party.name, hoverData])

  const transactions = React.useMemo(
    () => (topTransactions as Transaction[]) || [],
    [topTransactions]
  )

  const deltas: StatementDeltas | undefined = React.useMemo(() => {
    if (!hasFirstElement(hoverData?.history?.time)) return undefined
    if (!hasFirstElement(hoverData?.entity?.roles)) return undefined
    const { moneyFlow, deltaPrevious, deltaYearAgo } = hoverData.history.time[0].summary.total
    return toStatementDeltas({ moneyFlow, deltaPrevious, deltaYearAgo })
  }, [hoverData])

  const tabs = React.useMemo(() => {
    const data: React.ReactElement<TabProps>[] = []
    data.push(
      <PopOverTab key="overview" title="Overview">
        <OverTimeChart amountOverTime={amountOverTime} deltas={deltas} isBalance={false} />
      </PopOverTab>
    )

    // top transactions list is optional
    if (transactions.length) {
      data.push(
        <PopOverTab key="transactions" title="Top Transactions">
          <TopTransactions transactions={transactions} />
        </PopOverTab>
      )
    }
    return data
  }, [amountOverTime, deltas, transactions])

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <EntityPopOverPortal popUpRef={popUpRef}>
        <DetailsPopUp ref={popUpRef} width={420} maxHeight="auto">
          <PartyTitle party={party} />
          <PopOverTabs>{tabs}</PopOverTabs>
        </DetailsPopUp>
      </EntityPopOverPortal>
    </InvertValuesContext.Provider>
  )
}
