import * as React from "react"
import { Tooltip, TooltipLabel } from "@digits-shared/components/UI/Elements/Tooltip"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import colors from "@digits-shared/themes/colors"
import { animated, to, useSprings } from "@react-spring/web"
import { localPoint } from "@visx/event"
import { Group } from "@visx/group"
import { ParentSize } from "@visx/responsive"
import { scaleLinear, scaleTime } from "@visx/scale"
import { Circle, Line, LinePath } from "@visx/shape"
import { useTooltip } from "@visx/tooltip"
import { bisector, max, min } from "d3-array"
import { type ScaleLinear, scaleSequential, type ScaleTime } from "d3-scale"
import { interpolateGreens } from "d3-scale-chromatic"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import {
  type SparkChartPoint,
  type SparkChartProps,
} from "src/frontend/components/OS/Shared/Charts/index"
import { SharedLineChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import {
  useSpringControls,
  useSpringControlsKey,
} from "src/shared/components/Contexts/SpringControlsContext"
import { useRefEvent } from "src/shared/hooks/useRefEvent"

// This is a workaround for a browser bug which prevents SVG lines from having a
// gradient paint applied to them if all of the points have equal y values (creating
// a line with a zero slope). By nudging the first point by an invisible fraction of
// a pixel, it can be convinced to render the gradient on the line.
//
// Closest bug info I found:
// https://stackoverflow.com/questions/19708943/svg-straight-path-with-clip-path-not-visible-in-chrome
const FIRST_POINT_NUDGE = 0.01

const VERTICAL_PADDING = 1
const AXIS_PADDING = 0
const LEFT_PADDING = 0
const RIGHT_PADDING = 0

const CONTENT_TOP = VERTICAL_PADDING
const CONTENT_LEFT = LEFT_PADDING

const ANIMATION_INITIAL_DELAY = 60
const ANIMATION_PER_POINT_DELAY = 25

const SPARK_LINE_CHART_SPRING_CONFIG = "SparkLineChart"

/*
  STYLES
*/

const Container = styled.div`
  position: relative;
  overflow: visible;
  cursor: pointer;
`

const SVGContainer = styled.svg<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  overflow: visible;
  z-index: 10;
`

const AmountTooltip = styled(Tooltip)`
  text-align: center;
  display: block;
  pointer-events: none;
`

const TooltipAmount = styled(TooltipLabel)``

const TooltipDate = styled(TooltipLabel)`
  font-size: 9px;
  text-transform: none;
  color: ${colors.altoGray};
`

// <ElementType> is a workaround for TS compiler error triggered by react-springs v9 & styled-components
// https://github.com/pmndrs/react-spring/issues/1515
// By setting these generic react element type we lose the real component's property inference.
// Once issue is fixed or there is a better work around we should remove the generic type.
const AnimatedLinePath = animated<React.ElementType>(LinePath)
const AnimatedCircle = animated<React.ElementType>(Circle)

/*
  INTERFACES
*/

interface SizedLineChartProps {
  className?: string
  series: Point[]
  width: number
  height: number
  animateLineInitialDelay?: number
  animateLine?: boolean
  highlightedIndices?: number[]
  lineWidth?: number
  pointRadius?: number
}

interface LineAndCirclesProps {
  uuid: string
  width: number
  series: Point[]
  hoveredIndex: number | undefined
  animateLineInitialDelay?: number
  animateLine?: boolean
  xValue: (point: Point) => Date
  xScale: ScaleTime<number, number, never>
  yValue: (point: Point) => number
  yScale: ScaleLinear<number, number, never>
  minYValue: number
  lineWidth?: number
  pointRadius?: number
}

interface ToolTipData {
  data: Point
  index: number
}

interface Point extends SparkChartPoint {
  // A point is incomplete if the value does not cover the full period
  incomplete?: boolean
}

interface ChartTooltipProps {
  containerRef: React.RefObject<HTMLDivElement | null>
  graphWidth: number
  series: Point[]
  xValue: (point: Point) => Date
  xScale: ScaleTime<number, number, never>
  onPointHovered: (index?: number) => void
}

/*
  COMPONENTS
*/

export const SparkLineChart: React.FC<
  SparkChartProps & Pick<SizedLineChartProps, "lineWidth" | "pointRadius">
> = ({
  className,
  series,
  highlightedIndices,
  animateChart,
  animateChartInitialDelay,
  lineWidth,
  pointRadius,
}) => (
  <ParentSize className={className}>
    {({ width, height }) => (
      <SizedLineChart
        className={className}
        width={width}
        height={height}
        series={series}
        highlightedIndices={highlightedIndices}
        animateLine={animateChart}
        animateLineInitialDelay={animateChartInitialDelay}
        lineWidth={lineWidth}
        pointRadius={pointRadius}
      />
    )}
  </ParentSize>
)

const SizedLineChart: React.FC<SizedLineChartProps> = ({
  className,
  width,
  height,
  series,
  animateLine,
  animateLineInitialDelay,
  lineWidth,
  pointRadius,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const springControlsKey = useSpringControlsKey(SPARK_LINE_CHART_SPRING_CONFIG)
  const uuid = useConstant<string>(generateUUID)

  const xMax = width - LEFT_PADDING - RIGHT_PADDING
  const yMax = height - VERTICAL_PADDING * 2 - AXIS_PADDING

  const xValue = React.useCallback((value: Point) => dayjs.unix(value.x.endedAt).utc().toDate(), [])

  const yValue = React.useCallback(
    ({
      y: {
        value: { amount, currencyMultiplier },
        isNormal,
      },
    }: Point) => (Math.abs(amount) * (isNormal ? 1 : -1)) / currencyMultiplier,
    []
  )

  const maxXValue = max(series, xValue) || dayjs.utc().toDate()
  const minXValue = min(series, xValue) || dayjs.utc(0).toDate()

  const xScale = scaleTime({
    domain: [minXValue, maxXValue],
    range: [0, xMax],
  })

  const maxYValue = max(series, yValue) || 10
  const minYValue = min(series, yValue) || 0

  const yMaxDomain = Math.ceil(maxYValue)
  const yMinDomain = Math.ceil(minYValue)

  const yScale = scaleLinear({
    domain: [yMaxDomain, yMinDomain],
    range: [0, yMax],
    nice: true,
  })

  const [hoveredIndex, setHoveredIndex] = React.useState<number | undefined>()

  const zeroLocation = yScale(0)
  // Only show the zero line if it is needed
  const showZeroLine = maxYValue > 0 && minYValue < 0

  return (
    <Container className={className} ref={containerRef}>
      <SVGContainer width={width} height={height}>
        <defs>
          <linearGradient id={`line-stroke-gradient-${uuid}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={SharedLineChartStyles.lineGradientStart} />
            <stop offset="100%" stopColor={SharedLineChartStyles.lineGradientEnd} />
          </linearGradient>
        </defs>
        <Group top={CONTENT_TOP} left={CONTENT_LEFT}>
          {showZeroLine && (
            <Line
              from={{ x: 0, y: zeroLocation }}
              to={{ x: xMax, y: zeroLocation }}
              className="zero-line"
              strokeDasharray="3 3"
              strokeWidth={1}
              stroke={colors.translucentWhite20}
            />
          )}
          <LineAndCircles
            key={springControlsKey}
            uuid={uuid}
            width={width}
            series={series}
            hoveredIndex={hoveredIndex}
            animateLine={animateLine}
            animateLineInitialDelay={animateLineInitialDelay}
            xScale={xScale}
            xValue={xValue}
            yScale={yScale}
            yValue={yValue}
            minYValue={minYValue}
            lineWidth={lineWidth}
            pointRadius={pointRadius}
          />
        </Group>
      </SVGContainer>
      <ChartTooltip
        containerRef={containerRef}
        graphWidth={width}
        series={series}
        xValue={xValue}
        xScale={xScale}
        onPointHovered={setHoveredIndex}
      />
    </Container>
  )
}

const LineAndCircles: React.FC<LineAndCirclesProps> = ({
  uuid,
  width,
  series,
  hoveredIndex,
  animateLine = true,
  animateLineInitialDelay,
  xScale,
  xValue,
  yScale,
  yValue,
  minYValue,
  lineWidth = 1.5,
  pointRadius = 2,
}) => {
  const x = React.useCallback((point: Point) => xScale(xValue(point)) ?? 0, [xScale, xValue])
  const y = React.useCallback(
    (point: Point, index: number) => {
      const yVal = yScale(yValue(point)) ?? 0
      return index === 0 ? yVal + FIRST_POINT_NUDGE : yVal
    },
    [yScale, yValue]
  )

  const controls = useSpringControls(SPARK_LINE_CHART_SPRING_CONFIG, {
    friction: 16,
  })
  const [springs] = useSprings(series.length, (index) => {
    const delay = animateLine
      ? (animateLineInitialDelay ?? ANIMATION_INITIAL_DELAY) + index * ANIMATION_PER_POINT_DELAY
      : 0

    return {
      ...controls,
      from: { scale: 0 },
      to: { scale: 1 },
      delay,
      immediate: !animateLine,
    }
  })

  const fillColor = React.useMemo(
    () =>
      scaleSequential(interpolateGreens)
        .domain([0, width])
        .range([SharedLineChartStyles.lineGradientStart, SharedLineChartStyles.lineGradientEnd]),
    [width]
  )

  // Creates an array of interpolated point values each referencing a different spring
  const animatedPoints = series.map((point, index) => {
    const spring = springs[index] as (typeof springs)[number]
    return spring.scale.to((s) => scalePoint(point, s))
  })

  // Uses a stand-alone interpolator to aggregate the per-point interpolations into a single
  // interpolated value for the whole series
  const animatedSeries = to(animatedPoints, (...points) => points)

  return (
    <>
      <AnimatedLinePath
        data={animatedSeries}
        x={x}
        y={y}
        className="chart-line"
        stroke={`url(#line-stroke-gradient-${uuid})`}
        strokeWidth={lineWidth}
        mask={`url(#line-mask-${uuid})`}
      />
      {/* Create a mask which includes all of the SVG area (the white rectangle) except for the circles 
          (in black) drawn at each point location. When applied as a mask for the line, this allows the 
          translucent line and translucent circles to have zero overlap (looks wrong), and perfect edges 
          where they meet. */}
      <mask id={`line-mask-${uuid}`}>
        <rect id="bg" x="0" y="0" width="100%" height="100%" fill="white" />
        {series.map((point, i) => {
          const spring = springs[i] as (typeof springs)[number]
          const animatedY = spring.scale.to((s) => {
            const scaledY = yScale(Math.max(yValue(scalePoint(point, s)), minYValue))
            return i === 0 ? scaledY + FIRST_POINT_NUDGE : scaledY
          })
          return (
            <AnimatedCircle
              key={`clip-circle-${i}`}
              cx={x(point)}
              cy={animatedY}
              r={pointRadius}
              fill="black"
              stroke={point.incomplete ? "black" : undefined}
            />
          )
        })}
      </mask>
      {series.map((point, i) => {
        const cx = x(point)
        const fill = fillColor(cx)
        const circleFill = point.incomplete ? colors.transparent : fill
        const circleStroke = hoveredIndex === i ? colors.white : point.incomplete ? fill : undefined

        const spring = springs[i] as (typeof springs)[number]
        const animatedY = spring.scale.to((s) => {
          const scaledY = yScale(Math.max(yValue(scalePoint(point, s)), minYValue))
          return i === 0 ? scaledY + FIRST_POINT_NUDGE : scaledY
        })

        return (
          <g key={`point-${i}`}>
            <AnimatedCircle cx={cx} cy={animatedY} r={3} fill={circleFill} stroke={circleStroke} />
          </g>
        )
      })}
    </>
  )
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  containerRef,
  graphWidth,
  series,
  xValue,
  xScale,
  onPointHovered,
}) => {
  const { showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop } =
    useTooltip<ToolTipData>()

  const bisectDate = bisector(xValue).center

  const onLeave = React.useCallback(() => {
    onPointHovered(undefined)
    hideTooltip()
  }, [hideTooltip, onPointHovered])

  const onHover = React.useCallback(
    (event: MouseEvent) => {
      const point = localPoint(event)
      const x = point ? point.x : 0
      const date = xScale.invert(x - CONTENT_LEFT)
      const index = bisectDate(series, date)
      const data = series[index]

      if (!point || !data) {
        onPointHovered(undefined)
        hideTooltip()
        return
      }

      onPointHovered(index)
      showTooltip({
        tooltipData: { data, index },
        tooltipLeft: (point?.x || 0) + 10,
        tooltipTop: (point?.y || 0) - 20,
      })
    },
    [xScale, bisectDate, series, onPointHovered, showTooltip, hideTooltip]
  )

  useRefEvent(containerRef, "mouseenter", onHover)
  useRefEvent(containerRef, "mousemove", onHover)
  useRefEvent(containerRef, "mouseleave", onLeave)

  const tooltipRight =
    tooltipData && tooltipData.index >= series.length - 2
      ? graphWidth - (tooltipLeft ?? 0) + 20
      : undefined

  return (
    <>
      {tooltipData && (
        <div>
          <AmountTooltip
            top={tooltipTop}
            left={tooltipRight ? undefined : tooltipLeft}
            right={tooltipRight}
          >
            <TooltipAmount>{displayAmount(tooltipData.data)}</TooltipAmount>
            <TooltipDate>{displayDate(tooltipData.data)}</TooltipDate>
          </AmountTooltip>
        </div>
      )}
    </>
  )
}

/*
  FUNCTIONS
*/

function scalePoint(point: Point, scale: number): Point {
  return {
    ...point,
    y: {
      ...point.y,
      value: {
        ...point.y.value,
        amount: point.y.value.amount * scale,
      },
    },
  }
}

function displayDate(p: Point) {
  return dateTimeHelper.displayNameFromPeriod(p.x, DateFormat.Medium)
}

function displayAmount(p: Point) {
  return moneyFlowHelper.currency(p.y, {
    style: CurrencyStyle.Aggregation,
  })
}
