import React from "react"
import {
  ActionItemTargetMode,
  DirectionFromOrigin,
  Interval,
  useListActionItemsBadgeCountsQuery,
  useNotificationCreatedSubscription,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import { type LegalEntityPermission } from "src/frontend/components/OS/Applications/Application"
import { STATUS_OPEN } from "src/frontend/components/OS/Applications/Inbox/ActionItems/constants"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import type FrontendSession from "src/frontend/session/FrontendSession"

export function useActionItemsBadgeCountsQuery(
  legalEntityIds: string[],
  skip?: boolean,
  legalEntityPerms?: LegalEntityPermission[]
) {
  const variables = useActionItemsBadgeCountsQueryVariables(
    legalEntityPerms ??
      legalEntityIds.map((id) => ({ legalEntityId: id, canManageTransactions: false }))
  )
  const result = useListActionItemsBadgeCountsQuery({
    variables,
    skip: skip || !legalEntityIds.length,
  })

  return React.useMemo(
    () => ({
      counts: result.data?.listActionItemsBadgeCounts,
      loading: result.loading,
      refetch: result.refetch,
    }),
    [result]
  )
}

export function useActionItemsBadgeCountsQueryVariables(
  legalEntityOrEntities: LegalEntityPermission | LegalEntityPermission[]
) {
  return React.useMemo(
    () => ({
      origin: dateTimeHelper.todayIntervalOrigin(Interval.Month, 48),
      direction: DirectionFromOrigin.Past,
      legalEntities: Array.isArray(legalEntityOrEntities)
        ? legalEntityOrEntities
        : [legalEntityOrEntities],
      legalEntityIds: Array.isArray(legalEntityOrEntities)
        ? legalEntityOrEntities.map((le) => le.legalEntityId)
        : [legalEntityOrEntities.legalEntityId],
      filter: {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Assign],
      },
    }),
    [legalEntityOrEntities]
  )
}

export function useCurrentLegalEntityActionItemsBadgeCount() {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const legalEntityIds = React.useMemo(
    () => (currentLegalEntity ? [currentLegalEntity.id] : []),
    [currentLegalEntity]
  )
  const legalEntityPerms = React.useMemo(
    () =>
      currentLegalEntity
        ? [
            {
              legalEntityId: currentLegalEntity.id,
              canManageTransactions: currentLegalEntity.hasAccessToAspect(
                AspectCode.TransactionActionItems
              ),
            },
          ]
        : [],
    [currentLegalEntity]
  )
  const { counts, refetch } = useActionItemsBadgeCountsQuery(
    legalEntityIds,
    undefined,
    legalEntityPerms
  )

  const view = useViewVersion()
  React.useEffect(() => {
    refetch()
  }, [refetch, view])

  useNotificationCreatedSubscription({
    onData: (subscriptionData) => {
      if (
        currentLegalEntity &&
        subscriptionData.data.data?.notificationCreated.id &&
        subscriptionData.data.data.notificationCreated.legalEntityId === currentLegalEntity.id
      ) {
        refetch()
      }
    },
  })
  // when there are no action items the counts array will be empty
  return counts?.[0]?.count ?? 0
}
