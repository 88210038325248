import { CategoryType, Flow } from "@digits-graphql/frontend/graphql-bearer"
import SearchBox, { SearchIcon } from "@digits-shared/components/UI/Elements/SearchBox"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

export const MEDIUM_VIEW_BREAKPOINT = 1000
export const WIDE_VIEW_BREAKPOINT = 1200
export const CATEGORY_HEADER_HEIGHT = 64
export const TD_SPACING = 16
export const AMOUNT_COLUMN_WIDTH = 100
export const TABLE_PADDING = 16
export const SMALL_TD_SPACING = 5
export const TransactionItemClass = "transaction-dashboard-transaction"
const CSS_COLOR_VARS = css`
  --accounting-dashboard-middle-background-color: rgb(246, 248, 251);
  --accounting-dashboard-table-row-border-color: rgba(50, 73, 127, 0.1);
`
export const ANIMATION_TRANSITION_DURATION = 0.5

export const CATEGORY_COLOR_MAP = new Map<CategoryType, string>([
  [CategoryType.Assets, "#2C9D80"],
  [CategoryType.Liabilities, "#FAB372"],
  [CategoryType.Equity, "#7B82D0"],
  [CategoryType.Income, "#33BDD0"],
  [CategoryType.CostOfGoodsSold, "#9D498F"],
  [CategoryType.Expenses, "#FA8072"],
  [CategoryType.OtherIncome, "#1E91A1"],
  [CategoryType.OtherExpenses, "#DA576E"],
  [CategoryType.UnknownType, colors.gray],
])

export const TransactionSearch = styled(SearchBox)`
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${colors.secondary20};
  padding: 12px 0;

  ${SearchIcon} {
    width: 16px;
    height: 16px;
  }

  input::placeholder {
    color: ${colors.translucentSecondary40};
    ${fonts.scale.body};
    text-transform: none;
  }

  &:hover,
  &:focus {
    border-bottom-color: ${colors.secondary50};
  }
`

export const TabContainer = styled.div`
  // Place container on top of fading container
  position: relative;
  ${CSS_COLOR_VARS};
`

export const CategoryAmount = styled.div<{ isNormal: boolean; flow: Flow; isZero: boolean }>`
  color: ${({ isNormal, flow, isZero }) =>
    isNormal || isZero
      ? colors.secondary
      : flow === Flow.Inbound
        ? colors.primary
        : "rgba(215, 104, 0, 1)"};

  ${({ isNormal, isZero }) =>
    !isNormal &&
    !isZero &&
    css`
      &::before {
        content: "-";
      }
    `}
`
