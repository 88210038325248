import * as React from "react"
import { ReportKind } from "@digits-graphql/frontend/graphql-bearer"
import { SvgActivity } from "@digits-shared/components/SVGIcons/line/Activity.svg"
import { SvgBuilding02 } from "@digits-shared/components/SVGIcons/line/Building02.svg"
import { SvgCornerDownLeft } from "@digits-shared/components/SVGIcons/line/CornerDownLeft.svg"
import { SvgCreditCardAngle } from "@digits-shared/components/SVGIcons/line/CreditCardAngle.svg"
import { SvgFile07 } from "@digits-shared/components/SVGIcons/line/File07.svg"
import { SvgFileLock03 } from "@digits-shared/components/SVGIcons/line/FileLock03.svg"
import { SvgGrid01 } from "@digits-shared/components/SVGIcons/line/Grid01.svg"
import { SvgHome02 } from "@digits-shared/components/SVGIcons/line/Home02.svg"
import { SvgInvoicing } from "@digits-shared/components/SVGIcons/line/Invoicing.svg"
import { SvgMoneyBag } from "@digits-shared/components/SVGIcons/line/MoneyBag.svg"
import { SvgTransaction } from "@digits-shared/components/SVGIcons/line/Transaction.svg"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/AspectCode"
import styled from "styled-components"
import { Application } from "src/frontend/components/OS/Applications/Application"
import { useBadgeContext } from "src/frontend/components/Shared/Contexts/BadgeContext"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { AccountingSidebar } from "src/frontend/components/Shared/NavSidebar/AccountingSidebar"
import {
  ActionItemsSidebarRow,
  InboxIcon,
} from "src/frontend/components/Shared/NavSidebar/ActionItemsSidebarRow"
import {
  SIDEBAR_PATH_SVG_STYLES,
  SidebarDivider,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { RequiresAspect } from "src/frontend/components/Shared/Permissions/RequiresAspect"
import { RequiresView } from "src/frontend/components/Shared/View/RequiresView"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session/FrontendSession"
import { Experience } from "src/frontend/session/personas"

/*
  STYLES
*/

const Contents = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`

const HomeIcon = styled(SvgHome02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const GetPaidIcon = styled(SvgMoneyBag)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const BillPayIcon = styled(SvgCreditCardAngle)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const InvoicingIcon = styled(SvgInvoicing)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const ReportsIcon = styled(SvgFile07)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const AnalysisIcon = styled(SvgActivity)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const TransactionsIcon = styled(SvgTransaction)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const VaultIcon = styled(SvgFileLock03)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const BackIcon = styled(SvgCornerDownLeft)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const VendorsIcon = styled(SvgBuilding02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const OnboardingIcon = styled(SvgGrid01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const MutedSidebarRow = styled(SidebarRow)`
  opacity: 0.2;
`

/*
  COMPONENT
*/

const REPORTS_ACTIVE_ROUTES = [routes.reports]
export const SidebarContents: React.FC = () => {
  const { currentLegalEntity, hasAccessToAspect, currentPrimaryExperience } =
    useSession<FrontendSession>()
  const params = { leSlug: currentLegalEntity?.slug }
  const reportsParams = { ...params, kind: ReportKind.ProfitAndLoss }
  const viewVersion = useViewVersion()
  const { badgeCount } = useBadgeContext()

  const {
    operatorFeatures,
    accountantFeatures,
    showBillPay,
    showInvoicing,
    showGetPaid,
    showAccounting,
    showAudit: _,
  } = {
    operatorFeatures: currentPrimaryExperience !== Experience.AccountantPortal,
    accountantFeatures: currentPrimaryExperience === Experience.AccountantPortal,
    showBillPay: hasAccessToAspect(AspectCode.PayBills),
    showInvoicing: hasAccessToAspect(AspectCode.Invoicing),
    showGetPaid: hasAccessToAspect(AspectCode.GetPaid),
    showAccounting: hasAccessToAspect(AspectCode.AIBookkeeper),
    showAudit: hasAccessToAspect(AspectCode.Audit),
  }

  const reportsCount = currentLegalEntity?.id
    ? badgeCount(currentLegalEntity.id, Application.DigitsReports.name)
    : undefined
  const { isFooterMenuOpen, isHeaderMenuOpen } = useNavSidebarContext()

  return (
    <Contents inert={isHeaderMenuOpen || isFooterMenuOpen}>
      <RequiresView view={viewVersion}>
        <SidebarRow
          Icon={HomeIcon}
          route={routes.legalEntityHome}
          activeRoutes={[routes.legalEntityHome]}
          params={params}
        >
          Home
        </SidebarRow>
        <ActionItemsSidebarRow params={params} />
        <SidebarRow Icon={AnalysisIcon} route={routes.analysis} params={params}>
          Analysis
        </SidebarRow>

        <SidebarRow
          Icon={ReportsIcon}
          route={routes.reportsStatement}
          activeRoutes={REPORTS_ACTIVE_ROUTES}
          params={reportsParams}
          badge={Boolean(reportsCount)}
        >
          Financials
        </SidebarRow>

        <RequiresAspect aspect={AspectCode.Vault}>
          <SidebarRow Icon={VaultIcon} route={routes.vault} params={params}>
            Vault
          </SidebarRow>
        </RequiresAspect>
        {(showBillPay || showGetPaid) && <SidebarDivider />}
      </RequiresView>

      {showBillPay && (
        <SidebarRow Icon={BillPayIcon} route={routes.billPay} params={params}>
          Bill Pay
        </SidebarRow>
      )}
      {showInvoicing && (
        <SidebarRow Icon={InvoicingIcon} route={routes.invoicing} params={params}>
          Invoicing
        </SidebarRow>
      )}
      {showGetPaid && (
        <SidebarRow Icon={GetPaidIcon} route={routes.getPaid} params={params} isExact>
          Get Paid
        </SidebarRow>
      )}

      <>
        {showAccounting && operatorFeatures && (
          <RequiresView view={viewVersion}>
            <SidebarDivider />
            <SidebarRow Icon={TransactionsIcon} route={routes.accounting} params={params}>
              Transactions
            </SidebarRow>

            <SidebarRow Icon={VendorsIcon} route={routes.vendorDirectory} params={params}>
              Vendors
            </SidebarRow>
          </RequiresView>
        )}

        {accountantFeatures && <AccountingSidebar />}
      </>
    </Contents>
  )
}

// It is filled via a Portal with contents from the Shared With Me entities
// See ObjectKindSelector.tsx
export const SharedUserSidebarContents: React.FC = () => {
  const { currentLegalEntity, isAffiliatedSession, currentOrganization, allActiveLegalEntities } =
    useSession<FrontendSession>()

  // Make sure that we can actually return to the dashboard for the given legal entity.
  const navigableLegalEntity = React.useMemo(() => {
    if (currentLegalEntity?.accountingViewVersions.size > 0) {
      return currentLegalEntity
    }

    const allDashboardLegalEntities = isAffiliatedSession
      ? currentOrganization?.affiliatedLegalEntities.filter((le) => le.hasDashboardAccess())
      : allActiveLegalEntities.map((le) => le.legalEntity)

    const navigableLEs = (allDashboardLegalEntities ?? [])
      .filter((le) => le.accountingViewVersions.size > 0)
      .sort((a, b) => (a.slug < b.slug ? -1 : 1))
    return navigableLEs.length ? navigableLEs[0] : undefined
  }, [
    allActiveLegalEntities,
    currentLegalEntity,
    currentOrganization?.affiliatedLegalEntities,
    isAffiliatedSession,
  ])

  const params = { leSlug: navigableLegalEntity?.slug ?? "" }
  const hasLEAccess = !!navigableLegalEntity

  return (
    <Contents id="nav-sidebar-contents">
      <div css="width: 100%; margin: 10px 0" />
      {hasLEAccess && (
        <>
          <SidebarRow Icon={BackIcon} route={routes.legalEntityHome} params={params} isExact>
            Back to Home
          </SidebarRow>
          <SidebarDivider />
        </>
      )}
    </Contents>
  )
}

export const OnboardingSidebarContents: React.FC = () => {
  const { isFooterMenuOpen, isHeaderMenuOpen } = useNavSidebarContext()

  return (
    <Contents inert={isHeaderMenuOpen || isFooterMenuOpen}>
      <SidebarRow Icon={OnboardingIcon} rowType="button" active>
        Onboarding
      </SidebarRow>
      <MutedSidebarRow Icon={HomeIcon}>Home</MutedSidebarRow>
      <MutedSidebarRow Icon={InboxIcon}>Inbox</MutedSidebarRow>
      <MutedSidebarRow Icon={AnalysisIcon}>Analysis</MutedSidebarRow>
      <MutedSidebarRow Icon={ReportsIcon}>Financials</MutedSidebarRow>
      <SidebarDivider />
      <MutedSidebarRow Icon={BillPayIcon}>Bill Pay</MutedSidebarRow>
      <MutedSidebarRow Icon={InvoicingIcon}>Invoicing</MutedSidebarRow>
      <SidebarDivider />
      <MutedSidebarRow Icon={TransactionsIcon}>Transactions</MutedSidebarRow>
      <MutedSidebarRow Icon={VendorsIcon}>Vendors</MutedSidebarRow>
    </Contents>
  )
}
