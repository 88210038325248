import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import { snackbarDismissalsSelector } from "src/frontend/components/Shared/ManagedModals/state/selectors"
import { makeSnackbarStorageId } from "src/frontend/components/Shared/ManagedModals/state/storageIds"
import {
  useModalManagerDispatch,
  useModalManagerStore,
} from "src/frontend/components/Shared/ManagedModals/state/store"
import {
  type Dismissal,
  type ManagedSnackbarName,
} from "src/frontend/components/Shared/ManagedModals/state/types"
import { getLocalPreference } from "src/frontend/components/Shared/ManagedModals/storage"
import type FrontendSession from "src/frontend/session"

/**
 * Returns a memoized callback to hide `snackbarName`
 */
export function useHideSnackbar(snackbarName: ManagedSnackbarName, dismissal?: Dismissal) {
  const dispatch = useModalManagerDispatch()
  const { currentLegalEntityId } = useSession<FrontendSession>()
  return React.useCallback(
    () =>
      dispatch({
        type: "CloseSnackbar",
        name: snackbarName,
        dismissal,
        legalEntityId: dismissal === "Persistent" ? currentLegalEntityId : undefined,
      }),
    [dispatch, snackbarName, currentLegalEntityId, dismissal]
  )
}

/**
 * Returns true if the snackbar was dismissed in the current session
 * or was previously persistently dismissed.
 */
export function useWasSnackbarDismissed(snackbarName: ManagedSnackbarName) {
  const { currentLegalEntityId } = useSession<FrontendSession>()
  const dismissals = useModalManagerStore(snackbarDismissalsSelector)
  const persistentlyDismissedAt = React.useMemo(
    () => getLocalPreference(makeSnackbarStorageId(snackbarName), currentLegalEntityId),
    [snackbarName, currentLegalEntityId]
  )
  const wasDismissed = React.useMemo(() => dismissals.has(snackbarName), [snackbarName, dismissals])

  return Boolean(persistentlyDismissedAt || wasDismissed)
}
