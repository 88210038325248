import * as React from "react"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import { AnimatePresence, LayoutGroup } from "framer-motion"
import styled from "styled-components"
import { AlertItem } from "src/frontend/components/Shared/Alerts/AlertItem"
import { useChromeAlertContext } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"

/*
 STYLES
*/

export const AlertsCentering = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  min-height: 48px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 64px;
  pointer-events: none;
  z-index: ${zIndexes.modalOverlay + 1};
`

/*
 COMPONENTS
*/

export const AlertsList: React.FC = () => {
  const { alerts, removeAlert } = useChromeAlertContext()

  return (
    <AlertsCentering>
      <LayoutGroup>
        <AnimatePresence>
          {alerts.map((alert) => (
            <AlertItem key={alert.id} alert={alert} removeAlert={removeAlert} />
          ))}
        </AnimatePresence>
      </LayoutGroup>
    </AlertsCentering>
  )
}
