import React from "react"
import { type IconShape, type IconSize } from "@digits-shared/components/UI/Icons/Icon"
import styled, { css } from "styled-components"

const ImageCSS = ({ size, iconShape }: LazyImageProps) => css`
  ${size &&
  css`
    height: ${`${size?.pixels}px`};
    min-height: ${`${size?.pixels}px`};
    width: ${`${size?.pixels}px`};
    min-width: ${`${size?.pixels}px`};
  `};

  ${iconShape &&
  css`
    border-radius: ${iconShape === "square" ? "3px" : "50%"};
  `}
`

const Container = styled.div``

const Placeholder = styled.div<{ size?: IconSize; iconShape?: IconShape }>`
  background: rgba(50, 73, 127, 0.05);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: inherit;
`

interface LazyLoadProps {
  className?: string
  placeholder?: React.ReactNode
  force?: boolean
  options?: IntersectionObserverInit
  timeout?: number
}

interface LazyImageProps {
  size?: IconSize
  iconShape?: IconShape
}

export const LazyComponentLoad: React.FC<React.PropsWithChildren<LazyLoadProps>> = ({
  children,
  className,
  placeholder,
  force = false,
  timeout,
  options: {
    root = null, // Use the viewport as the root
    rootMargin = "5%", // 5% margin around the root
    threshold = 0.01, // 1% of the image should be visible
  } = {},
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = React.useState(force)

  const handleIntersection: IntersectionObserverCallback = React.useCallback(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          // Cleanup the observer when the component is visible
          observer.disconnect()
        }
      })
    },
    []
  )

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root,
      rootMargin,
      threshold,
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      // Cleanup the observer when the component is unmounted
      observer?.disconnect()
    }
  }, [handleIntersection, root, rootMargin, threshold])

  React.useEffect(() => {
    if (timeout && !isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(true)
      }, timeout)

      return () => clearTimeout(timer)
    }
  }, [timeout, isVisible])

  if (isVisible) return <>{children}</>

  return (
    <Container ref={containerRef} className={className}>
      {placeholder ?? <Placeholder className={className} />}
    </Container>
  )
}

export const LazyImageLoad: React.FC<React.PropsWithChildren<LazyImageProps & LazyLoadProps>> = ({
  children,
  className,
  placeholder,
  options,
  force,
  size,
  iconShape,
}) => (
  <LazyComponentLoad
    className={className}
    css={ImageCSS({ size, iconShape })}
    placeholder={placeholder}
    options={options}
    force={force}
  >
    {children}
  </LazyComponentLoad>
)
