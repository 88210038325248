import * as React from "react"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useDimensionDisplayOptionGroup } from "src/frontend/components/OS/Shared/DisplayOptions/DimensionDisplayOptionGroupContext"
import { DimensionActivity } from "src/frontend/types/DimensionFilter"

const DimensionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DimensionActivityButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-color: ${colors.secondary};
  }

  padding: 8px;
  border: 1px solid ${colors.secondary20};

  &.selected {
    border-color: ${colors.secondary};
  }
`

/*
  COMPONENTS
*/

export const DimensionActivityFilter: React.FC = () => {
  const { dimensionActivity } = useDimensionDisplayOptionGroup()

  const onCategoryActivityClick = React.useCallback(
    (activity: DimensionActivity) => dimensionActivity.set(activity),
    [dimensionActivity]
  )

  const dropDownValues = React.useMemo(() => Object.values(DimensionActivity), [])

  return (
    <DimensionButtons>
      {dropDownValues.map((activity, i) => (
        <DimensionActivityButton
          key={activity}
          className={stringHelper.classNames(activity === dimensionActivity.get() && "selected")}
          onClick={() => onCategoryActivityClick(activity)}
        >
          {activity}
        </DimensionActivityButton>
      ))}
    </DimensionButtons>
  )
}
