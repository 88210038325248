import {
  type EntityReportPackage,
  type LayoutDocument,
  type Report,
  ReportKind,
  type ReportPackage,
  type ReportPackageDocumentDetails,
  type ReportPackageDocumentMetadata,
  ReportPackageDocumentType,
  type ReportPackageMetadata,
  ReportPackageStatus,
  TaxForm,
  type TextDocument,
} from "@digits-graphql/frontend/graphql-bearer"
import zIndexes from "@digits-shared/DesignSystem/zIndexes"
import { FileType } from "@digits-shared/helpers/fileHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import {
  type PendingReportUpload,
  type ReportUpload,
} from "src/frontend/components/OS/Applications/Reports/Report/Uploader/shared"

export const TOP_BAR_Z_INDEX = zIndexes.modalOverlay + 3

export const PREPARED_BY_NO_ONE = "<null>"

export const REPORT_TOP_BAR_HEIGHT = 58

/*
  TYPES
*/

export type ReportPackageDocumentKind = ReportKind | "ExecutiveSummary" | "Layout"

type ReportPackageType = ReportPackage | ReportPackageMetadata | EntityReportPackage

/*
  HELPERS
*/

export function reportKindSortPosition(kind: ReportKind) {
  switch (kind) {
    case ReportKind.ProfitAndLoss:
      return 0
    case ReportKind.BalanceSheet:
      return 1
    case ReportKind.CashFlow:
      return 2
    case ReportKind.ExpenseSummary:
      return 3
    case ReportKind.ARAging:
      return 4
    case ReportKind.APAging:
      return 5
    default:
      return 6
  }
}

export function reportKindDisplayName(reportKind: ReportKind, taxForm?: TaxForm) {
  switch (taxForm) {
    case TaxForm.NonprofitOrganization:
      return nonProfitDisplayNameForReportKind(reportKind)
    default:
      return displayNameForReportKind(reportKind)
  }
}

function displayNameForReportKind(reportKind: ReportKind): string {
  switch (reportKind) {
    case ReportKind.BalanceSheet:
    case ReportKind.ExpenseSummary:
    case ReportKind.CashFlow:
      return stringHelper.camelCaseToSpaces(reportKind)
    case ReportKind.ProfitAndLoss:
      return "Profit & Loss"
    case ReportKind.APAging:
      return "Accounts Payable Aging"
    case ReportKind.ARAging:
      return "Accounts Receivable Aging"
    case ReportKind.Unknown:
      return "New Report"
  }
}

function nonProfitDisplayNameForReportKind(reportKind: ReportKind): string {
  switch (reportKind) {
    case ReportKind.BalanceSheet:
      return "Statement of Financial Position"
    case ReportKind.CashFlow:
      return "Statement of Cash Flows"
    case ReportKind.ProfitAndLoss:
      return "Statement of Activity"
    // TODO - do AP/AR aging reports have different names for non-profits?
    default:
      return displayNameForReportKind(reportKind)
  }
}

export function titleForReportPackageDocument(
  document: ReportPackageDocumentMetadata | ReportUpload | PendingReportUpload
): string {
  if (document.title) return document.title
  if (document.reportFile?.fileName) return document.reportFile.fileName
  if (
    "type" in document &&
    (document.type === ReportPackageDocumentType.Text ||
      document.type === ReportPackageDocumentType.Layout)
  ) {
    return "Executive Summary"
  }
  return "Custom Report"
}

export type PackageDocument =
  | Report
  | TextDocument
  | LayoutDocument
  | ReportPackageDocumentMetadata
  | ReportPackageDocumentDetails

export interface ReportPackageReportDetails {
  document: Report
  metadata: ReportPackageDocumentMetadata
}

function getReportSummary(document?: PackageDocument | null) {
  return (
    (document as TextDocument)?.summary ||
    ((document as ReportPackageDocumentDetails)?.document as TextDocument)?.summary
  )
}

function getReportFile(document?: PackageDocument | null) {
  return (
    (document as Report)?.reportFile ||
    ((document as ReportPackageDocumentDetails)?.document as Report)?.reportFile
  )
}

export function isTextDocument(document?: PackageDocument | null): document is TextDocument {
  return !!getReportSummary(document)
}

export function isReport(document?: PackageDocument | null): document is Report {
  return !!getReportFile(document)
}

export function isGenerated(document?: PackageDocument | null): document is Report {
  return isReport(document) && getReportFile(document)?.mimeType === FileType.DIGITS
}

export function isFileDetails(
  details?: ReportPackageDocumentDetails
): details is ReportPackageReportDetails {
  return (
    isReport(details?.document) && getReportFile(details?.document)?.mimeType !== FileType.DIGITS
  )
}

export function isFile(document?: PackageDocument): document is Report {
  return isReport(document) && getReportFile(document)?.mimeType !== FileType.DIGITS
}

export function isLayoutDocument(document?: PackageDocument | null): document is LayoutDocument {
  return (
    (document as LayoutDocument)?.layoutId !== undefined ||
    ((document as ReportPackageDocumentDetails)?.document as LayoutDocument)?.layoutId !== undefined
  )
}

export function isEntityReportPackage(
  reportPackage: ReportPackageType
): reportPackage is EntityReportPackage {
  return !!(reportPackage as EntityReportPackage).periodEndedAt
}

export function isReportPackage(reportPackage: ReportPackageType): reportPackage is ReportPackage {
  return !!(reportPackage as ReportPackage).entities
}

export function isReportPackageMetadata(
  reportPackage: ReportPackageType
): reportPackage is ReportPackageMetadata {
  return !isEntityReportPackage(reportPackage) && !isReportPackage(reportPackage)
}

export function isPublishedAsDraft(reportPackage: ReportPackageType | undefined) {
  if (
    !reportPackage ||
    isEntityReportPackage(reportPackage) ||
    reportPackage.status === ReportPackageStatus.Draft
  ) {
    return false
  }

  if (isReportPackage(reportPackage) && reportPackage.packageOptions?.publishedAsDraft) return true

  return isReportPackageMetadata(reportPackage) && reportPackage.publishedAsDraft
}

export function isReportPackageStatus(
  reportPackage: ReportPackageType | undefined,
  status: ReportPackageStatus
) {
  if (!reportPackage || isEntityReportPackage(reportPackage)) return false

  switch (status) {
    case ReportPackageStatus.Draft:
      return (
        isPublishedAsDraft(reportPackage) ||
        reportPackage?.status === ReportPackageStatus.Draft ||
        reportPackage?.status === ReportPackageStatus.Transient
      )

    case ReportPackageStatus.Published:
      return (
        !isPublishedAsDraft(reportPackage) &&
        reportPackage?.status === ReportPackageStatus.Published
      )

    case ReportPackageStatus.Transient:
    case ReportPackageStatus.Unknown:
      return reportPackage?.status === status
  }
}

// Published final or Publish draft
export function isReportPublished(reportPackage: ReportPackageType | undefined) {
  if (!reportPackage) return false
  return (
    isReportPackageStatus(reportPackage, ReportPackageStatus.Published) ||
    isPublishedAsDraft(reportPackage)
  )
}

export function isReportAutoDrafted(
  reportPackage: ReportPackage | ReportPackageMetadata | undefined
) {
  if (!reportPackage) return false
  return !reportPackage?.author
}
