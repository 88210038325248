import * as React from "react"
import {
  CategoryType,
  type InsightComponentConfig,
  Interval,
  type IntervalOrigin,
  LayoutComponentType,
  type ObjectEntities,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAlertTriangle } from "@digits-shared/components/SVGIcons/line/AlertTriangle.svg"
import { SvgStars01 } from "@digits-shared/components/SVGIcons/line/Stars01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { insightDriversParser } from "src/frontend/components/Shared/Layout/Components/Insights/insightDriversParser"
import { useInsightComponentData } from "src/frontend/components/Shared/Layout/Components/Insights/useListInsightsForComponent"
import { useComponentConfigPeriodName } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { EntityInsight } from "src/shared/components/Insights/Item"
import { formatInsightChildNode } from "src/shared/components/Insights/sentenceParsing"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import {
  type EntityHover,
  type EntityPopOverComponent,
} from "src/shared/components/ObjectEntities/entityPopOverTypes"

/*
  STYLES
*/

const Container = styled.div`
  position: relative;
`

const EmptyInsight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 6px;
  color: ${colors.secondary70};
  margin-top: 25%;
  transform: translateY(-70%);
`

const EmptyIcon = styled(SvgStars01)`
  height: 40px;
  width: 40px;
  ${svgPathStyles(colors.secondary70)};
`

const Today = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 14px;

  svg {
    width: 14px;
    height: 14px;
    ${svgPathStyles(colors.secondary)};
  }
`

/*
  INTERFACES
*/

interface ComponentProps extends SizingProps {
  component: MatchedComponent<"insight">
  componentSize: ComponentSize
  // used by Portals to override the origin (time selectors)
  configOverride?: InsightComponentConfig
  layoutId: string
  hasHeader?: boolean
  entityPopOver?: (hovers: EntityHover[] | undefined) => EntityPopOverComponent
  onDelete?: () => void
}

interface HeaderProps {
  config?: InsightComponentConfig
  componentSize: ComponentSize
  entities?: ObjectEntities | null
  loading: boolean
}

interface ItemProps {
  origin: IntervalOrigin
  sentence?: string | null
  entities?: ObjectEntities | null
  loading: boolean
  entityPopOver?: EntityPopOverComponent
  onDelete?: () => void
  legalEntityNameReplacement?: string
}

/*
  COMPONENTS
*/

export const InsightComponent: React.FC<ComponentProps> = ({
  component,
  componentSize,
  configOverride,
  layoutId,
  entityPopOver,
  hasHeader,
  onDelete,
}) => {
  const {
    dataId,
    config: { insight },
  } = component
  const insightConfig = configOverride ?? insight

  const { loading, hovers, entities, sentence } = useInsightComponentData(
    insightConfig,
    layoutId,
    dataId
  )

  return (
    <InsightComponentItem
      origin={insightConfig.origin}
      sentence={sentence}
      entities={entities}
      loading={loading}
      entityPopOver={entityPopOver?.(hovers)}
      onDelete={onDelete}
    >
      {hasHeader && (
        <InsightHeader
          config={insightConfig}
          loading={loading}
          componentSize={componentSize}
          entities={entities}
        />
      )}
    </InsightComponentItem>
  )
}

export const InsightHeader: React.FC<HeaderProps> = ({
  config,
  componentSize,
  loading,
  entities,
}) => {
  const periodName = useComponentConfigPeriodName(
    React.useMemo(() => ({ insight: config, type: LayoutComponentType.Insight }), [config])
  )

  if (loading) {
    return (
      <>
        <LoadingBlock width={280} height={17} />
        <LoadingBlock width={150} height={12} margin="0 0 10px" />
      </>
    )
  }

  // We dont know from the insight which category type; instead look at the categories entities included in it
  const categoryType = entities?.categories
    ?.flatMap((c) => c.type)
    .filter((t) => t === CategoryType.Income || t == CategoryType.Expenses)?.[0]
  const title = `${categoryType || ""} Analysis`

  return (
    <ComponentHeaderTitle componentSize={componentSize} title={title} periodName={periodName} />
  )
}

export const InsightComponentItem: React.FC<React.PropsWithChildren<ItemProps>> = ({
  origin,
  loading,
  entities,
  sentence,
  entityPopOver,
  legalEntityNameReplacement,
  onDelete,
  children,
}) => {
  const todayOrigin = dateTimeHelper.todayIntervalOrigin(Interval.Month)
  const isTodayInterval = dateTimeHelper.areIntervalOriginsEqual(todayOrigin, origin, true)

  if (loading) {
    return (
      <Container css="display: flex; flex-direction: column; gap: 8px;">
        {children}
        <LoadingBlock width={280} height={17} />
        <LoadingBlock width={280} height={17} />
        <LoadingBlock width={150} height={17} />
      </Container>
    )
  }

  if (!sentence) {
    return (
      <Container>
        {children}
        <EmptyInsight>
          <EmptyIcon />
          <b>Nothing Notable</b>
          {onDelete ? (
            <DigitsButton onClick={onDelete} size="small" $variant="secondary-dark">
              Delete
            </DigitsButton>
          ) : (
            <div>Try Another Period</div>
          )}
        </EmptyInsight>
      </Container>
    )
  }

  return (
    <Container>
      {children}
      {isTodayInterval && <TodayLegend />}
      <EntityInsight>
        <EntitiesParser
          text={insightDriversParser(sentence, legalEntityNameReplacement)}
          entities={entities}
          entityPopOver={entityPopOver}
          formatChildNode={formatInsightChildNode}
        />
      </EntityInsight>
    </Container>
  )
}

const TodayLegend: React.FC = () => (
  <Today>
    <SvgAlertTriangle />
    <div>This month is in-flight so insights may change</div>
  </Today>
)
