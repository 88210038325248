import * as React from "react"
import { usePrevious } from "react-use"
import { type SearchHit, type SearchResult } from "@digits-graphql/frontend/graphql-bearer"
import { GLASS_LAYER } from "@digits-shared/DesignSystem/Containers/Glass"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts, { H2Text } from "@digits-shared/themes/typography"
import styled from "styled-components"
import DatePicker from "src/frontend/components/OS/Applications/Search/SearchResults/DatePicker"
import { useHitsCount } from "src/frontend/components/OS/Applications/Search/SearchResults/resultType"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import Odometer from "src/shared/components/Elements/Odometer"

/*
 STYLES
*/

const SectionHeader = styled.div`
  ${GLASS_LAYER};
  border-bottom: none;
  display: flex;
  border-radius: 14px 14px 0 0;

  align-items: center;

  font-weight: ${fonts.weight.black};
  font-size: 18px;
  color: ${colors.secondary};

  padding: 10px 25px;
`

const Count = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 19px;
`

const Counter = styled(Odometer)`
  position: relative;
  font-weight: ${fonts.weight.normal};
`

/*
 INTERFACES
*/

interface ResultSectionHeaderProps {
  type: SearchResultType
  hits: SearchHit[]
  search: SearchResult
}

/*
 COMPONENTS
*/

export const ResultSectionHeader: React.FC<ResultSectionHeaderProps> = ({ type, hits, search }) => {
  const facetCount = useHitsCount(search)
  const count = facetCount?.count ?? 0
  const previousCount = usePrevious(facetCount?.count)

  const typeName = React.useMemo(() => {
    switch (type) {
      case SearchResultType.Party:
        return stringHelper.pluralize(count, type, "Parties")
      case SearchResultType.Category:
        return stringHelper.pluralize(count, type, "Categories")
      case SearchResultType.Comment:
        return stringHelper.pluralize(count, type, "Comments")
      case SearchResultType.Report:
        return stringHelper.pluralize(count, type, "Reports")
      case SearchResultType.Transaction:
        return stringHelper.pluralize(count, type, "Transactions")
      case SearchResultType.User:
        return stringHelper.pluralize(count, type, "Users")
      case SearchResultType.Invoice:
        return stringHelper.pluralize(count, type, "Invoices")
      case SearchResultType.Department:
        return stringHelper.pluralize(count, type, "Departments")
      case SearchResultType.Location:
        return stringHelper.pluralize(count, type, "Locations")
    }
  }, [type, count])

  const hasInterval = type === SearchResultType.Party || type === SearchResultType.Category
  const hasDatePicker = !hasInterval && type === SearchResultType.Transaction

  const duration = Math.log10(count) * 500

  return (
    <SectionHeader>
      <Count>
        <Counter
          key={count}
          startValue={previousCount}
          value={count}
          duration={duration}
          delay={700}
          animation="count"
        />
        <H2Text>&nbsp;{typeName}</H2Text>
      </Count>
      {hasDatePicker && <DatePicker />}
    </SectionHeader>
  )
}
