import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import { useMachine } from "@xstate/react"
import styled from "styled-components"
import type QueryBuilder from "src/frontend/components/OS/Applications/Search/QueryBuilder"
import {
  type Chip,
  type InsightChip,
  type InsightChipHoverCallback,
} from "src/frontend/components/OS/Applications/Search/SearchSuggestions/Shared"
import { SuggestionChips } from "src/frontend/components/OS/Applications/Search/SearchSuggestions/SuggestionChips"
import {
  suggestionsStateMachine,
  TERMS_HIDDEN_TAG,
} from "src/frontend/components/OS/Applications/Search/SearchSuggestions/suggestionsStateMachine"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import type FrontendSession from "src/frontend/session"
import { useSuggestionInsights } from "src/shared/components/Insights/useSuggestionInsights"
import { useTerms } from "src/shared/hooks/useTermsQuery"

/*
  STYLES
*/

const SuggestionsContainer = styled.div`
  width: 100%;
  height: 39px;
  overflow: hidden;
`

/*
  INTERFACES
*/

interface SearchSuggestionsProps {
  builder: QueryBuilder
  isSearchActive: boolean
  onHoveredInsightChanged: InsightChipHoverCallback
}

/*
  COMPONENTS
*/

export const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({
  isSearchActive,
  builder,
  onHoveredInsightChanged,
}) => {
  const { currentLegalEntity, doppelganger } = useSession<FrontendSession>()
  // State machine which handles the coordinated display of the suggestions area
  const [currentState, sendEvent] = useMachine(suggestionsStateMachine)
  const searchTermsViewKey = useViewVersion()

  const { insights, insightSuggestions } = useSuggestionInsights(
    isSearchActive,
    !currentLegalEntity?.hasDashboardAccess(doppelganger)
  )

  const onHoveredInsightChipChanged = React.useCallback(
    (chip: Chip | undefined) => {
      if (!chip) {
        onHoveredInsightChanged(undefined)
        return
      }

      const insight = insights[chip.index]
      if (!insight) {
        onHoveredInsightChanged(undefined)
        return
      }

      // Enrich the Chip with the Insight that created it
      const insightChip: InsightChip = {
        ...chip,
        insight,
      }
      onHoveredInsightChanged(insightChip)
    },
    [insights, onHoveredInsightChanged]
  )

  const query = builder.build()
  const queryText = query.text.trim()
  React.useEffect(() => {
    sendEvent({ type: "QUERY_CHANGED", query: queryText })
  }, [queryText, sendEvent])

  const { terms, loading: termsLoading } = useTerms(query, searchTermsViewKey)

  React.useEffect(() => {
    if (!termsLoading) {
      sendEvent({ type: "TERMS_CHANGED", terms })
    }
  }, [sendEvent, terms, termsLoading])

  const hideTerms = currentState.hasTag(TERMS_HIDDEN_TAG)
  // term suggestions to be shown are managed by the state machine, and
  // are forced to an empty array while in the dismissTerms state.
  const termsSuggestions = currentState.matches("dismissTerms") ? [] : currentState.context.terms

  return (
    <SuggestionsContainer>
      <SuggestionChips
        labelText="Did you mean?"
        hide={hideTerms}
        suggestions={termsSuggestions || []}
        source="autocomplete"
      />
      <SuggestionChips
        labelText="Suggested"
        hide={isSearchActive}
        suggestions={insightSuggestions}
        onHoveredChipChanged={onHoveredInsightChipChanged}
        source="suggested"
      />
    </SuggestionsContainer>
  )
}
