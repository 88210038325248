import * as React from "react"
import { type EntityParty, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { RowContent } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import styled from "styled-components"
import { PartyRoleSummary } from "src/frontend/components/OS/Applications/Search/SearchResults/PartyRoleSummary"
import { usePartyMostActiveSummary } from "src/frontend/components/OS/Applications/Search/SearchResults/usePartyMostActiveSummary"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { type SearchResultType } from "src/frontend/types/SearchResultType"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import MatchedText from "./MatchedText"
import { ResultDescription, ResultRow, ResultTitle, type RowProps } from "./ResultRow"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
  align-items: center;
`

const PartyDescription = styled(RowContent)`
  flex: 1;
  margin-right: 5px;
`

/*
  INTERFACE
*/

interface Props extends RowProps<EntityParty> {
  type: SearchResultType
}

/*
  COMPONENT
*/

const PartyResult: React.FC<Props> = ({ entity, hit }) => {
  const origin = React.useMemo(() => dateTimeHelper.todayIntervalOrigin(Interval.Month, 12), [])
  const summaryData = usePartyMostActiveSummary(entity, origin)

  // Use the most active role for the URL if available, otherwise fall back to first role
  const role = summaryData?.role || entity.roles?.[0]
  if (!role) {
    return null
  }

  const urlRole = role && FrontendPartyRole.roleURLKey(role)
  if (!urlRole) {
    return null
  }

  const path = routes.partyDetails.generateFromCurrentPath({
    partyId: entity.id,
    partyRole: urlRole,
    ...origin,
  })

  return (
    <ResultRow to={path}>
      <Details>
        <PartyIcon party={entity} css="margin-right: 10px;" disableHoverBorder />
        <PartyDescription>
          <ResultTitle>
            <MatchedText fieldName="name" highlights={hit.highlights}>
              {entity.name}
            </MatchedText>
          </ResultTitle>
          <ResultDescription>
            <MatchedText fieldName="short_description" highlights={hit.highlights}>
              {entity.shortDescription || ""}
            </MatchedText>
          </ResultDescription>
        </PartyDescription>
        <PartyRoleSummary summaryData={summaryData} />
      </Details>
    </ResultRow>
  )
}

export default PartyResult
