import * as React from "react"
import {
  type BalanceSummary,
  type BalanceSummary as GqlBalanceSummary,
  DirectionFromOrigin,
  type EntityCategory,
  type IntervalOrigin,
  type PartyRole,
  ProductArea,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useSummarizeBalances(periodBalances: BalanceSummary[], loading: boolean) {
  const highlight = useHighlight()
  const intervalOrigin = useIntervalOrigin()

  return React.useMemo(() => {
    const periodStartSummary = periodBalances.length ? periodBalances[0] : undefined
    const periodStartValue = periodStartSummary?.total.moneyFlow
      ? periodStartSummary.total.moneyFlow
      : moneyFlowHelper.buildZeroMoneyFlow()

    const lastIndex = highlight ?? periodBalances.length - 1
    const endingBalance = periodBalances[lastIndex]
    const endingValue = endingBalance?.total.moneyFlow
      ? endingBalance.total.moneyFlow
      : moneyFlowHelper.buildZeroMoneyFlow()

    const balanceDeltaPercent = deltaHelper.deltaPercentageForMoneyFlow(
      endingValue,
      endingValue.value.amount - periodStartValue.value.amount
    )

    const balanceDeltaDirection = deltaHelper.deltaDirectionForValue({
      delta: balanceDeltaPercent,
      moneyFlow: endingValue,
    })

    const balanceDeltaColor = deltaHelper.deltaValueColor({
      delta: balanceDeltaPercent,
      moneyFlow: endingValue,
    })

    const balanceDeltaText = balanceDeltaPercent
      ? numberHelper.formatPercentage(Math.abs(balanceDeltaPercent))
      : null

    const balanceDeltaSince = periodStartSummary
      ? dateTimeHelper.displayNameFromUnixTimestamp(
          periodStartSummary.period.endedAt,
          intervalOrigin.interval,
          DateFormat.Default
        )
      : "Period Start"

    return {
      balanceDeltaText,
      balanceDeltaSince,
      balanceDeltaDirection,
      balanceDeltaColor,
      endingValue,
      periodBalances,
      loading,
    }
  }, [periodBalances, highlight, intervalOrigin.interval, loading])
}

export function useSummarizePartyAndRoleBalances(
  partyId: string,
  partyRole: PartyRole,
  origin?: IntervalOrigin
) {
  const intervalOrigin = useIntervalOrigin()
  const viewKey = useViewVersion()

  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: origin ?? intervalOrigin,
      filter: {
        viewKey,
        partyId,
        partyRole,
      },
      direction: DirectionFromOrigin.Past,
      asPermanent: true,
    },
    context: { avsBatch: true },
  })

  const periodBalances = React.useMemo(
    () =>
      (data?.dimensionalTransactionSummary?.time || [])
        .map(
          // TODO this is the right shape for this to work out, but this is a temporary approach
          (timeSlice) => timeSlice.summary as GqlBalanceSummary
        )
        .sort((a, b) => a.period.endedAt - b.period.endedAt),
    [data?.dimensionalTransactionSummary?.time]
  )

  return useSummarizeBalances(periodBalances, loading)
}

export function useSummarizeCategoryBalances(category: EntityCategory, origin?: IntervalOrigin) {
  const intervalOrigin = useIntervalOrigin()
  const viewKey = useViewVersion()

  // TODO: does not support ugly other (from category details context)
  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: origin ?? intervalOrigin,
      filter: {
        viewKey,
        categoryId: category.id,
        productArea: ProductArea.None,
      },
      direction: DirectionFromOrigin.Past,
      asPermanent: true,
    },
    context: { avsBatch: true },
  })

  const periodBalances = React.useMemo(
    () =>
      (data?.dimensionalTransactionSummary?.time || [])
        .map(
          // TODO this is the right shape for this to work out, but this is a temporary approach
          (timeSlice) => timeSlice.summary as GqlBalanceSummary
        )
        .sort((a, b) => a.period.endedAt - b.period.endedAt),
    [data?.dimensionalTransactionSummary?.time]
  )

  return useSummarizeBalances(periodBalances, loading)
}
