import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { CategoryAmount } from "src/frontend/components/OS/Applications/Accounting/TransactionDashboard/TransactionUI"

/*
  SEARCH RESULT SUMMARY
*/

export const ChartContainer = styled.div`
  margin-right: 20px;
  width: 100px;
  height: 30px;
  transition: opacity 200ms ease;
  opacity: 0.6;
  a:hover & {
    opacity: 1;
  }
`

export const AmountAndCount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 10px;
`

export const ResultAmount = styled(CategoryAmount)`
  ${fonts.scale.body};
  font-weight: ${fonts.weight.heavy};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
